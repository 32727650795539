import { useMutation } from '@tanstack/react-query';
import { authenticate } from '@repository/authenticateRepository';
import { storeAccessToken } from '@repository/accessTokenRepository';
import { toast } from 'react-toastify';
import useStartPage from '@components/features/startPageSwitch/hooks/useStartPage';
import { useTranslation } from 'react-i18next';
import { Authenticate } from '@models/Authenticate';

const useAuthenticate = () => {
    const { startPage } = useStartPage();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: (data: Authenticate) => authenticate(data),
        onSuccess: (response: any) => {
            storeAccessToken('The user logged in', response.token);
            startPage?.navigate();
        },
        onError: (error) => {
            if (error.response?.status === 401) {
                toast.error(t('login.noPermission.toastError', { ns: 'authentication' }));
                return;
            }
            if (error.code === 'ERR_NETWORK') {
                toast.error(t('login.networkError.toastError', { ns: 'authentication' }));
                return;
            }
            toast.error(t('error'));
        },
    });
};

export default useAuthenticate;
