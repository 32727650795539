import { executeDeleteRequest, executeGetRequest, executePostRequest, executePutRequest } from '@api/client';
import {
    createAddOcrCodingTrainingPath,
    createAddOcrTrainingFieldPath,
    createApplyOcrTrainingPath, createDeleteOcrCodingTrainingPath,
    createDeleteOcrTrainingFieldPath, createDeleteOcrTrainingSqlPath, createFetchOcrCodingTrainingPath,
    createFetchOcrTrainingFieldPath, createFetchOcrTrainingLogPath,
    createFetchOcrTrainingPath,
    createUpdateOcrTrainingFieldPath, createUpdateOcrTrainingSqlPath,
} from '@routing/apiUrls';
import {
    ApplyOcrTrainingResponse,
    CreateOcrTrainingFieldResponse,
    DeleteOcrTrainingFieldResponse, DeleteOcrTrainingSqlResponse, OcrCodingTrainingMutation,
    OcrTraining,
    OcrTrainingField,
    OcrTrainingFieldForm, OcrTrainingSqlForm,
    UpdateOcrTrainingFieldResponse, UpdateOcrTrainingSqlResponse,
} from '@models/OcrTraining';
import { OcrTrainingLog } from '@models/OcrTrainingLog';

export const fetchOcrTraining = async (documentId: number, signal?: AbortSignal): Promise<OcrTraining> => {
    const result = await executeGetRequest<OcrTraining>(createFetchOcrTrainingPath(documentId), signal);
    return result.data;
};

export const fetchOcrTrainingField = async (documentId: number, fieldId: string, signal?: AbortSignal): Promise<OcrTrainingField> => {
    const result = await executeGetRequest<OcrTrainingField>(createFetchOcrTrainingFieldPath(documentId, fieldId), signal);
    return result.data;
};

export const addOcrTrainingField = async (documentId: number, data: OcrTrainingFieldForm): Promise<CreateOcrTrainingFieldResponse> => {
    const result = await executePostRequest<CreateOcrTrainingFieldResponse>(createAddOcrTrainingFieldPath(documentId), data);
    return result.data;
};

export const updateOcrTrainingField = async (documentId: number, fieldId: string, data: OcrTrainingFieldForm): Promise<UpdateOcrTrainingFieldResponse> => {
    const result = await executePutRequest<UpdateOcrTrainingFieldResponse>(createUpdateOcrTrainingFieldPath(documentId, fieldId), data);
    return result.data;
};

export const deleteOcrTrainingField = async (documentId: number, fieldId: string): Promise<DeleteOcrTrainingFieldResponse> => {
    const result = await executeDeleteRequest<DeleteOcrTrainingFieldResponse>(createDeleteOcrTrainingFieldPath(documentId, fieldId));
    return result.data;
};

export const updateOcrTrainingSql = async (documentId: number, data: OcrTrainingSqlForm): Promise<UpdateOcrTrainingSqlResponse> => {
    const result = await executePutRequest<UpdateOcrTrainingSqlResponse>(createUpdateOcrTrainingSqlPath(documentId), data);
    return result.data;
};

export const deleteOcrTrainingSql = async (documentId: number): Promise<DeleteOcrTrainingSqlResponse> => {
    const result = await executeDeleteRequest<DeleteOcrTrainingSqlResponse>(createDeleteOcrTrainingSqlPath(documentId));
    return result.data;
};

export const applyOcrTraining = async (documentId: number): Promise<ApplyOcrTrainingResponse> => {
    const result = await executePostRequest<ApplyOcrTrainingResponse>(createApplyOcrTrainingPath(documentId));
    return result.data;
};

export const fetchOcrTrainingLog = async (documentId: number, signal?: AbortSignal): Promise<OcrTrainingLog> => {
    const result = await executeGetRequest<OcrTrainingLog>(createFetchOcrTrainingLogPath(documentId), signal);
    return result.data;
};

export const fetchOcrCodingTraining = async (documentId: number, signal?: AbortSignal): Promise<OcrTrainingLog> => {
    const result = await executeGetRequest<OcrTrainingLog>(createFetchOcrCodingTrainingPath(documentId), signal);
    return result.data;
};

export const addOcrCodingTraining = async (documentId: number, data: OcrCodingTrainingMutation): Promise<CreateOcrTrainingFieldResponse> => {
    const result = await executePostRequest<CreateOcrTrainingFieldResponse>(createAddOcrCodingTrainingPath(documentId), data);
    return result.data;
};

export const deleteOcrCodingTraining = async (documentId: number): Promise<DeleteOcrTrainingFieldResponse> => {
    const result = await executeDeleteRequest<DeleteOcrTrainingFieldResponse>(createDeleteOcrCodingTrainingPath(documentId));
    return result.data;
};
