import React, { FC } from 'react';
import PlusIcon from '@images/svg/plus.svg?react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface Props {
    addingLine: boolean;
    deletingLine: boolean;
    isReadOnlyMode: boolean;
    onAddLine: () => void;
}

const AddCodingLineButton: FC<Props> = ({ addingLine, onAddLine, deletingLine, isReadOnlyMode }) => {
    const { t } = useTranslation();

    return (
        <button
            type="button"
            className={classNames('buttonStrip__button', {
                'buttonStrip__button--loading': addingLine,
            })}
            onClick={onAddLine}
            title={t('add.button', { ns: 'coding' })}
            disabled={isReadOnlyMode || addingLine || deletingLine}
        >
            <PlusIcon />
        </button>
    );
};

export default AddCodingLineButton;
