import { FC } from 'react';
import { Sizes } from '@utils/sizes';
import Modal from '@components/primitives/modal/Modal';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { FileHandlerAdministrationsModalData } from '@js/store/modals/fileHandlerAdministrationsModalAtom';
import { ewsSelectFolderModalAtom, EwsSelectFolderModalData } from '@js/store/modals/ewsSelectFolderModalAtom';
import EwsSelectFolderModalInner from './EwsSelectFolderModalInner';

const EwsSelectFolderModal: FC = () => {
    const [modalState, setModalState] = useAtom(ewsSelectFolderModalAtom);
    const { t } = useTranslation();

    if (!modalState.data) {
        return null;
    }

    return (
        <Modal<EwsSelectFolderModalData>
            modalState={modalState}
            setModalState={setModalState}
            size={Sizes.lg}
            title={modalState.data.title}
        >
            <EwsSelectFolderModalInner
                modalData={modalState.data}
            />
        </Modal>
    );
};

export default EwsSelectFolderModal;
