import { BsArrowReturnRight } from "react-icons/bs"

interface SubfolderProps {
    subfolder: any;
    selectedFolder: any;
    onFolderClick: () => void;
}

const Subfolder = ({ subfolder, selectedFolder, onFolderClick }: SubfolderProps) => {
    return (
        <div
            tabIndex={0}
            role="button"
            onClick={onFolderClick}
            className={`p-4 flex flex-row items-center cursor-pointer ${selectedFolder?.path === subfolder.path ? 'bg-brand-secondary text-white font-bold' : 'hover:bg-white/50'}`}
        >
            <BsArrowReturnRight className="ml-4 mr-6 w-4 h-4 opacity-50" />
            {subfolder.name}
        </div>
    )
}

export default Subfolder;