import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { DynamicField, DynamicFieldValue } from '@models/DynamicField';
import StaticDocumentField from '@components/features/documentFields/StaticDocumentField';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { getCurrentLocale } from '@utils/locales';
import { toLocalDate } from '@utils/dateUtils';
import DocumentFieldFeedback from '@components/features/documentFields/DocumentFieldFeedback';
import CustomDateInput from '@components/features/documentFields/CustomDateInput';

interface Props {
    isEditable: boolean;
    initialValue: DynamicFieldValue;
    fieldDefinition: DynamicField;
    field: any;
    onFieldFocus?: () => void;
    onFieldBlur?: () => void;
    onFieldUpdated?: (id: string, value: string) => void;
    inputId: string;
}
const DocumentDateField: FC<Props> = ({
    isEditable,
    initialValue,
    field,
    fieldDefinition,
    onFieldFocus,
    onFieldBlur,
    onFieldUpdated,
    inputId,
}) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<string>(initialValue as string);
    const [cacheValue, setCacheValue] = useState<string | undefined>();
    const { isRequired, isReadOnly } = fieldDefinition;
    const currentLocale = getCurrentLocale();
    const dateString = toLocalDate(value as string);
    registerLocale(currentLocale.code, currentLocale.dateLocale);

    const onFocus = () => {
        setCacheValue(initialValue as string);

        if (onFieldFocus) {
            onFieldFocus();
        }
    };

    const updateData = (value: string) => {
        if (value === cacheValue || !onFieldUpdated) {
            return;
        }

        onFieldUpdated(field.id, value);
        setCacheValue(undefined);
    };

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = () => {
        if (onFieldBlur) {
            onFieldBlur();
        }
        updateData(value);
    };

    const handleChange = async (date: Date | null) => {
        const year = date?.getFullYear();
        const month = date?.getMonth() || 0;
        const day = date?.getDate();

        if (!year) {
            setValue('');
            updateData('');
            return;
        }

        const dateString = `${year}-${(month + 1).toString().padStart(2, '0')}-${day?.toString().padStart(2, '0')}`;
        setValue(`${dateString}T00:00:00`);
        updateData(dateString);
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue as string);
    }, [initialValue]);

    if (!isEditable) {
        return (
            <StaticDocumentField
                value={initialValue}
                fieldDefinition={fieldDefinition}
                field={field}
            />
        );
    }

    return (
        <div className="form__field form__field--date">
            <DatePicker
                className={classNames('form__date', {
                    'form__date--required': !value && isRequired && !isReadOnly,
                })}
                customInput={<CustomDateInput />}
                id={inputId}
                portalId="theme"
                selected={dateString ? new Date(dateString) : null}
                onChange={handleChange}
                onFocus={onFocus}
                onBlur={() => onBlur()}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                locale={currentLocale.code}
                dateFormat={currentLocale.dateFormat}
                preventOpenOnFocus
                readOnly={isReadOnly}
                todayButton={t('today')}
                autoComplete="off"
            />
            <DocumentFieldFeedback
                fieldDefinition={fieldDefinition}
                value={value}
                field={field}
            />
        </div>
    );
};

export default DocumentDateField;
