export enum StorageKeys {
    CurrentDocClass = 'CurrentDocClass',
    TenantId = 'TenantId',
    AccessToken = 'access_token',
    ApiBaseUrl = 'ApiBaseUrl',
    Language = 'language',
    RightPanelCollapsed = 'RightPanelCollapsed',
    NavigationCollapsed = 'NavigationCollapsed',
    WidthHeaderFields = 'WidthHeaderFields',
    WidthInvoiceLines = 'WidthInvoiceLines',
    HeightCodingMatching = 'HeightCodingMatching',
    HeightPreviewContent = 'HeightPreviewContent',
    WidthCopyCodingLinesPreview = 'WidthCopyCodingLinesPreview',
    ZoomLevelDocumentPage = 'ZoomLevelDocumentPage',
    ZoomLevelPreviewModal = 'ZoomLevelPreviewModal',
    ZoomLevelCopyCodingModal = 'ZoomLevelCopyCodingModal',
    ZoomLevelRepairDocumentModal = 'ZoomLevelRepairDocumentModal',
    GridDocList = 'GridDocList',
    GridCoding = 'GridCoding',
    GridCopyCodingList = 'GridCopyCodingList',
    GridCopyCodingLines = 'GridCopyCodingLines',
    GridMatchingInvoiceLines = 'GridMatchingInvoiceLines',
    GridMatchingOrderLines = 'GridMatchingOrderLines',
    GridExternalInfo = 'GridExternalInfo',
    GridCreditorValidation = 'GridCreditorValidation',
    GridSearch = 'GridSearch',
    GridOcrTrainingLog = 'GridOcrTrainingLog',
    GridDocumentAudit = 'GridDocumentAudit',
    SearchValues = 'SearchValues',
    SearchOperators = 'SearchOperators',
    StartPage = 'StartPage',
    FileHandlerStates = 'FileHandlerStates',
    FileHandlerSection = 'FileHandlerSection',
    FileHandlerSplitSizes = 'FileHandlerSplitSizes',
    FileHandlerFileNames = 'FileHandlerFileNames',
    FileHandlerRotations = 'FileHandlerRotations',
    FileHandlerPages = 'FileHandlerPages',
    FileHandlerNewRowCounter = 'FileHandlerNewRowCounter',
    FileHandlerCurrent = 'FileHandlerCurrent',
    FileHandlerOpenedRows = 'FileHandlerOpenedRows',
    FileHandlerThumbnailWidth = 'FileHandlerThumbnailWidth',
    FileHandlerThumbnailsPerRow = 'FileHandlerThumbnailsPerRow',
    FileHandlerMigratedToRefactor2 = 'FileHandlerMigratedToRefactor2',
    ShowCopyEmailAddress = 'ShowCopyEmailAddress',
    LoggingSettings = 'LoggingSettings',
    Theme = 'Theme',
    // Feature flags
    RedPencil = 'RedPencil',
    OcrTraining = 'OcrTraining',
    TemplateSharing = 'TheFeatureFlagWhichShallNotBeNamed',
    TemplateSharingManagement = 'TemplateSharingManagement',
    OcrCodingTrainingFields = 'OcrCodingTrainingFields',
}
