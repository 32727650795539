import { FC } from 'react';
import { NumericFormat } from 'react-number-format';
import { getCurrentLocale } from '@utils/locales';

interface Props {
    value: string | number | null;
    decimals?: number;
}

const DecimalCell: FC<Props> = ({ value, decimals }) => {
    const currentLocale = getCurrentLocale();

    return (
        <NumericFormat
            value={value}
            thousandSeparator={currentLocale.thousandSeparator}
            decimalSeparator={currentLocale.decimalSeparator}
            decimalScale={decimals || 2}
            fixedDecimalScale
            displayType="text"
        />
    );
};

export default DecimalCell;
