import { createPathFromRoute } from '@utils/routeGenerator';
import { createStorageKey, getLocalStorageItem } from '@utils/storage';
import { StorageKeys } from '@constants/storageKeys';

export const DEFAULT_DOC_CLASS = 'Facturen';
export const DEFAULT_LIST_TYPE = 'S';
export const SEARCH_LIST_TYPE = 'SEARCH';

const storedDocClass = getLocalStorageItem(createStorageKey([StorageKeys.CurrentDocClass]));
const currentDocClass = storedDocClass || DEFAULT_DOC_CLASS;

export enum PagePath {
    dashboard = '/',
    settings = '/settings',
    settingsImportProfiles = '/settings/import-profiles',
    settingsImportProfile = '/settings/import-profiles/:importProfileId',
    search = '/search/:docClass?',
    list = '/list/:docClass?/:listType?',
    firstDocument = '/document',
    document = '/document/:documentId',
    allDocumentsProcessed = '/all-documents-processed',
    support = '/support',
    supportVideos = '/support/videos',
    supportTechnicalInformation = '/support/technical-information',
    releaseNotes = '/support/release-notes',
    introduction = '/support/introduction',
    faq = '/support/faq',
    contact = '/support/contact',
    fileHandler = '/fileHandler',

    // Authentication
    login = '/login',
    ewsCallback = '/auth/ews/callback',
    ewsSelectFolders = '/auth/ews/select-folders',
}

export function createSearchPath(docClass: string): string {
    return createPathFromRoute(PagePath.search, {
        ':docClass?': docClass || currentDocClass,
    });
}

export function createListPath(docClass?: string, listType?: string): string {
    return createPathFromRoute(PagePath.list, {
        ':docClass?': docClass || currentDocClass,
        ':listType?': listType !== undefined ? listType : DEFAULT_LIST_TYPE,
    });
}

export function createDocumentPath(id?: number, editMode?: boolean): string {
    const url = createPathFromRoute(PagePath.document, {
        ':documentId': id ? id.toString() : '',
    });
    return `${url}${editMode ? '?edit=true' : ''}`;
}

export function createImportProfilePath(id?: number): string {
    const url = createPathFromRoute(PagePath.settingsImportProfile, {
        ':importProfileId': id ? id.toString() : '',
    });
    return `${url}`;
}
