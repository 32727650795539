import { FC, ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
    children: ReactNode;
    hasCover?: boolean;
}

const Main: FC<Props> = ({ children, hasCover }) => {
    const classes = classNames('main', 'loginPage', {
        'main--cover': hasCover,
    });

    return (
        <main className={classes}>
            {hasCover ? (
                <div className="main__pattern" />
            ) : null}
            {children}
        </main>
    );
};

export default Main;
