import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { User } from '@models/User';
import { fetchCurrentUser } from '@repository/userRepository';
import { minutesToMillis } from '@utils/millis';
import { useIsAuthenticated } from '@azure/msal-react';
import useToken from '@hooks/useToken';

const useFetchCurrentUser = (): UseQueryResult<User> => {
    const isAuthenticated = useIsAuthenticated();
    const { token } = useToken();

    return useQuery({
        queryKey: [QueryKey.Profile],
        queryFn: ({ signal }) => fetchCurrentUser(signal),
        enabled: isAuthenticated || !!token,
        staleTime: minutesToMillis(30),
        retry: (failureCount: number, error: AxiosError) => {
            if (error?.response?.status === 401) return false;
            return failureCount <= 3;
        },
    });
};

export default useFetchCurrentUser;
