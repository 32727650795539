import { FC } from 'react';
import classNames from 'classnames';
import LinkIcon from '@images/svg/link.svg?react';
import useLinkMatchingLine from '@components/features/matching/hooks/useLinkMatchingLine';
import { useTranslation } from 'react-i18next';
import useLinkedLineInfo from '@components/features/matching/hooks/useLinkedLineInfo';
import useRefreshDocumentMatching from '@components/features/matching/hooks/useRefreshDocumentMatching';
import { useSetAtom } from 'jotai';
import useInvoiceLinesCaret from '@hooks/caret/useInvoiceLinesCaret';
import useOrderLinesCaret from '@hooks/caret/useOrderLinesCaret';
import { confirmModalAtom } from '@js/store/modals/confirmModalAtom';

interface Props {
    documentId: number;
    filteredOrderLines: boolean;
    isReadOnly: boolean;
}

const LinkButton: FC<Props> = ({ documentId, filteredOrderLines, isReadOnly }) => {
    const { t } = useTranslation();
    const { invoiceLinesCaret } = useInvoiceLinesCaret();
    const { orderLinesCaret } = useOrderLinesCaret();
    const { mutate: refreshDocumentMatching } = useRefreshDocumentMatching(documentId);
    const { mutate: linkMatchingLine, isPending: isLinkingMatchingLine } = useLinkMatchingLine(documentId);
    const {
        currentInvoiceLineLinked,
        currentOrderLineLinked,
        orderLineSelected,
        linesAreLinked,
    } = useLinkedLineInfo(documentId, filteredOrderLines);
    const setConfirmModal = useSetAtom(confirmModalAtom);
    const linkButtonClasses = classNames('buttonStrip__button', {
        'buttonStrip__button--loading': isLinkingMatchingLine,
    });

    const confirmIfNeeded = (confirmNeeded: boolean, isInvoiceLine: boolean, onConfirmed: () => void) => {
        if (!confirmNeeded) {
            onConfirmed();
            return;
        }
        setConfirmModal({
            isOpen: true,
            data: {
                title: isInvoiceLine ? t('invoiceLineAlreadyLinked.confirm.title', { ns: 'matching' }) : t('orderLineAlreadyLinked.confirm.title', { ns: 'matching' }),
                onConfirm: onConfirmed,
            },
        });
    };

    const onLink = async () => {
        if (!orderLineSelected || orderLinesCaret?.lineId === undefined || invoiceLinesCaret?.lineId === undefined) {
            return;
        }

        confirmIfNeeded(currentInvoiceLineLinked, true, () => {
            confirmIfNeeded(currentOrderLineLinked, false, async () => {
                await linkMatchingLine({
                    rowid: invoiceLinesCaret?.lineId,
                    matchKey: orderLinesCaret?.lineId,
                });
                await refreshDocumentMatching();
            });
        });
    };

    return (
        <button
            type="button"
            title={t('actions.link', { ns: 'matching' })}
            className={linkButtonClasses}
            onClick={onLink}
            disabled={
                isLinkingMatchingLine
                || !orderLineSelected
                || invoiceLinesCaret?.lineId === undefined
                || linesAreLinked
                || currentOrderLineLinked
                || currentInvoiceLineLinked
                || isReadOnly
            }
        >
            <LinkIcon className="md" />
        </button>
    );
};

export default LinkButton;
