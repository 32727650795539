import { DocumentOcrWord } from '@models/DocumentOcrData';
import useDocumentCoding from '@components/features/coding/hooks/useDocumentCoding';
import log, { LogContext } from '@utils/logging';
import { useTranslation } from 'react-i18next';
import useUpdateCodingLine from '@components/features/coding/hooks/useUpdateCodingLine';
import useLookupParams from '@components/features/lookup/hooks/useLookupParams';
import useGetLookupOptionsFromCache from '@components/features/lookup/hooks/useGetLookupOptionsFromCache';
import useOcrUtils from '@components/features/ocr/hooks/useOcrUtils';
import { useQueryClient } from '@tanstack/react-query';
import { DocumentCoding, UpdateCodingField } from '@models/DocumentCoding';
import { LookupListItem } from '@models/Lookup';
import { QueryKey } from '@constants/queryKey';
import { toast } from 'react-toastify';
import { DynamicFieldValue } from '@models/DynamicField';
import { OcrTraining } from '@models/OcrTraining';
import useUpdateCodingLines, { UpdateCodingLineMutation } from '@components/features/coding/hooks/useUpdateCodingLines';

const useCodingFieldUpdater = (documentId: number) => {
    const { mutate: updateCodingLine } = useUpdateCodingLine(documentId);
    const { mutate: updateCodingLines } = useUpdateCodingLines(documentId);
    const { documentCoding, getCodingLineFieldDefinition } = useDocumentCoding(documentId);
    const { getLookupParams } = useLookupParams(documentId);
    const { getLookupOptionsFromCache } = useGetLookupOptionsFromCache();
    const { t } = useTranslation();
    const { formatOcrValue, ocrToLookupValue } = useOcrUtils(documentId);
    const client = useQueryClient();
    const queryCache = client.getQueryCache();

    const getDocumentCodingFromCache = (): DocumentCoding | undefined => {
        const coding = queryCache.find<DocumentCoding>({
            queryKey: [QueryKey.DocumentCoding, documentId],
        });
        return coding?.state?.data;
    };

    const getOcrTrainingFromCache = (): OcrTraining | undefined => {
        const ocrTraining = queryCache.find<OcrTraining>({
            queryKey: [QueryKey.OcrTraining, documentId],
        });
        return ocrTraining?.state?.data;
    };

    const getSelectedLookupOption = (fieldId: string, value: DynamicFieldValue) => {
        const fieldDefinition = getCodingLineFieldDefinition(fieldId);

        if (!fieldDefinition || fieldDefinition.format !== 'lookup') {
            return undefined;
        }

        const lookupParams = getLookupParams(fieldDefinition.lookup);
        const lookupData = getLookupOptionsFromCache(fieldDefinition.lookup, lookupParams);

        if (!lookupData) {
            return undefined;
        }

        return lookupData.list?.find((option) => option.key === value);
    };

    const convertLookupOptionToValuesToSave = (lineId: number, fieldId: string, option: LookupListItem, overwrite: boolean) => {
        const codingFromCache = getDocumentCodingFromCache();
        const fieldDefinition = getCodingLineFieldDefinition(fieldId);
        const row = codingFromCache?.rows.find((row) => row.values.find((field) => field.id === 'row' && field.value === lineId));
        const result: UpdateCodingField[] = [];

        if (!row || !fieldDefinition || !Object.entries(option).length) {
            return result;
        }

        const lookupParams = getLookupParams(fieldDefinition.lookup);
        const lookupData = getLookupOptionsFromCache(fieldDefinition.lookup, lookupParams);

        for (const [id, value] of Object.entries(option)) {
            const targetFieldDefinition = codingFromCache?.definitions.find((field) => field.id === id);
            const targetField = row.values.find((field) => field.id === id);

            // Make sure the field exists in the coding line
            if (targetField && targetFieldDefinition) {
                // Check the scheme of this entry and make sure this entry has useValue enabled and is not hidden
                const lookupScheme = lookupData?.scheme.find((item) => item.columnId === id);
                if (lookupScheme?.useValue && (value !== null && value !== undefined)) {
                    // Make sure the value is different from the current value
                    if (targetField.value !== value) {
                        // Overwrite the value if it's not set or if the overwrite flag is set
                        if (overwrite || !targetField.value) {
                            result.push({
                                id,
                                value: value as string | number | null,
                            });
                        }
                    }
                }
            }
        }
        return result;
    };

    const convertFieldIdsToRowsToSave = (fieldIds: string[]): UpdateCodingLineMutation[] => {
        const codingFromCache = getDocumentCodingFromCache();

        const rows: UpdateCodingLineMutation[] = [];

        codingFromCache?.rows.forEach((row) => {
            const values: UpdateCodingField[] = [];
            const lineIdField = row.values.find((field) => field.id === 'row');
            const lineId = lineIdField?.value;

            if (typeof lineId !== 'number') {
                return;
            }

            const changedLookupFields = row.values.filter((field) => {
                const fieldDefinition = getCodingLineFieldDefinition(field.id);
                return fieldDefinition?.format === 'lookup' && field.value && fieldIds.includes(field.id);
            });

            changedLookupFields.forEach((field) => {
                const fieldDefinition = codingFromCache?.definitions.find((codingField) => codingField.id === field.id);

                if (!fieldDefinition) {
                    return;
                }

                const selectedOption = getSelectedLookupOption(field.id, field.value);

                if (!selectedOption) {
                    return;
                }

                const dependentValues = convertLookupOptionToValuesToSave(lineId, field.id, selectedOption, true);

                dependentValues.forEach((val) => {
                    values.push(val);
                });
            });

            if (values.length) {
                rows.push({
                    rowId: lineId,
                    values,
                });
            }
        });

        return rows;
    };

    const purifyValues = (values: UpdateCodingField[]): UpdateCodingField[] => {
        return values.map((val) => {
            const fieldDefinition = getCodingLineFieldDefinition(val.id);

            if (!fieldDefinition) {
                return val;
            }

            // Truncate string to maxLength
            if (fieldDefinition.maxLength && val.value && typeof val.value === 'string' && (val.value.length > fieldDefinition.maxLength)) {
                toast.warn(t('validation.ocr.tooLarge', { maxLength: fieldDefinition.maxLength }));
                return {
                    ...val,
                    value: val.value.substring(0, fieldDefinition.maxLength),
                };
            }

            return val;
        });
    };

    const onCodingFieldInit = (lineId: number, fieldId: string, value: DynamicFieldValue) => {
        const fieldDefinition = getCodingLineFieldDefinition(fieldId);

        if (!fieldDefinition || documentCoding?.meta?.readOnly) {
            return;
        }

        const selectedOption = getSelectedLookupOption(fieldId, value);

        const values: UpdateCodingField[] = [
            ...(selectedOption ? convertLookupOptionToValuesToSave(lineId, fieldId, selectedOption, false) : []),
        ];

        if (!values.length) {
            return;
        }

        let logTitle = `Update field${values.length > 1 ? 's ' : ' '}`;
        values.forEach((val, index) => {
            logTitle += `${index !== 0 ? ' + ' : ''}"${val.id}"`;
        });

        log({
            context: LogContext.Coding,
            title: logTitle,
            reason: `On coding field init (${fieldId})`,
            data: { values },
        });

        updateCodingLine({
            data: {
                values: purifyValues(values),
            },
            codingLineId: lineId,
        });
    };

    const onCodingFieldChange = (lineId: number, fieldId: string, value: DynamicFieldValue) => {
        const fieldDefinition = getCodingLineFieldDefinition(fieldId);

        if (!fieldDefinition || fieldDefinition?.isReadOnly || documentCoding?.meta?.readOnly) {
            return;
        }

        const selectedOption = getSelectedLookupOption(fieldId, value);

        const values: UpdateCodingField[] = [
            {
                id: fieldId,
                value,
            },
            ...(selectedOption ? convertLookupOptionToValuesToSave(lineId, fieldId, selectedOption, true) : []),
        ];

        log({
            context: LogContext.Coding,
            title: `Update field "${fieldId}"${lineId !== undefined ? ` (line ${lineId})` : ''}`,
            reason: 'On coding field change',
            data: { values },
        });

        updateCodingLine({
            data: {
                values: purifyValues(values),
            },
            codingLineId: lineId,
        });
    };

    const getOcrValue = (fieldId: string, value: string): string => {
        const fieldDefinition = getCodingLineFieldDefinition(fieldId);

        if (!fieldDefinition) {
            return value;
        }

        if (fieldDefinition.format === 'lookup') {
            const selectedOption = ocrToLookupValue(value, fieldDefinition.lookup);

            if (!selectedOption?.key) {
                return value;
            }

            return selectedOption?.key;
        }

        return formatOcrValue(value, fieldDefinition.format);
    };

    const onCodingFieldOcr = (lineId: number, fieldId: string, ocr: DocumentOcrWord) => {
        const fieldDefinition = getCodingLineFieldDefinition(fieldId);

        if (!fieldDefinition || fieldDefinition?.isReadOnly || documentCoding?.meta.readOnly) {
            return;
        }

        const values: UpdateCodingField[] = [
            {
                id: fieldId,
                value: getOcrValue(fieldId, ocr.text),
                zone: ocr.zone,
            },
        ];

        if (fieldDefinition.format === 'lookup') {
            const selectedOption = ocrToLookupValue(ocr.text, fieldDefinition.lookup, lineId);

            if (!selectedOption) {
                return;
            }

            const dependentValues = convertLookupOptionToValuesToSave(lineId, fieldId, selectedOption, true);

            dependentValues.forEach((val) => {
                values.push(val);
            });
        }

        log({
            context: LogContext.Coding,
            title: `Update field "${fieldId}" (line ${lineId})`,
            reason: 'On OCR clicked',
            data: { values },
        });

        updateCodingLine({
            data: {
                values: purifyValues(values),
            },
            codingLineId: lineId,
        });
    };

    const onCodingFieldApplyTraining = () => {
        const ocrTraining = getOcrTrainingFromCache();

        if (!ocrTraining) {
            return;
        }

        const fieldIds: string[] = ocrTraining.fields.filter((field) => {
            return field.fieldId.indexOf('lineitem_') === 0;
        }).map((field) => {
            const regex = /lineitem_(.*)_(\d+)/;
            const match = field.fieldId.match(regex);
            return match ? match[1] : '';
        });

        if (!fieldIds?.length) {
            return;
        }

        const rows = convertFieldIdsToRowsToSave(fieldIds);

        if (!rows.length) {
            return;
        }

        log({
            context: LogContext.Coding,
            title: 'Update dependent coding fields',
            reason: 'On apply OCR training',
            data: {
                rows,
            },
        });

        updateCodingLines(rows);
    };

    const onDeterminativeFieldChange = (fieldIds: string[]) => {
        const rows = convertFieldIdsToRowsToSave(fieldIds);

        if (!rows.length) {
            return;
        }

        log({
            context: LogContext.Coding,
            title: 'Update dependent coding fields',
            reason: 'On determinative field change',
            data: {
                rows,
            },
        });

        updateCodingLines(rows);
    };

    return {
        onCodingFieldInit,
        onCodingFieldChange,
        onCodingFieldOcr,
        onCodingFieldApplyTraining,
        onDeterminativeFieldChange,
    };
};

export default useCodingFieldUpdater;
