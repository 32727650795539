import { StorageKeys } from '@constants/storageKeys';
import { atom } from 'jotai';
import { createStorageKey } from '@utils/storage';
import { stringAtomWithLocalStorage } from '@utils/atomWithLocalStorage';

export const currentDocClassAtom = stringAtomWithLocalStorage(
    createStorageKey([StorageKeys.CurrentDocClass]),
    '',
);
export const disableUserSelectionAtom = atom<boolean>(false);

export const firstDocumentAtom = atom<number | null>(null);
