import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { deleteDocumentCodingLine } from '@repository/documentCodingRepository';

const useDeleteCodingLine = (documentId: number) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: (codingLineIds: number[]) => deleteDocumentCodingLine(documentId, codingLineIds),
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [QueryKey.DocumentCoding, documentId],
            });
        },
    });
};

export default useDeleteCodingLine;
