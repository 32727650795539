import { FC } from 'react';
import { themeAtom, Themes } from '@js/store/settingsAtoms';
import Select from 'react-select';
import CustomReactSelectInput from '@components/primitives/form/components/CustomReactSelectInput';
import CustomReactSelectNoOptions from '@components/primitives/form/components/CustomReactSelectNoOptions';
import useTheme from '@components/features/themeSwitch/hooks/useTheme';
import { useAtom } from 'jotai';

interface Option {
    label: string;
    value: string;
}

const ThemeSwitch: FC = () => {
    const [theme, setTheme] = useAtom(themeAtom);
    const { themes } = useTheme();

    const handleChange = (option: Option | null) => {
        setTheme(option?.value as Themes || Themes.Light);
    };

    return (
        <div className="form__field">
            <Select
                components={{
                    Input: CustomReactSelectInput,
                }}
                inputId="themeSwitch"
                className="form__select"
                classNamePrefix="form__select"
                onChange={handleChange}
                value={themes.find((t) => t.value === theme)}
                options={themes}
                noOptionsMessage={() => <CustomReactSelectNoOptions />}
            />
        </div>
    );
};

export default ThemeSwitch;
