import React, { FC } from 'react';
import { Sizes } from '@utils/sizes';
import EmailDocumentModalInner from '@components/modals/emailDocumentModal/EmailDocumentModalInner';
import { useTranslation } from 'react-i18next';
import Modal from '@components/primitives/modal/Modal';
import { useAtom } from 'jotai';
import { emailDocumentModalAtom, EmailDocumentModalData } from '@js/store/modals/emailDocumentModalAtom';

const EmailDocumentModal: FC = () => {
    const [modalState, setModalState] = useAtom(emailDocumentModalAtom);
    const { t } = useTranslation();

    if (!modalState.data) {
        return null;
    }

    return (
        <Modal<EmailDocumentModalData>
            modalState={modalState}
            setModalState={setModalState}
            size={Sizes.lg}
            title={t('email.title', { ns: 'documentActions' })}
        >
            <EmailDocumentModalInner
                modalData={modalState.data}
            />
        </Modal>
    );
};

export default EmailDocumentModal;
