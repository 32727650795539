import { executeDeleteRequest, executeGetRequest, executePostRequest } from '@api/client';
import {
    createApproveMatchingDifferencePath, createCopyDocumentMatchingOrderLinePath,
    createLinkDocumentMatchingLinePath,
    createRefreshDocumentMatchingPath, createRejectMatchingDifferencePath, createUnlinkDocumentMatchingLinePath,
} from '@routing/apiUrls';
import {
    ApproveMatchingDifferenceForm,
    ApproveMatchingDifferenceResponse,
    CopyOrderLineForm,
    CopyOrderLineResponse,
    LinkMatchingLineForm,
    LinkMatchingLineResponse,
    RefreshMatchingResponse,
    RejectMatchingDifferenceForm,
    RejectMatchingDifferenceResponse,
    UnlinkMatchingLineResponse,
} from '@models/DocumentMatching';

// Invoice lines

export const refreshDocumentMatching = async (documentId: number, signal?: AbortSignal): Promise<RefreshMatchingResponse> => {
    const result = await executeGetRequest<RefreshMatchingResponse>(createRefreshDocumentMatchingPath(documentId), signal);
    return result.data;
};

export const linkDocumentMatchingLine = async (documentId: number, data: LinkMatchingLineForm): Promise<LinkMatchingLineResponse> => {
    const result = await executePostRequest<LinkMatchingLineResponse>(createLinkDocumentMatchingLinePath(documentId), data);
    return result.data;
};

export const unlinkDocumentMatchingLine = async (documentId: number, rowId: number): Promise<UnlinkMatchingLineResponse> => {
    const result = await executeDeleteRequest<UnlinkMatchingLineResponse>(createUnlinkDocumentMatchingLinePath(documentId, rowId));
    return result.data;
};

// Order lines

export const copyDocumentMatchingOrderLine = async (documentId: number, data: CopyOrderLineForm): Promise<CopyOrderLineResponse> => {
    const result = await executePostRequest<CopyOrderLineResponse>(createCopyDocumentMatchingOrderLinePath(documentId), data);
    return result.data;
};

// Approve Matching Difference
export const approveMatchingDifference = async (documentId: number, data: ApproveMatchingDifferenceForm): Promise<ApproveMatchingDifferenceResponse> => {
    const result = await executePostRequest<ApproveMatchingDifferenceResponse>(createApproveMatchingDifferencePath(documentId), data);
    return result.data;
};

// Reject Matching Difference
export const rejectMatchingDifference = async (documentId: number, data: RejectMatchingDifferenceForm): Promise<RejectMatchingDifferenceResponse> => {
    const result = await executePostRequest<RejectMatchingDifferenceResponse>(createRejectMatchingDifferencePath(documentId), data);
    return result.data;
};
