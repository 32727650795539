import { atom } from 'jotai';
import { closedModalState, ModalState } from '@js/store/modalAtoms';

export interface DocumentValidationModalData {
    nrOfErrors: number;
    invalidHeaderFields: string[];
    invalidCodingLines: string[];
    invalidCodingTotal: boolean;
}

export const documentValidationModalAtom = atom<ModalState<DocumentValidationModalData>>(closedModalState);
