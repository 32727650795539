import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { DynamicField, DynamicFieldValue } from '@models/DynamicField';
import StaticDocumentField from '@components/features/documentFields/StaticDocumentField';
import DocumentFieldFeedback from '@components/features/documentFields/DocumentFieldFeedback';

interface Props {
    isEditable: boolean;
    initialValue: DynamicFieldValue;
    fieldDefinition: DynamicField;
    field: any;
    onFieldFocus?: () => void;
    onFieldBlur?: () => void;
    onFieldUpdated?: (id: string, value: string) => void;
    onFieldChange?: (value: DynamicFieldValue) => void;
    inputId: string;
    showAsText?: boolean;
}
const DocumentTextField: FC<Props> = ({
    isEditable,
    initialValue,
    field,
    fieldDefinition,
    onFieldFocus,
    onFieldBlur,
    onFieldUpdated,
    onFieldChange,
    inputId,
    showAsText,
}) => {
    const [value, setValue] = useState<string>(initialValue as string);
    const [cacheValue, setCacheValue] = useState<string | undefined>();
    const { isRequired, isReadOnly } = fieldDefinition;

    const onFocus = () => {
        setCacheValue(initialValue as string);

        if (onFieldFocus) {
            onFieldFocus();
        }
    };

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = () => {
        if (onFieldBlur) {
            onFieldBlur();
        }

        if (value === cacheValue || !onFieldUpdated) {
            return;
        }

        onFieldUpdated(field.id, value);
        setCacheValue(undefined);
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue as string);
    }, [initialValue]);

    if (showAsText) {
        if (field?.display) {
            return (
                <>{field.display}</>
            );
        }

        return (
            <>{initialValue}</>
        );
    }

    if (!isEditable) {
        return (
            <StaticDocumentField
                value={initialValue}
                fieldDefinition={fieldDefinition}
                field={field}
            />
        );
    }

    return (
        <div
            className={classNames('form__field', 'form__field--input', {
                'form__field--hasDisplay': !!field?.display,
            })}
        >
            <input
                className={classNames('form__input', {
                    'form__input--required': !value && isRequired && !isReadOnly,
                })}
                data-lpignore="true"
                type="text"
                value={value || ''}
                onChange={(e) => {
                    setValue(e.target.value);

                    if (onFieldChange) {
                        onFieldChange(e.target.value);
                    }
                }}
                onBlur={onBlur}
                onFocus={onFocus}
                maxLength={fieldDefinition.maxLength}
                readOnly={fieldDefinition.isReadOnly}
                autoComplete="off"
                id={inputId}
            />
            {field?.display ? (
                <label className="form__field-display" htmlFor={inputId}>{field.display}</label>
            ) : null}
            <DocumentFieldFeedback
                fieldDefinition={fieldDefinition}
                value={value}
                field={field}
            />
        </div>
    );
};

export default DocumentTextField;
