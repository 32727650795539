import { atom } from 'jotai';
import { closedModalState, ModalState } from '@js/store/modalAtoms';
import { Sizes } from '@utils/sizes';

export interface TextModalData {
    title: string;
    text: string;
    size?: Sizes;
    isError?: boolean;
}

export const textModalAtom = atom<ModalState<TextModalData>>(closedModalState);
