import { useMutation } from '@tanstack/react-query';
import { duplicateImportProfile } from '@repository/importProfilesRepository';
import { ImportProfile } from '@models/ImportProfiles';

const useDuplicateImportProfile = () => {
    return useMutation({
        mutationFn: (importProfile: ImportProfile) => duplicateImportProfile(importProfile?.id, importProfile),
    });
};

export default useDuplicateImportProfile;
