import { FC, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import EyeClosedIcon from '@images/svg/eyeClosed.svg?react';
import EyeIcon from '@images/svg/eye.svg?react';
import classNames from 'classnames';
import { Column, RowData } from '@tanstack/react-table';

interface Props {
    column: Column<RowData, unknown>;
    index: number;
    moveListItem: (dragIndex: number, hoverIndex: number) => void;
    onToggleColumn: (id: string) => void;
    columnHidden: boolean;
    disableToggleButton: boolean;
}

const SortableColumn: FC<Props> = ({ column, index, moveListItem, onToggleColumn, columnHidden, disableToggleButton }) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ isDragging }, dragRef] = useDrag({
        type: 'item',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, dropRef] = useDrop({
        accept: 'item',
        hover: (item: any, monitor: any) => {
            const dragIndex = item.index;
            const hoverIndex = index;
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            if (!monitor?.getClientOffset()?.y || !hoverBoundingRect) {
                return;
            }
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            const hoverActualY = monitor?.getClientOffset().y - hoverBoundingRect.top;

            // if dragging down, continue only when hover is smaller than middle Y
            if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
            // if dragging up, continue only when hover is bigger than middle Y
            if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

            moveListItem(dragIndex, hoverIndex);
            // eslint-disable-next-line no-param-reassign
            item.index = hoverIndex;
        },
    });

    const dragDropRef = dragRef(dropRef(ref));
    const opacity = isDragging ? 0 : 1;

    const classes = classNames('grid__sortableColumn', {
        'grid__sortableColumn--hidden': columnHidden,
    });

    return (
        // @ts-ignore
        <div className={classes} ref={dragDropRef} style={{ opacity }}>
            <>
                <button type="button" disabled={!columnHidden && disableToggleButton} onClick={() => onToggleColumn(column.id)}>
                    {columnHidden ? (
                        <EyeClosedIcon />
                    ) : (
                        <EyeIcon />
                    )}
                </button>
                <div className="dragHandle">
                    <div className="dragHandle__bars" />
                </div>
                {column.columnDef.header}
            </>
        </div>
    );
};

export default SortableColumn;
