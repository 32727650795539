import { FC, useEffect, useRef } from 'react';

interface Props {
    width: number;
    height: number;
}
const OcrDiagonal: FC<Props> = ({ width, height }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;

        if (!canvas) {
            return;
        }
        // @ts-ignore
        const context = canvas.getContext('2d');
        context.beginPath();
        context.moveTo(0, 0);
        context.lineTo(width, height);
        context.lineWidth = 3;
        context.strokeStyle = '#CB0606';
        context.stroke();
    }, []);

    return <canvas width={width} height={height} ref={canvasRef} />;
};

export default OcrDiagonal;
