import { executeGetRequest, executePostRequest } from '@api/client';
import { createFetchMailTemplatePath, createFetchMailTemplatesPath, createSendMailPath } from '@routing/apiUrls';
import { MailTemplate, MailTemplates } from '@models/MailTemplates';
import { EmailDocumentMutation } from '@models/EmailDocument';

export const fetchMailTemplates = async (signal?: AbortSignal): Promise<MailTemplates> => {
    const result = await executeGetRequest<MailTemplates>(createFetchMailTemplatesPath(), signal);
    return result.data;
};

export const fetchMailTemplate = async (templateId: number, documentId?: number, signal?: AbortSignal): Promise<MailTemplate> => {
    const result = await executeGetRequest<MailTemplate>(createFetchMailTemplatePath(templateId, documentId), signal);
    return result.data;
};

export const sendMail = async (mutation: EmailDocumentMutation): Promise<void> => {
    const result = await executePostRequest<void>(createSendMailPath(), {
        ...mutation.data,
        documentId: mutation.documentId,
    });
    return result.data;
};
