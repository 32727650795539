import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { fetchLookup } from '@repository/lookupRepository';
import { Lookup } from '@models/Lookup';
import { minutesToMillis } from '@utils/millis';
import { paramsAreFilled } from '@utils/paramsAreFilled';

type UseLookupResponse = {
    isLoading: boolean;
    isError: boolean;
    data?: Lookup;
};

export type Params = {
    [key: string]: string;
};

export const OPTIONAL_KEY = '|OPTIONAL';

const useLookup = (lookup: string | null, params: Params, ignoreMissingFields: boolean, enabled: boolean): UseLookupResponse => {
    const query = useQuery({
        queryKey: [QueryKey.Lookup, lookup, params],
        queryFn: params && paramsAreFilled(lookup, params, ignoreMissingFields) && lookup ? ({ signal }) => fetchLookup(lookup, params, signal) : undefined,
        enabled: enabled && !!params && paramsAreFilled(lookup, params, ignoreMissingFields) && !!lookup,
        staleTime: minutesToMillis(30),
        retry: false,
        refetchOnWindowFocus: false,
    });

    const { data, isLoading, isError } = query;

    return {
        data,
        isLoading,
        isError,
    };
};

export default useLookup;
