import { FC } from 'react';
import classNames from 'classnames';
import resolvePath from '@utils/resolvePath';
import { useFormContext } from 'react-hook-form';

interface Props {
    className?: string;
    name: string;
}

const FormError: FC<Props> = ({ className, name }) => {
    const classes = classNames('form__error', className);
    const { formState: { errors } } = useFormContext(); // retrieve all hook methods
    const error = resolvePath(errors, name);

    if (!error) {
        return null;
    }

    const messages = [];

    if (error?.type) {
        messages.push(error.message);
    }
    if (error?.types) {
        Object.keys(error.types).forEach((type) => {
            messages.push(error.types[type]);
        });
    }

    return (
        <div className="form__errors">
            {messages.map((message, i) => (
                <div key={i} className={classes}>
                    {message}
                    {/*{t(message)}*/}
                </div>
            ))}
        </div>
    );
};

export default FormError;
