import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { MailTemplates } from '@models/MailTemplates';
import { fetchMailTemplates } from '@repository/mailTemplatesRepository';
import { minutesToMillis } from '@utils/millis';

const useFetchMailTemplates = (): UseQueryResult<MailTemplates> => {
    return useQuery({
        queryKey: [QueryKey.MailTemplates],
        queryFn: ({ signal }) => fetchMailTemplates(signal),
        staleTime: minutesToMillis(30),
    });
};

export default useFetchMailTemplates;
