import { ConfirmSharedTemplateMutation } from '@models/SharedTemplates';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { confirmSharedTemplate } from '@repository/templateSharingRepository';
import { toast } from 'react-toastify';
import { QueryKey } from '@constants/queryKey';
import { useAtomValue } from 'jotai';
import { currentTemplateAtom } from '@js/store/templateSharingAtoms';

const useConfirmSharedTemplate = (documentId: number) => {
    const client = useQueryClient();
    const currentTemplate = useAtomValue(currentTemplateAtom);
    return useMutation({
        mutationFn: (mutation: ConfirmSharedTemplateMutation) => confirmSharedTemplate(documentId, mutation.templateId),
        onSuccess: async () => {
            toast.info(`Template ${currentTemplate?.name} is goedgekeurd.`);
            await client.invalidateQueries({
                queryKey: [QueryKey.DocumentCoding, documentId],
            });
        },
    });
};

export default useConfirmSharedTemplate;
