import { FC, useMemo } from 'react';
import { StorageKeys } from '@constants/storageKeys';
import Throbber from '@components/primitives/throbber/Throbber';
import useFetchDocumentExternalInfo from '@components/features/externalInfo/hooks/useFetchDocumentExternalInfo';
import DataGrid from '@components/primitives/dataGrid/DataGrid';
import DataGridCell from '@components/primitives/dataGrid/DataGridCell';
import { createColumnHelper } from '@tanstack/react-table';
import { DocumentExternalInfoItem } from '@models/DocumentExternalInfo';
import classNames from 'classnames';
import { createStorageKey } from '@utils/storage';
import { useAtomValue } from 'jotai';
import { currentDocClassAtom } from '@js/store/appAtoms';

interface Props {
    documentId: number;
}

const ExternalInfo: FC<Props> = ({ documentId }) => {
    const currentDocClass = useAtomValue(currentDocClassAtom);
    const { data: documentExternalInfo, isLoading } = useFetchDocumentExternalInfo(documentId);

    const data = useMemo(() => {
        if (!documentExternalInfo) {
            return [];
        }

        return documentExternalInfo?.list;
    }, [documentExternalInfo]);

    const columnHelper = createColumnHelper<DocumentExternalInfoItem>();
    const columns = useMemo(() => {
        if (!documentExternalInfo) {
            return [];
        }

        const usedColumns: string[] = [];

        return documentExternalInfo?.scheme
            .filter((row) => {
                if (usedColumns.includes(row.columnId)) {
                    return false;
                }
                usedColumns.push(row.columnId);
                return true;
            })
            .filter((row) => !row.isHidden)
            .map((row) => {
                return columnHelper.accessor(row.columnId.toLowerCase(), {
                    header: row.title,
                    meta: {
                        format: row.format,
                        ...(row.format === 'decimal' ? {
                            className: 'dataGrid__cell dataGrid__cell--decimal',
                        } : undefined),
                    },
                    enableColumnFilter: false,
                    cell: (info) => {
                        return (
                            <DataGridCell
                                value={info.getValue()}
                                format={row.format}
                            />
                        );
                    },
                    ...(row.format === 'text' ? {
                        filterFn: 'fuzzy',
                        sortingFn: 'alphanumeric',
                    } : row.format === 'decimal' || row.format === 'int' ? {
                        filterFn: 'numeric',
                        sortingFn: 'numeric',
                    } : {
                        enableColumnFilter: false,
                    }),
                });
            });
    }, [documentExternalInfo]);

    return (
        <div className="externalInfo">
            {isLoading ? (
                <Throbber />
            ) : (
                <DataGrid
                    name={`${documentId}_externalInfo`}
                    columns={columns}
                    data={data}
                    columnHelper={columnHelper}
                    storageKey={createStorageKey([currentDocClass, StorageKeys.GridExternalInfo])}
                    getRowProps={(row) => {
                        const classes = classNames('dataGrid__tr', {
                            'dataGrid__tr--matchNo': row.original.matchvalue === -1,
                            'dataGrid__tr--matchPriceDifference': row.original.matchvalue === 1,
                            'dataGrid__tr--matchFull': row.original.matchvalue === 2,
                            'dataGrid__tr--matchNumberOfDifference': row.original.matchvalue === 3,
                            'dataGrid__tr--matchDateDifference': row.original.matchvalue === 4,
                        });

                        return {
                            className: classes,
                        };
                    }}
                />
            )}
        </div>
    );
};

export default ExternalInfo;
