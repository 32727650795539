import { executePostRequest } from '@api/client';
import { createPerformDocumentActionPath } from '@routing/apiUrls';
import { DefaultActionId } from '@models/DocumentActions';

type DocumentAction = {
    id: DefaultActionId | string;
    remark?: string;
    numberDays?: number;
};

export interface DocumentActionResponse {
    openNextDocument: boolean;
    openUrl: null;
    statuscode: number;
    validationMessage: string | null;
}

export const performDocumentAction = async (documentId: number, data: DocumentAction): Promise<DocumentActionResponse> => {
    const result = await executePostRequest<DocumentActionResponse>(createPerformDocumentActionPath(documentId), data);
    return result.data;
};
