import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { themeAtom, Themes } from '@js/store/settingsAtoms';
import { useAtomValue } from 'jotai';

interface Props {
    children: ReactNode;
}

const ThemeProvider: FC<Props> = ({ children }) => {
    const theme = useAtomValue(themeAtom);

    return (
        <div
            id="theme"
            className={classNames('theme', {
                themeLight: theme === Themes.Light,
                themeDark: theme === Themes.Dark,
                themeAuto: theme === Themes.Auto,
            })}
        >
            {children}
        </div>
    );
};

export default ThemeProvider;
