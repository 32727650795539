import axios, { AxiosRequestConfig } from 'axios';
import { clearAccessToken, getAccessToken } from '@repository/accessTokenRepository';
import { createAuthenticateApiPath } from '@routing/apiUrls';
import { PagePath } from '@routing/appUrls';
import { createStorageKey, getLocalStorageItem } from '@utils/storage';
import { StorageKeys } from '@constants/storageKeys';

const defaultTimeoutLength = 60000; // 60 seconds
export const apiBaseUrl = getLocalStorageItem(createStorageKey([StorageKeys.ApiBaseUrl]));

export const axiosInstance = axios.create({
    baseURL: '/api/',
    headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json; charset=utf-8',
    },
});

axiosInstance.interceptors.request.use(async (config) => {
    const newConfig = {
        ...config,
    };

    if (config.url === createAuthenticateApiPath()) {
        return newConfig;
    }

    if (apiBaseUrl) {
        newConfig.baseURL = apiBaseUrl;
    } else {
        console.warn('No apiBaseUrl found...');
    }

    const wvToken = await getAccessToken();

    if (!wvToken) {
        return newConfig;
    }

    if (newConfig.headers) {
        newConfig.headers.Authorization = `Bearer ${wvToken}`;
    }

    return newConfig;
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error?.response?.status === 401 && error?.response?.config?.url !== createAuthenticateApiPath()) {
            clearAccessToken('The server responded with a 401');
            window.location.href = PagePath.login;
        }

        return Promise.reject(error);
    },
);

export function executeGetRequest<ResponseType>(
    path: string,
    signal?: AbortSignal | undefined,
    config?: AxiosRequestConfig,
) {
    return axiosInstance.get<ResponseType>(path, {
        signal,
        timeout: defaultTimeoutLength,
        ...config,
    });
}

export function executePostRequest<ResponseType>(
    path: string,
    data: object | FormData = {},
    config?: AxiosRequestConfig,
) {
    return axiosInstance.post<ResponseType>(path, data, {
        timeout: defaultTimeoutLength,
        ...config,
    });
}

export function executePutRequest<ResponseType>(
    path: string,
    data: object = {},
) {
    return axiosInstance.put<ResponseType>(path, data, {
        timeout: defaultTimeoutLength,
    });
}

export function executeDeleteRequest<ResponseType>(
    path: string,
    data: object = {},
) {
    return axiosInstance.delete<ResponseType>(path, {
        timeout: defaultTimeoutLength,
        data,
    });
}
