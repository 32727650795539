import { FC, memo, useEffect, useRef, useState, MouseEvent } from 'react';
import AllOcrRectangles from '@components/features/ocr/components/AllOcrRectangles';
import useFetchDocumentOcrSummary from '@components/features/ocr/hooks/useFetchDocumentOcrSummary';
import useFetchDocumentOcrData from '@components/features/ocr/hooks/useFetchDocumentOcrData';
import { getOriginalFileWidth } from '@utils/documentInfo';
import { DocumentOcrWord } from '@models/DocumentOcrData';
import useDocumentStatus from '@hooks/useDocumentStatus';
import useFetchDocument from '@hooks/useFetchDocument';
import OcrInvoiceLines from '@components/features/ocr/components/OcrInvoiceLines';
import HighlightedOcr from '@components/features/ocr/components/HighlightedOcr';
import OcrCorrections from '@components/features/ocr/components/OcrCorrections';
import MouseToolTip from '@components/primitives/mouseToolTip/MouseToolTip';
import OcrRect from '@components/features/ocr/components/OcrRect';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useCopyToClipboard from '@utils/copyToClipboard';
import useIsKeyPressed from '@utils/useIsKeyPressed';
import OcrTrainingTableBorder from '@components/features/ocr/components/OcrTrainingTableBorder';
import { useAtomValue, useSetAtom } from 'jotai';
import { SelectingOcrTrainingFieldContext } from '@models/OcrTraining';
import { disableUserSelectionAtom } from '@js/store/appAtoms';
import { ocrTrainingFieldModalAtom } from '@js/store/modals/ocrTrainingFieldModalAtom';

interface Props {
    documentId: number;
    page: number;
    onOcrClick?: (ocrItem: DocumentOcrWord) => void;
    readOnly?: boolean;
    fileWidth: number;
    fileHeight: number;
    rerenderDependency?: any[];
    ocrTrainingEnabled: boolean;
}

const Ocr: FC<Props> = ({ documentId, page, onOcrClick, readOnly, fileWidth, fileHeight, rerenderDependency, ocrTrainingEnabled }) => {
    const { t } = useTranslation();
    const { data: documentOcrSummary } = useFetchDocumentOcrSummary(documentId);
    const setDisableUserSelection = useSetAtom(disableUserSelectionAtom);
    const { data: documentOcrData } = useFetchDocumentOcrData(documentId, page);
    const [selectedOcrItems, setSelectedOcrItems] = useState<DocumentOcrWord[]>([]);
    const [selectedByRect, setSelectedByRect] = useState<DocumentOcrWord[][]>([]);
    const { isEditMode } = useDocumentStatus();
    const { data: document } = useFetchDocument(documentId, isEditMode);
    const matchingEnabled = document?.meta.matchingEnabled;
    const { shiftPressed, altPressed } = useIsKeyPressed();
    const copyToClipboard = useCopyToClipboard();
    const ocrTrainingFieldModal = useAtomValue(ocrTrainingFieldModalAtom);

    const ref = useRef<HTMLDivElement | null>(null);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [startLeft, setStartLeft] = useState<number | null>(null);
    const [startTop, setStartTop] = useState<number | null>(null);

    const [left, setLeft] = useState<number | null>(null);
    const [top, setTop] = useState<number | null>(null);
    const [width, setWidth] = useState<number | null>(null);
    const [height, setHeight] = useState<number | null>(null);

    useEffect(() => {
        setDisableUserSelection(isDragging);
    }, [isDragging]);

    const removeOffset = (e: MouseEvent<HTMLDivElement>) => {
        const offset = ref.current?.getBoundingClientRect();
        if (!offset) {
            return { clientX: 0, clientY: 0 };
        }

        return {
            clientX: e.clientX - offset.x,
            clientY: e.clientY - offset.y,
        };
    };

    const relativeDimensions = () => {
        const offset = ref.current?.getBoundingClientRect();
        if (!offset || !left || !top || !width || !height) {
            return {
                left: 0,
                top: 0,
                width: 0,
                height: 0,
            };
        }

        const ratio = getOriginalFileWidth(fileWidth) / offset.width;

        return {
            rectLeft: left * ratio,
            rectTop: top * ratio,
            rectWidth: width * ratio,
            rectHeight: height * ratio,

            rectRight: (left * ratio) + (width * ratio),
            rectBottom: (top * ratio) + (height * ratio),
        };
    };

    const handleSelectedOcrRects = () => {
        const offset = ref.current?.getBoundingClientRect();
        if (!offset) {
            return;
        }

        const { rectLeft, rectTop, rectWidth, rectHeight, rectRight, rectBottom } = relativeDimensions();

        if (!rectLeft || !rectTop || !rectWidth || !rectHeight || !rectRight || !rectBottom) {
            return;
        }

        setSelectedByRect([]);
        setSelectedOcrItems([]);

        if (!documentOcrData?.lines?.length) {
            return;
        }

        documentOcrData?.lines.forEach((line) => {
            const selectedWords: DocumentOcrWord[] = [];

            line.words.forEach((ocrItem) => {
                const zones = ocrItem.zone.split(/\s/u);
                const [left, top, width, height] = zones;

                if (rectLeft <= (+left + +width) && rectRight >= +left) {
                    if (rectTop <= (+top + +height) && rectBottom >= +top) {
                        setSelectedOcrItems((selectedOcrItems) => {
                            return [
                                ...selectedOcrItems,
                                ocrItem,
                            ];
                        });
                        selectedWords.push(ocrItem);
                    }
                }
            });

            if (selectedWords.length) {
                setSelectedByRect((selectedByRect) => {
                    return [
                        ...selectedByRect,
                        selectedWords,
                    ];
                });
            }
        });
    };

    const onMouseMove = (e: MouseEvent<HTMLDivElement>) => {
        if (!isDragging || !left || !top || !startTop || !startLeft || !onOcrClick) {
            return;
        }

        const { clientX, clientY } = removeOffset(e);

        if (clientY < startTop) {
            setTop(clientY);
            setHeight(startTop - clientY);
        } else {
            setHeight(clientY - top);
        }

        if (clientX < startLeft) {
            setLeft(clientX);
            setWidth(startLeft - clientX);
        } else {
            setWidth(clientX - left);
        }

        handleSelectedOcrRects();
    };

    const onMouseDown = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (!onOcrClick) {
            return;
        }

        const { clientX, clientY } = removeOffset(e);
        setIsDragging(true);
        setStartLeft(clientX);
        setStartTop(clientY);

        setWidth(null);
        setHeight(null);

        setLeft(clientX);
        setTop(clientY);

        setSelectedOcrItems([]);
        setSelectedByRect([]);
    };

    const getPartlySelectedText = () => {
        const { rectLeft, rectTop, rectWidth, rectHeight, rectRight, rectBottom } = relativeDimensions();

        if (!rectLeft || !rectTop || !rectWidth || !rectHeight || !rectRight || !rectBottom) {
            return '';
        }

        let partlySelectedText = '';
        selectedByRect.forEach((line, rectIndex) => {
            let lineStr = '';
            line.forEach((ocrItem, index) => {
                if (!ocrItem.text) {
                    return;
                }

                const zones = ocrItem.zone.split(/\s/u);
                const [left, , width] = zones;

                const startPercentage = rectLeft <= +left ? 0 : rectLeft >= (+left + +width) ? 100 : ((rectLeft - +left) / +width) * 100;
                const endPercentage = rectRight <= +left ? 0 : rectRight >= (+left + +width) ? 100 : ((rectRight - +left) / +width) * 100;

                const letterPercentage = 100 / ocrItem.text.length;

                let progress = 0;
                let result = '';

                ocrItem.text.split('').forEach((letter) => {
                    if ((startPercentage - (letterPercentage / 2)) <= progress && endPercentage > (progress + (letterPercentage / 2))) {
                        result += letter;
                    }
                    progress += letterPercentage;
                });

                lineStr += `${result}${line.length > (index + 1) ? ' ' : ''}`;
            });

            partlySelectedText += `${lineStr}${selectedByRect.length > (rectIndex + 1) ? '\n' : ''}`;
        });

        return partlySelectedText;
    };

    const getSelectedText = (): string | undefined => {
        if (altPressed) {
            return getPartlySelectedText();
        }
        let fullySelectedText = '';
        selectedByRect.forEach((line, rectIndex) => {
            let lineStr = '';
            line.forEach((word, index) => {
                lineStr += `${word.text}${line.length > (index + 1) ? ' ' : ''}`;
            });

            fullySelectedText += `${lineStr}${selectedByRect.length > (rectIndex + 1) ? '\n' : ''}`;
        });

        return fullySelectedText;
    };

    const onMouseUp = () => {
        setIsDragging(false);
        setSelectedOcrItems([]);

        const offset = ref.current?.getBoundingClientRect();
        const { rectLeft, rectTop, rectWidth, rectHeight } = relativeDimensions();
        if (!onOcrClick || !rectLeft || !rectTop || !rectWidth || !offset) {
            return;
        }

        if (shiftPressed) {
            const selectedText = getSelectedText();
            if (selectedText) {
                copyToClipboard(selectedText);
            }
        } else {
            onOcrClick({
                page,
                text: getSelectedText() || '',
                zone: `${Math.round(rectLeft)} ${Math.round(rectTop)} ${Math.round(rectWidth)} ${Math.round(rectHeight)}`,
            });
        }

        setWidth(null);
        setHeight(null);
        setLeft(null);
        setTop(null);
        setStartLeft(null);
        setStartTop(null);
    };

    return (
        <div className="ocr" ref={ref} onMouseUp={onMouseUp} onMouseDown={onMouseDown} onMouseMove={onMouseMove}>
            {left && top && width && height ? (
                <MouseToolTip
                    force
                    title={shiftPressed ? t('copyToClipboard.title') : undefined}
                    text={!readOnly ? getSelectedText() : undefined}
                >
                    <div
                        className="ocr__selectRect"
                        style={{
                            ...(left ? { left } : null),
                            ...(top ? { top } : null),
                            ...(width ? { width } : null),
                            ...(height ? { height } : null),
                        }}
                    />
                </MouseToolTip>
            ) : null}
            {matchingEnabled ? (
                <OcrInvoiceLines
                    documentId={documentId}
                    page={page}
                    documentOcrSummary={documentOcrSummary}
                    fileHeight={fileHeight}
                />
            ) : null}
            {documentOcrData ? (
                <AllOcrRectangles
                    isDragging={!!left && !!top && !!width && !!height}
                    documentOcrData={documentOcrData}
                    page={page}
                    onOcrClick={(ocrItem: DocumentOcrWord) => {
                        if (shiftPressed) {
                            copyToClipboard(ocrItem.text);
                        } else if (onOcrClick) {
                            onOcrClick(ocrItem);
                        }
                    }}
                    selectedOcrItems={selectedOcrItems}
                    fileWidth={fileWidth}
                    fileHeight={fileHeight}
                    rerenderDependency={rerenderDependency}
                    shiftPressed={shiftPressed}
                />
            ) : null}
            {!isDragging ? (
                <HighlightedOcr
                    documentId={documentId}
                    documentOcrSummary={documentOcrSummary}
                    page={page}
                    fileWidth={fileWidth}
                    fileHeight={fileHeight}
                />
            ) : null}
            {ocrTrainingEnabled && documentOcrSummary ? (
                <OcrTrainingTableBorder
                    documentOcrSummary={documentOcrSummary}
                    page={page}
                    fileWidth={fileWidth}
                    fileHeight={fileHeight}
                />
            ) : null}
            {ocrTrainingFieldModal.data?.ocrValue?.zone && ocrTrainingFieldModal.data?.ocrValue?.page === page ? (
                <OcrRect
                    scrollToElement
                    zone={ocrTrainingFieldModal.data.ocrValue.zone}
                    className={classNames('ocr__rectangle', 'ocr__rectangle--training-value', {
                        'ocr__rectangle--trainingActive': ocrTrainingFieldModal.data?.context === SelectingOcrTrainingFieldContext.Value,
                    })}
                    fileWidth={fileWidth}
                    fileHeight={fileHeight}
                />
            ) : null}
            {ocrTrainingFieldModal.data?.ocrNearbyValue?.zone && ocrTrainingFieldModal.data?.ocrNearbyValue?.page === page ? (
                <OcrRect
                    scrollToElement
                    zone={ocrTrainingFieldModal.data.ocrNearbyValue.zone}
                    className={classNames('ocr__rectangle', 'ocr__rectangle--training-nearbyValue', {
                        'ocr__rectangle--trainingActive': ocrTrainingFieldModal.data?.context === SelectingOcrTrainingFieldContext.NearByValue,
                    })}
                    fileWidth={fileWidth}
                    fileHeight={fileHeight}
                />
            ) : null}
            <OcrCorrections
                documentOcrSummary={documentOcrSummary}
                page={page}
                fileWidth={fileWidth}
                fileHeight={fileHeight}
            />
        </div>
    );
};

export default memo(Ocr);
