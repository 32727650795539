import { FC, MouseEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface Props {
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    className?: string;
}

const ExternalInfoTab: FC<Props> = ({ onClick, className }) => {
    const { t } = useTranslation();
    const classes = classNames('codingMatching__tab', className);

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        if (!onClick) {
            return;
        }

        onClick(e);
    };

    return (
        <button type="button" className={classes} onClick={handleClick}>
            <strong>{t('tab', { ns: 'externalInfo' })}</strong>
        </button>
    );
};

export default ExternalInfoTab;
