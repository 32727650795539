import { useAtom } from 'jotai';
import log, { LogContext } from '@utils/logging';
import { lookupErrorsAtom } from '@js/store/lookupErrors/lookupErrorsAtoms';

export const useLookupErrors = () => {
    const [lookupErrors, setLookupErrors] = useAtom(lookupErrorsAtom);

    const getLookupError = (lineId: number, id?: string) => {
        if (!id) {
            return lookupErrors.find((lookupError) => {
                return lookupError.lineId === lineId;
            });
        }

        return lookupErrors.find((lookupError) => {
            return lookupError.lineId === lineId && lookupError.id === id;
        });
    };
    const registerLookupError = (lineId: number, id: string) => {
        const lookupError = getLookupError(lineId, id);
        if (lookupError) {
            return;
        }

        log({
            context: LogContext.LookupErrors,
            title: `Registered lookupError "${id}"${lineId !== undefined ? ` (line ${lineId})` : ''}`,
            reason: 'This field is invalid',
        });

        setLookupErrors((lookupErrors) => {
            return [
                ...lookupErrors,
                {
                    lineId,
                    id,
                },
            ];
        });
    };

    const unregisterLookupError = (logReason: string, lineId: number, id?: string) => {
        const lookupError = getLookupError(lineId, id);
        if (!lookupError || !lookupErrors.length) {
            return;
        }

        if (!id) {
            log({
                context: LogContext.LookupErrors,
                title: `Unregistered lookupErrors of line ${lineId}`,
                reason: logReason,
            });
            setLookupErrors((lookupErrors) => {
                return lookupErrors.filter((lookupError) => lookupError.lineId !== lineId);
            });
            return;
        }

        log({
            context: LogContext.LookupErrors,
            title: `Unregistered lookupError "${lookupError.id}"${lookupError.lineId !== undefined ? ` (line ${lookupError.lineId})` : ''}`,
            reason: logReason,
        });

        setLookupErrors((lookupErrors) => {
            return lookupErrors.filter((lookupError) => lookupError.lineId !== lineId && lookupError.id !== id);
        });
    };
    const resetLookupErrors = () => setLookupErrors([]);

    const hasLookupErrors = lookupErrors.length > 0;

    return {
        registerLookupError,
        unregisterLookupError,
        lookupErrors,
        resetLookupErrors,
        hasLookupErrors,
    };
};

export default useLookupErrors;
