import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { fetchVersion } from '@repository/versionRepository';
import { Version } from '@models/Version';

const useFetchVersion = (): UseQueryResult<Version> => {
    return useQuery({
        queryKey: [QueryKey.Version],
        queryFn: ({ signal }) => fetchVersion(signal),
        staleTime: 0,
    });
};

export default useFetchVersion;
