import { DocumentOcrWord } from '@models/DocumentOcrData';
import { DocumentHeaders, UpdateDocumentHeader } from '@models/DocumentHeaders';
import { toast } from 'react-toastify';
import log, { LogContext } from '@utils/logging';
import useDocumentHeaders from '@components/features/documentHeaders/hooks/useDocumentHeaders';
import useLookupParams from '@components/features/lookup/hooks/useLookupParams';
import useGetLookupOptionsFromCache from '@components/features/lookup/hooks/useGetLookupOptionsFromCache';
import { useTranslation } from 'react-i18next';
import useUpdateDocumentHeader from '@components/features/documentHeaders/hooks/useUpdateDocumentHeader';
import useOcrUtils from '@components/features/ocr/hooks/useOcrUtils';
import { DynamicFieldValue } from '@models/DynamicField';
import { LookupListItem } from '@models/Lookup';
import { QueryKey } from '@constants/queryKey';
import { useQueryClient } from '@tanstack/react-query';
import { OcrTraining } from '@models/OcrTraining';

const useHeaderUpdater = (documentId: number) => {
    const { mutate: updateDocumentHeader } = useUpdateDocumentHeader(documentId);
    const { documentHeaders, getHeaderFieldDefinition } = useDocumentHeaders(documentId);
    const { getLookupParams } = useLookupParams(documentId);
    const { getLookupOptionsFromCache } = useGetLookupOptionsFromCache();
    const { t } = useTranslation();
    const { formatOcrValue, ocrToLookupValue } = useOcrUtils(documentId);
    const client = useQueryClient();
    const queryCache = client.getQueryCache();

    const getDocumentHeadersFromCache = (): DocumentHeaders | undefined => {
        const headers = queryCache.find<DocumentHeaders>({
            queryKey: [QueryKey.DocumentHeaders, documentId],
        });
        return headers?.state?.data;
    };

    const getOcrTrainingFromCache = (): OcrTraining | undefined => {
        const ocrTraining = queryCache.find<OcrTraining>({
            queryKey: [QueryKey.OcrTraining, documentId],
        });
        return ocrTraining?.state?.data;
    };

    const getSelectedLookupOption = (fieldId: string, value: DynamicFieldValue) => {
        const fieldDefinition = getHeaderFieldDefinition(fieldId);

        if (!fieldDefinition || fieldDefinition.format !== 'lookup') {
            return undefined;
        }

        const lookupParams = getLookupParams(fieldDefinition.lookup);
        const lookupData = getLookupOptionsFromCache(fieldDefinition.lookup, lookupParams);

        if (!lookupData) {
            return undefined;
        }

        return lookupData.list?.find((option) => option.key === value);
    };

    const convertLookupOptionToValuesToSave = (fieldId: string, option: LookupListItem, overwrite: boolean) => {
        const headersFromCache = getDocumentHeadersFromCache();
        const fieldDefinition = getHeaderFieldDefinition(fieldId);
        const result: UpdateDocumentHeader[] = [];

        if (!fieldDefinition || !Object.entries(option).length) {
            return result;
        }

        const lookupParams = getLookupParams(fieldDefinition.lookup);
        const lookupData = getLookupOptionsFromCache(fieldDefinition.lookup, lookupParams);

        for (const [id, value] of Object.entries(option)) {
            const targetFieldDefinition = headersFromCache?.definitions.find((header) => header.id === id);
            const targetField = headersFromCache?.values.find((header) => header.id === id);

            // Make sure the field exists in the header definition and this field is not read only or hidden
            if (targetField && targetFieldDefinition) {
                // Check the scheme of this entry and make sure this entry has useValue enabled and is not hidden
                const lookupScheme = lookupData?.scheme.find((item) => item.columnId === id);
                if (lookupScheme?.useValue && (value !== null && value !== undefined)) {
                    // Make sure the value is different from the current value
                    if (targetField.value !== value) {
                        // Overwrite the value if it's not set or if the overwrite flag is set
                        if (overwrite || !targetField.value) {
                            result.push({
                                id,
                                value: value as string | number | null,
                            });
                        }
                    }
                }
            }
        }

        return result;
    };

    const purifyValues = (values: UpdateDocumentHeader[]): UpdateDocumentHeader[] => {
        return values.map((val) => {
            const fieldDefinition = getHeaderFieldDefinition(val.id);

            if (!fieldDefinition) {
                return val;
            }

            // Truncate string to maxLength
            if (fieldDefinition.maxLength && val.value && typeof val.value === 'string' && (val.value.length > fieldDefinition.maxLength)) {
                toast.warn(t('validation.ocr.tooLarge', { maxLength: fieldDefinition.maxLength }));
                return {
                    ...val,
                    value: val.value.substring(0, fieldDefinition.maxLength),
                };
            }

            return val;
        });
    };

    const onHeaderInit = (fieldId: string, value: DynamicFieldValue) => {
        const fieldDefinition = getHeaderFieldDefinition(fieldId);

        if (!fieldDefinition || documentHeaders?.meta?.readOnly) {
            return;
        }

        const selectedOption = getSelectedLookupOption(fieldId, value);

        const values: UpdateDocumentHeader[] = [
            ...(selectedOption ? convertLookupOptionToValuesToSave(fieldId, selectedOption, false) : []),
        ];

        if (!values.length) {
            return;
        }

        let logTitle = `Update field${values.length > 1 ? 's ' : ' '}`;
        values.forEach((val, index) => {
            logTitle += `${index !== 0 ? ' + ' : ''}"${val.id}"`;
        });

        log({
            context: LogContext.Headers,
            title: logTitle,
            reason: `On header init (${fieldId})`,
            data: { values },
        });

        updateDocumentHeader({
            values: purifyValues(values),
        });
    };

    const onHeaderChange = (fieldId: string, value: DynamicFieldValue) => {
        const fieldDefinition = getHeaderFieldDefinition(fieldId);

        if (!fieldDefinition || fieldDefinition?.isReadOnly || documentHeaders?.meta?.readOnly) {
            return;
        }

        const selectedOption = getSelectedLookupOption(fieldId, value);

        const values: UpdateDocumentHeader[] = [
            {
                id: fieldId,
                value,
            },
            ...(selectedOption ? convertLookupOptionToValuesToSave(fieldId, selectedOption, true) : []),
        ];

        log({
            context: LogContext.Headers,
            title: `Update field "${fieldId}"`,
            reason: 'On header change',
            data: { values },
        });

        updateDocumentHeader({
            values: purifyValues(values),
        });
    };

    const getOcrValue = (fieldId: string, value: string): string => {
        const fieldDefinition = getHeaderFieldDefinition(fieldId);

        if (!fieldDefinition) {
            return value;
        }

        if (fieldDefinition.format === 'lookup') {
            const selectedOption = ocrToLookupValue(value, fieldDefinition.lookup);

            if (!selectedOption?.key) {
                return value;
            }

            return selectedOption?.key;
        }

        return formatOcrValue(value, fieldDefinition.format);
    };

    const onHeaderOcr = (fieldId: string, ocr: DocumentOcrWord) => {
        const fieldDefinition = getHeaderFieldDefinition(fieldId);

        if (!fieldDefinition || fieldDefinition?.isReadOnly || documentHeaders?.meta.readOnly) {
            return;
        }

        const values: UpdateDocumentHeader[] = [
            {
                id: fieldId,
                value: getOcrValue(fieldId, ocr.text),
                zone: ocr.zone,
            },
        ];

        if (fieldDefinition.format === 'lookup') {
            const selectedOption = ocrToLookupValue(ocr.text, fieldDefinition.lookup);

            if (!selectedOption) {
                return;
            }

            const dependentValues = convertLookupOptionToValuesToSave(fieldId, selectedOption, true);

            dependentValues.forEach((val) => {
                values.push(val);
            });
        }

        log({
            context: LogContext.Headers,
            title: `Update field "${fieldId}"`,
            reason: 'On OCR clicked',
            data: { values },
        });

        updateDocumentHeader({
            values: purifyValues(values),
        });
    };

    const onHeaderApplyTraining = () => {
        const ocrTraining = getOcrTrainingFromCache();
        const headersFromCache = getDocumentHeadersFromCache();

        if (!ocrTraining) {
            return;
        }

        const fieldIds: string[] = ocrTraining.fields.filter((field) => {
            return field.fieldId.indexOf('lineitem_') !== 0;
        }).map((field) => {
            return field.fieldId;
        });

        if (!fieldIds?.length) {
            return;
        }

        const changedLookupFields = headersFromCache?.values.filter((field) => {
            const fieldDefinition = headersFromCache?.definitions.find((header) => header.id === field.id);
            return fieldDefinition?.format === 'lookup' && field.value && fieldIds.includes(field.id);
        });

        if (!changedLookupFields) {
            return;
        }

        const values: UpdateDocumentHeader[] = [];

        changedLookupFields.forEach((field) => {
            const fieldDefinition = headersFromCache?.definitions.find((header) => header.id === field.id);

            if (!fieldDefinition) {
                return;
            }

            const selectedOption = getSelectedLookupOption(field.id, field.value);

            if (!selectedOption) {
                return;
            }

            const dependentValues = convertLookupOptionToValuesToSave(field.id, selectedOption, true);

            dependentValues.forEach((val) => {
                values.push(val);
            });
        });

        log({
            context: LogContext.Headers,
            title: 'Update dependent header fields',
            reason: 'On apply OCR training',
            data: { values },
        });

        updateDocumentHeader({
            values: purifyValues(values),
        });
    };

    return {
        onHeaderInit,
        onHeaderChange,
        onHeaderOcr,
        onHeaderApplyTraining,
    };
};

export default useHeaderUpdater;
