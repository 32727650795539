import {
    CreateDocumentInvoiceLineResponse,
    DeleteDocumentInvoiceLineResponse, DocumentInvoiceLineForm,
    DocumentInvoiceLines,
    UpdateDocumentInvoiceLineResponse,
} from '@models/DocumentInvoiceLines';
import { executeDeleteRequest, executeGetRequest, executePostRequest, executePutRequest } from '@api/client';
import {
    createAddDocumentInvoiceLinePath, createDeleteDocumentInvoiceLinePath,
    createFetchDocumentInvoiceLinesPath, createUpdateDocumentInvoiceLinePath,
} from '@routing/apiUrls';

export const fetchDocumentInvoiceLines = async (documentId: number, signal?: AbortSignal): Promise<DocumentInvoiceLines> => {
    const result = await executeGetRequest<DocumentInvoiceLines>(createFetchDocumentInvoiceLinesPath(documentId), signal);
    return result.data;
};

export const createDocumentInvoiceLine = async (documentId: number, data: DocumentInvoiceLineForm): Promise<CreateDocumentInvoiceLineResponse> => {
    const result = await executePostRequest<CreateDocumentInvoiceLineResponse>(createAddDocumentInvoiceLinePath(documentId), data);
    return result.data;
};

export const updateDocumentInvoiceLine = async (
    documentId: number,
    codingLineId: number,
    data: DocumentInvoiceLineForm,
): Promise<UpdateDocumentInvoiceLineResponse> => {
    const result = await executePutRequest<UpdateDocumentInvoiceLineResponse>(createUpdateDocumentInvoiceLinePath(documentId, codingLineId), data);
    return result.data;
};

export const deleteDocumentInvoiceLine = async (documentId: number, documentInvoiceLineIds: number[]): Promise<DeleteDocumentInvoiceLineResponse> => {
    const result = await executeDeleteRequest<DeleteDocumentInvoiceLineResponse>(createDeleteDocumentInvoiceLinePath(documentId), {
        rowids: documentInvoiceLineIds,
    });
    return result.data;
};
