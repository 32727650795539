import { FC, useEffect, useState } from 'react';
import Select from 'react-select';
import CustomReactSelectInput from '@components/primitives/form/components/CustomReactSelectInput';
import CustomReactSelectNoOptions from '@components/primitives/form/components/CustomReactSelectNoOptions';
import FormRow from '@components/primitives/form/FormRow';
import { useTranslation } from 'react-i18next';
import useUpdateCurrentUser from '@components/features/currentUser/hooks/useUpdateCurrentUser';
import { refreshAccessToken } from '@repository/accessTokenRepository';
import useFetchCurrentUser from '@components/features/currentUser/hooks/useFetchCurrentUser';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';

type Option = {
    label: string;
    value: boolean;
};

const SamSettings: FC = () => {
    const { t } = useTranslation();
    const { data: user } = useFetchCurrentUser();
    const [enableSam, setEnableSam] = useState<boolean>(false);
    const { mutate: updateCurrentUser } = useUpdateCurrentUser();
    const query = useQueryClient();

    useEffect(() => {
        if (typeof user?.samEnabledForUser !== 'boolean') {
            return;
        }

        setEnableSam(user.samEnabledForUser);
    }, [user]);

    const options: Array<Option> = [
        {
            label: t('sam.options.disable', { ns: 'settings' }),
            value: false,
        },
        {
            label: t('sam.options.enable', { ns: 'settings' }),
            value: true,
        },
    ];

    const handleChange = (option: Option | null) => {
        if (!option) {
            return;
        }

        setEnableSam(option.value);

        updateCurrentUser({
            samEnabledForUser: option.value,
        }, {
            onSuccess: async () => {
                await refreshAccessToken();
                await query.invalidateQueries({
                    queryKey: [QueryKey.Profile],
                });
            },
        });
    };

    return (
        <FormRow
            name="enableSam"
            label={t('sam.label', { ns: 'settings' })}
            description={t('sam.description', { ns: 'settings' })}
        >
            <div className="form__field">
                <Select
                    components={{
                        Input: CustomReactSelectInput,
                    }}
                    inputId="enableSam"
                    className="form__select"
                    placeholder={t('select')}
                    classNamePrefix="form__select"
                    onChange={handleChange}
                    value={options?.find((option) => option?.value === enableSam)}
                    options={options}
                    noOptionsMessage={() => <CustomReactSelectNoOptions />}
                />
            </div>
        </FormRow>
    );
};

export default SamSettings;
