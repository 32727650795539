import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import useFetchDocument from '@hooks/useFetchDocument';
import { minutesToMillis } from '@utils/millis';
import useDocumentStatus from '@hooks/useDocumentStatus';
import { fetchDocumentOrderLines } from '@repository/documentOrderLinesRepository';
import { DocumentOrderLines } from '@models/DocumentOrderLines';

const useFetchDocumentOrderLines = (documentId: number, filtered: boolean): UseQueryResult<DocumentOrderLines> => {
    const { isEditMode } = useDocumentStatus();
    const { data: document } = useFetchDocument(documentId, isEditMode);

    return useQuery({
        queryKey: [QueryKey.DocumentOrderLines, documentId, filtered],
        queryFn: ({ signal }) => fetchDocumentOrderLines(documentId, filtered, signal),
        staleTime: minutesToMillis(30),
        enabled: document?.meta.matchingEnabled || false,
    });
};

export default useFetchDocumentOrderLines;
