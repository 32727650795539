import { useMutation } from '@tanstack/react-query';
import { CurrentUserUpdateMutation } from '@models/CurrentUser';
import { updateCurrentUser } from '@repository/userRepository';

const useUpdateCurrentUser = () => {
    return useMutation({
        mutationFn: (mutation: CurrentUserUpdateMutation) => updateCurrentUser(mutation),
    });
};

export default useUpdateCurrentUser;
