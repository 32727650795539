import { atom } from 'jotai';
import { closedModalState, ModalState } from '@js/store/modalAtoms';
import { Column, RowData, VisibilityState } from '@tanstack/react-table';

export interface GridSettingsModalData {
    name: string;
    onSettingsSaved: (orderedColumns: any[], hiddenColumns: VisibilityState) => void;
    columns: Column<RowData, unknown>[];
    defaultColumns: any;
}

export const gridSettingsModalAtom = atom<ModalState<GridSettingsModalData>>(closedModalState);
