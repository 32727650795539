import React, { FC } from 'react';
import { Sizes } from '@utils/sizes';
import DocumentAuditModalInner from '@components/modals/documentAuditModal/DocumentAuditModalInner';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import Modal from '@components/primitives/modal/Modal';
import { documentAuditModalAtom, DocumentAuditModalData } from '@js/store/modals/documentAuditModalAtom';

const DocumentAuditModal: FC = () => {
    const [modalState, setModalState] = useAtom(documentAuditModalAtom);
    const { t } = useTranslation();

    if (!modalState.data) {
        return null;
    }

    return (
        <Modal<DocumentAuditModalData>
            modalState={modalState}
            setModalState={setModalState}
            size={Sizes.xxl}
            title={t('history.title', { ns: 'documentActions' })}
        >
            <DocumentAuditModalInner
                modalData={modalState.data}
            />
        </Modal>
    );
};

export default DocumentAuditModal;
