import React, { FC } from 'react';
import DocumentAuditModal from '@components/modals/documentAuditModal/DocumentAuditModal';
import EmailDocumentModal from '@components/modals/emailDocumentModal/EmailDocumentModal';
import ConfirmModal from '@components/modals/confirmModal/ConfirmModal';
import TextModal from '@components/modals/textModal/TextModal';
import PreviewModal from '@components/modals/previewModal/PreviewModal';
import RepairDocumentModal from '@components/modals/repairDocumentModal/RepairDocumentModal';
import DynamicAssignModal from '@components/modals/dynamicAssignModal/DynamicAssignModal';
import ListModal from '@components/modals/listModal/ListModal';
import PerformDocumentActionModal from '@components/modals/performDocumentActionModal/PerformDocumentActionModal';
import WorkflowActionValidationModal from '@components/modals/workflowActionValidationModal/WorkflowActionValidationModal';
import AsyncConfirmModal from './asyncConfirmModal/AsyncConfirmModal';

const ApplicationModals: FC = () => {
    return (
        <>
            <DocumentAuditModal />
            <EmailDocumentModal />
            <PreviewModal />
            <RepairDocumentModal />
            <TextModal />
            <ConfirmModal />
            <AsyncConfirmModal />
            <ListModal />
            <PerformDocumentActionModal />
            <DynamicAssignModal />
            <WorkflowActionValidationModal />
        </>
    );
};

export default ApplicationModals;
