import { useMutation, useQueryClient } from '@tanstack/react-query';
import { refreshDocumentMatching } from '@repository/documentMatchingRepository';
import { QueryKey } from '@constants/queryKey';

const useRefreshDocumentMatching = (documentId: number) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: () => refreshDocumentMatching(documentId),
        onSuccess: async () => {
            await Promise.all([
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentInvoiceLines, documentId],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentOrderLines, documentId],
                }),
            ]);
        },
    });
};

export default useRefreshDocumentMatching;
