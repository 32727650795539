import update from 'immutability-helper';
import ModalContent from '@components/primitives/modal/components/ModalContent';
import ModalFooter from '@components/primitives/modal/components/ModalFooter';
import Button from '@components/primitives/button/Button';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useEffect, useState } from 'react';
import SortableColumn from '@components/modals/gridSettingsModal/components/SortableColumn';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Column, RowData, VisibilityState } from '@tanstack/react-table';
import { closedModalState } from '@js/store/modalAtoms';
import { gridSettingsModalAtom, GridSettingsModalData } from '@js/store/modals/gridSettingsModalAtom';
import { useSetAtom } from 'jotai';

interface Props {
    modalData: GridSettingsModalData;
}

const GridSettingsModalInner: FC<Props> = ({ modalData }) => {
    const { onSettingsSaved, columns, defaultColumns } = modalData;
    const setModalState = useSetAtom(gridSettingsModalAtom);
    const [orderedColumns, setOrderedColumns] = useState<any[]>([]);
    const [hiddenColumns, setHiddenColumns] = useState<VisibilityState>({});
    const { t } = useTranslation();

    const closeModal = () => {
        setModalState(closedModalState);
    };

    useEffect(() => {
        if (!columns?.length) {
            return;
        }
        setOrderedColumns(columns);

        const hiddenColumns: VisibilityState = {};
        columns
            .filter((column: any) => !column.getIsVisible())
            .forEach((column: any) => {
                hiddenColumns[column.id] = false;
            });

        setHiddenColumns(hiddenColumns);
    }, [columns]);

    const handleConfirm = () => {
        onSettingsSaved(orderedColumns, hiddenColumns);
        closeModal();
    };

    const moveListItem = useCallback((dragIndex: number, hoverIndex: number) => {
        setOrderedColumns((prevColumns: any[]) => update(prevColumns, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, prevColumns[dragIndex]],
            ],
        }));
    }, []);

    const onReset = () => {
        // Reset order
        const originalOrder: any[] = [];
        defaultColumns.forEach((column: any) => {
            const col = orderedColumns.find((c) => c.id === column.accessorKey);
            if (!col) {
                return;
            }
            originalOrder.push(col);
        });

        setOrderedColumns(originalOrder);

        // Reset visibility
        setHiddenColumns({});
    };

    const onToggleColumn = (columnId: string) => {
        if (hiddenColumns[columnId] === false) {
            setHiddenColumns((hiddenColumns) => {
                const newHiddenColumns: VisibilityState = {};
                Object.entries(hiddenColumns).forEach(([key]) => {
                    if (key === columnId) {
                        return;
                    }
                    newHiddenColumns[key] = false;
                });
                return newHiddenColumns;
            });
            return;
        }

        setHiddenColumns((columns) => {
            return {
                ...columns,
                [columnId]: false,
            };
        });
    };

    const renderColumn = useCallback((column: Column<RowData, unknown>, index: number) => {
        return (
            <SortableColumn
                index={index}
                column={column}
                key={column.id}
                moveListItem={moveListItem}
                onToggleColumn={onToggleColumn}
                disableToggleButton={(Object.entries(hiddenColumns).length + 1) >= columns.length}
                columnHidden={hiddenColumns[column.id] === false}
            />
        );
    }, [hiddenColumns]);

    return (
        <>
            <ModalContent>
                <DndProvider backend={HTML5Backend}>
                    {columns.length ? (
                        <>
                            {orderedColumns.map((column, index: number) => renderColumn(column, index))}
                        </>
                    ) : null}
                </DndProvider>
            </ModalContent>
            <ModalFooter>
                <Button type="submit" onClick={handleConfirm} primary>{t('save')}</Button>
                <Button isLink onClick={() => closeModal()}>{t('cancel')}</Button>
                <Button className="button--right" onClick={onReset} secondary>{t('reset')}</Button>
            </ModalFooter>
        </>
    );
};

export default GridSettingsModalInner;
