import { session } from '@utils/storage';

const getStorageKey = (name: LazyRetryNames): string => {
    return `retry-lazy-refreshed-${name}`;
};

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = (componentImport: () => Promise<any>, name: LazyRetryNames): Promise<any> => {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            session.getItem(getStorageKey(name)) || 'false',
        );
        // try to import the component
        componentImport().then((component) => {
            session.removeItem(getStorageKey(name)); // success so reset the refresh
            resolve(component);
        }).catch((error) => {
            if (!hasRefreshed) { // not been refreshed yet
                session.setItem(getStorageKey(name), 'true'); // we are now going to refresh
                return window.location.reload(); // refresh the page
            }
            return reject(error); // Default error behaviour as already tried refresh
        });
    });
};

export enum LazyRetryNames {
    Login = 'Login',
    Dashboard = 'Dashboard',
    List = 'List',
    Document = 'Document',
    FirstDocumentPage = 'FirstDocumentPage',
    AllDocumentProcessed = 'AllDocumentProcessed',
    Settings = 'Settings',
    ImportProfilesSettings = 'ImportProfilesSettings',
    ImportProfile = 'ImportProfile',
    EwsCallback = 'EwsCallback',
    EwsSelectFolders = 'EwsSelectFolders',
    Support = 'Support',
    Search = 'Search',
    FileHandler = 'FileHandler',
    TechnicalInformation = 'TechnicalInformation',
    Faq = 'Faq',
    Videos = 'Videos',
    Introduction = 'Introduction',
    Contact = 'Contact',
    ReleaseNotes = 'ReleaseNotes',
    PageNotFound = 'PageNotFound',
}

export default lazyRetry;
