import { formatRelative, format } from 'date-fns';
import { getCurrentLocale } from '@utils/locales';

const getDefaultOptions = () => {
    return {
        locale: getCurrentLocale().dateLocale,
    };
};

export const formatDate = (date: string, relative?: boolean): string => {
    const currentLocale = getCurrentLocale();

    if (!date) {
        return '';
    }

    if (relative) {
        return formatRelative(new Date(date), new Date(), getDefaultOptions());
    }

    return format(new Date(date), currentLocale.dateFormat);
};

export const formatDateTime = (date: string, relative?: boolean, showSeconds?: boolean): string => {
    const currentLocale = getCurrentLocale();

    if (!date) {
        return '';
    }

    if (relative) {
        return formatRelative(new Date(date), new Date(), getDefaultOptions());
    }

    if (showSeconds) {
        return format(new Date(date), currentLocale.dateTimeAccurateFormat);
    }

    return format(new Date(date), currentLocale.dateTimeFormat);
};
