import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { Replacements } from '@models/Replacements';
import { fetchReplacements } from '@repository/replacementsRepository';
import { minutesToMillis } from '@utils/millis';

const useFetchReplacements = (): UseQueryResult<Replacements> => {
    return useQuery({
        queryKey: [QueryKey.Replacements],
        queryFn: ({ signal }) => fetchReplacements(signal),
        staleTime: minutesToMillis(30),
    });
};

export default useFetchReplacements;
