import { FC } from 'react';
import {
    RenderPage,
    Viewer,
    Worker,
} from '@react-pdf-viewer/core';
import {
    RenderThumbnailItemProps,
    thumbnailPlugin,
} from '@react-pdf-viewer/thumbnail';
import Thumbnail from '@components/features/pdfViewer/components/thumbnail/Thumbnail';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { RenderZoomOutProps } from '@react-pdf-viewer/zoom';
import ZoomPlusIcon from '@images/svg/zoomPlus.svg?react';
import ZoomMinusIcon from '@images/svg/zoomMinus.svg?react';
import Throbber from '@components/primitives/throbber/Throbber';
import Button from '@components/primitives/button/Button';
import ToggleList, { ToggleListPosition } from '@components/primitives/toggleList/ToggleList';
import { useTranslation } from 'react-i18next';
import { File } from '@models/File';
import { pdfWorkerUrl } from '@constants/pdf';
import useZoom from '@components/features/pdfViewer/hooks/useZoom';

interface Props {
    file?: File;
    token?: string | null;
    storageKey?: string;
    renderPage?: RenderPage;
    defaultZoomLevel?: number;
}

const PdfViewer: FC<Props> = (
    {
        file,
        token,
        storageKey,
        renderPage,
        defaultZoomLevel,
    },
) => {
    const { t } = useTranslation();
    const { ZoomIn, ZoomOut, zoomLevel, zoomLevels, onZoom, zoomPluginInstance } = useZoom({
        defaultZoomLevel,
        storageKey,
    });
    const thumbnailPluginInstance = thumbnailPlugin({
        renderSpinner: () => <div className="pdfViewer__thumbnail pdfViewer__thumbnail--loading" />,
    });
    const { Thumbnails } = thumbnailPluginInstance;

    return (
        <Worker workerUrl={pdfWorkerUrl}>
            <div className="pdfViewer">
                {file ? (
                    <>
                        <div className="pdfViewer__content">
                            <div className="pdfViewer__zoom">
                                <ZoomOut>
                                    {(props: RenderZoomOutProps) => (
                                        <ZoomMinusIcon onClick={props.onClick} />
                                    )}
                                </ZoomOut>
                                <ToggleList
                                    position={ToggleListPosition.Top}
                                    list={zoomLevels}
                                    trigger={(toggle) => (
                                        <Button
                                            hideDisabledState
                                            className="pdfViewer__zoomList"
                                            onClick={toggle}
                                            title={t('moreActions.button', { ns: 'workflowActions' })}
                                        >
                                            {typeof zoomLevel === 'number' ? `${Math.round(zoomLevel * 100)}%` : ''}
                                        </Button>
                                    )}
                                />
                                <ZoomIn>
                                    {(props: RenderZoomOutProps) => (
                                        <ZoomPlusIcon onClick={props.onClick} />
                                    )}
                                </ZoomIn>
                            </div>
                            <Viewer
                                defaultScale={+zoomLevel}
                                renderPage={renderPage}
                                renderError={(error) => {
                                    console.error(error);
                                    return <>Error</>;
                                }}
                                renderLoader={() => (
                                    <Throbber />
                                )}
                                onZoom={onZoom}
                                fileUrl={file?.url}
                                httpHeaders={
                                    token
                                        ? {
                                            Authorization: `Bearer ${token}`,
                                        }
                                        : undefined
                                }
                                plugins={[
                                    thumbnailPluginInstance,
                                    zoomPluginInstance,
                                ]}
                            />
                        </div>
                        <div className="pdfViewer__thumbnails">
                            <Thumbnails
                                renderThumbnailItem={(props: RenderThumbnailItemProps) => (
                                    <Thumbnail
                                        key={props.key}
                                        currentPage={props.currentPage}
                                        numPages={props.numPages}
                                        pageIndex={props.pageIndex}
                                        renderPageLabel={props.renderPageLabel}
                                        renderPageThumbnail={props.renderPageThumbnail}
                                        onJumpToPage={props.onJumpToPage}
                                        onRotatePage={props.onRotatePage}
                                    />
                                )}
                            />
                        </div>
                    </>
                ) : null}
            </div>
        </Worker>
    );
};

export default PdfViewer;
