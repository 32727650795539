import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { fetchDocument } from '@repository/documentRepository';
import { Document } from '@models/Document';
import { secondsToMillis } from '@utils/millis';

const useFetchDocument = (documentId?: number, editMode?: boolean): UseQueryResult<Document> => {
    const isEditMode = editMode || false;
    return useQuery({
        queryKey: [QueryKey.Document, documentId, isEditMode],
        queryFn: (documentId ? ({ signal }) => fetchDocument(documentId, isEditMode, signal) : undefined),
        staleTime: secondsToMillis(5),
        enabled: !!documentId,
        retry: 0,
    });
};

export default useFetchDocument;
