import { Templates } from '@models/SharedTemplates';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { minutesToMillis } from '@utils/millis';
import { fetchSharedTemplates } from '@repository/templateSharingRepository';
import { featureFlagTemplateSharing as templateSharingEnabled } from '@utils/featureFlags';

const useFetchSharedTemplates = (documentId: number, limitToEnabledTemplates: boolean = true): UseQueryResult<Templates> => {
    return useQuery({
        queryKey: [QueryKey.SharedTemplates, documentId, limitToEnabledTemplates],
        queryFn: templateSharingEnabled ? ({ signal }) => fetchSharedTemplates(documentId, limitToEnabledTemplates, signal) : undefined,
        staleTime: minutesToMillis(2),
        enabled: !!documentId && templateSharingEnabled,
    });
};

export default useFetchSharedTemplates;
