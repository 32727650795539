import { atom, SetStateAction } from 'jotai';
import { editDocumentModalAtom } from '@js/store/modals/editDocumentModalAtonm';

interface OpenEditDocumentModalAtomProps {
    isOpen: boolean;
    data: {
        documentId: number
    };
}

export const openEditDocumentModalAtom = atom(
    null,
    async (get: any, set: SetStateAction<any>, props: OpenEditDocumentModalAtomProps) => {
        const setEditDocumentModal = (props: any) => set(editDocumentModalAtom, props);
        setEditDocumentModal({
            isOpen: true,
            data: {
                documentId: props.data.documentId,
            },
        });
    },
);
export const initialPageAtom = atom(0);
