import { atom } from 'jotai';

export interface ReadOnlyFields {
    [key: string]: boolean;
}

export interface RequiredFields {
    [key: string]: boolean;
}

export interface CodingPropsLine {
    readOnlyFields: ReadOnlyFields,
    requiredFields: RequiredFields,
}

export interface CodingPropsLines {
    [key: number]: CodingPropsLine;
}
export interface CodingPropsDocuments {
    [key: number]: CodingPropsLines
}

export const codingFieldPropsAtom = atom<CodingPropsDocuments>({});
