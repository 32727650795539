import { useAtomValue } from 'jotai';
import { currentLineSourceIdAtom, SamLineSourceId, samLineSourcesAtom } from '@js/store/samAtoms';

const useSamLineSource = () => {
    const currentLineSourceId = useAtomValue(currentLineSourceIdAtom);
    const samLineSources = useAtomValue(samLineSourcesAtom);

    const getSamLineSource = (samLineSourceId: SamLineSourceId) => {
        return samLineSources.find((source) => source.id === samLineSourceId);
    };

    const currentSamLineSource = getSamLineSource(currentLineSourceId);

    return {
        samLineSources,
        getSamLineSource,
        currentSamLineSource,
    };
};

export default useSamLineSource;
