import { FC, useEffect, useState } from 'react';
import useFetchReplacements from '@components/features/replacements/hooks/useFetchReplacements';
import Select from 'react-select';
import CustomReactSelectInput from '@components/primitives/form/components/CustomReactSelectInput';
import CustomReactSelectNoOptions from '@components/primitives/form/components/CustomReactSelectNoOptions';
import FormRow from '@components/primitives/form/FormRow';
import { useTranslation } from 'react-i18next';
import useUpdateReplacements from '@components/features/replacements/hooks/useUpdateReplacements';
import DatePicker, { registerLocale } from 'react-datepicker';
import { getCurrentLocale } from '@utils/locales';
import useDeleteReplacements from '@components/features/replacements/hooks/useDeleteReplacements';
import useFetchCurrentUser from '@components/features/currentUser/hooks/useFetchCurrentUser';
import Message from '@components/primitives/message/Message';

type Option = {
    label: string;
    value: string;
};

const Replacements: FC = () => {
    const { data: user } = useFetchCurrentUser();
    const { t } = useTranslation();
    const currentLocale = getCurrentLocale();
    registerLocale(currentLocale.code, currentLocale.dateLocale);
    const { data: replacements } = useFetchReplacements();
    const { mutate: updateReplacements } = useUpdateReplacements();
    const { mutate: deleteReplacements } = useDeleteReplacements();
    const [currentReplacement, setCurrentReplacement] = useState<string | null>(null);
    const [useEndDate, setUseEndDate] = useState<boolean>(false);
    const [endDate, setEndDate] = useState<string | null>(null);

    const sortOnFullName = (a: Option, b: Option) => {
        return a.label.toLowerCase() < b.label.toLowerCase() ? -1 : a.label.toLowerCase() > b.label.toLowerCase() ? 1 : 0;
    };

    useEffect(() => {
        if (!replacements?.currentReplacement?.id) {
            return;
        }
        setCurrentReplacement(replacements?.currentReplacement?.id);

        if (!replacements?.currentReplacement?.endDate) {
            return;
        }
        setEndDate(replacements?.currentReplacement?.endDate);
        setUseEndDate(true);
    }, [replacements]);

    const options = replacements?.list.map((item) => {
        return {
            label: item.name,
            value: item.id,
        };
    }).sort(sortOnFullName);

    const updateReplacement = async () => {
        if (!currentReplacement) {
            if (replacements?.currentReplacement.id) {
                await deleteReplacements();
            }
            return;
        }
        await updateReplacements({
            userid: currentReplacement,
            ...(endDate && useEndDate ? { endDate } : null),
        });
    };

    const handleChange = async (option: Option | null) => {
        setCurrentReplacement(option?.value || '');
    };

    const handleUseEndDateChange = async () => {
        setUseEndDate((useEndDate) => {
            return !useEndDate;
        });
    };

    const handleEndDateChange = async (date: any) => {
        setEndDate(date?.toISOString());
    };

    useEffect(() => {
        if (currentReplacement === replacements?.currentReplacement.id || currentReplacement === null) {
            return;
        }
        updateReplacement();
    }, [currentReplacement]);

    useEffect(() => {
        if (endDate === replacements?.currentReplacement.endDate || endDate === null) {
            return;
        }
        updateReplacement();
    }, [endDate]);

    useEffect(() => {
        if (!useEndDate && endDate !== null) {
            setEndDate(null);
            updateReplacement();
        }
    }, [useEndDate]);

    return (
        <>
            <FormRow name="language" label={t('replacements.label', { ns: 'settings' })}>
                <div className="form__field">
                    <Select
                        components={{
                            Input: CustomReactSelectInput,
                        }}
                        inputId="language"
                        isClearable
                        isDisabled={!user?.allowSetReplacement}
                        className="form__select"
                        placeholder={t('select')}
                        classNamePrefix="form__select"
                        onChange={handleChange}
                        value={options?.find((option) => option?.value === currentReplacement)}
                        options={options}
                        noOptionsMessage={() => <CustomReactSelectNoOptions />}
                    />
                </div>
            </FormRow>
            {currentReplacement ? (
                <FormRow name="language" label={' '}>
                    <div className="form__field replacements__settings">
                        <div className="replacements__endDateToggle">
                            <input
                                name="useEndDate"
                                id="useEndDate"
                                type="checkbox"
                                checked={useEndDate}
                                onChange={handleUseEndDateChange}
                                disabled={!user?.allowSetReplacement}
                            />
                            <label htmlFor="useEndDate">{t('replacements.useEndDate', { ns: 'settings' })}</label>
                        </div>
                        {useEndDate ? (
                            <DatePicker
                                className="form__date"
                                id="endDate"
                                selected={endDate ? new Date(endDate) : null}
                                onChange={handleEndDateChange}
                                readOnly={!user?.allowSetReplacement}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                locale={currentLocale.code}
                                dateFormat={currentLocale.dateFormat}
                                preventOpenOnFocus
                                todayButton={t('today')}
                            />
                        ) : null}
                    </div>
                </FormRow>
            ) : null }
            {!user?.allowSetReplacement ? (
                <FormRow label={' '}>
                    <div className="form__field">
                        <Message isInfo>{t('replacements.notAllowedMessage', { ns: 'settings' })}</Message>
                    </div>
                </FormRow>
            ) : null}
        </>
    );
};

export default Replacements;
