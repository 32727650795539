import React, { FC, useEffect } from 'react';
import useBackendLanguage from '@hooks/useBackendLanguage';
import useToken from '@hooks/useToken';
import { decodeToken } from '@repository/accessTokenRepository';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useMsal } from '@azure/msal-react';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import PrivateRoutes from '@js/PrivateRoutes';
import log, { LogContext } from '@utils/logging';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { disableUserSelectionAtom } from '@js/store/appAtoms';
import useSentry from '@components/features/sentry/hooks/useSentry';
import Sam from '@components/features/sam/Sam';

const App: FC = () => {
    const { token } = useToken();
    const [cookies, setCookie, removeCookie] = useCookies();
    const { pushInstruction } = useMatomo();
    const { accounts } = useMsal();
    const { setSentryUser } = useSentry();
    const currentAccount = accounts.length ? accounts[0] : null;
    const { t } = useTranslation();
    const disableUserSelection = useAtomValue(disableUserSelectionAtom);

    useBackendLanguage();

    useEffect(() => {
        setSentryUser(currentAccount?.username ? { username: currentAccount?.username } : null);
        if (!currentAccount?.username) {
            return;
        }
        pushInstruction('setUserId', currentAccount?.username);
    }, [currentAccount?.username]);

    const storeClientVersion = (newToken: string) => {
        const decodedToken = decodeToken(newToken);
        // Remove clientversion cookie and refresh page when clientversion in token is empty.
        if (!decodedToken?.clientversion && cookies?.clientversion) {
            log({
                context: LogContext.User,
                title: 'Removed clientVersion cookie',
                reason: 'No clientVersion available in token',
                data: {
                    removedClientVersion: cookies.clientversion,
                },
            });
            removeCookie('clientversion');
            window.location.reload();
            return;
        }

        // Do nothing when clientversion from cookie is the same as clientversion from token. Everything is fine.
        if (!decodedToken?.clientversion || cookies?.clientversion === decodedToken?.clientversion) {
            return;
        }

        // Update cookie and refresh page when clientversion from token differs from clientversion from cookie.
        log({
            context: LogContext.User,
            title: 'Update clientVersion cookie',
            reason: 'clientVersion from token differs from clientVersion from cookie',
            data: {
                oldClientVersion: cookies.clientversion,
                newClientVersion: decodedToken.clientversion,
            },
        });
        setCookie('clientversion', decodedToken.clientversion, {
            path: '/',
        });
        window.location.reload();
    };

    useEffect(() => {
        if (!token) {
            return;
        }

        storeClientVersion(token);
    }, [token]);

    return (
        <div
            className={classNames('app', {
                'app--disableUserSelection': disableUserSelection,
            })}
        >
            <Helmet>
                <meta name="description" content={t('meta.description')} />
            </Helmet>
            <PrivateRoutes />
            <div className="modals" />
            <Sam />
        </div>
    );
};

export default App;
