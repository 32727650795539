import { executeGetRequest, executePostRequest } from '@api/client';
import { createFetchFilePath, createFetchFileRepairedPath, createRepairFilePath } from '@routing/apiUrls';
import { File } from '@models/File';

export const fetchFile = async (documentId: number, signal?: AbortSignal): Promise<File> => {
    const result = await executeGetRequest<File>(createFetchFilePath(documentId), signal, {
        headers: {
            Accept: 'application/json',
        },
    });

    return result.data;
};

export const fetchFileRepaired = async (documentId: number, signal?: AbortSignal): Promise<File> => {
    const result = await executeGetRequest<File>(createFetchFileRepairedPath(documentId), signal, {
        headers: {
            Accept: 'application/json',
        },
    });

    return result.data;
};

export const repairFile = async (documentId: number): Promise<File> => {
    const result = await executePostRequest<File>(createRepairFilePath(documentId), {});

    return result.data;
};
