import React, { FC } from 'react';
import MinusIcon from '@images/svg/minus.svg?react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface Props {
    addingLine: boolean;
    deletingLine: boolean;
    isReadOnlyMode: boolean;
    linesAreSelected: boolean;
    onDeleteLine: () => void;
}

const DeleteCodingLineButton: FC<Props> = ({ deletingLine, onDeleteLine, linesAreSelected, addingLine, isReadOnlyMode }) => {
    const { t } = useTranslation();

    return (
        <button
            type="button"
            className={classNames('buttonStrip__button', {
                'buttonStrip__button--loading': deletingLine,
            })}
            onClick={() => onDeleteLine()}
            title={t('delete.button', { ns: 'coding' })}
            disabled={!linesAreSelected || isReadOnlyMode || deletingLine || addingLine}
        >
            <MinusIcon />
        </button>
    );
};

export default DeleteCodingLineButton;
