import { FC, useEffect, useState } from 'react';
import SelectIcon from '@images/svg/select.svg?react';
import FormRow from '@components/primitives/form/FormRow';
import ModalContent from '@components/primitives/modal/components/ModalContent';
import Button from '@components/primitives/button/Button';
import ModalFooter from '@components/primitives/modal/components/ModalFooter';
import { useTranslation } from 'react-i18next';
import useDeleteOcrTrainingField from '@components/features/ocrTraining/hooks/useDeleteOcrTrainingField';
import FormInput from '@components/primitives/form/FormInput';
import { FormProvider, useForm } from 'react-hook-form';
import { OcrTainingContext, OcrTrainingField, OcrTrainingFieldForm, SelectingOcrTrainingFieldContext } from '@models/OcrTraining';
import useUpdateOcrTrainingField from '@components/features/ocrTraining/hooks/useUpdateOcrTrainingField';
import useCreateOcrTrainingField from '@components/features/ocrTraining/hooks/useCreateOcrTrainingField';
import FormCheckbox from '@components/primitives/form/FormCheckbox';
import FormLabel from '@components/primitives/form/FormLabel';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import useCreateOcrTrainingFieldValidationSchema from '@components/modals/ocrTrainingFieldModal/validation/useCreateOcrTrainingFieldValidationSchema';
import FormError from '@components/primitives/form/FormError';
import FormRadioButtons from '@components/primitives/form/FormRadioButtons';
import useUpdateDocumentHeader from '@components/features/documentHeaders/hooks/useUpdateDocumentHeader';
import { QueryKey } from '@constants/queryKey';
import { useQueryClient } from '@tanstack/react-query';
import { closedModalState } from '@js/store/modalAtoms';
import { useSetAtom } from 'jotai';
import { ocrTrainingFieldModalAtom, OcrTrainingFieldModalData } from '@js/store/modals/ocrTrainingFieldModalAtom';

interface Props {
    modalData: OcrTrainingFieldModalData;
}

export enum Types {
    Dynamic = 'Dynamic',
    Static = 'Static',
    Empty = 'Empty',
}

export enum Structure {
    FreeText = 'FreeText',
    LikeValue = 'LikeValue',
    Regex = 'Regex',
}

export const emptyOcrTrainingField: OcrTrainingField = {
    fieldId: '',
    isFreeText: true,
    sampleValue: '',
    allowMoveVertical: false,
    searchAllPages: false,
    regEx: '',
    regExPart: '',
    refwordText: '',
    refwordZone: '',
    isGroupField: false,
    isAboveLine: false,
    isBelowLine: false,
    isMultiLine: false,
    forceSampleValue: false,
    isKeyField: false,
    page: 0,
    zone: '',
};

const OcrTrainingFieldModalInner: FC<Props> = ({ modalData }) => {
    const { documentId, trainingField } = modalData;
    const { format, field } = trainingField;
    const setModalState = useSetAtom(ocrTrainingFieldModalAtom);
    const client = useQueryClient();
    const isEditMode = !!trainingField?.field?.fieldId;
    const { t } = useTranslation();
    const { mutate: deleteOcrTrainingField, isPending: isDeletingOcrTrainingField } = useDeleteOcrTrainingField(documentId);
    const { mutate: updateOcrTrainingField, isPending: isUpdatingOcrTrainingField } = useUpdateOcrTrainingField(documentId);
    const { mutate: createOcrTrainingField, isPending: isCreatingOcrTrainingField } = useCreateOcrTrainingField(documentId);
    const { mutate: updateDocumentHeader } = useUpdateDocumentHeader(documentId);
    const [type, setType] = useState<Types>(Types.Dynamic);

    const getInitialStructure = (): Structure => {
        if (!trainingField?.field?.fieldId) {
            return Structure.FreeText;
        }

        if (trainingField.field.isFreeText) {
            return Structure.FreeText;
        }

        if (trainingField.field.regEx) {
            return Structure.Regex;
        }

        return Structure.LikeValue;
    };

    const [structure, setStructure] = useState<Structure>(getInitialStructure());
    const schema = useCreateOcrTrainingFieldValidationSchema(type, structure);

    const methods = useForm<OcrTrainingFieldForm>({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: {
            ...emptyOcrTrainingField,
            fieldId: trainingField?.fieldId,
            isKeyField: field?.isKeyField || emptyOcrTrainingField.isKeyField,
            ...(isEditMode && field ? {
                ...field,
            } : {}),
        },
    });

    const { handleSubmit, trigger, setValue, getValues } = methods;

    useEffect(() => {
        if (!modalData.ocrValue) {
            return;
        }

        if (format === 'decimal') {
            setValue('sampleValue', modalData.ocrValue.text.replace(',', '.') || modalData.ocrValue.value?.replace(',', '.'));
        } else {
            setValue('sampleValue', modalData.ocrValue.text || modalData.ocrValue.value);
        }
        setValue('zone', modalData.ocrValue.zone);
        setValue('page', modalData.ocrValue.page);
        trigger('sampleValue');
        trigger('zone');
    }, [modalData.ocrValue]);

    useEffect(() => {
        if (!modalData.ocrNearbyValue) {
            return;
        }

        setValue('refwordText', modalData.ocrNearbyValue.text || modalData.ocrNearbyValue.value);
        setValue('refwordZone', modalData.ocrNearbyValue.zone);
        trigger('refwordText');
        trigger('refwordZone');

        if (modalData.trainingField?.context === OcrTainingContext.Headers) {
            setValue('allowMoveVertical', true);
            trigger('allowMoveVertical');
        }
    }, [modalData.ocrNearbyValue]);

    useEffect(() => {
        setValue('forceSampleValue', type !== Types.Dynamic);

        if (type !== Types.Dynamic) {
            setValue('isFreeText', false);
            setValue('page', 0);
        }

        if (type === Types.Empty) {
            setValue('sampleValue', '');
        }
    }, [type]);

    useEffect(() => {
        if (format !== 'decimal' && format !== 'date') {
            return;
        }
        setValue('isMultiLine', false);
    }, [format]);

    useEffect(() => {
        setValue('isFreeText', structure === Structure.FreeText);

        // Reset regEx and regExPart when changing the structure.
        // Otherwise, we are not able to set the defaultValue of structure.
        if (structure !== Structure.Regex) {
            setValue('regEx', '');
            setValue('regExPart', '');
        }

        if (structure === Structure.FreeText) {
            trigger('sampleValue');
        }

        // Disable isMultiLine when structure is regex
        if (structure === Structure.Regex) {
            setValue('isMultiLine', false);
        }
    }, [structure]);

    useEffect(() => {
        if (!field || !isEditMode) {
            return;
        }
        if (field.forceSampleValue) {
            if (!field.sampleValue) {
                setType(Types.Empty);
            } else {
                setType(Types.Static);
            }
        }

        if (field.isFreeText) {
            setStructure(Structure.FreeText);
        } else if (!field.regEx) {
            setStructure(Structure.LikeValue);
        } else if (field.regEx) {
            setStructure(Structure.Regex);
        }

        if (field.zone && field.sampleValue) {
            setModalState((modalState) => {
                if (!modalState.data) {
                    return modalState;
                }

                return {
                    ...modalState,
                    data: {
                        ...modalState.data,
                        ocrValue: {
                            zone: field.zone,
                            value: field.sampleValue,
                            text: field.sampleValue,
                            page: field.page,
                        },
                    },
                };
            });
        }

        if (field.refwordZone && field.refwordText) {
            setModalState((modalState) => {
                if (!modalState.data) {
                    return modalState;
                }

                return {
                    ...modalState,
                    data: {
                        ...modalState.data,
                        ocrNearbyValue: {
                            zone: field.refwordZone,
                            value: field.refwordText,
                            text: field.refwordText,
                            page: field.page,
                        },
                    },
                };
            });
        }
    }, [field]);

    const closeModal = () => {
        setModalState(closedModalState);
    };

    const updateField = async (data: OcrTrainingFieldForm) => {
        if (!trainingField) {
            return;
        }
        const newValue = type === Types.Empty ? '' : data.sampleValue || '';

        if (trainingField.coding || trainingField.matching) {
            closeModal();
        } else {
            updateDocumentHeader({
                values: [{
                    id: trainingField.fieldId,
                    value: newValue,
                    ...(data.zone ? {
                        zone: data.zone,
                    } : {}),
                }],
            }, {
                onSuccess: async () => {
                    await client.invalidateQueries({
                        queryKey: [QueryKey.DocumentHeaders, documentId],
                    }).then(() => {
                        closeModal();
                    });
                },
            });
        }
    };

    const onSubmit = async (data: OcrTrainingFieldForm) => {
        if (isEditMode) {
            updateOcrTrainingField(data, {
                onSuccess: async () => {
                    await updateField(data);
                },
            });
        } else {
            createOcrTrainingField(data, {
                onSuccess: async () => {
                    await updateField(data);
                },
            });
        }
    };

    const onSelectOcr = (context: SelectingOcrTrainingFieldContext) => {
        setModalState((modalState) => {
            if (!modalState.data) {
                return modalState;
            }

            return {
                ...modalState,
                data: {
                    ...modalState.data,
                    context,
                },
                isHidden: true,
            };
        });
    };

    const onDelete = async () => {
        if (!isEditMode) {
            return;
        }

        deleteOcrTrainingField(trainingField.fieldId, {
            onSuccess: () => {
                closeModal();
            },
        });
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="false">
                <ModalContent>
                    <FormRow label={t('fieldModal.form.type.label', { ns: 'ocrTraining' })}>
                        <div className="form__radiobuttons">
                            <div className="form__field form__field--radio">
                                <label htmlFor={Types.Dynamic}>
                                    <input
                                        className="form__radiobutton"
                                        type="radio"
                                        checked={type === Types.Dynamic}
                                        onChange={() => setType(Types.Dynamic)}
                                        id={Types.Dynamic}
                                        name="type"
                                    />
                                    <span className="form__radiobutton-shadow" />
                                </label>
                                <FormLabel name={Types.Dynamic}>
                                    {t('fieldModal.form.type.dynamic', { ns: 'ocrTraining' })}
                                </FormLabel>
                            </div>
                            <div className="form__field form__field--radio">
                                <label htmlFor={Types.Static}>
                                    <input
                                        className="form__radiobutton"
                                        type="radio"
                                        checked={type === Types.Static}
                                        onChange={() => setType(Types.Static)}
                                        id={Types.Static}
                                        name="type"
                                    />
                                    <span className="form__radiobutton-shadow" />
                                </label>
                                <FormLabel name={Types.Static}>
                                    {t('fieldModal.form.type.static', { ns: 'ocrTraining' })}
                                </FormLabel>
                            </div>
                            <div className="form__field form__field--radio">
                                <label htmlFor={Types.Empty}>
                                    <input
                                        className="form__radiobutton"
                                        type="radio"
                                        checked={type === Types.Empty}
                                        onChange={() => setType(Types.Empty)}
                                        id={Types.Empty}
                                        name="type"
                                    />
                                    <span className="form__radiobutton-shadow" />
                                </label>
                                <FormLabel name={Types.Empty}>
                                    {t('fieldModal.form.type.empty', { ns: 'ocrTraining' })}
                                </FormLabel>
                            </div>
                        </div>
                    </FormRow>
                    {type !== Types.Empty ? (
                        <>
                            <FormRow label={t('fieldModal.form.sampleValue', { ns: 'ocrTraining' })}>
                                <div className="form__selectInputWrapper">
                                    <div className="form__selectInput">
                                        <FormInput
                                            showValidationError={false}
                                            name="sampleValue"
                                            type={format === 'decimal' ? 'number' : 'text'}
                                            isReadOnly={!(type === Types.Static || structure === Structure.LikeValue)}
                                        />
                                        <Button
                                            type="button"
                                            onClick={() => onSelectOcr(SelectingOcrTrainingFieldContext.Value)}
                                            className="button--select"
                                            secondary
                                            title={t('fieldModal.selectZone', { ns: 'ocrTraining' })}
                                        >
                                            <SelectIcon />
                                        </Button>
                                    </div>
                                    <FormError name="sampleValue" />
                                    <div>
                                        <span className="form__tip">
                                            {!getValues('zone') || getValues('zone') === '0 0 0 0' ? (
                                                <>{t('fieldModal.noZoneSelectedTip', { ns: 'ocrTraining' })}</>
                                            ) : (
                                                <>{t('fieldModal.zoneSelectedTip', { ns: 'ocrTraining', zone: getValues('zone') })}</>
                                            )}
                                        </span>
                                        <FormError name="zone" />
                                    </div>
                                </div>
                            </FormRow>
                        </>
                    ) : null}
                    {type === Types.Dynamic ? (
                        <>
                            {format !== 'decimal' && format !== 'date' ? (
                                <FormRow label={t('fieldModal.form.structure.label', { ns: 'ocrTraining' })}>
                                    <div className="form__radiobuttons">
                                        <div className="form__field form__field--radio">
                                            <label htmlFor={Structure.FreeText}>
                                                <input
                                                    className="form__radiobutton"
                                                    type="radio"
                                                    checked={structure === Structure.FreeText}
                                                    onChange={() => setStructure(Structure.FreeText)}
                                                    id={Structure.FreeText}
                                                    name="structure"
                                                />
                                                <span className="form__radiobutton-shadow" />
                                            </label>
                                            <FormLabel name={Structure.FreeText}>
                                                {t('fieldModal.form.structure.freeText', { ns: 'ocrTraining' })}
                                            </FormLabel>
                                        </div>
                                        <div className="form__field form__field--radio">
                                            <label htmlFor={Structure.LikeValue}>
                                                <input
                                                    className="form__radiobutton"
                                                    type="radio"
                                                    checked={structure === Structure.LikeValue}
                                                    onChange={() => setStructure(Structure.LikeValue)}
                                                    id={Structure.LikeValue}
                                                    name="structure"
                                                />
                                                <span className="form__radiobutton-shadow" />
                                            </label>
                                            <FormLabel name={Structure.LikeValue}>
                                                {t('fieldModal.form.structure.likeValue', { ns: 'ocrTraining' })}
                                            </FormLabel>
                                        </div>
                                        <div className="form__field form__field--radio">
                                            <label htmlFor={Structure.Regex}>
                                                <input
                                                    className="form__radiobutton"
                                                    type="radio"
                                                    checked={structure === Structure.Regex}
                                                    onChange={() => setStructure(Structure.Regex)}
                                                    id={Structure.Regex}
                                                    name="structure"
                                                />
                                                <span className="form__radiobutton-shadow" />
                                            </label>
                                            <FormLabel name={Structure.Regex}>
                                                {t('fieldModal.form.structure.regex', { ns: 'ocrTraining' })}
                                            </FormLabel>
                                        </div>
                                    </div>
                                </FormRow>
                            ) : null}
                            {structure === Structure.Regex ? (
                                <>
                                    <FormRow label={t('fieldModal.form.regEx', { ns: 'ocrTraining' })}>
                                        <FormInput name="regEx" />
                                    </FormRow>
                                    <FormRow label={t('fieldModal.form.regExPart', { ns: 'ocrTraining' })}>
                                        <FormInput name="regExPart" />
                                    </FormRow>
                                </>
                            ) : null}
                            <FormRow label={t('fieldModal.form.refWordText', { ns: 'ocrTraining' })}>
                                <div className="form__selectInputWrapper">
                                    <div className="form__selectInput">
                                        <FormInput name="refwordText" />
                                        <Button
                                            type="button"
                                            onClick={() => onSelectOcr(SelectingOcrTrainingFieldContext.NearByValue)}
                                            className="button--select"
                                            title={t('fieldModal.selectRefWordZone', { ns: 'ocrTraining' })}
                                            secondary
                                        >
                                            <SelectIcon />
                                        </Button>
                                    </div>
                                    <div>
                                        <span className="form__tip">
                                            {!getValues('refwordZone') || getValues('refwordZone') === '0 0 0 0' ? (
                                                <>{t('fieldModal.noRefWordZoneSelectedTip', { ns: 'ocrTraining' })}</>
                                            ) : (
                                                <>{t('fieldModal.refWordZoneSelectedTip', { ns: 'ocrTraining', zone: getValues('refwordZone') })}</>
                                            )}
                                        </span>
                                        <FormError name="refwordZone" />
                                    </div>
                                </div>
                            </FormRow>
                            {trainingField?.context === OcrTainingContext.Headers ? (
                                <FormRow label={t('fieldModal.form.position.label', { ns: 'ocrTraining' })}>
                                    <div className="form__checkboxes">
                                        <FormCheckbox
                                            name="allowMoveVertical"
                                            isReadOnly={!getValues('refwordZone') || getValues('refwordZone') === '0 0 0 0'}
                                            label={t('fieldModal.form.position.allowMoveVertical', { ns: 'ocrTraining' })}
                                        />
                                        <FormCheckbox
                                            name="searchAllPages"
                                            label={t('fieldModal.form.position.searchAllPages', { ns: 'ocrTraining' })}
                                        />
                                    </div>
                                </FormRow>
                            ) : null}
                            {
                                trainingField?.context === OcrTainingContext.Coding
                                || trainingField?.context === OcrTainingContext.Matching
                                    ? (
                                        <>
                                            <FormRow label={t('fieldModal.form.tableOptions.label', { ns: 'ocrTraining' })}>
                                                <div className="form__checkboxes">
                                                    <FormCheckbox
                                                        name="isKeyField"
                                                        label={t('fieldModal.form.tableOptions.isKeyField', { ns: 'ocrTraining' })}
                                                    />
                                                    {format !== 'decimal' && format !== 'date' ? (
                                                        <FormCheckbox
                                                            name="isMultiLine"
                                                            isReadOnly={structure === Structure.Regex}
                                                            label={t('fieldModal.form.tableOptions.isMultiLine', { ns: 'ocrTraining' })}
                                                        />
                                                    ) : null}
                                                </div>
                                            </FormRow>
                                            <FormRow label={t('fieldModal.form.tablePosition.label', { ns: 'ocrTraining' })}>
                                                <FormRadioButtons
                                                    name="tablePosition"
                                                    defaultValue="isLineField"
                                                    options={[
                                                        {
                                                            // isHidden: format === 'date' || format === 'decimal',
                                                            id: 'isLineField',
                                                            label: t('fieldModal.form.tablePosition.isLineField', { ns: 'ocrTraining' }),
                                                        },
                                                        {
                                                            id: 'isGroupField',
                                                            label: t('fieldModal.form.tablePosition.isGroupField', { ns: 'ocrTraining' }),
                                                        },
                                                        {
                                                            id: 'isAboveLine',
                                                            label: t('fieldModal.form.tablePosition.isAboveLine', { ns: 'ocrTraining' }),
                                                        },
                                                        {
                                                            id: 'isBelowLine',
                                                            label: t('fieldModal.form.tablePosition.isBelowLine', { ns: 'ocrTraining' }),
                                                        },
                                                    ]}
                                                />
                                            </FormRow>
                                        </>
                                    ) : null
                            }
                        </>
                    ) : null}
                </ModalContent>
                <ModalFooter className="modal__footer--split">
                    <div>
                        <Button
                            type="submit"
                            disabled={isDeletingOcrTrainingField}
                            primary
                            isLoading={isEditMode ? isUpdatingOcrTrainingField : isCreatingOcrTrainingField}
                        >
                            {t('fieldModal.submit', { ns: 'ocrTraining' })}
                        </Button>
                        {/*<Button type="submit" onClick={onSubmit} secondary>{t('ocrTraining.fieldModal.submitOneTime')}</Button>*/}
                        <Button isLink onClick={closeModal}>{t('cancel')}</Button>
                    </div>
                    <div>
                        {isEditMode ? (
                            <Button
                                className="button--right"
                                isLink
                                warning
                                isLoading={isDeletingOcrTrainingField}
                                onClick={onDelete}
                            >
                                {t('fieldModal.delete', { ns: 'ocrTraining' })}
                            </Button>
                        ) : null}
                    </div>
                </ModalFooter>
            </form>
        </FormProvider>
    );
};

export default OcrTrainingFieldModalInner;
