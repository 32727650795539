import { FC, ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
    children?: ReactNode;
    isLight?: boolean;
    className?: string;
}

const Content: FC<Props> = ({ children, className, isLight = false }) => {
    const classes = classNames('content', className, {
        'content--light': isLight,
    });

    return (
        <div className={classes}>
            {/*<div className="content__navShape content__navShape--top" />*/}
            {/*<div className="content__navShape" />*/}
            {/*<div className="content__navShape content__navShape--bottom" />*/}
            {children}
        </div>
    );
};

export default Content;
