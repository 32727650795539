import { atom } from 'jotai';
import { closedModalState, ModalState } from '@js/store/modalAtoms';

export interface RefreshLookupModalData {
    documentId: number;
    title: string;
    fieldName: string;
    fieldLabel: string;
    lookup: string;
    onSuccess?: (value: string) => void;
}

export const refreshLookupModalAtom = atom<ModalState<RefreshLookupModalData>>(closedModalState);
