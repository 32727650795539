import { executeDeleteRequest, executeGetRequest, executePostRequest, executePutRequest } from '@api/client';
import { ImportProfile } from '@components/pages/settingsPage/components/tabs/importProfiles/ImportProfilesTab';
import { createDeleteImportProfilePath, createDuplicateImportProfilePath, createEwsAuthenticatePath, createGetEwsFoldersPath, createGetImportProfilePath, createGetImportProfilesPath, createUpdateImportProfilePath } from '@routing/apiUrls';

export const ewsAuthenticate = async (tenantId: string, callback: string, signal?: AbortSignal): Promise<any> => {
    const result = await executeGetRequest<any>(createEwsAuthenticatePath(tenantId, callback), signal);
    return result.data;
};

export const fetchEwsFolders = async (emailAddress: string, signal?: AbortSignal): Promise<any> => {
    const result = await executeGetRequest<any>(createGetEwsFoldersPath(emailAddress), signal);
    return result.data;
};

export const fetchImportProfiles = async (signal?: AbortSignal): Promise<any> => {
    const result = await executeGetRequest<any>(createGetImportProfilesPath(), signal);
    return result.data;
};

export const getImportProfile = async (id: string, signal?: AbortSignal): Promise<any> => {
    const result = await executeGetRequest<any>(createGetImportProfilePath(id), signal);
    return result.data;
};

export const updateImportProfile = async (id: string, importProfile: ImportProfile): Promise<any> => {
    const result = await executePutRequest<any>(createUpdateImportProfilePath(id), importProfile);
    return result.data;
};

export const deleteImportProfile = async (id: string): Promise<any> => {
    const result = await executeDeleteRequest<any>(createDeleteImportProfilePath(id));
    return result.data;
};

export const duplicateImportProfile = async (id: string, importProfile: ImportProfile): Promise<any> => {
    const result = await executePostRequest<any>(createDuplicateImportProfilePath(id), importProfile);
    return result.data;
};
