import * as yup from 'yup';
import { PerformDocumentActionFormType } from '@components/modals/performDocumentActionModal/PerformDocumentActionModalInner';
import { useTranslation } from 'react-i18next';

const usePerformDocumentActionSchema = (
    remarkRequired?: boolean,
    minNumDays?: number,
    maxNumDays?: number,
    userIdRequired?: boolean,
): yup.ObjectSchema<PerformDocumentActionFormType> => {
    const { t } = useTranslation();

    const schema = yup.object().shape({
        remark: yup.string().when('remarkRequired', {
            is: () => !!remarkRequired,
            then: (schema) => schema.trim().required(t('validation.remark.required', { ns: 'documentActions' })),
        }),
        numberDays: yup.number().when('numberDaysRequired', {
            is: () => !!minNumDays,
            then: (schema) => schema
                .typeError(t('validation.numberDays.typeError', { ns: 'documentActions' }))
                .min(minNumDays || 0, (val) => t('validation.numberDays.min', { ns: 'documentActions', min: val.min }))
                .max(maxNumDays || Infinity, (val) => t('validation.numberDays.max', { ns: 'documentActions', max: val.max }))
                .required(t('validation.numberDays.required', { ns: 'documentActions' })),
        }),
        userId: yup.string().when('testUserIdRequired', (_, stringSchema) => {
            return userIdRequired ? stringSchema.nullable().required(t('validation.userId.required', { ns: 'workflowActions' })) : stringSchema.nullable();
        }),
    });

    schema.describe({ value: { userIdRequired } });

    return schema;
};

export default usePerformDocumentActionSchema;
