import React, { FC } from 'react';
import { useSetAtom } from 'jotai/index';
import { samLineSourceModalAtom } from '@js/store/modals/samLineSourceModalAtom';
import useSamLineSource from '@components/modals/samLineSourceModal/hooks/useSamLineSource';
import useFetchSamCodingSources from '@components/modals/samLineSourceModal/hooks/useFetchSamCodingSources';

interface Props {
    documentId: number;
}

const SamLineSourceButton: FC<Props> = ({ documentId }) => {
    const { currentSamLineSource } = useSamLineSource();
    const { data: samCodingSources } = useFetchSamCodingSources(documentId);
    const setSamLineSourceModal = useSetAtom(samLineSourceModalAtom);
    const samLineSourceButtonVisible = samCodingSources && samCodingSources.samTenantLevel > 1;

    const onSamButtonClick = () => {
        setSamLineSourceModal({
            isOpen: true,
            data: {
                documentId,
            },
        });
    };

    if (!currentSamLineSource || !samLineSourceButtonVisible) {
        return null;
    }

    return (
        <button
            type="button"
            onClick={onSamButtonClick}
            className="SAM_lineSource__button"
            style={{
                background: `linear-gradient(90deg, ${currentSamLineSource.colors.primary.dark} 0%, ${currentSamLineSource.colors.primary.light} 100%)`,
            }}
        >
            {currentSamLineSource.icon}
            {currentSamLineSource.title}
        </button>
    );
};

export default SamLineSourceButton;
