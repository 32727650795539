import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Portal } from 'react-portal';
import classNames from 'classnames';
import { Sizes } from '@utils/sizes';
import Heading from '@components/primitives/heading/Heading';
import { HeadingTags } from '@utils/headingTags';
import CloseIcon from '@images/svg/close.svg?react';
import Throbber from '@components/primitives/throbber/Throbber';
import randomString from '@utils/randomString';
import { useHotkeys } from 'react-hotkeys-hook';
import { CLOSE_MODAL_HOTKEYS } from '@constants/hotkeys';
import { useTranslation } from 'react-i18next';
import useCloseOnLocationChange from '@components/primitives/modal/hooks/useCloseOnLocationChange';
import useMoveModal from '@components/primitives/modal/hooks/useMoveModal';
import { closedModalState, ModalState, SetModalState } from '@js/store/modalAtoms';

interface Props<T extends object> {
    children: ReactNode;
    modalState: ModalState<T>;
    setModalState: SetModalState<ModalState<T>>;
    className?: string;
    isLoading?: boolean;
    size?: Sizes;
    title?: string;
    subTitle?: string;
    onClose?: () => void;
}

const Modal = <T extends object>({ children, modalState, setModalState, className, isLoading, size, title, subTitle, onClose } : Props<T>) => {
    const { isOpen, isHidden } = modalState;
    const portalTarget = document.querySelectorAll('.modals')[0];
    const focusButton = useRef<HTMLButtonElement | null>(null);
    const [modalId] = useState(randomString());
    const { t } = useTranslation();
    useCloseOnLocationChange(() => setModalState(closedModalState));
    const { onMouseMove, onMouseUp, onMouseDown, style } = useMoveModal(isOpen);

    useEffect(() => {
        if (!portalTarget) {
            return;
        }

        if (!isHidden) {
            portalTarget.classList.remove('modals--isHidden');
        } else {
            portalTarget.classList.add('modals--isHidden');
        }

        if (!portalTarget.children.length) {
            portalTarget.classList.remove('modals--isOpen');
            return;
        }
        portalTarget.classList.add('modals--isOpen');
    }, [isOpen, isHidden]);

    useEffect(() => {
        return () => {
            if (!portalTarget) {
                return;
            }

            if (!portalTarget.children.length) {
                portalTarget.classList.remove('modals--isOpen');
            }
        };
    }, []);

    const classes = classNames('modal__wrapper', className, {
        'modal__wrapper--xxxl': size === Sizes.xxxl,
        'modal__wrapper--xxl': size === Sizes.xxl,
        'modal__wrapper--xl': size === Sizes.xl,
        'modal__wrapper--lg': size === Sizes.lg,
        'modal__wrapper--sm': size === Sizes.sm,
        'modal__wrapper--xs': size === Sizes.xs,
        'modal__wrapper--loading': isLoading,
    });

    const closeModal = () => {
        if (isHidden) {
            return;
        }
        if (onClose) {
            onClose();
        }
        setModalState(closedModalState);
    };

    useHotkeys(CLOSE_MODAL_HOTKEYS, () => {
        const modals = document.querySelectorAll('.modals')[0];
        // Only close last modal
        if (modals.lastElementChild?.id !== modalId) {
            return;
        }

        // Prevent closing parent modal
        setTimeout(() => {
            closeModal();
        }, 10);
    }, [modalId]);

    if (!isOpen) {
        return null;
    }

    return (
        <Portal node={portalTarget}>
            <div className="modal__container" onMouseMove={onMouseMove} id={modalId}>
                {isLoading ? (
                    <Throbber className="throbber--white" />
                ) : null}
                <div className="modal__overlay" onClick={closeModal} />
                <div
                    className={classes}
                    style={style}
                >
                    <div className="modal">
                        {title ? (
                            <div className="modal__title" onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
                                <Heading tag={HeadingTags.h3} size={Sizes.md} noMargin>
                                    {title}
                                    {subTitle ? (
                                        <span className="modal__subTitle">{subTitle}</span>
                                    ) : null}
                                </Heading>
                            </div>
                        ) : null}
                        <button type="button" ref={focusButton} className="modal__close" onClick={closeModal}><CloseIcon /></button>
                        {children}
                    </div>
                </div>
            </div>
            {isHidden ? (
                <button
                    title={title || t('modal.hidden.showModalButton')}
                    type="button"
                    className="modal__preventClick"
                    onClick={() => setModalState((modalState) => {
                        return {
                            ...modalState,
                            isHidden: false,
                        };
                    })}
                />
            ) : null}
        </Portal>
    );
};

export default Modal;
