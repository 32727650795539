import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const CustomReactSelectNoOptions: FC = () => {
    const { t } = useTranslation();

    return (
        <>{t('noOptions')}</>
    );
};

export default CustomReactSelectNoOptions;
