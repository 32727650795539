import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '@components/primitives/modal/Modal';
import useAsyncConfirmModal from '@components/modals/asyncConfirmModal/hooks/useAsyncConfirmModal';
import AsyncConfirmModalInner from '@components/modals/asyncConfirmModal/AsyncConfirmModalInner';

const AsyncConfirmModal: FC = () => {
    const { t } = useTranslation();
    const { show, setShow, asyncConfirmModalProps } = useAsyncConfirmModal();
    const { title, message, size } = asyncConfirmModalProps || {};

    const modalState = {
        isOpen: true,
        isHidden: false,
        data: {
            title,
            message,
        },
    };

    const handleCloseModal = () => {
        setShow(false);
    }

    if (!show) return null;

    return (
        <Modal
            modalState={modalState}
            size={size}
            title={title || t('modal.confirmModal.defaultTitle', { ns: 'global' })}
            setModalState={handleCloseModal}
        >
            <AsyncConfirmModalInner message={message} />
        </Modal>
    );
};

export default AsyncConfirmModal;
