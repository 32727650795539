import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { linkDocumentMatchingLine } from '@repository/documentMatchingRepository';
import { LinkMatchingLineForm } from '@models/DocumentMatching';

const useLinkMatchingLine = (documentId: number) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: (data: LinkMatchingLineForm) => linkDocumentMatchingLine(documentId, data),
        onSuccess: async () => {
            await Promise.all([
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentInvoiceLines, documentId],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentOrderLines, documentId],
                }),
            ]);
        },
    });
};

export default useLinkMatchingLine;
