import { useMutation } from '@tanstack/react-query';
import { deleteImportProfile } from '@repository/importProfilesRepository';

const useDeleteImportProfile = () => {
    return useMutation({
        mutationFn: (id: string) => deleteImportProfile(id),
    });
};

export default useDeleteImportProfile;
