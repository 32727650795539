import { FC } from 'react';
import { Sizes } from '@utils/sizes';
import GridSettingsModalInner from '@components/modals/gridSettingsModal/GridSettingsModalInner';
import { useTranslation } from 'react-i18next';
import Modal from '@components/primitives/modal/Modal';
import { useAtom } from 'jotai';
import { gridSettingsModalAtom, GridSettingsModalData } from '@js/store/modals/gridSettingsModalAtom';

interface Props {
    name: string;
}

const GridSettingsModal: FC<Props> = ({ name }) => {
    const [modalState, setModalState] = useAtom(gridSettingsModalAtom);
    const { t } = useTranslation();

    // Make sure we only open the modal for the correct grid.
    if (!modalState.data || modalState.data.name !== name) {
        return null;
    }

    return (
        <Modal<GridSettingsModalData>
            modalState={modalState}
            setModalState={setModalState}
            size={Sizes.md}
            title={t('settingsModal.title', { ns: 'dataGrid' })}
        >
            <GridSettingsModalInner
                modalData={modalState.data}
            />
        </Modal>
    );
};

export default GridSettingsModal;
