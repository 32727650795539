import { executeGetRequest } from '@api/client';
import { Templates } from '@models/SharedTemplates';
import {
    createApplySharedTemplatePath,
    createConfirmSharedTemplatePath,
    createDeclineSharedTemplatePath,
    createFetchSharedTemplatesPath, createSetEnabledSharedTemplatePath, createUpdateSharedTemplatePath,
} from '@routing/apiUrls';

export const fetchSharedTemplates = async (documentId: number, limitToEnabledTemplates: boolean, signal?: AbortSignal): Promise<Templates> => {
    const result = await executeGetRequest<Templates>(createFetchSharedTemplatesPath(documentId, limitToEnabledTemplates), signal);
    return result.data;
};

export const applySharedTemplate = async (documentId: number, templateId: string): Promise<void> => {
    const result = await executeGetRequest<void>(createApplySharedTemplatePath(documentId, templateId));
    return result.data;
};

export const declineSharedTemplate = async (documentId: number, templateId: string): Promise<void> => {
    const result = await executeGetRequest<void>(createDeclineSharedTemplatePath(documentId, templateId));
    return result.data;
};

export const confirmSharedTemplate = async (documentId: number, templateId: string): Promise<void> => {
    const result = await executeGetRequest<void>(createConfirmSharedTemplatePath(documentId, templateId));
    return result.data;
};

export const setEnabledSharedTemplate = async (documentId: number, templateId: string, enabled: boolean): Promise<void> => {
    const result = await executeGetRequest<void>(createSetEnabledSharedTemplatePath(documentId, templateId, enabled));
    return result.data;
};

export const updateSharedTemplate = async (documentId: number, templateId: string, name: string): Promise<void> => {
    const result = await executeGetRequest<void>(createUpdateSharedTemplatePath(documentId, templateId, name));
    return result.data;
};
