import { useAtom } from 'jotai';
import { codingCaretAtom } from '@js/store/caretAtoms';

const useCodingCaret = () => {
    const [codingCaret, setCodingCaret] = useAtom(codingCaretAtom);

    const resetCodingCaret = () => {
        if (!codingCaret) {
            return;
        }
        setCodingCaret(undefined);
    };

    return {
        codingCaret,
        setCodingCaret,
        resetCodingCaret,
    };
};

export default useCodingCaret;
