import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import FormError from '@components/primitives/form/FormError';
import FormLabel from '@components/primitives/form/FormLabel';

interface Props {
    className?: string;
    name: string;
    isRequired?: boolean;
    isReadOnly?: boolean;
    label?: string;
}

const FormCheckbox: FC<Props> = (
    {
        className,
        name,
        isReadOnly = false,
        label,
    },
) => {
    const { register } = useFormContext();
    const classes = classNames('form__field', 'form__field--checkbox', className);

    return (
        <div className={classes}>
            <label htmlFor={name}>
                <input
                    {...register(name)}
                    type="checkbox"
                    className="form__checkbox"
                    disabled={isReadOnly}
                    id={name}
                />
                <span className="form__checkbox-shadow" />
            </label>
            {label ? (
                <FormLabel name={name}>{label}</FormLabel>
            ) : null}
            {name ? (
                <FormError name={name} />
            ) : null}
        </div>
    );
};

export default FormCheckbox;
