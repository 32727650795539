import React, { useEffect, useState } from 'react';

const useMoveModal = (isOpen: boolean) => {
    const [isMoving, setIsMoving] = useState<boolean>(false);
    const [cacheX, setCacheX] = useState<number | null>(null);
    const [cacheY, setCacheY] = useState<number | null>(null);
    const [diffX, setDiffX] = useState<number | null>(null);
    const [diffY, setDiffY] = useState<number | null>(null);

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        setCacheX(null);
        setCacheY(null);
        setDiffX(null);
        setDiffY(null);
    }, [isOpen]);

    const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        setCacheX(e.pageX + (diffX || 0));
        setCacheY(e.pageY + (diffY || 0));
        setIsMoving(true);
    };

    const onMouseUp = () => {
        setIsMoving(false);
    };

    const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isMoving || !cacheX || !cacheY) {
            return;
        }
        setDiffX(cacheX - e.pageX);
        setDiffY(cacheY - e.pageY);
    };

    return {
        onMouseDown,
        onMouseUp,
        onMouseMove,
        style: {
            transform: `
                translateX(
                    calc(-50% ${diffX ? diffX >= 0 ? `- ${diffX}px` : diffX < 0 ? `+ ${Math.abs(diffX)}px` : '' : ''})
                )
                translateY(
                    calc(0% ${diffY ? diffY >= 0 ? `- ${diffY}px` : diffY < 0 ? `+ ${Math.abs(diffY)}px` : '' : ''})
                )
            `,
        },
    };
};

export default useMoveModal;
