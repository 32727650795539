import { useTranslation } from 'react-i18next';
import { createListPath, PagePath } from '@routing/appUrls';
import { useNavigate } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import { startPageAtom } from '@js/store/settingsAtoms';

const useStartPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const startPage = useAtomValue(startPageAtom);

    const pages = [
        {
            value: 'DASHBOARD_PAGE',
            label: t('startPage.dashboardPage', { ns: 'settings' }) || '',
            navigate: () => {
                navigate(PagePath.dashboard);
            },
        },
        {
            value: 'DOCUMENT_LIST_PAGE',
            label: t('startPage.documentListPage', { ns: 'settings' }) || '',
            navigate: () => {
                navigate(createListPath());
            },
        },
        {
            value: 'PROCESSING_PAGE',
            label: t('startPage.processingPage', { ns: 'settings' }) || '',
            navigate: () => {
                navigate(PagePath.firstDocument);
            },
        },
    ];

    return {
        startPage: pages.find((page) => page.value === startPage),
        pages,
    };
};

export default useStartPage;
