import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import FormLabel from '@components/primitives/form/FormLabel';

interface Props {
    className?: string;
    children?: ReactNode;
    name?: string;
    label?: string | ReactNode;
    hideLabel?: boolean;
    noMarginBottom?: boolean;
    description?: string;
}

const FormRow: FC<Props> = ({ className, children, label, name, hideLabel, noMarginBottom, description }) => {
    const classes = classNames('form__row', className, {
        'form__row--no-label': !label && !hideLabel,
        'form__row--hide-label': hideLabel,
        'form__row--noMarginBottom': noMarginBottom,
    });

    if (!children) {
        return null;
    }

    return (
        <div className={classes}>
            {label || hideLabel ? (
                <FormLabel name={name}>
                    {label}
                    {description ? (
                        <small>{description}</small>
                    ) : null}
                </FormLabel>
            ) : null}
            {children}
        </div>
    );
};

export default FormRow;
