import { Locale as DateFnsLocale } from 'date-fns';

export enum LocaleKey {
    nl_NL = 'nl-NL',
    en_GB = 'en-GB',
    de_DE = 'de-DE',
    fr_FR = 'fr-FR',
}

export enum BackendLocaleKey {
    NL = 'NL',
    EN = 'EN',
    DE = 'DE',
    FR = 'FR',
}

export type Locale = {
    code: LocaleKey;
    backendCode: BackendLocaleKey;
    dateLocale: DateFnsLocale;
    dateFormat: string;
    dateTimeFormat: string;
    dateTimeAccurateFormat: string;
    thousandSeparator: string;
    decimalSeparator: string;
};
