import { FC, useRef } from 'react';
import { RenderPageProps } from '@react-pdf-viewer/core';

interface Props extends RenderPageProps {}

const PdfPage: FC<Props> = ({
    canvasLayer,
    textLayer,
    annotationLayer,
    width,
    height,
}) => {
    const wrapperRef = useRef<null | HTMLDivElement>(null);

    return (
        <div className="pdfPage" ref={wrapperRef} style={{ width, height }}>
            {canvasLayer.children}
            {textLayer.children}
            {annotationLayer.children}
        </div>
    );
};

export default PdfPage;
