import { FC, useEffect } from 'react';
import ModalFooter from '@components/primitives/modal/components/ModalFooter';
import ModalContent from '@components/primitives/modal/components/ModalContent';
import Button from '@components/primitives/button/Button';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormRow from '@components/primitives/form/FormRow';
import FormText from '@components/primitives/form/FormText';
import FormInput from '@components/primitives/form/FormInput';
import usePerformDocumentActionSchema from '@components/modals/performDocumentActionModal/validation/usePerformDocumentActionSchema';
import FormSelect from '@components/primitives/form/FormSelect';
import usePerformDocumentAction from '@hooks/usePerformDocumentAction';
import { closedModalState } from '@js/store/modalAtoms';
import Message from '@components/primitives/message/Message';
import { performDocumentActionModalAtom, PerformDocumentActionModalData, UserChoice } from '@js/store/modals/performDocumentActionModalAtom';
import { useSetAtom } from 'jotai';

interface Props {
    modalData: PerformDocumentActionModalData;
}

export interface PerformDocumentActionFormType {
    remark?: string;
    numberDays?: number;
    userId?: string | null;
}

const PerformDocumentActionModalInner: FC<Props> = ({ modalData }) => {
    const { documentId, action, onSuccess, onSubmit, onCanceled, buttonLabel, returnValue, listType } = modalData;
    const setModalState = useSetAtom(performDocumentActionModalAtom);
    const { remarkRequired, defaultNumDays, minNumDays, maxNumDays, userChoices } = action;
    const userIdRequired = !!userChoices?.length;
    const { t } = useTranslation();
    const schema = usePerformDocumentActionSchema(remarkRequired, minNumDays, maxNumDays, userIdRequired);
    const methods = useForm<PerformDocumentActionFormType>({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
        defaultValues: {
            remark: '',
            numberDays: defaultNumDays,
            userId: null,
        },
    });
    const { handleSubmit, setValue, watch, setFocus } = methods;
    const numberDays = watch('numberDays');
    const { mutate: performDocumentAction, isPending: isPerformingDocumentAction } = usePerformDocumentAction(listType, documentId);

    const closeModal = () => {
        setModalState(closedModalState);
    };

    const submitHandler = async (data: PerformDocumentActionFormType) => {
        if (returnValue) {
            if (!onSubmit) {
                console.warn('No onSubmit provided. This property is needed when returnValue is true.');
                return;
            }
            onSubmit(data, closeModal);
            return;
        }
        performDocumentAction({
            ...data,
            actionId: action.id,
            documentId,
        }, {
            onSuccess: (response) => {
                if (onSuccess) {
                    onSuccess(response);
                }
                closeModal();
            },
        });
    };

    // Use maxNumDays when exceeding this number
    useEffect(() => {
        if (!maxNumDays || !numberDays || numberDays <= maxNumDays) {
            return;
        }

        setValue('numberDays', maxNumDays);
    }, [numberDays]);

    useEffect(() => {
        setFocus('remark');
    }, []);

    const sortOnFullName = (a: UserChoice, b: UserChoice) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0;
    };

    const onCancel = () => {
        if (onCanceled) {
            onCanceled();
        }
        closeModal();
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(submitHandler)} noValidate autoComplete="false">
                <ModalContent>
                    {minNumDays ? (
                        <FormRow label={t('park.numberDays', { ns: 'documentActions' })} name="numberDays">
                            <FormInput isReadOnly={minNumDays === maxNumDays} name="numberDays" type="number" min={minNumDays} max={maxNumDays} />
                        </FormRow>
                    ) : null}
                    {userChoices?.length ? (
                        <FormRow label={t('userChoices', { ns: 'workflowActions' })} name="userId">
                            <FormSelect
                                name="userId"
                                options={userChoices
                                    .sort(sortOnFullName)
                                    .map((choice) => {
                                        return {
                                            value: choice.id,
                                            label: choice.name,
                                        };
                                    })}
                            />
                        </FormRow>
                    ) : null}
                    <FormRow label={t('remark', { ns: 'documentActions' })} name="remark">
                        <FormText name="remark" />
                    </FormRow>

                    {(!!maxNumDays && maxNumDays > 0) && (
                        <Message isInfo>
                            {t('park.maxDaysWarning', {
                                ns: 'documentActions',
                                maxDays: maxNumDays,
                            })}
                        </Message>
                    )}
                </ModalContent>
                <ModalFooter>
                    <Button
                        type="submit"
                        disabled={isPerformingDocumentAction}
                        isLoading={isPerformingDocumentAction}
                        primary
                    >
                        {buttonLabel || t('okay')}
                    </Button>
                    <Button isLink onClick={onCancel}>{t('cancel')}</Button>
                </ModalFooter>
            </form>
        </FormProvider>
    );
};

export default PerformDocumentActionModalInner;
