import { FC } from 'react';
import SettingsIcon from '@images/svg/cogFull.svg?react';
import DotsIcon from '@images/svg/dots.svg?react';
import DownloadIcon from '@images/svg/download.svg?react';
import FilterIcon from '@images/svg/filter.svg?react';
import SortArrowIcon from '@images/svg/sortArrow.svg?react';
import EyeIcon from '@images/svg/eye.svg?react';
import EyeClosedIcon from '@images/svg/eyeClosed.svg?react';
import ToggleList, { ToggleListPosition } from '@components/primitives/toggleList/ToggleList';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

export interface CSVRow {
    [key: string]: unknown;
}

export interface CSVHeader {
    label: string;
    key: string;
}

interface Props {
    storageKey?: string;
    csvFileName: string;
    csvData: Array<CSVRow>;
    csvHeaders: Array<CSVHeader>;
    onOpenGridSettings: () => void;
    gridSettingsEnabled: boolean;
    onResetFilters: () => void;
    resetFiltersEnabled: boolean;
    resetFiltersVisible: boolean;
    onResetSorting: () => void;
    resetSortingEnabled: boolean;
    resetSortingVisible: boolean;
    onToggleShowFilters: () => void;
    toggleFiltersVisible: boolean;
    showFilters: boolean;
}

const DataGridOptions: FC<Props> = ({
    storageKey,
    csvFileName,
    csvData,
    csvHeaders,
    onOpenGridSettings,
    gridSettingsEnabled,
    onResetFilters,
    resetFiltersEnabled,
    resetFiltersVisible,
    onResetSorting,
    resetSortingEnabled,
    resetSortingVisible,
    onToggleShowFilters,
    toggleFiltersVisible,
    showFilters,
}) => {
    const { t } = useTranslation();

    return (
        <ToggleList
            className="dataGrid__options"
            position={ToggleListPosition.BottomRight}
            list={[
                [
                    {
                        id: 'resetFilters',
                        isHidden: !resetFiltersVisible,
                        enabled: resetFiltersEnabled,
                        title: t('resetFilters.button', { ns: 'dataGrid' }),
                        onClick: onResetFilters,
                        icon: <FilterIcon />,
                    },
                    {
                        id: 'resetSorting',
                        isHidden: !resetSortingVisible,
                        enabled: resetSortingEnabled,
                        title: t('resetSorting.button', { ns: 'dataGrid' }),
                        onClick: onResetSorting,
                        icon: <SortArrowIcon />,
                    },
                    {
                        id: 'toggleFilters',
                        isHidden: !toggleFiltersVisible || !storageKey,
                        enabled: true,
                        title: showFilters ? t('gridFilters.hide', { ns: 'dataGrid' }) : t('gridFilters.show', { ns: 'dataGrid' }),
                        onClick: onToggleShowFilters,
                        icon: showFilters ? <EyeClosedIcon /> : <EyeIcon />,
                    },
                ],
                [
                    {
                        id: 'settings',
                        isHidden: !gridSettingsEnabled,
                        enabled: gridSettingsEnabled,
                        title: t('options.settings', { ns: 'dataGrid' }),
                        onClick: onOpenGridSettings,
                        icon: <SettingsIcon />,
                    },
                ],
                [
                    {
                        element: (
                            <CSVLink
                                className="toggleList__item toggleList__item--hasIcon"
                                filename={csvFileName}
                                data={csvData}
                                headers={csvHeaders}
                            >
                                <div className="toggleList__item-icon">
                                    <DownloadIcon />
                                </div>
                                <span className="title">
                                    {t('options.exportToCSV', { ns: 'dataGrid' })}
                                </span>
                            </CSVLink>
                        ),
                    },
                ],
            ]}
            trigger={(toggle) => (
                <button
                    className="dataGrid__options-button"
                    type="button"
                    onClick={toggle}
                >
                    <DotsIcon />
                </button>
            )}
        />
    );
};

export default DataGridOptions;
