import { FC, ReactNode, useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useLocation } from 'react-router-dom';

interface Props {
    children: ReactNode;
}

const TrackPageVisits: FC<Props> = ({ children }) => {
    const { enableLinkTracking, trackPageView } = useMatomo();
    const location = useLocation();

    enableLinkTracking();

    useEffect(() => {
        setTimeout(() => {
            trackPageView({
                href: window.location.href,
            });
        }, 100);
    }, [location]);

    return (
        <>
            {children}
        </>
    );
};

export default TrackPageVisits;
