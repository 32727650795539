import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { OcrTrainingFieldForm } from '@models/OcrTraining';
import { addOcrTrainingField } from '@repository/ocrTrainingRepository';

const useCreateOcrTrainingField = (documentId: number) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: (data: OcrTrainingFieldForm) => addOcrTrainingField(documentId, data),
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [QueryKey.OcrTraining, documentId],
            });
        },
    });
};

export default useCreateOcrTrainingField;
