import { FC } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import FormError from '@components/primitives/form/FormError';
import { useFormContext } from 'react-hook-form';

interface Props {
    name: string;
}

const FormMultiEmail: FC<Props> = ({ name }) => {
    const { getValues, setValue } = useFormContext();
    const getMailArray = (fieldName: string): string[] => {
        const emails = getValues(fieldName) as string;
        return emails.split(';').filter((email) => !!email);
    };

    return (
        <>
            <ReactMultiEmail
                id={name}
                emails={getMailArray(name)}
                onChange={(emails: string[]) => {
                    setValue(name, emails.join(';'));
                }}
                getLabel={(email, index, removeEmail) => {
                    return (
                        <div data-tag key={index}>
                            <div data-tag-item>{email}</div>
                            <span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                        </div>
                    );
                }}
            />
            {name ? (
                <FormError name={name} />
            ) : null}
        </>
    );
};

export default FormMultiEmail;
