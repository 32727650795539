import { executeDeleteRequest, executeGetRequest, executePutRequest } from '@api/client';
import {
    createDeleteReplacementsPath,
    createFetchReplacementsPath,
    createUpdateReplacementsPath,
} from '@routing/apiUrls';
import {
    DeleteReplacementsResponse,
    Replacements,
    ReplacementsMutation,
    UpdateReplacementsResponse,
} from '@models/Replacements';

export const fetchReplacements = async (signal?: AbortSignal): Promise<Replacements> => {
    const result = await executeGetRequest<Replacements>(createFetchReplacementsPath(), signal);
    return result.data;
};

export const updateReplacements = async (data: ReplacementsMutation): Promise<UpdateReplacementsResponse> => {
    const result = await executePutRequest<UpdateReplacementsResponse>(createUpdateReplacementsPath(), data);
    return result.data;
};

export const deleteReplacements = async (): Promise<DeleteReplacementsResponse> => {
    const result = await executeDeleteRequest<DeleteReplacementsResponse>(createDeleteReplacementsPath());
    return result.data;
};
