import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import EmptyIcon from '@images/svg/empty.svg?react';
import ErrorIcon from '@images/svg/error.svg?react';

interface Props {
    children?: ReactNode | string;
    className?: string;
    isWarning?: boolean;
    isError?: boolean;
    isInfo?: boolean;
    isNoItems?: boolean;
    isErrorMsg?: boolean;
}

const Message: FC<Props> = ({
    children,
    className,
    isWarning = true,
    isError = false,
    isInfo = false,
    isNoItems = false,
    isErrorMsg = false,
}) => {
    const classes = classNames('message', className, {
        'message--warning': isWarning,
        'message--error': isError,
        'message--info': isInfo,
        'message--no-items': isNoItems,
        'message--error-msg': isErrorMsg,
    });

    if (!children) {
        return null;
    }

    return (
        <div className={classes}>
            {isNoItems ? (
                <EmptyIcon />
            ) : null}
            {isErrorMsg ? (
                <ErrorIcon />
            ) : null}
            {children}
        </div>
    );
};

export default Message;
