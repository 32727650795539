import { FC } from 'react';
import { formatDate } from '@utils/formatDate';
import { NumericFormat } from 'react-number-format';
import Text from '@components/primitives/text/Text';
import PdfViewer from '@components/features/pdfViewer/PdfViewer';
import { StorageKeys } from '@constants/storageKeys';
import Comments from '@components/features/comments/Comments';
import { getCurrentLocale } from '@utils/locales';
import useFetchAnnotations from '@components/features/annotations/hooks/useFetchAnnotations';
import { Document } from '@models/Document';
import DocumentGrids from '@components/features/documentGrids/DocumentGrids';
import { createStorageKey } from '@utils/storage';
import Split from 'react-split';
import AnnotatedPdfPage from '@components/features/pdfViewer/components/pdfPage/AnnotatedPdfPage';
import useFetchDocumentFile from '@hooks/useFetchDocumentFile';
import useFetchDocumentHeaders from '@components/features/documentHeaders/hooks/useFetchDocumentHeaders';
import Throbber from '@components/primitives/throbber/Throbber';
import useDocumentHeaders from '@components/features/documentHeaders/hooks/useDocumentHeaders';
import DocumentLookupField from '@components/features/documentFields/DocumentLookupField';
import { useAtom } from 'jotai';
import { HeightPreviewContentAtom } from '@js/store/viewSettingsAtoms';
import TopBar from '@components/features/topBar/TopBar';

interface Props {
    document: Document;
    listType: string;
}
const PreviewContent: FC<Props> = ({ document, listType }) => {
    const { documentId } = document;
    const { data: documentAnnotations } = useFetchAnnotations(documentId);
    const { isLoading } = useFetchDocumentHeaders(document.documentId);
    const { documentHeaders, getHeaderField } = useDocumentHeaders(documentId);
    const currentLocale = getCurrentLocale();
    const DEFAULT_PANEL_SIZES = [60, 40];
    const { data: file } = useFetchDocumentFile(documentId);
    const [heightPreviewContent, setHeightPreviewContent] = useAtom(HeightPreviewContentAtom);

    return (
        <>
            <TopBar
                document={document}
                ocrTrainingEnabled={false}
                ocrTrainingAllowed={false}
                isPreviewMode
                listType={listType}
            />
            <Split
                sizes={heightPreviewContent}
                minSize={DEFAULT_PANEL_SIZES}
                gutterSize={4}
                cursor="row-resize"
                direction="vertical"
                className="preview__content"
                onDragEnd={setHeightPreviewContent}
            >
                <div className="preview__top">
                    <div className="preview__headers">
                        {isLoading ? (
                            <Throbber />
                        ) : documentHeaders ? (
                            <div className="documentHeaders">
                                {documentHeaders.definitions.map((headerDefinition) => {
                                    if (headerDefinition.isHidden) {
                                        return null;
                                    }

                                    const field = getHeaderField(headerDefinition.id);

                                    if (!field) {
                                        return null;
                                    }

                                    return (
                                        <div key={headerDefinition.id} className="preview__headers-row">
                                            <div className="preview__headers-label">{headerDefinition.title}</div>
                                            <div className="preview__headers-value">
                                                {headerDefinition.format === 'lookup' ? (
                                                    <DocumentLookupField
                                                        isEditable={false}
                                                        showAsText
                                                        initialValue={field.value}
                                                        fieldDefinition={headerDefinition}
                                                        field={field}
                                                        documentId={documentId}
                                                        inputId={`previewHeader_${headerDefinition.id}`}
                                                    />
                                                ) : headerDefinition.format === 'date' && field.value ? (
                                                    <>{formatDate(field.value as string)}</>
                                                ) : headerDefinition.format === 'decimal' ? (
                                                    <NumericFormat
                                                        data-lpignore="true"
                                                        value={field.value !== null && field.value !== undefined ? field.value : ''}
                                                        allowedDecimalSeparators={['.', ',']}
                                                        displayType="text"
                                                        thousandSeparator={currentLocale.thousandSeparator}
                                                        decimalSeparator={currentLocale.decimalSeparator}
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                    />
                                                ) : (
                                                    <span title={field.value as string}>
                                                        <Text text={field.value as string} />
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : null}
                    </div>
                    <div className="preview__viewer">
                        <PdfViewer
                            file={file}
                            storageKey={createStorageKey([StorageKeys.ZoomLevelPreviewModal])}
                            renderPage={(props) => (
                                <AnnotatedPdfPage
                                    {...props}
                                    documentId={documentId}
                                    annotationMode={false}
                                    annotations={documentAnnotations}
                                    readOnly
                                />
                            )}
                        />
                    </div>
                    <div className="preview__comments">
                        <Comments userCanAddComments={document.meta.canAddRemarks} documentId={documentId} />
                    </div>
                </div>
                <div className="preview__bottom">
                    <DocumentGrids
                        document={document}
                        ocrTrainingEnabled={false}
                        isPreviewMode
                    />
                </div>
            </Split>
        </>
    );
};

export default PreviewContent;
