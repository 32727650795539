import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import useLookup, { Params } from '@components/features/lookup/hooks/useLookup';
import stringToObject from '@utils/stringToObject';
import useDocumentHeaders from '@components/features/documentHeaders/hooks/useDocumentHeaders';
import useDocumentCoding from '@components/features/coding/hooks/useDocumentCoding';

type Option = {
    value: string;
    label: string;
};

const useLookupOptions = (lookup: string | null, documentId: number, lineId?: number) => {
    const [options, setOptions] = useState<Option[] | null>(null);
    const [lookupParams, setLookupParams] = useState<Params>({});
    const isLookupField = !!lookup;
    const lookupFormat = lookup && lookup.indexOf('?') > -1 ? lookup?.split('?')[1] : undefined;
    const { data, isLoading } = useLookup(lookup, lookupParams, false, isLookupField);
    const { documentHeaders, getHeaderValue } = useDocumentHeaders(documentId);
    const { documentCoding, getCodingLineFieldValue } = useDocumentCoding(documentId);

    const sortOnLabel = (a: Option, b: Option) => {
        if (typeof a?.label === 'string' && typeof b?.label === 'string') {
            return a?.label?.toLowerCase() < b?.label?.toLowerCase() ? -1 : a?.label?.toLowerCase() > b?.label?.toLowerCase() ? 1 : 0;
        }
        return a?.label < b?.label ? -1 : a?.label > b?.label ? 1 : 0;
    };

    const getDependentFieldsObject = useCallback((): Params => {
        const headerValues = Object.entries(stringToObject(lookupFormat)).reduce((result, [key]) => {
            const value = getHeaderValue(key);
            // const value = getHeaderValue(documentId, key);
            if (!value) {
                return result;
            }

            return {
                ...result,
                [key]: value,
            };
        }, {});

        const codingLineValues = Object.entries(stringToObject(lookupFormat)).reduce((result, [key]) => {
            if (lineId === undefined) {
                return result;
            }

            const value = getCodingLineFieldValue(lineId, key);

            if (value === null) {
                return result;
            }

            return {
                ...result,
                [key]: value,
            };
        }, {});

        return {
            ...headerValues,
            ...codingLineValues,
        };
    }, [documentHeaders, documentCoding]);

    const dependentFieldsObject = getDependentFieldsObject();

    useEffect(() => {
        // Prevent infinite loop
        if (_.isEqual(lookupParams, dependentFieldsObject)) {
            return;
        }
        if (!isLookupField) {
            return;
        }
        setLookupParams(dependentFieldsObject);
    }, [dependentFieldsObject, lookupParams]);

    useEffect(() => {
        if (!isLookupField) {
            return;
        }

        // List is undefined while fetching new data.
        if (!data?.list) {
            return;
        }

        const newOptions = data.list?.map((item) => {
            return {
                label: item.display ? item.display : item.key,
                value: item.key,
            };
        });

        if (_.isEqual(newOptions.sort(sortOnLabel), options?.sort(sortOnLabel))) {
            return;
        }

        setOptions(newOptions);
    }, [data, options]);

    return {
        data,
        options: options?.sort(sortOnLabel),
        isLoading,
    };
};

export default useLookupOptions;
