import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormLabel from '@components/primitives/form/FormLabel';

interface Option {
    isHidden?: boolean;
    label: string;
    id: string;
}

interface Props {
    name: string;
    defaultValue?: string;
    options: Option[];
}
const FormRadioButtons: FC<Props> = ({ name, defaultValue, options }) => {
    const { setValue, getValues } = useFormContext();

    const [localValue, setLocalValue] = useState<string | null>(null);

    const optionIsChecked = (name: string) => {
        let selectedOption;

        options.forEach((option) => {
            if (getValues(option.id)) {
                selectedOption = option.id;
            }
        });

        if (!selectedOption) {
            if (name === defaultValue) {
                return true;
            }
        }

        return getValues(name);
    };

    useEffect(() => {
        if (!localValue) {
            return;
        }

        options
            .filter((option) => !option.isHidden)
            .forEach((option) => {
                setValue(option.id, option.id === localValue);
            });
    }, [localValue]);

    return (
        <>
            {options.length ? (
                <div className="form__radiobuttons">
                    {options
                        .filter((option) => !option.isHidden)
                        .map((option) => {
                            return (
                                <div key={option.id} className="form__field form__field--radio">
                                    <label htmlFor={`${name}_${option.id}`}>
                                        <input
                                            className="form__radiobutton"
                                            type="radio"
                                            checked={localValue ? localValue === option.id : optionIsChecked(option.id)}
                                            onChange={(e) => {
                                                setLocalValue(e.target.value);
                                            }}
                                            value={option.id}
                                            id={`${name}_${option.id}`}
                                            name={name}
                                        />
                                        <span className="form__radiobutton-shadow" />
                                    </label>
                                    <FormLabel name={`${name}_${option.id}`}>{option.label}</FormLabel>
                                </div>
                            );
                        })}
                </div>
            ) : null}
        </>
    );
};

export default FormRadioButtons;
