/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
import { FC, useState } from 'react';
import { FileHandlerAdministrationsModalData } from '@js/store/modals/fileHandlerAdministrationsModalAtom';
import { useSetAtom } from 'jotai';
import { FcOpenedFolder } from 'react-icons/fc';
import Button from '@components/primitives/button/Button';
import ModalContent from '@components/primitives/modal/components/ModalContent';
import ModalFooter from '@components/primitives/modal/components/ModalFooter';
import { t } from 'i18next';
import { ewsSelectFolderModalAtom } from '@js/store/modals/ewsSelectFolderModalAtom';
import { closedModalState } from '@js/store/modalAtoms';
import Subfolder from './components/Subfolder';
import Folder from './components/Folder';
import React from 'react';

interface Props {
    modalData: FileHandlerAdministrationsModalData;
}

const EwsSelectFolderModalInner: FC<Props> = ({ modalData }) => {
    const setModalState = useSetAtom(ewsSelectFolderModalAtom);
    const { folders, onSelect, selectedFolder } = modalData || {};
    const [highlightedFolder, setHighlightedFolder] = useState(selectedFolder);

    const closeModal = () => {
        setModalState(closedModalState);
    };

    const handleClickFolder = (folder) => {
        setHighlightedFolder(folder);
    };

    const handleSave = (e) => {
        e.preventDefault();
        onSelect(highlightedFolder);
        closeModal();
    };

    return (
        <>
            <ModalContent>
                <section className="overflow-y-auto max-h-[70vh]">
                    <div
                        className="flex flex-col mt-4 bg-black/5 divide-y divide-black/10 rounded-lg overflow-auto">
                        <span className="flex flex-row items-center p-8 pb-4">
                            <FcOpenedFolder className="w-8 h-8 mr-4" />
                            Postvak IN
                        </span>

                        {folders?.map((folder, index) => (
                            <React.Fragment
                                key={`folder-${index}-${folder?.path}`}>
                                <Folder
                                    folder={folder}
                                    selectedFolder={highlightedFolder}
                                    onFolderClick={() => handleClickFolder(folder)}
                                />
                                {folder?.subfolders?.length > 0 && (
                                    <div
                                        className="flex flex-col bg-black/5 divide-y divide-black/10 divide-solid divide-x-0 rounded-l-lg overflow-auto ml-16">
                                        {folder?.subfolders?.map((subfolder, subIndex) => (
                                            <Subfolder
                                                key={`folder-${index}-${subIndex}-${folder.path}`}
                                                subfolder={subfolder}
                                                selectedFolder={highlightedFolder}
                                                onFolderClick={() => handleClickFolder(subfolder)}
                                            />
                                        ))}
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </section>
            </ModalContent>
            <ModalFooter>
                <Button primary onClick={handleSave}>
                    Opslaan
                </Button>
                <Button isLink onClick={() => closeModal()}>
                    {t('cancel')}
                </Button>
            </ModalFooter>
        </>
    );
};

export default EwsSelectFolderModalInner;
