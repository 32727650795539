import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { buildVersion } from '@constants/buildVersion';

const currentURL = window.location.href;
const urlObject = new URL(currentURL);
const { hostname } = urlObject;

const prodEnvironments = ['hubl.whitevision.nl', 'hubl.whitevision.com'];
const testEnvironments = ['dev.docbaseweb.nl', 'test.docbaseweb.nl', 'acc.docbaseweb.nl'];
const devEnvironments = ['localhost'];

const sentryEnvironment = (prodEnvironments.includes(hostname)) ? 'PROD'
    : (testEnvironments.includes(hostname)) ? 'TEST'
        : (devEnvironments.includes(hostname)) ? 'DEV'
            : 'UNKNOWN';

Sentry.init({
    dsn: 'https://13431d7697251443eb2bf8dcda2de14e@monitoring.hubl.online/1',
    environment: sentryEnvironment,
    release: buildVersion,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        Sentry.browserTracingIntegration(),
        // Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
        'localhost',
        /^https:\/\/devweb.docbaseweb\.nl\/api/,
        /^https:\/\/testweb.docbaseweb\.nl\/api/,
        /^.*\.workflowindecloud\.nl\/api/,
    ],
    profilesSampleRate: 0.5,
});

// Sentry.init({
//     dsn: 'https://39b5d4255777b86619e8bc021be66ffa@o4506229515485184.ingest.sentry.io/4506229526298624',
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0,
//     integrations: [
//         new Sentry.BrowserProfilingIntegration(),
//         new Sentry.Replay(),
//         new Sentry.BrowserTracing({
//             routingInstrumentation: Sentry.reactRouterV6Instrumentation(
//                 useEffect,
//                 useLocation,
//                 useNavigationType,
//                 createRoutesFromChildren,
//                 matchRoutes,
//             ),
//         }),
//     ],
//     tracesSampleRate: 1.0,
//     enabled: process.env.NODE_ENV !== 'development',
//     // tracePropagationTargets: [
//     //     'localhost',
//     //     /^https:\/\/devweb.docbaseweb\.nl\/api/,
//     //     /^https:\/\/testweb.docbaseweb\.nl\/api/,
//     //     /^.*\.workflowindecloud\.nl\/api/,
//     // ],
// });
