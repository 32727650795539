import ModalContent from '@components/primitives/modal/components/ModalContent';
import ModalFooter from '@components/primitives/modal/components/ModalFooter';
import Button from '@components/primitives/button/Button';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { closedModalState } from '@js/store/modalAtoms';
import useFetchDocumentFileRepaired from '@hooks/useFetchDocumentFileRepaired';
import { createStorageKey } from '@utils/storage';
import { StorageKeys } from '@constants/storageKeys';
import PdfViewer from '@components/features/pdfViewer/PdfViewer';
import PdfPage from '@components/features/pdfViewer/components/pdfPage/PdfPage';
import useRepairDocument from '@components/features/repairDocument/hooks/useRepairDocument';
import { useSetAtom } from 'jotai';
import { repairDocumentModalAtom, RepairDocumentModalData } from '@js/store/modals/repairDocumentModalAtom';

interface Props {
    modalData: RepairDocumentModalData;
}

const RepairDocumentModalInner: FC<Props> = ({ modalData }) => {
    const { documentId } = modalData;
    const setModalState = useSetAtom(repairDocumentModalAtom);
    const { t } = useTranslation();
    const { data: repairedFile } = useFetchDocumentFileRepaired(documentId);
    const { mutate: repairDocument, isPending: isRepairingDocument } = useRepairDocument();

    const closeModal = () => {
        setModalState(closedModalState);
    };

    const onRepairDocument = () => {
        repairDocument({
            documentId,
        }, {
            onSuccess: () => {
                closeModal();
            },
        });
    };

    return (
        <>
            <ModalContent>
                {t('repairDocument.modal.text', { ns: 'documentActions' })}
            </ModalContent>
            <PdfViewer
                file={repairedFile}
                storageKey={createStorageKey([StorageKeys.ZoomLevelRepairDocumentModal])}
                renderPage={(props) => (
                    <PdfPage
                        {...props}
                    />
                )}
            />
            <ModalFooter>
                <Button
                    onClick={onRepairDocument}
                    type="submit"
                    disabled={isRepairingDocument}
                    isLoading={isRepairingDocument}
                    primary
                >
                    {t('repairDocument.modal.submit', { ns: 'documentActions' })}
                </Button>
                <Button isLink onClick={() => closeModal()}>{t('cancel')}</Button>
            </ModalFooter>
        </>
    );
};

export default RepairDocumentModalInner;
