import React, { FC } from 'react';
import { Sizes } from '@utils/sizes';
import TextModalInner from '@components/modals/textModal/TextModalInner';
import { useAtom } from 'jotai';
import Modal from '@components/primitives/modal/Modal';
import { textModalAtom, TextModalData } from '@js/store/modals/textModalAtom';

const TextModal: FC = () => {
    const [modalState, setModalState] = useAtom(textModalAtom);

    if (!modalState.data) {
        return null;
    }

    return (
        <Modal<TextModalData>
            modalState={modalState}
            setModalState={setModalState}
            size={modalState.data.size || Sizes.md}
            title={modalState.data.title}
        >
            <TextModalInner
                modalData={modalState.data}
            />
        </Modal>
    );
};

export default TextModal;
