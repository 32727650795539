const numericSorting = (rowA: any, rowB: any, columnName: string) => {
    const valA = rowA.getValue(columnName);
    const valB = rowB.getValue(columnName);

    if (typeof valA !== 'number' && typeof valB !== 'number') {
        return 0;
    }

    if (typeof valA !== 'number') {
        return 1;
    }

    if (typeof valB !== 'number') {
        return -1;
    }

    return valA - valB;
};

export default numericSorting;
