import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import Throbber from '@components/primitives/throbber/Throbber';

interface Props {
    children: ReactNode;
    isLoading?: boolean;
}

const ModalContent: FC<Props> = ({ children, isLoading }) => {
    const classes = classNames('modal__content', {
        'modal__content--isLoading': isLoading,
    });

    return (
        <div className={classes}>
            {isLoading ? (
                <Throbber />
            ) : children}
        </div>
    );
};

export default ModalContent;
