import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { fetchDocumentAudits } from '@repository/documentAuditsRepository';
import { DocumentAudits } from '@models/DocumentAudits';

const useFetchDocumentAudits = (documentId: number): UseQueryResult<DocumentAudits> => {
    return useQuery({
        queryKey: [QueryKey.DocumentAudits, documentId],
        queryFn: ({ signal }) => fetchDocumentAudits(documentId, signal),
        staleTime: 0,
    });
};

export default useFetchDocumentAudits;
