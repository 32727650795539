import { addComment } from '@repository/commentsRepository';
import { Comment } from '@models/Comments';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';

const useCreateComment = (documentId: number) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: (comment: Comment) => addComment(documentId, comment),
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [QueryKey.Comments],
            });
        },
    });
};

export default useCreateComment;
