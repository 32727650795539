export const toLocalDate = (input: string): string | null => {
    if (!input) {
        return null;
    }

    try {
        const parsedInput = new Date(input).toISOString();
        const [date] = parsedInput.split('T');
        const [year, month, day] = date.split('-');
        const localDate = new Date(+year, +month - 1, +day + 1);
        return localDate.toISOString();
    } catch (e) {
        return null;
    }
};
