import ModalContent from '@components/primitives/modal/components/ModalContent';
import ModalFooter from '@components/primitives/modal/components/ModalFooter';
import Button from '@components/primitives/button/Button';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import Text from '@components/primitives/text/Text';
import Message from '@components/primitives/message/Message';
import { closedModalState } from '@js/store/modalAtoms';
import { listModalAtom, ListModalData } from '@js/store/modals/listModalAtom';
import { useSetAtom } from 'jotai';

interface Props {
    modalData: ListModalData;
}

const ListModalInner: FC<Props> = ({ modalData }) => {
    const { text, isError, list } = modalData;
    const setModalState = useSetAtom(listModalAtom);
    const { t } = useTranslation();

    const List = () => {
        return (
            <ul>
                {list.map((item, index) => {
                    return <li key={`${item}_${index}`}>{item}</li>;
                })}
            </ul>
        );
    };

    return (
        <>
            <ModalContent>
                {isError ? (
                    <Message isError>
                        <Text text={text} />
                        <List />
                    </Message>
                ) : (
                    <>
                        <Text text={text} />
                        <List />
                    </>
                )}
            </ModalContent>
            <ModalFooter>
                <Button type="submit" onClick={() => setModalState(closedModalState)} primary>{t('close')}</Button>
            </ModalFooter>
        </>
    );
};

export default ListModalInner;
