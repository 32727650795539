type Result = {
    [key: string]: string;
};

const stringToObject = (input?: string | null): Result => {
    const result: Result = {};
    let params;

    if (!input) {
        return result;
    }

    if (input.indexOf('?') === -1) {
        params = input;
    } else {
        // eslint-disable-next-line prefer-destructuring
        params = input.split('?')[1];
    }

    if (!params) {
        return result;
    }

    const props = params.split('&');
    if (!props) {
        return result;
    }

    props.forEach((prop) => {
        const [key, value] = prop.split('=');
        if (!value) {
            return;
        }
        result[key] = value;
    });

    return result;
};

export default stringToObject;
