import { FC } from 'react';
import RefreshIcon from '@images/svg/refresh.svg?react';
import Button from '@components/primitives/button/Button';
import { useTranslation } from 'react-i18next';
import { useSetAtom } from 'jotai';
import { refreshLookupModalAtom } from '@js/store/modals/refreshLookupModalAtom';

interface Props {
    documentId: number;
    fieldName: string;
    lookup: string;
    title: string;
    onSuccess?: (value: string) => void;
}

const CustomReactSelectRefreshButton: FC<Props> = ({ documentId, fieldName, lookup, title, onSuccess }) => {
    const { t } = useTranslation();
    const setRefreshLookupModal = useSetAtom(refreshLookupModalAtom);

    const onRefreshLookup = () => {
        setRefreshLookupModal({
            isOpen: true,
            data: {
                documentId,
                fieldName,
                fieldLabel: title,
                lookup,
                title: t('refreshLookup.modal.title', { ns: 'headers', fieldName: title }),
                onSuccess,
            },
        });
    };

    return (
        <div className="form__select-refresh">
            <Button icon={<RefreshIcon />} secondary onClick={onRefreshLookup}>{t('refreshLookup.button', { ns: 'headers', fieldName: title })}</Button>
        </div>
    );
};

export default CustomReactSelectRefreshButton;
