import { atom } from 'jotai';
import { closedModalState, ModalState } from '@js/store/modalAtoms';
import { Sizes } from '@utils/sizes';

export interface ConfirmModalData {
    onConfirm: () => void;
    size?: Sizes;
    title?: string;
    text?: string;
    onCanceled?: () => void;
}

export const confirmModalAtom = atom<ModalState<ConfirmModalData>>(closedModalState);
