import { useAtom } from 'jotai';
import { headerCaretAtom } from '@js/store/caretAtoms';

const useHeaderCaret = () => {
    const [headerCaret, setHeaderCaret] = useAtom(headerCaretAtom);

    const resetHeaderCaret = () => {
        if (!headerCaret) {
            return;
        }
        setHeaderCaret(undefined);
    };

    return {
        headerCaret,
        setHeaderCaret,
        resetHeaderCaret,
    };
};

export default useHeaderCaret;
