import { ApplySharedTemplateMutation } from '@models/SharedTemplates';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { applySharedTemplate } from '@repository/templateSharingRepository';
import { toast } from 'react-toastify';
import { QueryKey } from '@constants/queryKey';

const useApplySharedTemplate = (ocrTrainingEnabled: boolean, documentId: number) => {
    const client = useQueryClient();
    return useMutation({
        mutationFn: (mutation: ApplySharedTemplateMutation) => applySharedTemplate(documentId, mutation.templateId),
        onSuccess: async () => {
            await Promise.all([
                client.invalidateQueries({
                    queryKey: [QueryKey.DocumentInvoiceLines, documentId],
                }),
                client.invalidateQueries({
                    queryKey: [QueryKey.DocumentCoding, documentId],
                }),
                client.invalidateQueries({
                    queryKey: [QueryKey.OcrTraining, documentId],
                }),
                client.invalidateQueries({
                    queryKey: [QueryKey.LineSource, documentId],
                }),
            ]);
            toast.success('Template successvol toegepast.');
        },
    });
};

export default useApplySharedTemplate;
