import PageContent from '@components/primitives/pageContent/PageContent';
import PageContentBlock from '@components/primitives/pageContent/PageContentBlock';
import { FC } from 'react';
import ImportProfileSelector from '@components/pages/settingsPage/components/ImportProfileSelector';
import EwsSelectFolderModal from '@components/modals/ewsSelectFolderModal/EwsSelectFolderModal';
import useFetchImportProfiles from '@hooks/useFetchImportProfiles';
import { useParams } from 'react-router-dom';
import useFetchImportProfile from '@hooks/useFetchImportProfile';
import ImportProfileForm from './ImportProfileForm';

const ImportProfilesTab: FC = () => {
    const { importProfileId } = useParams();
    const { data: importProfilesData } = useFetchImportProfiles();
    const importProfiles = importProfilesData?.list || [];
    const { data: importProfile, isLoading: isLoadingProfile } = useFetchImportProfile(importProfileId);

    return (
        <>
            <section className="flex flex-col">
                <PageContent>
                    <PageContentBlock>
                        <ImportProfileSelector
                            profiles={importProfiles}
                            currentProfileId={importProfileId}
                            importProfile={importProfile}
                        />
                    </PageContentBlock>

                    {!!importProfileId
                        && <ImportProfileForm
                            importProfileId={importProfileId}
                            importProfile={importProfile}
                            isLoadingProfile={isLoadingProfile}
                        />
                    }
                </PageContent>
            </section>

            <EwsSelectFolderModal />
        </>
    );
};

export default ImportProfilesTab;