import { FC, memo } from 'react';
import { DocumentOcrSummary } from '@models/DocumentOcrSummary';
import OcrCorrection from '@components/features/ocr/components/OcrCorrection';
import useOcrCorrections from '@js/store/ocrCorrections/useOcrCorrections';

interface Props {
    documentOcrSummary?: DocumentOcrSummary;
    page: number;
    fileWidth: number;
    fileHeight: number;
}
const OcrCorrections: FC<Props> = ({ documentOcrSummary, page, fileWidth, fileHeight }) => {
    const { ocrCorrections } = useOcrCorrections();

    const pageCorrections = documentOcrSummary?.list
        ?.filter((ocrItem) => ocrItem.page === page)
        ?.filter((ocrItem) => !!ocrCorrections[ocrItem.id]);

    if (!pageCorrections?.length) {
        return null;
    }

    return (
        <>
            {pageCorrections.map((ocrItem) => {
                if (!ocrItem.zone) {
                    return null;
                }

                return (
                    <OcrCorrection
                        zone={ocrItem.zone}
                        id={ocrItem.id}
                        key={ocrItem.id}
                        correctedValue={ocrCorrections[ocrItem.id]}
                        fileWidth={fileWidth}
                        fileHeight={fileHeight}
                    />
                );
            })}
        </>
    );
};

const propsAreEqual = (prevProps: Props, nextProps: Props) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};
export default memo(OcrCorrections, propsAreEqual);
