import { FC, ReactNode } from 'react';
import { HeadingTags } from '@utils/headingTags';
import { Sizes } from '@utils/sizes';
import Heading from '@components/primitives/heading/Heading';

interface Props {
    children: ReactNode;
    title?: string;
}

const PageContentBlock: FC<Props> = ({ children, title }) => {
    return (
        <div className="pageContentBlock">
            {title ? (
                <Heading tag={HeadingTags.h3} size={Sizes.md} className="pageContentBlock__heading">{title}</Heading>
            ) : null}
            {children}
        </div>
    );
};

export default PageContentBlock;
