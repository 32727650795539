import { loginRequest } from '@js/authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

const useAzureAccessToken = () => {
    const { instance, accounts } = useMsal();

    const getAzureAccessToken = async () => {
        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        await instance.initialize();

        return instance.acquireTokenSilent(request).then(async (accessTokenResponse) => {
            return accessTokenResponse.accessToken;
        }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenPopup(request).then(async (accessTokenResponse) => {
                    // Acquire token interactive success
                    return accessTokenResponse.accessToken;
                });
            }
            console.error(error);
        });
    };

    return {
        getAzureAccessToken,
    };
};

export default useAzureAccessToken;
