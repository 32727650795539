import { createImportProfilePath } from '@routing/appUrls';
import { ChangeEvent, useEffect, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

type Props = {
    currentProfileId: string;
    profiles: any;
};

const ImportProfileSelector = ({
    currentProfileId,
    importProfile,
    profiles,
}: Props) => {
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(!currentProfileId);
    const [filteredProfiles, setFilteredProfiles] = useState(profiles);

    useEffect(() => {
        setIsDropdownOpen(!currentProfileId);
    }, [currentProfileId]);

    useEffect(() => {
        setFilteredProfiles(profiles);
    }, [profiles]);

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const searchQuery = e.target.value.toLowerCase();
        const filteredUsers = profiles.filter((profile) => {
            return (
                profile.name.toLowerCase()
                    .includes(searchQuery)
            );
        });
        setFilteredProfiles(filteredUsers);
    };

    const handleClickProfile = async (profile) => {
        if (currentProfileId === profile?.id) return;
        navigate(createImportProfilePath(profile.id));
        setIsDropdownOpen(false);
    };

    const ImportProfileDropdown = () => (
        <div className={`
            border border-solid border-black/10 rounded-b-3xl overflow-hidden sticky top-0 flex flex-col w-full transition-all overflow-hidden
            absolute z-10 bg-white shadow shadow-gray-100
        `}
        >
            <div className="flex flex-row w-full gap-2 rounded-b-xl overflow-hidden p-3 pb-0 !box-border">
                <input
                    name="userId"
                    className="form__input"
                    placeholder="Zoeken..."
                    onChange={onInputChange}
                />
            </div>

            <ul className="m-0 list-none p-0 w-full box-border overflow-hidden border-8 border-solid border-transparent space-y-2 h-full max-h-[60vh] overflow-y-scroll divide-x-0">

                {filteredProfiles?.length
                    ? filteredProfiles
                        .sort((profileA, profileB) => profileB.id > profileA.id)
                        .map((profile) => (
                            <li
                                key={profile.name}
                                className={
                                    `flex rounded-md items-start justify-between px-4 py-2 gap-8 2xl:gap-20 cursor-pointer
                                            ${currentProfileId.toString() === profile?.id.toString()
                                ? 'bg-[color:var(--html-bg-dark)] text-black font-bold'
                                : 'bg-transparent hover:bg-gray-100'}
                                        `
                                }
                                onClick={() => handleClickProfile(profile)}
                            >
                                <span className="text-left flex-1 break-all">{profile?.name}</span>
                                {/* <span className="text-right flex-1 break-word italic">{profile?.description}</span> */}
                            </li>
                        ))
                    : <li className="py-8">Geen importprofielen gevonden..</li>}

            </ul>
        </div>
    );

    return (
        <div className="flex flex-col">
            <button
                className="w-full h-20 bg-gray-200 hover:bg-gray-200 border-0 relative font-bold cursor-pointer px-64 rounded-lg overflow-hidden"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
                {!currentProfileId
                    ? 'Selecteer een importprofiel'
                    : importProfile?.name}
                <BiChevronDown
                    className="absolute right-6 top-6 bottom-6 w-8 h-8 transition-all"
                    style={{
                        transform: isDropdownOpen ? 'rotate(0deg)' : 'rotate(180deg)',
                    }}
                />
            </button>
            {isDropdownOpen
                && <ImportProfileDropdown />}
        </div>
    );
};

export default ImportProfileSelector;
