import { UseFormResetField } from 'react-hook-form';
import useFormHandler from '@hooks/useFormHandler';
import { Comment } from '@models/Comments';
import { AddCommentFormType } from '@components/features/comments/AddComment';
import useCreateComment from '@components/features/comments/hooks/useCreateComment';

const useAddCommentFormHandler = (documentId: number, resetField: UseFormResetField<AddCommentFormType>) => {
    const { mutate: createComment } = useCreateComment(documentId);

    const submitFn = async (comment: Comment) => {
        createComment(comment, {
            onSuccess: () => {
                resetField('text');
            },
        });
    };

    return useFormHandler(submitFn);
};

export default useAddCommentFormHandler;
