import React, { FC } from 'react';
import Layout from '@components/primitives/layout/Layout';
import { Outlet } from 'react-router-dom';
import { DEFAULT_LIST_TYPE } from '@routing/appUrls';
import useFetchDocClasses from '@components/features/docClass/hooks/useFetchDocClasses';
import useFetchDocList from '@components/features/docList/hooks/useFetchDocList';
import Content from '@components/primitives/content/Content';
import { useAtomValue } from 'jotai';
import { currentDocClassAtom } from '@js/store/appAtoms';
import ApplicationModals from '@components/modals/ApplicationModals';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { fetchDocLists } from '@repository/docListsRepository';

const PrivateRoutes: FC = () => {
    const currentDocClass = useAtomValue(currentDocClassAtom);
    const queryClient = useQueryClient();
    const { isLoading: docClassesFetched } = useFetchDocClasses();
    const { isLoading: docListFetched } = useFetchDocList(
        currentDocClass,
        DEFAULT_LIST_TYPE,
        { refetchOnWindowFocus: false },
    );

    queryClient.prefetchQuery({
        queryKey: [QueryKey.DocLists, currentDocClass],
        queryFn: currentDocClass ? ({ signal }) => fetchDocLists(currentDocClass, signal) : undefined,
    });

    const pageReadyToDisplay = !docClassesFetched && !docListFetched;

    return (
        <Layout>
            {pageReadyToDisplay ? (
                <Outlet />
            ) : (
                <Content>
                    {/*<Throbber />*/}
                </Content>
            )}
            <ApplicationModals />
        </Layout>
    );
};

export default PrivateRoutes;
