import { FC } from 'react';
import ModalFooter from '@components/primitives/modal/components/ModalFooter';
import ModalContent from '@components/primitives/modal/components/ModalContent';
import Button from '@components/primitives/button/Button';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { asyncConfirmModalButtonHandlersAtom } from '@js/store/modals/asyncConfirmModalAtoms';

interface Props {
    message?: string;
}

const AsyncConfirmModalInner: FC<Props> = ({ message }) => {
    const { t } = useTranslation();
    const { onConfirm, onCancel } = useAtomValue(asyncConfirmModalButtonHandlersAtom);

    return (
        <>
            <ModalContent>
                {message}
            </ModalContent>
            <ModalFooter>
                <Button onClick={onConfirm} id="asyncConfirmModal-confirm-btn" type="submit" primary>{t('yes')}</Button>
                <Button onClick={onCancel} id="asyncConfirmModal-cancel-btn" isLink>{t('cancel')}</Button>
            </ModalFooter>
        </>
    );
};

export default AsyncConfirmModalInner;
