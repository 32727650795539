import { FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import FormText from '@components/primitives/form/FormText';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import Button from '@components/primitives/button/Button';
import FormRow from '@components/primitives/form/FormRow';
import { t } from 'i18next';
import useAddCommentFormHandler from './hooks/useAddCommentFormHandler';

export type AddCommentFormType = {
    text: string;
};

const createSchema = (): yup.ObjectSchema<AddCommentFormType> => {
    return yup.object().shape({
        text: yup.string().trim().required(t('validation.text.required', { ns: 'comments' })),
    });
};

interface Props {
    documentId: number;
    userCanAddComments: boolean;
}

const AddComment: FC<Props> = ({ documentId, userCanAddComments }) => {
    const { t } = useTranslation();
    const schema = createSchema();
    const methods = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: {
            text: '',
        },
    });

    const { handleSubmit, resetField, formState: { isSubmitting, isValid } } = methods;
    const { onSubmit } = useAddCommentFormHandler(documentId, resetField);

    if (!userCanAddComments) {
        return null;
    }

    return (
        <FormProvider {...methods}>
            <form className="addComment" onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                <FormRow name="text">
                    <FormText name="text" placeholder={t('add.placeholder', { ns: 'comments' })} />
                </FormRow>
                <Button type="submit" disabled={!isValid || isSubmitting} secondary>{t('add.button', { ns: 'comments' })}</Button>
            </form>
        </FormProvider>
    );
};

export default AddComment;
