import { useAtomValue } from 'jotai';
import { redPencilModeAtom } from '@js/store/documentAtoms';
import useDocumentCoding from '@components/features/coding/hooks/useDocumentCoding';
import useFetchDocumentOcrSummary from '@components/features/ocr/hooks/useFetchDocumentOcrSummary';
import { DynamicFieldValue } from '@models/DynamicField';
import useOcrCorrections from '@js/store/ocrCorrections/useOcrCorrections';
import useUpdateDocumentHeader from '@components/features/documentHeaders/hooks/useUpdateDocumentHeader';
import useUpdateCodingLine from '@components/features/coding/hooks/useUpdateCodingLine';

const useRedPencilEffects = (documentId: number) => {
    const redPencilMode = useAtomValue(redPencilModeAtom);
    const redPencilField = 'aantal';
    const { getCodingLineFieldValue } = useDocumentCoding(documentId);
    const { data: documentOcrSummary } = useFetchDocumentOcrSummary(documentId);
    const { registerOcrCorrection, getOcrCorrectionById } = useOcrCorrections();
    const { mutate: updateDocumentHeader } = useUpdateDocumentHeader(documentId);
    const { mutate: updateCodingLine } = useUpdateCodingLine(documentId);

    const updateHeader = (id: string, value: DynamicFieldValue) => {
        updateDocumentHeader({
            values: [{
                id,
                value,
            }],
        });
    };

    const updateCodingLineFieldValue = async (id: string, value: DynamicFieldValue, lineId: number) => {
        await updateCodingLine({
            data: {
                values: [{
                    id,
                    value,
                }],
            },
            codingLineId: lineId,
        });
    };

    const onRedPencilFieldChange = async (value: DynamicFieldValue, lineId?: number) => {
        if (!redPencilMode || !lineId) {
            return;
        }

        const activeCodingLine = getCodingLineFieldValue(lineId, 'ocrrow');
        const ocrAmountRect = documentOcrSummary?.list.find((item) => item.id === `lineitem_aantal_${activeCodingLine}`);
        const ocrAmountValue = ocrAmountRect?.value && typeof ocrAmountRect?.value === 'string' ? +ocrAmountRect?.value.replace(',', '.') : 0;
        const ocrPriceRect = documentOcrSummary?.list.find((item) => item.id === `lineitem_bedrag_${activeCodingLine}`);
        const ocrPriceValue = ocrPriceRect?.value && typeof ocrPriceRect?.value === 'string' ? +ocrPriceRect.value.replace(',', '.') : 0;
        const ocrVatRect = documentOcrSummary?.list.find((item) => item.id === 'vatrate');
        const ocrVatValue = ocrVatRect?.value && typeof ocrVatRect?.value === 'string' ? +ocrVatRect.value.replace(',', '.') : 19;
        const piecePrice = ocrPriceValue ? ocrPriceValue / ocrAmountValue : 0;
        const amount = value ? typeof value === 'string' ? +value.replace(',', '.') : value : 0;

        // Change amount on document
        registerOcrCorrection(`lineitem_${redPencilField}_${activeCodingLine}`, amount);

        if (piecePrice) {
            // Change line price on document
            registerOcrCorrection(`lineitem_bedrag_${activeCodingLine}`, amount * piecePrice);
            // Change line price on coding
            await updateCodingLineFieldValue('bedrag', amount * piecePrice, lineId);
        }

        let lineTotal = 0;
        documentOcrSummary?.list
            .filter((item) => item.id.indexOf('lineitem_bedrag') !== -1)
            .forEach((item) => {
                if (!item.value || typeof item.value !== 'string') {
                    return;
                }

                if (item.id === `lineitem_bedrag_${activeCodingLine}`) {
                    lineTotal += amount * piecePrice;
                    return;
                }

                const correction = getOcrCorrectionById(item.id);

                if (correction) {
                    lineTotal += correction;
                    return;
                }

                lineTotal += +item.value.replace(',', '.');
            });

        // Change netto price on document
        registerOcrCorrection('netto_bedrag', lineTotal);
        // Change netto price on headers
        await updateHeader('netto_bedrag', lineTotal);

        if (ocrVatValue) {
            const newVat = (lineTotal / 100) * ocrVatValue;
            // Change VAT price on document
            registerOcrCorrection('btw_bedrag', newVat);
            // Change VAT price on headers
            await updateHeader('btw_bedrag', newVat);
            // Change bruto price on document
            registerOcrCorrection('bruto_bedrag', lineTotal + newVat);
            // Change bruto price on headers
            await updateHeader('bruto_bedrag', lineTotal + newVat);
        }
    };

    return {
        redPencilField,
        onRedPencilFieldChange,
    };
};

export default useRedPencilEffects;
