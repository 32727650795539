import { useMutation } from '@tanstack/react-query';
import { performSuperVisorAction } from '@repository/superVisorRepository';
import { SuperVisorMutation } from '@models/SuperVisorActions';
import useHandleDocumentActionResponse from '@hooks/useHandleDocumentActionResponse';

const usePerformSuperVisorAction = (listType: string, documentId: number, isPreviewMode?: boolean) => {
    const handleDocumentActionResponse = useHandleDocumentActionResponse(listType, documentId, isPreviewMode);

    return useMutation({
        mutationFn: (mutation: SuperVisorMutation) => performSuperVisorAction(documentId, mutation),
        onSuccess: handleDocumentActionResponse,
    });
};

export default usePerformSuperVisorAction;
