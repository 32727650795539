import useFetchDocumentHeaders from '@components/features/documentHeaders/hooks/useFetchDocumentHeaders';
import { useCallback } from 'react';
import { DynamicFieldValue } from '@models/DynamicField';

const useDocumentHeaders = (documentId?: number) => {
    const { data: documentHeaders } = useFetchDocumentHeaders(documentId);

    const getHeaderField = useCallback((id: string) => {
        return documentHeaders?.values.find((field) => field.id === id);
    }, [documentHeaders]);

    const getHeaderFieldDefinition = useCallback((id: string) => {
        return documentHeaders?.definitions.find((field) => field.id === id);
    }, [documentHeaders]);

    const getHeaderValue = useCallback((id: string): DynamicFieldValue => {
        const header = getHeaderField(id);

        return header?.value !== undefined ? header.value : null;
    }, [documentHeaders]);

    return {
        getHeaderValue,
        getHeaderField,
        getHeaderFieldDefinition,
        documentHeaders,
    };
};

export default useDocumentHeaders;
