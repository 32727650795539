import { useState } from 'react';

type UseFormHandlerReturnType<T> = {
    onSubmit: (data: T) => Promise<void>;
    globalError?: string;
};

const useFormHandler = <T>(
    submitFn: (data: T) => Promise<void>,
): UseFormHandlerReturnType<T> => {
    const [globalError, setGlobalError] = useState<string | undefined>();
    const onSubmit = (data: T) => {
        setGlobalError(undefined);
        return submitFn(data).catch((err) => {
            if (err.response?.data?.Message) {
                setGlobalError(err.response.data.Message);
                return;
            }

            setGlobalError('Er is iets mis gegaan');
            // if (err.response?.status === 422) {
            //     const violations = err.response.data.violations;
            //     for (const violation of violations) {
            //         setError(violation.propertyPath, {
            //             message: violation.message,
            //         });
            //     }
            // } else {
            //     let msg: string = '';
            //     if (err instanceof ApiError) {
            //         msg = err.message;
            //     } else {
            //         msg = err.response?.data?.message || defaultError;
            //     }
            //     setGlobalError(msg);
            // }
        });
    };
    return { globalError, onSubmit };
};

export default useFormHandler;
