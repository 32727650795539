import { useLocation } from 'react-router-dom';
import stringToObject from '@utils/stringToObject';

const useDocumentStatus = () => {
    const { search } = useLocation();
    const queryParams = stringToObject(search);
    const isEditMode = queryParams?.edit === 'true';

    return {
        isEditMode,
    };
};

export default useDocumentStatus;
