import { atom } from 'jotai';
import { closedModalState, ModalState } from '@js/store/modalAtoms';
import { DocumentAnnotation } from '@models/DocumentAnnotations';

export interface AnnotationModalData {
    documentId: number;
    page: number;
    position: [number, number];
    annotation?: DocumentAnnotation;
}

export const annotationModalAtom = atom<ModalState<AnnotationModalData>>(closedModalState);
