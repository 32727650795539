import { FC } from 'react';
import classNames from 'classnames';
import UnlinkIcon from '@images/svg/unlink.svg?react';
import useUnlinkMatchingLine from '@components/features/matching/hooks/useUnlinkMatchingLine';
import { useTranslation } from 'react-i18next';
import useLinkedLineInfo from '@components/features/matching/hooks/useLinkedLineInfo';
import useRefreshDocumentMatching from '@components/features/matching/hooks/useRefreshDocumentMatching';
import useInvoiceLinesCaret from '@hooks/caret/useInvoiceLinesCaret';

interface Props {
    documentId: number;
    filteredOrderLines: boolean;
    isReadOnly: boolean;
}

const UnlinkButton: FC<Props> = ({ documentId, filteredOrderLines, isReadOnly }) => {
    const { t } = useTranslation();
    const { invoiceLinesCaret } = useInvoiceLinesCaret();
    const { mutate: unlinkMatchingLine, isPending: isUnlinkingMatchingLine } = useUnlinkMatchingLine(documentId);
    const { mutate: refreshDocumentMatching } = useRefreshDocumentMatching(documentId);
    const { linesAreLinked } = useLinkedLineInfo(documentId, filteredOrderLines);
    const unlinkButtonClasses = classNames('buttonStrip__button', {
        'buttonStrip__button--loading': isUnlinkingMatchingLine,
    });

    const onUnlink = async () => {
        if (!linesAreLinked || invoiceLinesCaret?.lineId === undefined) {
            return;
        }

        await unlinkMatchingLine(invoiceLinesCaret.lineId);
        await refreshDocumentMatching();
    };

    return (
        <button
            type="button"
            title={t('actions.unlink', { ns: 'matching' })}
            className={unlinkButtonClasses}
            onClick={onUnlink}
            disabled={isUnlinkingMatchingLine || !linesAreLinked || isReadOnly}
        >
            <UnlinkIcon className="md" />
        </button>
    );
};

export default UnlinkButton;
