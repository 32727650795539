import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const useCloseOnLocationChange = (closeFn: () => void) => {
    const [cacheLocation, setCacheLocation] = useState<null | string>(null);
    const location = useLocation();

    useEffect(() => {
        if (!cacheLocation) {
            setCacheLocation(location.key);
            return;
        }

        if (cacheLocation !== location.key) {
            closeFn();
        }
    }, [location]);
};

export default useCloseOnLocationChange;
