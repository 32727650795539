import { useMutation } from '@tanstack/react-query';
import { PerformDocumentActionFormType } from '@components/modals/performDocumentActionModal/PerformDocumentActionModalInner';
import { performDocumentAction } from '@repository/performDocumentActionRepository';
import { DefaultActionId } from '@models/DocumentActions';
import useHandleDocumentActionResponse from '@hooks/useHandleDocumentActionResponse';

interface Data extends PerformDocumentActionFormType {
    documentId: number;
    actionId: DefaultActionId | string;
    assignments?: string[];
}

const usePerformDocumentAction = (listType: string, documentId?: number) => {
    const handleDocumentActionResponse = useHandleDocumentActionResponse(listType, documentId);

    return useMutation({
        mutationFn: (data: Data) => performDocumentAction(data.documentId, {
            ...(data?.remark ? { remark: data.remark } : null),
            ...(data?.numberDays ? { numberDays: data.numberDays } : null),
            ...(data?.userId ? { userId: data.userId } : null),
            ...(data?.assignments ? { assignments: { userIds: data.assignments } } : null),
            id: data.actionId,
        }),
        onSuccess: handleDocumentActionResponse,
    });
};

export default usePerformDocumentAction;
