import { FilterFn } from '@tanstack/react-table';

const numericFilter: FilterFn<any> = (row, columnId, value) => {
    const rowValue = row.getValue(columnId) as number;
    const valueAsNumber = rowValue?.toFixed(2);
    const filterValueAsNumber = value.replace(',', '.');
    return valueAsNumber ? valueAsNumber.indexOf(filterValueAsNumber) !== -1 : false;
};

export default numericFilter;
