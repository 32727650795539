import React, { FC } from 'react';
import { Sizes } from '@utils/sizes';
import ListModalInner from '@components/modals/listModal/ListModalInner';
import Modal from '@components/primitives/modal/Modal';
import { useAtom } from 'jotai';
import { listModalAtom, ListModalData } from '@js/store/modals/listModalAtom';

const ListModal: FC = () => {
    const [modalState, setModalState] = useAtom(listModalAtom);

    if (!modalState.data) {
        return null;
    }

    return (
        <Modal<ListModalData>
            modalState={modalState}
            setModalState={setModalState}
            size={modalState.data.size || Sizes.md}
            title={modalState.data.title}
        >
            <ListModalInner
                modalData={modalState.data}
            />
        </Modal>
    );
};

export default ListModal;
