import { Dispatch, FC, ReactNode, SetStateAction, useState } from 'react';
import ToggleListItem from '@components/primitives/toggleList/components/ToggleListItem';
import classNames from 'classnames';
import { useHotkeys } from 'react-hotkeys-hook';
import { CLOSE_TOGGLELIST } from '@constants/hotkeys';
import { DefaultActionId } from '@models/DocumentActions';

export interface ToggleListItemBase {
    isHidden?: boolean;
    showInPreviewModal?: boolean;
}

export interface ToggleListButton extends ToggleListItemBase {
    id: DefaultActionId | string;
    title: string;
    subTitle?: string;
    icon?: ReactNode;
    onClick: (id: DefaultActionId | string) => void;
    onSuccess?: (id: DefaultActionId) => void;
    className?: string;
    options?: object;
    enabled?: boolean;
}

export interface ToggleListElement extends ToggleListItemBase {
    element: ReactNode;
}

export type ToggleListGroup = (ToggleListButton | ToggleListElement)[];

export enum ToggleListPosition {
    Top = 'Top',
    Bottom = 'Bottom',
    BottomLeft = 'BottomLeft',
    BottomRight = 'BottomRight',
    Left = 'Left',
    TopLeft = 'TopLeft',
    CenterLeft = 'CenterLeft',
}

interface Props {
    children?: ReactNode;
    trigger?: (toggle: () => void) => ReactNode;
    list: ToggleListGroup[];
    position?: ToggleListPosition;
    className?: string;
    isDark?: boolean;
    title?: string;
    isOpen?: boolean;
    setIsOpen?: Dispatch<SetStateAction<boolean>>;
    trackOpenList?: () => void;
}

const ToggleList: FC<Props> = ({
    trigger,
    isOpen,
    setIsOpen,
    trackOpenList,
    list,
    children,
    title,
    className,
    isDark,
    position = ToggleListPosition.Bottom,
}) => {
    const [open, setOpen] = useState<boolean>(false);
    const toggleListIsOpen = isOpen !== undefined ? isOpen : open;
    const setToggleListIsOpen = setIsOpen !== undefined ? setIsOpen : setOpen;

    const toggle = () => {
        setToggleListIsOpen((state) => {
            if (!state && trackOpenList) {
                trackOpenList();
            }
            return !state;
        });
    };

    useHotkeys(CLOSE_TOGGLELIST, () => {
        if (!toggleListIsOpen) {
            return;
        }
        setToggleListIsOpen(false);
    }, [toggleListIsOpen]);

    const classes = classNames('toggleList__wrapper', className, {
        'toggleList__wrapper--open': toggleListIsOpen,
        'toggleList__wrapper--top': position === ToggleListPosition.Top || position === ToggleListPosition.TopLeft,
        'toggleList__wrapper--left': position === ToggleListPosition.Left,
        'toggleList__wrapper--topLeft': position === ToggleListPosition.TopLeft,
        'toggleList__wrapper--centerLeft': position === ToggleListPosition.CenterLeft,
        'toggleList__wrapper--bottomLeft': position === ToggleListPosition.BottomLeft,
        'toggleList__wrapper--bottomRight': position === ToggleListPosition.BottomRight,
        'toggleList__wrapper--isDark': isDark,
    });

    return (
        <div className={classes}>
            <div className="toggleList__trigger">
                {trigger && trigger(toggle)}
            </div>
            {toggleListIsOpen ? (
                <>
                    <div className="toggleList__overlay" onClick={() => setToggleListIsOpen(false)} />
                    <div className="toggleList">
                        {title ? (
                            <div className="toggleList__title">
                                {title}
                            </div>
                        ) : null}
                        {list
                            .filter((group) => {
                                const filteredGroup = group.filter((item) => !item.isHidden);
                                return filteredGroup.length;
                            })
                            .map((group, groupIndex) => (
                                <div className="toggleList__group" key={groupIndex}>
                                    {group
                                        .filter((item) => !item.isHidden)
                                        .map((item, index) => {
                                            if ('element' in item) {
                                                return (
                                                    <span key={index} onClick={toggle}>
                                                        {item.element}
                                                    </span>
                                                );
                                            }

                                            return (
                                                <ToggleListItem key={index} {...item} toggle={toggle} />
                                            );
                                        })}
                                </div>
                            ))}
                        {children}
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default ToggleList;
