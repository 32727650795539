import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { SavedComments } from '@models/Comments';
import { fetchComments } from '@repository/commentsRepository';

const useFetchDocumentComments = (documentId: number): UseQueryResult<SavedComments> => {
    return useQuery({
        queryKey: [QueryKey.Comments, documentId],
        queryFn: ({ signal }) => fetchComments(documentId, signal),
        staleTime: 0,
    });
};

export default useFetchDocumentComments;
