import { FC, useEffect } from 'react';
import ModalFooter from '@components/primitives/modal/components/ModalFooter';
import ModalContent from '@components/primitives/modal/components/ModalContent';
import Button from '@components/primitives/button/Button';
import { useTranslation } from 'react-i18next';
import useFetchDocumentDynamicAssign from '@components/modals/dynamicAssignModal/hooks/useFetchDocumentDynamicAssign';
import FormRow from '@components/primitives/form/FormRow';
import FormSelect from '@components/primitives/form/FormSelect';
import { useForm, FormProvider } from 'react-hook-form';
import useDynamicAssignSchema from '@components/modals/dynamicAssignModal/validation/useDynamicAssignSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import QuestionIcon from '@images/svg/question-circle.svg?react';
import { DocumentDynamicAssignUser } from '@models/DocumentDynamicAssign';
import { closedModalState } from '@js/store/modalAtoms';
import { useSetAtom } from 'jotai';
import { dynamicAssignModalAtom, DynamicAssignModalData } from '@js/store/modals/dynamicAssignModalAtom';

interface Props {
    modalData: DynamicAssignModalData;
}

const DynamicAssignModalInner: FC<Props> = ({ modalData }) => {
    const { documentId, onConfirm, onCanceled } = modalData;
    const setModalState = useSetAtom(dynamicAssignModalAtom);
    const { data: documentDynamicAssign } = useFetchDocumentDynamicAssign(documentId);
    const { t } = useTranslation();
    const schema = useDynamicAssignSchema(documentDynamicAssign?.steps);
    const methods = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
    });
    const { handleSubmit, setValue } = methods;

    const closeModal = () => {
        setModalState(closedModalState);
    };

    const handleCancel = () => {
        if (onCanceled) {
            onCanceled();
        }
        closeModal();
    };

    const onSubmit = (data: { [key: string]: string }) => {
        const assignments = Object.entries(data).map(([, value]) => value);
        onConfirm({ assignments });
        closeModal();
    };

    const sortOnFullname = (a: DocumentDynamicAssignUser, b: DocumentDynamicAssignUser) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0;
    };

    useEffect(() => {
        documentDynamicAssign?.steps.forEach((step) => {
            setValue(`assignment_${step.index}`, step.preferredUser?.id || '');
        });
    }, [documentDynamicAssign]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="false">
                <ModalContent>
                    {documentDynamicAssign?.workflowName ? (
                        <p>{t('dynamicAssign.procedureToFollow', { ns: 'workflowActions' })} {documentDynamicAssign.workflowName}</p>
                    ) : null}
                    {documentDynamicAssign?.steps?.length ? (
                        <>
                            {documentDynamicAssign?.steps?.map((step) => {
                                const options = step.users
                                    .sort(sortOnFullname)
                                    .map((option) => {
                                        return {
                                            value: option.id,
                                            label: option.name ? option.name : option.id,
                                        };
                                    });

                                return (
                                    <div key={step.index} className="dynamicAssign__row">
                                        <FormRow
                                            label={
                                                <>{t('dynamicAssign.approvalStep', { ns: 'workflowActions', step: step.index + 1 })} {step.stateName}</>
                                            }
                                            name={`assignment_${step.index}`}
                                        >
                                            <FormSelect
                                                className="dynamicAssign__select"
                                                name={`assignment_${step.index}`}
                                                isReadOnly={step.readOnly}
                                                options={options}
                                            />
                                            {step.conditionText ? (
                                                <div
                                                    className="dynamicAssign__conditional"
                                                    title={`${t('dynamicAssign.conditional', { ns: 'workflowActions' })} ${step.conditionText}`}
                                                >
                                                    <QuestionIcon />
                                                </div>
                                            ) : null}
                                        </FormRow>
                                    </div>
                                );
                            })}
                        </>
                    ) : null}
                </ModalContent>
                <ModalFooter>
                    <Button type="submit" primary>{t('okay')}</Button>
                    <Button isLink onClick={handleCancel}>{t('cancel')}</Button>
                </ModalFooter>
            </form>
        </FormProvider>
    );
};

export default DynamicAssignModalInner;
