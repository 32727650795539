import { SetStateAction } from 'react';
import { SetAtom } from '@src/jotai';

export const closedModalState = {
    isOpen: false,
};

export type SetModalState<T> = SetAtom<[SetStateAction<T>], void>;
export interface ModalState<T = {}> {
    isOpen: boolean,
    isHidden?: boolean;
    data?: T,
}
