import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import FormError from '@components/primitives/form/FormError';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Controller, useFormContext } from 'react-hook-form';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export interface Option {
    value: string | number;
    label: string;
}

interface Props {
    defaultValue?: string;
    className?: string;
    name: string;
    placeholder?: string;
}

const FormEditor: FC<Props> = ({ className, name, defaultValue, placeholder }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const classes = classNames('form__field', 'form__field--editor', className);
    const { setValue } = useFormContext();

    useEffect(() => {
        if (!defaultValue) {
            return;
        }
        const blocksFromHtml = htmlToDraft(defaultValue);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        setEditorState(EditorState.createWithContent(contentState));
        setValue(name, defaultValue);
    }, [defaultValue]);

    const getValue = (): string => {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()));
    };

    const onEditorStateChange = (newEditorState: any, onChange: (val: string) => void) => {
        setEditorState(newEditorState);
        onChange(getValue());
    };

    return (
        <div className={classes}>
            <Controller
                name={name}
                defaultValue={defaultValue}
                render={({ field: { onChange } }) => (
                    <Editor
                        wrapperClassName="form__editor"
                        toolbarClassName="form__editor-toolbar"
                        editorClassName="form__editor-field"
                        editorState={editorState}
                        onEditorStateChange={(editorState) => onEditorStateChange(editorState, onChange)}
                        stripPastedStyles
                        placeholder={placeholder}
                        toolbar={{
                            options: ['inline'],
                            inline: {
                                options: ['bold', 'italic'],
                            },
                        }}
                    />
                )}
            />
            {name ? (
                <FormError name={name} />
            ) : null}
        </div>
    );
};

export default FormEditor;
