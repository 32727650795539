import { FC } from 'react';
import { useSetAtom } from 'jotai';
import AccrualsIcon from '@images/svg/accruals.svg?react';
import useFetchDocumentCoding from '@components/features/coding/hooks/useFetchDocumentCoding';
import classNames from 'classnames';
import useTrackEvent from '@hooks/useTrackEvent';
import { codingAccrualsModalAtom } from '@js/store/modals/codingAccrualsModalAtom';

interface Props {
    documentId?: number;
    lineId?: number;
    value: string;
    showAsText: boolean;
    onFieldBlur?: () => void;
}

const DocumentAccrualsField: FC<Props> = ({ documentId, lineId, value, showAsText, onFieldBlur }) => {
    const { data: documentCoding } = useFetchDocumentCoding(documentId);
    const setCodingAccrualsModal = useSetAtom(codingAccrualsModalAtom);
    const { trackEvent } = useTrackEvent();

    if (!documentCoding?.meta.codingAccrualsEnabled || typeof lineId !== 'number') {
        return null;
    }

    const onBlur = () => {
        if (onFieldBlur) {
            onFieldBlur();
        }
    };

    const onButtonClick = () => {
        if (!documentId) {
            console.warn('Document ID is not provided');
            return;
        }

        setCodingAccrualsModal({
            isOpen: true,
            data: {
                documentId,
                lineId,
            },
        });
        trackEvent({
            category: 'codingAccruals',
            action: 'opened',
            name: `Doc: ${documentId}, line: ${lineId}`,
        });
    };

    return (
        <div
            className={classNames('form__field', 'form__field--accruals')}
        >
            <div className="dynamicFieldCell--static">
                {value}
            </div>
            {!showAsText && !documentCoding?.meta.readOnly ? (
                <button
                    className="codingAccruals__button"
                    type="button"
                    onClick={onButtonClick}
                    onBlur={onBlur}
                >
                    <AccrualsIcon />
                </button>
            ) : null}
        </div>
    );
};

export default DocumentAccrualsField;
