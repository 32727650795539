import { executeGetRequest } from '@api/client';
import { createFetchDocListCountPath, createFetchDocListPath, createFetchDocListsPath } from '@routing/apiUrls';
import { DocLists } from '@models/DocLists';
import { DocList, DocListCountResponse } from '@models/DocList';

export const fetchDocLists = async (docClass: string, signal?: AbortSignal): Promise<DocLists> => {
    const result = await executeGetRequest<DocLists>(createFetchDocListsPath(docClass), signal);
    return result.data;
};

export const fetchDocList = async (docClass: string, listType: string, signal?: AbortSignal): Promise<DocList> => {
    const result = await executeGetRequest<DocList>(createFetchDocListPath(docClass, listType), signal);
    return result.data;
};

export const fetchDocListCount = async (docClass: string, listType: string, signal?: AbortSignal): Promise<DocListCountResponse> => {
    const result = await executeGetRequest<DocListCountResponse>(createFetchDocListCountPath(docClass, listType), signal);
    return result.data;
};
