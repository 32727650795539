import { FC, ReactNode, useEffect, useMemo, useRef } from 'react';
import { getOriginalFileHeight, getOriginalFileWidth } from '@utils/documentInfo';
import classNames from 'classnames';
import MouseToolTip from '@components/primitives/mouseToolTip/MouseToolTip';
import { useTranslation } from 'react-i18next';
import * as linkify from 'linkifyjs';

interface Props {
    onClick?: () => void;
    children?: ReactNode;
    title?: string;
    zone: string;
    className?: string;
    scrollToElement?: boolean;
    showToolTip?: boolean;
    fileWidth: number;
    fileHeight: number;
    shiftPressed?: boolean;
    truncateWithinDocument?: boolean;
}
const OcrRect: FC<Props> = (
    { onClick,
        children,
        zone,
        title,
        className,
        scrollToElement,
        showToolTip,
        fileWidth,
        fileHeight,
        shiftPressed,
        truncateWithinDocument,
    },
) => {
    const { t } = useTranslation();
    const ref = useRef<HTMLButtonElement | null>(null);
    const zones = zone.split(/\s/u);
    const [left, top, width, height] = zones;
    const offset = 0.2;

    const getHyperlink = (): string | undefined => {
        if (!title) {
            return undefined;
        }

        const foundLinks = linkify.find(title);

        return foundLinks.length ? foundLinks[0].href : undefined;
    };

    const isHyperlink = !!getHyperlink();

    useEffect(() => {
        if (!ref?.current || !scrollToElement) {
            return;
        }

        ref.current.scrollIntoView({
            block: 'center',
            inline: 'center',
        });
    }, [ref, zone, scrollToElement]);

    const handleClick = () => {
        if (!onClick) {
            return;
        }

        if (isHyperlink && shiftPressed) {
            window.open(getHyperlink(), '_blank');
            return;
        }

        onClick();
    };

    const getWidth = useMemo(() => {
        // Truncate width when the OCR rect is wider than the document. This happens on OcrTrainingTableBorder.
        if (truncateWithinDocument && (+left + +width) > getOriginalFileWidth(fileWidth)) {
            return `${(((getOriginalFileWidth(fileWidth) - +left) / getOriginalFileWidth(fileWidth)) * 100) + (offset * 2)}%`;
        }

        return `${((+width / getOriginalFileWidth(fileWidth)) * 100) + (offset * 2)}%`;
    }, [width, getOriginalFileWidth, fileWidth, offset, truncateWithinDocument, left]);

    const getHeight = useMemo(() => {
        // Truncate height when the OCR rect is higher than the document. This happens on OcrTrainingTableBorder.
        if (truncateWithinDocument && (+top + +height) > getOriginalFileHeight(fileHeight)) {
            return `${(((getOriginalFileHeight(fileHeight) - +top) / getOriginalFileHeight(fileHeight)) * 100) + (offset * 2)}%`;
        }

        return `${((+height / getOriginalFileHeight(fileHeight)) * 100) + (offset * 2)}%`;
    }, [height, getOriginalFileHeight, fileHeight, offset, truncateWithinDocument, top]);

    const getLeft = useMemo(() => {
        return `${((+left / getOriginalFileWidth(fileWidth)) * 100) - offset}%`;
    }, [left, getOriginalFileWidth, fileWidth, offset]);

    const getTop = useMemo(() => {
        return `${((+top / getOriginalFileHeight(fileHeight)) * 100) - offset}%`;
    }, [top, getOriginalFileHeight, fileHeight, offset]);

    const classes = classNames('', className);

    return (
        <button
            type="button"
            ref={ref}
            className={classes}
            onClick={handleClick}
            // title={title}
            style={{
                width: getWidth,
                height: getHeight,
                left: getLeft,
                top: getTop,
            }}
        >
            <MouseToolTip
                text={showToolTip ? title : ''}
                title={isHyperlink && shiftPressed ? t('openLink.title') : shiftPressed ? t('copyToClipboard.title') : ''}
            >
                {children}
            </MouseToolTip>
        </button>
    );
};

export default OcrRect;
