import { executeGetRequest, executePutRequest } from '@api/client';
import { User } from '@models/User';
import { createFetchCurrentUserPath, createUpdateCurrentUserPath } from '@routing/apiUrls';
import { CurrentUserUpdateMutation } from '@models/CurrentUser';

export const fetchCurrentUser = async (signal?: AbortSignal): Promise<User> => {
    const result = await executeGetRequest<User>(createFetchCurrentUserPath(), signal);
    return result.data;
};

export const updateCurrentUser = async (data: CurrentUserUpdateMutation): Promise<void> => {
    const result = await executePutRequest<void>(createUpdateCurrentUserPath(), data);
    return result.data;
};
