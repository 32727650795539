import { atom } from 'jotai';
import { closedModalState, ModalState } from '@js/store/modalAtoms';

export interface Assignments {
    assignments: string[];
}

export interface DynamicAssignModalData {
    documentId: number;
    onConfirm: (data?: Assignments) => void;
    onCanceled?: () => void;
}

export const dynamicAssignModalAtom = atom<ModalState<DynamicAssignModalData>>(closedModalState);
