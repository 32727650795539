import { RenderPageProps } from '@react-pdf-viewer/core';
import { FC, MouseEvent, useRef } from 'react';
import Annotations from '@components/features/annotations/Annotations';
import { DocumentAnnotations } from '@models/DocumentAnnotations';
import { getOriginalFileHeight, getOriginalFileWidth } from '@utils/documentInfo';
import classNames from 'classnames';
import Ocr from '@components/features/ocr/Ocr';
import { useSetAtom } from 'jotai';
import { DocumentOcrWord } from '@models/DocumentOcrData';
import { annotationModalAtom } from '@js/store/modals/annotationModalAtom';

interface Props extends RenderPageProps {
    annotations?: DocumentAnnotations;
    documentId: number;
    annotationMode: boolean;
    onOcrClick?: (ocrItem: DocumentOcrWord) => void;
    readOnly?: boolean;
    rerenderDependency?: any[];
    ocrTrainingEnabled?: boolean;
}

const AnnotatedPdfPage: FC<Props> = ({
    canvasLayer,
    textLayer,
    annotationLayer,
    pageIndex,
    width,
    height,
    annotations,
    documentId,
    annotationMode,
    onOcrClick,
    readOnly,
    scale,
    rerenderDependency,
    ocrTrainingEnabled = false,
}) => {
    const setAnnotationModal = useSetAtom(annotationModalAtom);
    const wrapperRef = useRef<null | HTMLDivElement>(null);
    const pageAnnotations = annotations?.list.filter(
        (item) => item.page === pageIndex,
    );
    const fileWidth = Math.round((width / (scale * 100)) * 100);
    const fileHeight = Math.round((height / (scale * 100)) * 100);

    const onDocClick = (e: MouseEvent<HTMLDivElement>) => {
        const clickedOnExistingAnnotation = (e.target as Element).classList.value === 'annotation'
            || (e.target as Element).parentElement?.classList.value === 'annotation';
        if (
            !annotationMode
            || !wrapperRef?.current
            || clickedOnExistingAnnotation
        ) {
            return;
        }
        const viewportOffset = wrapperRef.current.getBoundingClientRect();
        const positionLeftPx = e.pageX - viewportOffset.left;
        const positionTopPx = e.pageY - viewportOffset.top;
        const positionLeftPercentage = (positionLeftPx / viewportOffset.width) * 100;
        const positionTopPercentage = (positionTopPx / viewportOffset.height) * 100;
        const backendPositionLeft = (getOriginalFileWidth(fileWidth) / 100) * positionLeftPercentage;
        const backendPositionTop = (getOriginalFileHeight(fileHeight) / 100) * positionTopPercentage;

        setAnnotationModal({
            isOpen: true,
            data: {
                page: pageIndex,
                documentId,
                position: [
                    Math.round(backendPositionLeft),
                    Math.round(backendPositionTop),
                ],
            },
        });
    };

    const classes = classNames('pdfPage', {
        'pdfPage--annotationMode': annotationMode,
    });

    return (
        <div
            className={classes}
            onClick={onDocClick}
            ref={wrapperRef}
            style={{ width, height }}
        >
            {canvasLayer.children}
            {textLayer.children}
            {annotationLayer.children}
            {!annotationMode ? (
                <Ocr
                    documentId={documentId}
                    page={pageIndex}
                    onOcrClick={onOcrClick}
                    readOnly={readOnly}
                    fileWidth={fileWidth}
                    fileHeight={fileHeight}
                    rerenderDependency={rerenderDependency}
                    ocrTrainingEnabled={ocrTrainingEnabled}
                />
            ) : null}
            <Annotations
                documentId={documentId}
                annotations={pageAnnotations}
                wrapperRef={wrapperRef}
                page={pageIndex}
                readOnly={readOnly}
                fileWidth={fileWidth}
                fileHeight={fileHeight}
            />
        </div>
    );
};

export default AnnotatedPdfPage;
