import { FC, useMemo, useRef } from 'react';
import { StorageKeys } from '@constants/storageKeys';
import useOrderLinesGridEffects from '@components/features/matching/hooks/useOrderLinesGridEffects';
import classNames from 'classnames';
import DataGrid, { COLUMN_ID_SELECT } from '@components/primitives/dataGrid/DataGrid';
import { createColumnHelper, Row } from '@tanstack/react-table';
import DataGridCell from '@components/primitives/dataGrid/DataGridCell';
import CopyButton from '@components/features/matching/components/CopyButton';
import LinkButton from '@components/features/matching/components/LinkButton';
import UnlinkButton from '@components/features/matching/components/UnlinkButton';
import RefreshButton from '@components/features/matching/components/RefreshButton';
import ApproveButton from '@components/features/matching/components/ApproveButton';
import RejectButton from '@components/features/matching/components/RejectButton';
import FilterButton from '@components/features/matching/components/FilterButton';
import { DocumentInvoiceLine } from '@models/DocumentInvoiceLines';
import { DocumentOrderLine } from '@models/DocumentOrderLines';
import useOrderLinesCaret from '@hooks/caret/useOrderLinesCaret';
import { createStorageKey } from '@utils/storage';
import { useAtomValue } from 'jotai';
import { currentDocClassAtom } from '@js/store/appAtoms';
import useFetchDocumentInvoiceLines from '@components/features/documentInvoiceLines/hooks/useFetchDocumentInvoiceLines';
import useFetchDocumentOrderLines from '@components/features/documentOrderLines/hooks/useFetchDocumentOrderLines';

interface Props {
    documentId: number;
    onMouseDown: () => void;
    onFilter: () => void;
    isPreviewMode: boolean;
    filteredOrderLines: boolean;
}

const OrderLines: FC<Props> = ({
    documentId,
    onMouseDown,
    onFilter,
    isPreviewMode,
    filteredOrderLines,
}) => {
    const { data: documentOrderLines } = useFetchDocumentOrderLines(documentId, filteredOrderLines);
    const { data: documentInvoiceLines } = useFetchDocumentInvoiceLines(documentId);
    const isReadOnly = documentInvoiceLines ? documentInvoiceLines?.meta.readOnly : true;
    const currentDocClass = useAtomValue(currentDocClassAtom);
    const orderLinesRef = useRef(null);
    const { orderLinesCaret } = useOrderLinesCaret();
    const { onCellClick, rowSelection, setRowSelection } = useOrderLinesGridEffects(documentId, orderLinesRef);

    const getInvoiceLineByMatchKey = (matchKey: string): DocumentInvoiceLine | undefined => {
        return documentInvoiceLines?.rows.find((item) => {
            const matchKeyField = item.values.find((val) => val.id === 'matchkey');
            if (!matchKeyField) {
                return false;
            }

            return matchKeyField.value === matchKey;
        });
    };

    const data = useMemo(() => {
        if (!documentOrderLines) {
            return [];
        }

        return documentOrderLines.list.map((row) => {
            return Object.entries(row).reduce((result, [key, value]) => ({
                ...result,
                [key.toLowerCase()]: value,
            }), {});
        });
    }, [documentOrderLines]);

    const selectedLines = useMemo(() => {
        return Object.keys(rowSelection).reduce((result: any[], index) => {
            return [
                ...result,
                data[+index],
            ];
        }, []).map((row) => row.matchkey);
    }, [rowSelection]);

    const columnHelper = createColumnHelper<DocumentOrderLine>();
    const columns = useMemo(() => {
        if (!documentOrderLines) {
            return [];
        }

        return documentOrderLines.scheme
            .filter((column) => !column.isHidden)
            .map((column) => {
                return columnHelper.accessor(column.columnId.toLowerCase(), {
                    header: column.title,
                    ...(column.format === 'text' || column.format === 'lookup' ? {
                        filterFn: 'fuzzy',
                        sortingFn: 'alphanumeric',
                    } : column.format === 'decimal' ? {
                        filterFn: 'numeric',
                        sortingFn: 'numeric',
                    } : {
                        enableColumnFilter: false,
                    }),
                    meta: {
                        format: column.format,
                        ...(column.format === 'decimal' ? {
                            className: 'dataGrid__cell dataGrid__cell--decimal',
                        } : undefined),
                    },
                    cell: (info) => {
                        return (
                            <DataGridCell
                                value={info.getValue() as string}
                                format={column.format}
                            />
                        );
                    },
                });
            });
    }, [documentOrderLines]);

    const enableRowSelection = (row: Row<any>): boolean => {
        const matchedRow = documentInvoiceLines?.rows.find((matchingRow) => {
            const matchKeyField = matchingRow.values.find((field) => field.id === 'matchkey');
            if (!matchKeyField) {
                return true;
            }
            return matchKeyField.value === row.original.matchkey;
        });

        return !matchedRow;
    };

    const resetFiltersDependencies = useMemo(() => {
        return [documentId];
    }, [documentId]);

    return (
        <>
            <div className="buttonStrip matching__buttons">
                <div className="dragBar dragBar--invoiceLines" onMouseDown={onMouseDown} />
                {!isPreviewMode ? (
                    <>
                        <CopyButton
                            documentId={documentId}
                            selectedLines={selectedLines}
                            setRowSelection={setRowSelection}
                            isReadOnly={isReadOnly}
                        />
                        <LinkButton
                            documentId={documentId}
                            filteredOrderLines={filteredOrderLines}
                            isReadOnly={isReadOnly}
                        />
                        <UnlinkButton
                            documentId={documentId}
                            filteredOrderLines={filteredOrderLines}
                            isReadOnly={isReadOnly}
                        />
                        <RefreshButton
                            documentId={documentId}
                            isReadOnly={isReadOnly}
                        />
                        {documentInvoiceLines?.meta?.enableMatchDifferenceApprove ? (
                            <ApproveButton
                                documentId={documentId}
                                isReadOnly={isReadOnly}
                            />
                        ) : null}
                        {documentInvoiceLines?.meta?.enableMatchDifferenceReject ? (
                            <RejectButton
                                documentId={documentId}
                                isReadOnly={isReadOnly}
                            />
                        ) : null}
                        <FilterButton
                            onFilter={onFilter}
                            isFiltered={filteredOrderLines}
                        />
                    </>
                ) : null}
            </div>
            <div className="right__grid orderLines__grid" ref={orderLinesRef}>
                <DataGrid
                    columns={columns}
                    data={data}
                    resetFiltersDependencies={resetFiltersDependencies}
                    name={`${documentId}_orderLines`}
                    columnHelper={columnHelper}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    enableRowSelection={enableRowSelection}
                    storageKey={createStorageKey([currentDocClass, StorageKeys.GridMatchingOrderLines])}
                    selectedRow={orderLinesCaret?.lineId}
                    getRowProps={(row, selectedRow) => {
                        const { matchkey, matchvalue } = row.original;
                        const invoiceLine = getInvoiceLineByMatchKey(matchkey);
                        const matchValueField = invoiceLine?.values.find((f) => f.id === 'matchvalue');
                        const linkedLineHasMatchKey = matchValueField && (
                            matchValueField.value === -1
                            || matchValueField.value === 1
                            || matchValueField.value === 2
                            || matchValueField.value === 3
                        );

                        const classes = classNames('dataGrid__tr', {
                            'dataGrid__tr--active': selectedRow === matchkey,
                            'dataGrid__tr--matchNo': linkedLineHasMatchKey ? matchValueField?.value === -1 : matchvalue === -1,
                            'dataGrid__tr--matchPriceDifference': linkedLineHasMatchKey ? matchValueField?.value === 1 : matchvalue === 1,
                            'dataGrid__tr--matchFull': linkedLineHasMatchKey ? matchValueField?.value === 2 : matchvalue === 2,
                            'dataGrid__tr--matchNumberOfDifference': linkedLineHasMatchKey ? matchValueField?.value === 3 : matchvalue === 3,
                            'dataGrid__tr--matchDateDifference': row.original.matchvalue === 4,
                        });

                        return {
                            className: classes,
                        };
                    }}
                    getCellProps={(cellInfo, selectedRow, selectedCell) => {
                        const lineId = cellInfo.row.original.row;
                        const { name, format } = cellInfo.column.columnDef.meta;
                        const classes = classNames('dataGrid__cell', {
                            'dataGrid__cell--select': cellInfo.column.id === COLUMN_ID_SELECT,
                            'dataGrid__cell--decimal': format === 'decimal',
                            'dataGrid__cell--active': selectedCell === name && selectedRow === lineId,
                        });

                        return {
                            className: classes,
                            onClick: () => onCellClick(cellInfo.row, cellInfo),
                        };
                    }}

                />
            </div>
        </>
    );
};

export default OrderLines;
