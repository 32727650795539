export const extractNumbers = (input: string): number[] => {
    return input.trim().replace(/€/gm, '').split(/\s+/gm).map((stringElement) => {
        const decimal = stringElement.indexOf(',') > stringElement.indexOf('.') ? ',' : '.';
        const value = decimal === ',' ? stringElement.replace('.', '') : stringElement.replace(',', '');

        return parseFloat(value.replace(',', '.').replace(/([\d.]*)-/, '-$1'));
    })
        .filter((number) => !Number.isNaN(number));
};

export const sum = (numbers: number[]): number => numbers.reduce((a, b) => a + b, 0);
