import { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { NumericFormat } from 'react-number-format';
import { getCurrentLocale } from '@utils/locales';
import { DynamicField, DynamicFieldValue } from '@models/DynamicField';
import StaticDocumentField from '@components/features/documentFields/StaticDocumentField';
import DocumentFieldFeedback from '@components/features/documentFields/DocumentFieldFeedback';
import { evaluate } from 'mathjs';

interface Props {
    isEditable: boolean;
    initialValue: DynamicFieldValue;
    fieldDefinition: DynamicField;
    field: any;
    onFieldFocus?: () => void;
    onFieldBlur?: () => void;
    onFieldUpdated?: (id: string, value: number | string) => void;
    inputId: string;
}
const DocumentNumberField: FC<Props> = ({
    isEditable,
    initialValue,
    fieldDefinition,
    field,
    onFieldFocus,
    onFieldBlur,
    onFieldUpdated,
    inputId,
}) => {
    const currentLocale = getCurrentLocale();
    const [value, setValue] = useState<number | ''>(initialValue as number);
    const [cacheValue, setCacheValue] = useState<number | undefined>();
    const [calcMode, setCalcMode] = useState<boolean>(false);
    const [calcValue, setCalcValue] = useState<string>('');
    const [calcError, setCalcError] = useState<boolean>(false);
    const calcField = useRef<HTMLInputElement | null>(null);
    const numberField = useRef<HTMLInputElement | null>(null);

    const onFocus = () => {
        setCacheValue(initialValue as number);

        if (onFieldFocus) {
            onFieldFocus();
        }
    };

    const updateData = (value: number | '') => {
        if (value === cacheValue || !onFieldUpdated) {
            return;
        }
        onFieldUpdated(field.id, typeof value === 'number' ? value : '');
        setCacheValue(undefined);
    };

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = () => {
        if (onFieldBlur) {
            onFieldBlur();
        }
        updateData(value);
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        if (typeof initialValue !== 'number') {
            setValue(''); // WSD-186 (https://github.com/s-yadav/react-number-format/issues/528)
            return;
        }

        setValue(initialValue);
    }, [initialValue]);

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.code !== 'Equal' && e.code !== 'NumpadEqual' && e.code !== 'Slash') {
            return;
        }
        setCalcError(false);
        setCalcMode(true);
        if (typeof value === 'number') {
            console.log(value.toFixed(2).toString());
            setCalcValue(value.toFixed(2).toString());
        }
        calcField.current?.focus();
    };

    const onCalcSubmit = async () => {
        try {
            const result = evaluate(calcValue);

            if (typeof result !== 'number') {
                setCalcError(true);
                return;
            }

            setValue(result);
            setCalcValue('');
            setCalcMode(false);
            updateData(result);
        } catch (e) {
            console.log(e);
            setCalcError(true);
        }
    };

    const onCalcChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (calcError) {
            setCalcError(false);
        }

        const result = e.target.value.match(/[\d|\+|\/|\*|\-|\.|,|(|)]*/gi);

        if (result?.length) {
            setCalcValue(result.join('').replaceAll(/,/gi, '.'));
        } else {
            setCalcValue('');
        }
    };

    const onCalcKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Escape') {
            setCalcMode(false);
            numberField.current?.focus();
            return;
        }
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
            onCalcSubmit();
        }
    };

    if (!isEditable) {
        return (
            <StaticDocumentField
                value={initialValue}
                fieldDefinition={fieldDefinition}
                field={field}
            />
        );
    }

    return (
        <div
            className={classNames('form__field', 'form__field--number')}
        >
            <div
                className={classNames('number__calculator', {
                    'number__calculator--isCalculator': calcMode,
                    'number__calculator--error': calcError,
                })}
            >
                <div className="form__calculator--wrapper">
                    <input
                        value={calcValue}
                        onChange={onCalcChange}
                        type="text"
                        ref={calcField}
                        className={classNames('form__calculator', {
                            'form__calculator--required': (value === '') && fieldDefinition.isRequired && !fieldDefinition.isReadOnly,
                        })}
                        onKeyDown={onCalcKeyDown}
                        tabIndex={-1}
                        autoComplete="off"
                        data-lpignore="true"
                        id={`${inputId}_calculator`}
                    />
                </div>
                <div className="form__number--wrapper">
                    <NumericFormat
                        onKeyDown={onKeyDown}
                        data-lpignore="true"
                        value={value}
                        getInputRef={numberField}
                        readOnly={fieldDefinition.isReadOnly}
                        required={fieldDefinition.isRequired}
                        onBlur={onBlur}
                        allowedDecimalSeparators={['.', ',']}
                        onFocus={onFocus}
                        onValueChange={(values) => setValue(typeof values.floatValue === 'number' ? values.floatValue : '')}
                        className={classNames('form__number', {
                            'form__number--required': (value === '') && fieldDefinition.isRequired && !fieldDefinition.isReadOnly,
                        })}
                        thousandSeparator={currentLocale.thousandSeparator}
                        decimalSeparator={currentLocale.decimalSeparator}
                        decimalScale={fieldDefinition.decimals || 2}
                        fixedDecimalScale
                        id={inputId}
                        autoComplete="off"
                    />
                </div>
            </div>
            <DocumentFieldFeedback
                fieldDefinition={fieldDefinition}
                value={value}
                field={field}
            />
        </div>
    );
};

export default DocumentNumberField;
