import { FC, useRef } from 'react';
import classNames from 'classnames';
import { RenderThumbnailItemProps } from '@react-pdf-viewer/thumbnail';

const Thumbnail: FC<RenderThumbnailItemProps> = (props) => {
    const {
        pageIndex,
        numPages,
        renderPageThumbnail,
        currentPage,
        onJumpToPage,
    } = props;
    const thumbnailRef = useRef<HTMLDivElement>(null);
    const isActive = currentPage === pageIndex;

    const classes = classNames('pdfViewer__thumbnail', {
        'pdfViewer__thumbnail--active': isActive,
    });

    return (
        <div className={classes} onClick={onJumpToPage} ref={thumbnailRef}>
            {renderPageThumbnail}
            <div className="pdfViewer__thumbnail-counter">{pageIndex + 1} / {numPages}</div>
        </div>
    );
};

export default Thumbnail;
