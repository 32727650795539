import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { applyOcrTraining } from '@repository/ocrTrainingRepository';
import useHeaderUpdater from '@components/features/documentHeaders/hooks/useHeaderUpdater';
import useCodingFieldUpdater from '@components/features/coding/hooks/useCodingUpdater';

const useApplyOcrTraining = (documentId: number) => {
    const client = useQueryClient();
    const { onHeaderApplyTraining } = useHeaderUpdater(documentId);
    const { onCodingFieldApplyTraining } = useCodingFieldUpdater(documentId);

    return useMutation({
        mutationFn: () => applyOcrTraining(documentId),
        onSuccess: async () => {
            await Promise.all([
                await client.invalidateQueries({
                    queryKey: [QueryKey.OcrTraining, documentId],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentHeaders, documentId],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentCoding, documentId],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentInvoiceLines, documentId],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentOcrSummary, documentId],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.LineSource, documentId],
                }),
            ]);

            // WSD-350
            onHeaderApplyTraining();
            onCodingFieldApplyTraining();
        },
        onError: async () => {
            await Promise.all([
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentCoding, documentId],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentInvoiceLines, documentId],
                }),
            ]);
        },
    });
};

export default useApplyOcrTraining;
