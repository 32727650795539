import { useEffect, useState } from 'react';
import { getAccessToken } from '@repository/accessTokenRepository';
import { StorageKeys } from '@constants/storageKeys';
import { local } from '@utils/storage';

const useToken = () => {
    const [token, setToken] = useState<string | null>(local.getItem(StorageKeys.AccessToken));
    const storedToken = local.getItem(StorageKeys.AccessToken);

    useEffect(() => {
        if (!storedToken) {
            return;
        }
        setToken(storedToken);
    }, [storedToken]);

    const getToken = async () => {
        const t = await getAccessToken();
        setToken(t);
    };

    useEffect(() => {
        getToken();
    }, []);

    return {
        token,
    };
};

export default useToken;
