import { useEffect } from 'react';
import { CodingPropsDocuments, CodingPropsLine } from '@js/store/codingLines/codingFieldPropsAtoms';
import useCodingFieldProperties from '@components/features/coding/hooks/useCodingFieldProperties';
import useLookupParams from '@components/features/lookup/hooks/useLookupParams';
import useGetLookupOptionsFromCache from '@components/features/lookup/hooks/useGetLookupOptionsFromCache';
import useFetchDocumentCoding from '@components/features/coding/hooks/useFetchDocumentCoding';
import { DynamicFieldValue } from '@models/DynamicField';

export const DIMENSION_RESETTER = 'grootboek';
export const IS_READ_ONLY_SELECTOR = '_isreadonly';
export const IS_REQUIRED_SELECTOR = '_isrequired';

const useSetCodingFieldProperties = (documentId: number) => {
    const { setCodingProps, resetCodingLineProps } = useCodingFieldProperties();
    const { data: documentCoding } = useFetchDocumentCoding(documentId);
    const { setCodingPropsAtomValue } = useCodingFieldProperties();
    const { getLookupParams } = useLookupParams(documentId);
    const { getLookupOptionsFromCache } = useGetLookupOptionsFromCache();

    const getSelectedLookupOption = (fieldId: string, value: DynamicFieldValue) => {
        if (!documentCoding) {
            return undefined;
        }

        const fieldDefinition = documentCoding.definitions.find((definition) => definition.id === fieldId);

        if (!fieldDefinition || fieldDefinition.format !== 'lookup') {
            return undefined;
        }

        const lookupParams = getLookupParams(fieldDefinition.lookup);
        const lookupData = getLookupOptionsFromCache(fieldDefinition.lookup, lookupParams);

        if (!lookupData) {
            return undefined;
        }

        return lookupData.list?.find((option) => option.key === value);
    };

    const getCodingFieldProps = (fieldId: string, value: DynamicFieldValue) => {
        const codingFieldProps: CodingPropsLine = {
            readOnlyFields: {},
            requiredFields: {},
        };

        const selectedOption = getSelectedLookupOption(fieldId, value);

        if (!selectedOption) {
            return codingFieldProps;
        }

        for (const [key, value] of Object.entries(selectedOption)) {
            if (key.indexOf(IS_READ_ONLY_SELECTOR) !== -1 && typeof value === 'boolean') {
                const fieldId = key.replace(IS_READ_ONLY_SELECTOR, '');
                codingFieldProps.readOnlyFields[fieldId] = value;
            } else if (key.indexOf(IS_REQUIRED_SELECTOR) !== -1 && typeof value === 'boolean') {
                const fieldId = key.replace(IS_REQUIRED_SELECTOR, '');
                codingFieldProps.requiredFields[fieldId] = value;
            }
        }

        return codingFieldProps;
    };

    useEffect(() => {
        if (!documentCoding) {
            return;
        }
        const codingProps: CodingPropsDocuments = {};

        // Set readOnly and isRequired props on atom based on documentCoding definitions
        documentCoding.rows.forEach((row) => {
            const lineId = row.values.find((field) => field.id === 'row')?.value as number;

            row.values.forEach((field) => {
                const fieldDefinition = documentCoding.definitions.find((definition) => definition.id === field.id);

                codingProps[documentId] = codingProps[documentId] || {};
                codingProps[documentId][lineId] = codingProps[documentId][lineId] || {
                    requiredFields: {},
                    readOnlyFields: {},
                };

                if (typeof fieldDefinition?.isRequired === 'boolean') {
                    codingProps[documentId][lineId].requiredFields[field.id] = fieldDefinition.isRequired;
                }

                if (typeof fieldDefinition?.isReadOnly === 'boolean') {
                    codingProps[documentId][lineId].readOnlyFields[field.id] = fieldDefinition.isReadOnly;
                }
            });
        });

        // Set readOnly and isRequired props on atom based on selected lookup options
        // Note: Doesn't work the first time because the lookupData is not yet fetched
        // Therefore we also set the props on lookupInit
        documentCoding.rows.forEach((row) => {
            const lineId = row.values.find((field) => field.id === 'row')?.value as number;

            row.values.forEach((field) => {
                const fieldDefinition = documentCoding.definitions.find((definition) => definition.id === field.id);
                if (fieldDefinition?.format !== 'lookup') {
                    return;
                }

                const selectedOption = getSelectedLookupOption(field.id, field.value);

                if (selectedOption) {
                    for (const [key, value] of Object.entries(selectedOption)) {
                        if (key.indexOf(IS_READ_ONLY_SELECTOR) !== -1 && typeof value === 'boolean') {
                            const fieldId = key.replace(IS_READ_ONLY_SELECTOR, '');
                            codingProps[documentId][lineId].readOnlyFields[fieldId] = value;
                        } else if (key.indexOf(IS_REQUIRED_SELECTOR) !== -1 && typeof value === 'boolean') {
                            const fieldId = key.replace(IS_REQUIRED_SELECTOR, '');
                            codingProps[documentId][lineId].requiredFields[fieldId] = value;
                        }
                    }
                }
            });
        });
        setCodingPropsAtomValue(codingProps);
    }, [documentCoding]);

    const handleCodingFieldPropertiesOnInit = (lineId: number, fieldId: string, value: DynamicFieldValue) => {
        if (fieldId === DIMENSION_RESETTER && value === '') {
            resetCodingLineProps(documentId, lineId);
            return;
        }

        const codingFieldProps = getCodingFieldProps(fieldId, value);

        if (!Object.entries(codingFieldProps.requiredFields).length && !Object.entries(codingFieldProps.readOnlyFields).length) {
            return;
        }

        setCodingProps(documentId, lineId, codingFieldProps);
    };

    return {
        handleCodingFieldPropertiesOnInit,
    };
};

export default useSetCodingFieldProperties;
