import { components } from 'react-select';
import { FC } from 'react';

type Props = any;

const CustomReactSelectInput: FC<Props> = (props) => {
    return (
        <components.Input data-lpignore="true" {...props} />
    );
};

export default CustomReactSelectInput;
