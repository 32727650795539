import { useAtom } from 'jotai';
import { codingFieldPropsAtom, CodingPropsLine } from '@js/store/codingLines/codingFieldPropsAtoms';

const useCodingFieldProperties = () => {
    const [codingProps, setCodingPropsAtomValue] = useAtom(codingFieldPropsAtom);

    const getCodingPropsByDocumentId = (documentId: number) => {
        return codingProps[documentId] || {};
    };

    const getCodingPropsByLineId = (documentId: number, lineId: number) => {
        return codingProps[documentId]?.[lineId] || {};
    };

    const setCodingProps = (documentId: number, lineId: number, props: CodingPropsLine) => {
        setCodingPropsAtomValue((oldData) => {
            return {
                ...oldData,
                [documentId]: {
                    ...oldData[documentId],
                    [lineId]: {
                        ...oldData[documentId]?.[lineId],
                        readOnlyFields: {
                            ...oldData[documentId]?.[lineId]?.readOnlyFields,
                            ...props.readOnlyFields,
                        },
                        requiredFields: {
                            ...oldData[documentId]?.[lineId]?.requiredFields,
                            ...props.requiredFields,
                        },
                    },
                },
            };
        });
    };

    const resetCodingLineProps = (documentId: number, lineId: number) => {
        setCodingPropsAtomValue((oldData) => {
            return {
                ...oldData,
                [documentId]: {
                    ...oldData[documentId],
                    [lineId]: {
                        readOnlyFields: {},
                        requiredFields: {},
                    },
                },
            };
        });
    };

    return {
        getCodingPropsByDocumentId,
        getCodingPropsByLineId,
        setCodingProps,
        resetCodingLineProps,
        setCodingPropsAtomValue,
    };
};

export default useCodingFieldProperties;
