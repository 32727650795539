import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { copyDocumentMatchingOrderLine } from '@repository/documentMatchingRepository';
import { CopyOrderLineForm } from '@models/DocumentMatching';

const useCopyOrderLine = (documentId: number) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: (data: CopyOrderLineForm) => copyDocumentMatchingOrderLine(documentId, data),
        onSuccess: async () => {
            await Promise.all([
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentInvoiceLines, documentId],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentOrderLines, documentId],
                }),
            ]);
        },
    });
};

export default useCopyOrderLine;
