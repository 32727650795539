import { executeGetRequest, executePostRequest } from '@api/client';
import { createFetchSuperVisorActionsPath, createPerformSuperVisorActionPath } from '@routing/apiUrls';
import { PerformSuperVisorActionResponse, SuperVisorActions, SuperVisorMutation } from '@models/SuperVisorActions';

export const fetchSuperVisorActions = async (documentId: number, signal?: AbortSignal): Promise<SuperVisorActions> => {
    const result = await executeGetRequest<SuperVisorActions>(createFetchSuperVisorActionsPath(documentId), signal);
    return result.data;
};

export const performSuperVisorAction = async (documentId: number, mutation: SuperVisorMutation): Promise<PerformSuperVisorActionResponse> => {
    const result = await executePostRequest<PerformSuperVisorActionResponse>(createPerformSuperVisorActionPath(documentId), mutation);
    return result.data;
};
