import { FC, ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
    children: ReactNode;
    className?: string;
}

const ModalFooter: FC<Props> = ({ children, className }) => {
    const classes = classNames('modal__footer', className);

    return (
        <div className={classes}>
            {children}
        </div>
    );
};

export default ModalFooter;
