import { FC, ReactNode, useEffect, useState } from 'react';

interface Props {
    timeout?: number;
    children: ReactNode;
}

const Delayed: FC<Props> = ({ children, timeout = 1000 }) => {
    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setIsShown(true), timeout);
        return () => clearTimeout(timer);
    });

    return isShown ? <>{children}</> : null;
};

export default Delayed;
