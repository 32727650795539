import { executeDeleteRequest, executeGetRequest, executePostRequest, executePutRequest } from '@api/client';
import {
    createAddDocumentCodingLinePath,
    createFetchCopyCodingListPath,
    createDeleteDocumentCodingLinePath,
    createFetchDocumentCodingDefaultsPath,
    createFetchDocumentCodingPath,
    createUpdateDocumentCodingLinePath,
    createCopyCodingPath,
    createFetchCodingAccrualsPath, createUpdateCodingAccrualsPath, createUpdateDocumentCodingLinesPath,
} from '@routing/apiUrls';
import {
    CodingLineForm, CopyCodingData, CopyCodingResponse,
    CreateCodingLineResponse,
    DeleteCodingLineResponse,
    DocumentCoding,
    DocumentCodingDefaults, UpdateCodingLineResponse, UpdateCodingLinesResponse,
} from '@models/DocumentCoding';
import { CopyCodingList } from '@models/DocumentCopyCodingList';
import {
    CodingAccruals, CodingAccrualsMutation,
    UpdateCodingAccrualsResponse,
} from '@models/CodingAccruals';
import { UpdateCodingLinesMutation } from '@components/features/coding/hooks/useUpdateCodingLines';

export const fetchDocumentCoding = async (documentId: number, signal?: AbortSignal): Promise<DocumentCoding> => {
    const result = await executeGetRequest<DocumentCoding>(createFetchDocumentCodingPath(documentId), signal);
    return result.data;
};

export const fetchDocumentCodingDefaults = async (documentId: number, signal?: AbortSignal): Promise<DocumentCodingDefaults> => {
    const result = await executeGetRequest<DocumentCodingDefaults>(createFetchDocumentCodingDefaultsPath(documentId), signal);
    return result.data;
};

export const addDocumentCodingLine = async (documentId: number, data: CodingLineForm): Promise<CreateCodingLineResponse> => {
    const result = await executePostRequest<CreateCodingLineResponse>(createAddDocumentCodingLinePath(documentId), data);
    return result.data;
};

export const updateDocumentCodingLine = async (documentId: number, lineId: number, data: CodingLineForm): Promise<UpdateCodingLineResponse> => {
    const result = await executePutRequest<UpdateCodingLineResponse>(createUpdateDocumentCodingLinePath(documentId, lineId), data);
    return result.data;
};

export const updateDocumentCodingLines = async (documentId: number, data: UpdateCodingLinesMutation): Promise<UpdateCodingLinesResponse> => {
    const result = await executePutRequest<UpdateCodingLinesResponse>(createUpdateDocumentCodingLinesPath(documentId), data);
    return result.data;
};

export const deleteDocumentCodingLine = async (documentId: number, codingLineIds: number[]): Promise<DeleteCodingLineResponse> => {
    const result = await executeDeleteRequest<DeleteCodingLineResponse>(createDeleteDocumentCodingLinePath(documentId), {
        rowids: codingLineIds,
    });
    return result.data;
};

export const fetchCopyCodingList = async (documentId: number, signal?: AbortSignal): Promise<CopyCodingList> => {
    const result = await executeGetRequest<CopyCodingList>(createFetchCopyCodingListPath(documentId), signal);
    return result.data;
};

export const copyCoding = async (data: CopyCodingData): Promise<CopyCodingResponse> => {
    const result = await executePostRequest<CopyCodingResponse>(createCopyCodingPath(data));
    return result.data;
};

// Coding Accruals
export const fetchCodingAccruals = async (documentId: number, signal?: AbortSignal): Promise<CodingAccruals> => {
    const result = await executeGetRequest<CodingAccruals>(createFetchCodingAccrualsPath(documentId), signal);
    return result.data;
};

export const updateCodingAccruals = async (mutation: CodingAccrualsMutation): Promise<UpdateCodingAccrualsResponse> => {
    const result = await executePostRequest<UpdateCodingAccrualsResponse>(createUpdateCodingAccrualsPath(mutation.documentId, mutation.lineId), mutation.data);
    return result.data;
};
