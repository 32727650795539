import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { fetchDocumentOcrData } from '@repository/documentOcrRepository';
import { DocumentOcrData } from '@models/DocumentOcrData';
import { minutesToMillis } from '@utils/millis';

const useFetchDocumentOcrData = (documentId: number, page: number): UseQueryResult<DocumentOcrData> => {
    return useQuery({
        queryKey: [QueryKey.DocumentOcrData, documentId, page],
        queryFn: ({ signal }) => fetchDocumentOcrData(documentId, page, signal),
        staleTime: minutesToMillis(5),
    });
};

export default useFetchDocumentOcrData;
