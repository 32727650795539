import React, { FC } from 'react';
import { Sizes } from '@utils/sizes';
import RepairDocumentModalInner from '@components/modals/repairDocumentModal/RepairDocumentModalInner';
import Modal from '@components/primitives/modal/Modal';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { repairDocumentModalAtom, RepairDocumentModalData } from '@js/store/modals/repairDocumentModalAtom';

const RepairDocumentModal: FC = () => {
    const [modalState, setModalState] = useAtom(repairDocumentModalAtom);
    const { t } = useTranslation();

    if (!modalState.data) {
        return null;
    }

    return (
        <Modal<RepairDocumentModalData>
            modalState={modalState}
            setModalState={setModalState}
            size={Sizes.xl}
            className="repairDocumentModal"
            title={t('repairDocument.modal.title', { ns: 'documentActions' })}
        >
            <RepairDocumentModalInner
                modalData={modalState.data}
            />
        </Modal>
    );
};

export default RepairDocumentModal;
