export enum QueryKey {
    Profile = 'profile',
    DocClasses = 'docClasses',
    DocList = 'docList',
    DocListCount = 'docListCount',
    DocLists = 'docLists',
    Document = 'document',
    DocumentHeaders = 'documentHeaders',
    DocumentExternalInfo = 'documentExternalInfo',
    DocumentAnnotations = 'documentAnnotations',
    DocumentAudits = 'documentAudits',
    DocumentActions = 'documentActions',
    DocumentOcrData = 'documentOcrData',
    DocumentOcrSummary = 'documentOcrSummary',
    DocumentCoding = 'documentCoding',
    DocumentCodingDefaults = 'documentCodingDefaults',
    DocumentPagesOwners = 'documentPagesOwners',
    LineSource = 'lineSource',
    CodingAccruals = 'codingAccruals',
    DocumentCopyCodingList = 'documentCopyCodingList',
    DocumentInvoiceLines = 'documentInvoiceLines',
    DocumentOrderLines = 'documentOrderLines',
    DocumentDynamicAssign = 'documentDynamicAssign',
    SearchFields = 'searchFields',
    CreditorValidation = 'creditorValidation',
    File = 'file',
    FileRepaired = 'fileRepaired',
    Comments = 'comments',
    Lookup = 'lookup',
    MailTemplates = 'mailTemplates',
    MailTemplate = 'mailTemplate',
    OcrTraining = 'ocrTraining',
    OcrTrainingField = 'ocrTrainingField',
    OcrTrainingLog = 'ocrTrainingLog',
    OcrCodingTraining = 'ocrCodingTraining',
    Version = 'version',
    Replacements = 'replacements',
    News = 'news',
    Faq = 'faq',
    Introduction = 'introduction',
    Videos = 'videos',
    ReleaseNotes = 'ReleaseNotes',
    Contact = 'Contact',
    FileHandlerFiles = 'fileHandlerFiles',
    FileHandlerAdministrations = 'fileHandlerAdministrations',
    SuperVisorActions = 'superVisorActions',
    SharedTemplates = 'sharedTemplates',
    SamCodingSources = 'samCodingSources',
}
