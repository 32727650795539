import { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Number from '@components/primitives/number/Number';
import useMatchingTotals from '@components/features/matching/hooks/useMatchingTotals';
import useFetchDocumentInvoiceLines from '@components/features/documentInvoiceLines/hooks/useFetchDocumentInvoiceLines';

interface Props {
    documentId: number;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    className?: string;
}

const MatchingTab: FC<Props> = ({ documentId, onClick, className }) => {
    const { data: documentInvoiceLines, isLoading } = useFetchDocumentInvoiceLines(documentId);
    const { t } = useTranslation();
    const classes = classNames('codingMatching__tab', className);
    const { invoiceTotal, matchingTotal, absoluteDifference, difference } = useMatchingTotals(documentId);

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        if (!onClick) {
            return;
        }

        onClick(e);
    };

    if (isLoading || !documentInvoiceLines) {
        return null;
    }

    return (
        <button type="button" className={classes} onClick={handleClick}>
            <strong>{t('tab', { ns: 'matching' })}</strong>
            <span>{t('total', { ns: 'matching' })} <Number value={matchingTotal} prefix="" /></span>
            {invoiceTotal ? (
                <span className={difference === 0 ? 'text--success' : 'text--error'}>
                    {t('difference', { ns: 'matching' })} <Number value={absoluteDifference} prefix={difference === 0 ? ' ' : difference > 0 ? '- ' : '+ '} />
                </span>
            ) : null}
        </button>
    );
};

export default MatchingTab;
