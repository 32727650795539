import { atom } from 'jotai';
import SearchIcon from '@images/svg/search.svg?react';
import PercentageCircleIcon from '@images/svg/percentageCircle.svg?react';
import MergeIcon from '@images/svg/merge.svg?react';
import CodeIcon from '@images/svg/code.svg?react';
import DuplicateIcon from '@images/svg/duplicate.svg?react';
import { ReactElement, SVGProps } from 'react';
import { DocumentCodingRow } from '@models/DocumentCoding';
import { atomWithDefault } from 'jotai/utils';

export enum SamLineSourceSettingType {
    text = 'text',
    copyCoding = 'copyCoding',
}

export interface SamLineSourceSetting {
    type: SamLineSourceSettingType;
    id: string;
    label: string;
    value: string | number | null;
}

export interface SamLineSource {
    id: SamLineSourceId,
    title: string;
    ai: boolean;
    subTitle: string;
    description: string;
    settings?: SamLineSourceSetting[];
    icon: ReactElement<SVGProps<SVGSVGElement>>;
    colors: {
        primary: {
            light: string;
            dark: string;
        }
    };
    data?: DocumentCodingRow[];
}

export enum SamLineSourceId {
    RuleRecognition = 'RuleRecognition',
    CompactionOnVAT = 'CompactionOnVAT',
    Merge = 'Merge',
    XML = 'XML',
    Copy = 'Copy',
    Custom = 'Custom',
}

// Current lineSourceId of the document. Later, this should be provided by the API
export const currentLineSourceIdAtom = atom<SamLineSourceId>(SamLineSourceId.RuleRecognition);

// Selected lineSourceId in the lineSourceModal.
export const selectedLineSourceIdAtom = atomWithDefault<SamLineSourceId>(
    (get) => get(currentLineSourceIdAtom),
);

export const samLineSourceCodingPreviewAtom = atom<DocumentCodingRow[]>([]);

export const samLineSourceCodingPreviewMessageAtom = atom<string | undefined>(undefined);

export const samLineSourcesAtom = atom<SamLineSource[]>([
    {
        id: SamLineSourceId.CompactionOnVAT,
        ai: true,
        title: 'Verdichten op BTW',
        subTitle: 'Groepeer op BTW-tarief',
        description: 'Met deze template kun je eenvoudig de regels verdichten op het BTW-tarief. Regels met hetzelfde BTW-tarief worden samengevoegd tot 1 regel.',
        icon: <PercentageCircleIcon />,
        colors: {
            primary: {
                light: '#80a8ba',
                dark: '#3a859f',
            },
        },
        data: [{
            values: [
                {
                    id: 'row',
                    value: 107458,
                },
                {
                    id: 'omschrijving',
                    value: 'Artikelen 21% BTW',
                },
                {
                    id: 'bedrag',
                    value: 133.47,
                },
                {
                    id: 'grootboek',
                    value: '43390',
                },
                {
                    id: 'aantal',
                    value: 1,
                },
            ],
        }],
    },
    {
        id: SamLineSourceId.Custom,
        ai: true,
        title: 'Albert Heijn',
        subTitle: 'Meest gebruikt bij Albert Heijn facturen',
        description: 'Geautomatiseerde template voor Albert Heijn facturen, gevuld door AI op basis van data-analyse van vergelijkbare facturen bij andere klanten.',
        icon: <PercentageCircleIcon />,
        colors: {
            primary: {
                light: '#9f84ba',
                dark: '#7D4DA2',
            },
        },
    },
    {
        id: SamLineSourceId.Copy,
        ai: false,
        title: 'Kopieer',
        subTitle: 'Neem over van andere factuur',
        settings: [
            {
                type: SamLineSourceSettingType.copyCoding,
                id: 'selectedDocumentId',
                label: 'Selecteer bronfactuur:',
                value: null,
            },
        ],
        description: 'Gebruik de coderingsregels van een andere factuur.',
        icon: <DuplicateIcon />,
        colors: {
            primary: {
                light: '#b7d684',
                dark: '#8CC63f',
            },
        },
        data: [],
    },
    {
        id: SamLineSourceId.Merge,
        ai: false,
        title: 'Samenvoegen',
        subTitle: 'Voeg regels samen tot 1 regel',
        description: 'Lorem ipsum dolor sit amet.',
        icon: <MergeIcon />,
        colors: {
            primary: {
                light: '#e68ab5',
                dark: '#F15097',
            },
        },
        data: [{
            values: [
                {
                    id: 'row',
                    value: 107458,
                },
                {
                    id: 'omschrijving',
                    value: 'Miele artikelen',
                },
                {
                    id: 'bedrag',
                    value: 133.47,
                },
                {
                    id: 'grootboek',
                    value: '43390',
                },
                {
                    id: 'aantal',
                    value: 1,
                },
            ],
        }],
    },
    {
        id: SamLineSourceId.XML,
        ai: false,
        title: 'XML-Factuur',
        subTitle: 'Gebruik meegeleverde XML',
        description: 'Lorem ipsum dolor sit amet.',
        icon: <CodeIcon />,
        colors: {
            primary: {
                light: '#8384ba',
                dark: '#4D4fA2',
            },
        },
    },
    {
        id: SamLineSourceId.RuleRecognition,
        ai: false,
        title: 'Regelherkenning',
        subTitle: 'Gebruik de herkende regels',
        description: 'Met deze template gebruik je de herkende regels uit de OCR. Dit is de standaard template.',
        icon: <SearchIcon />,
        colors: {
            primary: {
                light: '#71868b',
                dark: '#465e62',
            },
        },
    },
]);

export interface SamMessageButton {
    id: string;
    label: string;
    isPrimary?: boolean;
    isLoading?: boolean;
    onClick: () => void;
}
export interface SamMessage {
    id: string;
    title?: string;
    message: string;
    buttons?: SamMessageButton[];
}

export interface SamChat {
    messages: SamMessage[];
}

export const samChatAtom = atom<SamChat>({
    messages: [],
});

export const samMessagesAtom = atom<Array<string>>([]);
