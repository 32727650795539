import { atom } from 'jotai';
import { closedModalState, ModalState } from '@js/store/modalAtoms';

export interface WorkflowActionValidationModalData {
    title: string;
    onConfirm: () => void;
    validations: string[];
    onCanceled?: () => void;
}
export const workflowActionValidationModalAtom = atom<ModalState<WorkflowActionValidationModalData>>(closedModalState);
