import React, { FC, useEffect, useState } from 'react';
import { Document } from '@models/Document';
import CodingTab from '@components/features/coding/CodingTab';
import Matching from '@components/features/matching/Matching';
import MatchingTab from '@components/features/matching/MatchingTab';
import ExternalInfo from '@components/features/externalInfo/ExternalInfo';
import ExternalInfoTab from '@components/features/externalInfo/ExternalInfoTab';
import classNames from 'classnames';
import Coding from '@components/features/coding/Coding';
import LineSourceToggle from '@components/features/lineSource/LineSourceToggle';

interface Props {
    document?: Document;
    isPreviewMode: boolean;
    ocrTrainingEnabled: boolean;
}

const DocumentGrids: FC<Props> = ({ document, isPreviewMode, ocrTrainingEnabled }) => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const codingOnly = document?.meta.codingEnabled && !document?.meta.matchingEnabled;
    const matchingOnly = !document?.meta.codingEnabled && document?.meta.matchingEnabled;
    const codingAndMatching = document?.meta.codingEnabled && document?.meta.matchingEnabled;
    const externalInfo = document?.meta.externalInfoEnabled;

    // Activate matching tab when matching is available.
    useEffect(() => {
        if (!codingAndMatching) {
            return;
        }
        setActiveTab(1);
    }, [codingAndMatching, document?.documentId]);

    const classes = classNames('codingMatching', {
        'codingMatching--previewMode': isPreviewMode,
    });

    if (!document) {
        return null;
    }

    return (
        <div className={classes}>
            {codingOnly ? (
                <>
                    <div className="codingMatching__tabs">
                        <CodingTab
                            documentId={document.documentId}
                            className="codingMatching__tab--active"
                        />
                        {!isPreviewMode && document?.meta.trainingEnabled ? (
                            <LineSourceToggle documentId={document.documentId} />
                        ) : null}
                    </div>
                    <Coding
                        documentId={document.documentId}
                        isPreviewMode={isPreviewMode}
                        ocrTrainingEnabled={ocrTrainingEnabled}
                    />
                </>
            ) : matchingOnly ? (
                <>
                    <div className="codingMatching__tabs">
                        <MatchingTab
                            documentId={document.documentId}
                            className="codingMatching__tab--active"
                        />
                    </div>
                    <Matching
                        documentId={document.documentId}
                        isPreviewMode={isPreviewMode}
                        ocrTrainingEnabled={ocrTrainingEnabled}
                    />
                </>
            ) : codingAndMatching ? (
                <>
                    <div className="codingMatching__tabs">
                        <CodingTab
                            documentId={document.documentId}
                            className={activeTab === 0 ? 'codingMatching__tab--active' : ''}
                            onClick={() => setActiveTab(0)}
                        />
                        <MatchingTab
                            documentId={document.documentId}
                            className={activeTab === 1 ? 'codingMatching__tab--active' : ''}
                            onClick={() => setActiveTab(1)}
                        />
                        {!isPreviewMode && document?.meta.trainingEnabled ? (
                            <LineSourceToggle documentId={document.documentId} />
                        ) : null}
                    </div>
                    {activeTab === 0 ? (
                        <>
                            <Coding
                                documentId={document.documentId}
                                isPreviewMode={isPreviewMode}
                                ocrTrainingEnabled={ocrTrainingEnabled}
                            />
                        </>
                    ) : (
                        <Matching
                            documentId={document.documentId}
                            isPreviewMode={isPreviewMode}
                            ocrTrainingEnabled={ocrTrainingEnabled}
                        />
                    )}
                </>
            ) : externalInfo ? (
                <>
                    <div className="codingMatching__tabs">
                        <ExternalInfoTab className="codingMatching__tab--active" />
                    </div>
                    <ExternalInfo
                        documentId={document.documentId}
                    />
                </>
            ) : null}
        </div>
    );
};

export default DocumentGrids;
