import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { ReplacementsMutation } from '@models/Replacements';
import { updateReplacements } from '@repository/replacementsRepository';

const useUpdateReplacements = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: (mutation: ReplacementsMutation) => updateReplacements(mutation),
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [QueryKey.Replacements],
            });
        },
    });
};

export default useUpdateReplacements;
