import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateDocumentHeaders } from '@repository/documentHeadersRepository';
import { DocumentHeaders, UpdateDocumentHeaders } from '@models/DocumentHeaders';
import { QueryKey } from '@constants/queryKey';
import { DocumentOcrItem, DocumentOcrSummary } from '@models/DocumentOcrSummary';
import useCodingFieldUpdater from '@components/features/coding/hooks/useCodingUpdater';

const useUpdateDocumentHeader = (documentId: number) => {
    const client = useQueryClient();
    const { onDeterminativeFieldChange } = useCodingFieldUpdater(documentId);

    return useMutation({
        mutationFn: (data: UpdateDocumentHeaders) => updateDocumentHeaders(documentId, data),
        onSuccess: async (response) => {
            if (response.meta.refreshCoding) {
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentCoding, documentId],
                });

                // WSD-350
                onDeterminativeFieldChange(response.values.map((val) => val.id));
            }

            if (response.meta.refreshMatching) {
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentInvoiceLines, documentId],
                });
            }

            if (response.meta.refreshSam) {
                await client.invalidateQueries({
                    queryKey: [QueryKey.SamCodingSources, documentId],
                });
            }

            await Promise.all([
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentHeaders, documentId],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentOcrSummary, documentId],
                }),
            ]);
        },
        onMutate: async (data) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await client.cancelQueries({
                queryKey: [QueryKey.DocumentHeaders, documentId],
            });

            // Update Document Headers
            client.setQueryData([QueryKey.DocumentHeaders, documentId], (old?: DocumentHeaders) => {
                if (!old) {
                    return {
                        definitions: [],
                        values: [],
                        meta: {
                            readOnly: false,
                        },
                    };
                }

                return {
                    ...old,
                    values: old.values.map((val) => {
                        const updatedValue = data.values.find((updatedVal) => updatedVal.id === val.id);

                        if (updatedValue) {
                            const fieldDefinition = old.definitions.find((def) => def.id === val.id);

                            // Don't update date fields in the cache because the API interprets the date strings
                            // This can cause the date picker to select the wrong date first (flicker)
                            if (fieldDefinition?.format === 'date') {
                                return val;
                            }

                            if (fieldDefinition?.format === 'text') {
                                return {
                                    ...val,
                                    value: updatedValue.value?.toString(),
                                };
                            }

                            return {
                                ...val,
                                value: updatedValue.value,
                            };
                        }

                        return val;
                    }),
                };
            });

            // Update Document Ocr Summary
            client.setQueryData([QueryKey.DocumentOcrSummary, documentId], (old?: DocumentOcrSummary) => {
                if (!old) {
                    return {
                        list: data.values,
                        count: 1,
                    };
                }

                return {
                    list: old.list.map((item: DocumentOcrItem) => {
                        let newItem;
                        data.values.forEach((updatedItem: DocumentOcrItem) => {
                            if (updatedItem.id === item.id) {
                                newItem = updatedItem;
                            }
                        });

                        if (!newItem) {
                            newItem = item;
                        }

                        return newItem;
                    }),
                    count: old.count,
                };
            });
        },
        onSettled: async () => {
            await client.invalidateQueries({
                queryKey: [QueryKey.DocumentHeaders, documentId],
            });
        },
    });
};

export default useUpdateDocumentHeader;
