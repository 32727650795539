import React, { forwardRef, MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';
import { Sizes } from '@utils/sizes';
import { useNavigate } from 'react-router-dom';

interface Props {
    id?: string;
    children: ReactNode;
    className?: string;
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    hideDisabledState?: boolean;
    isLoading?: boolean;
    isLink?: boolean;
    isWhite?: boolean;
    isGhost?: boolean;
    size?: Sizes.lg | Sizes.md | Sizes.sm;
    primary?: boolean;
    secondary?: boolean;
    tertiary?: boolean;
    warning?: boolean;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    icon?: ReactNode;
    title?: string;
    to?: string;
    testId?: string;
}

const Button = forwardRef<HTMLButtonElement, Props>(({
    id,
    children,
    type,
    className,
    disabled,
    size = Sizes.md,
    primary,
    secondary,
    tertiary,
    warning,
    onClick,
    isLink,
    isWhite,
    isLoading,
    icon,
    title,
    to,
    hideDisabledState,
    isGhost,
    testId,
}, ref) => {
    const navigate = useNavigate();

    const classes = classNames('button', className, {
        'button--sm': size === Sizes.sm,
        'button--lg': size === Sizes.lg,
        'button--primary': primary,
        'button--secondary': secondary,
        'button--tertiary': tertiary,
        'button--warning': warning,
        'button--disabled': disabled && !hideDisabledState,
        'button--link': isLink,
        'button--ghost': isGhost,
        'button--loading': isLoading,
        'button--icon': icon,
        'button--white': isWhite,
    });

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        if (onClick) {
            onClick(e);
            return;
        }

        if (to) {
            navigate(to);
        }
    };

    return (
        // eslint-disable-next-line react/button-has-type
        <button
            ref={ref}
            type={type || 'button'}
            className={classes}
            disabled={disabled}
            onClick={handleClick}
            title={title}
            data-testid={testId}
            {...!!id && { id }}
        >
            {children}
            {icon || null}
        </button>
    );
});

export default Button;
