import { useMutation } from '@tanstack/react-query';
import { sendMail } from '@repository/mailTemplatesRepository';
import { EmailDocumentMutation } from '@models/EmailDocument';

const useEmailDocument = () => {
    return useMutation({
        mutationFn: (mutation: EmailDocumentMutation) => sendMail({
            ...mutation,
            data: {
                ...mutation.data,
                azureToken: mutation.azureToken,
            },
        }),
    });
};

export default useEmailDocument;
