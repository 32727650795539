import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { DocumentExternalInfo } from '@models/DocumentExternalInfo';
import { fetchDocumentExternalInfo } from '@repository/documentExternalInfoRepository';

const useFetchDocumentExternalInfo = (documentId: number): UseQueryResult<DocumentExternalInfo> => {
    return useQuery({
        queryKey: [QueryKey.DocumentExternalInfo, documentId],
        queryFn: ({ signal }) => fetchDocumentExternalInfo(documentId, signal),
        staleTime: 0,
        retry: 0,
    });
};

export default useFetchDocumentExternalInfo;
