import { useTranslation } from 'react-i18next';

const usePageTitle = () => {
    const { t } = useTranslation();
    const createPageTitle = (title?: string) : string => {
        if (!title) {
            return t('meta.title');
        }

        return `${title} | ${t('meta.title')}`;
    };

    return {
        createPageTitle,
    };
};

export default usePageTitle;
