import { executeDeleteRequest, executeGetRequest, executePostRequest, executePutRequest } from '@api/client';
import {
    createAddDocumentAnnotationPath, createDeleteDocumentAnnotationPath,
    createFetchDocumentAnnotationsPath,
    createUpdateDocumentAnnotationPath,
} from '@routing/apiUrls';
import {
    AnnotationForm,
    CreateAnnotationResponse,
    DeleteAnnotationResponse,
    DocumentAnnotations, UpdateAnnotationResponse,
} from '@models/DocumentAnnotations';

export const fetchDocumentAnnotations = async (documentId: number, signal?: AbortSignal): Promise<DocumentAnnotations> => {
    const result = await executeGetRequest<DocumentAnnotations>(createFetchDocumentAnnotationsPath(documentId), signal);
    return result.data;
};

export const addDocumentAnnotation = async (documentId: number, data: AnnotationForm): Promise<CreateAnnotationResponse> => {
    const result = await executePostRequest<CreateAnnotationResponse>(createAddDocumentAnnotationPath(documentId), data);
    return result.data;
};

export const updateDocumentAnnotation = async (documentId: number, annotationId: number, data: AnnotationForm): Promise<UpdateAnnotationResponse> => {
    const result = await executePutRequest<UpdateAnnotationResponse>(createUpdateDocumentAnnotationPath(documentId, annotationId), data);
    return result.data;
};

export const deleteDocumentAnnotation = async (documentId: number, annotationId: number): Promise<DeleteAnnotationResponse> => {
    const result = await executeDeleteRequest<DeleteAnnotationResponse>(createDeleteDocumentAnnotationPath(documentId, annotationId));
    return result.data;
};
