import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateDocumentAnnotation } from '@repository/documentAnnotationsRepository';
import { QueryKey } from '@constants/queryKey';
import { AnnotationForm, DocumentAnnotation, DocumentAnnotations } from '@models/DocumentAnnotations';

type UpdateMutation = {
    id: number;
    data: AnnotationForm;
};

const useUpdateAnnotation = (documentId: number) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: (updateMutation: UpdateMutation) => updateDocumentAnnotation(documentId, updateMutation.id, updateMutation.data),
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [QueryKey.DocumentAnnotations, documentId],
            });
        },
        onMutate: (data) => {
            client.setQueryData<DocumentAnnotations | undefined>([QueryKey.DocumentAnnotations, documentId], (old) => {
                if (!old) {
                    return undefined;
                }

                return {
                    ...old,
                    list: old.list.map((annotation: DocumentAnnotation) => {
                        if (annotation.id !== data.id) {
                            return annotation;
                        }

                        return {
                            ...annotation,
                            ...data.data,
                        };
                    }),
                };
            });
        },
    });
};

export default useUpdateAnnotation;
