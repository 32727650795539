import React, { FC } from 'react';
import { Sizes } from '@utils/sizes';
import ConfirmModalInner from '@components/modals/confirmModal/ConfirmModalInner';
import { useTranslation } from 'react-i18next';
import Modal from '@components/primitives/modal/Modal';
import { useAtom } from 'jotai';
import { confirmModalAtom, ConfirmModalData } from '@js/store/modals/confirmModalAtom';

const ConfirmModal: FC = () => {
    const [modalState, setModalState] = useAtom(confirmModalAtom);
    const { t } = useTranslation();

    if (!modalState.data) {
        return null;
    }

    return (
        <Modal<ConfirmModalData>
            modalState={modalState}
            setModalState={setModalState}
            size={modalState.data.size || Sizes.sm}
            title={modalState.data.title || t('confirmModal.title', { ns: 'workflowActions' })}
        >
            <ConfirmModalInner
                modalData={modalState.data}
            />
        </Modal>
    );
};

export default ConfirmModal;
