import { memo } from 'react';
import { CellContext } from '@tanstack/react-table';
import { ColumnDefTemplate } from '@tanstack/table-core';
import DocumentTextField from '@components/features/documentFields/DocumentTextField';
import DocumentNumberField from '@components/features/documentFields/DocumentNumberField';
import DocumentLookupField from '@components/features/documentFields/DocumentLookupField';
import DocumentDateField from '@components/features/documentFields/DocumentDateField';
import DocumentSelectField from '@components/features/documentFields/DocumentSelectField';
import { DynamicFieldValue } from '@models/DynamicField';
import DocumentAccrualsField from '@components/features/documentFields/DocumentAccrualsField';
import useCodingFieldProperties from '@components/features/coding/hooks/useCodingFieldProperties';
import { COLUMN_ID_QUICKMENU, COLUMN_ID_SELECT } from '@components/primitives/dataGrid/DataGrid';

const EditableCell: ColumnDefTemplate<CellContext<any, any>> = ({
    getValue,
    row,
    column,
    table,
}) => {
    const { getCodingPropsByLineId } = useCodingFieldProperties();
    const fieldDefinition = column.columnDef.meta?.fieldDefinition;
    const onFieldChange = column.columnDef.meta?.onFieldChange || undefined;
    const showAsText = column.columnDef.meta?.showAsText || false;
    const field = table.options.meta?.getField ? table.options.meta?.getField(row.original.row, column.id) : undefined;
    const tableName = table.options.meta?.name || undefined;
    const format = fieldDefinition?.format;
    const lineIsActive = (row.original.row !== null && row.original.row !== undefined) && table.options.meta?.selectedRow === row.original.row;
    const documentId = column.columnDef.meta?.documentId;
    const lineProps = documentId ? getCodingPropsByLineId(documentId, row.original.row) : undefined;
    const visibleCells = row.getVisibleCells().filter((cell) => cell.column.id !== COLUMN_ID_QUICKMENU && cell.column.id !== COLUMN_ID_SELECT);

    const onFieldBlur = () => {
        // On last field blur
        if (column.getIndex() === visibleCells.length && table.options.meta?.onLastFieldBlur) {
            table.options.meta.onLastFieldBlur(row.index, visibleCells[0].column.id);
        }
    };

    const onFieldFocus = () => {
        if (!fieldDefinition || !table.options.meta?.onCellSelect) {
            return;
        }

        table.options.meta?.onCellSelect(row.original.row, fieldDefinition.id);
    };

    const onFieldUpdated = (id: string, value: string | number) => {
        if (!table.options.meta?.onFieldUpdated) {
            return;
        }

        table.options.meta.onFieldUpdated(row.original.row, id, value);
    };

    const onFieldInit = (id: string, value: string) => {
        if (!table.options.meta?.onFieldInit) {
            return;
        }

        table.options.meta.onFieldInit(row.original.row, id, value);
    };

    if (fieldDefinition?.id === 'per_boekingsdetails' && documentId) {
        return (
            <DocumentAccrualsField
                showAsText={showAsText}
                value={getValue()}
                documentId={documentId}
                onFieldBlur={onFieldBlur}
                lineId={row.original.row}
            />
        );
    }

    if (!format) {
        return null;
    }

    if (!fieldDefinition) {
        console.warn('Provide fieldDefinition');
        return null;
    }

    if (format === 'text' || format === 'int') {
        return (
            <DocumentTextField
                showAsText={showAsText}
                isEditable={lineIsActive}
                initialValue={getValue()}
                fieldDefinition={fieldDefinition}
                field={field}
                onFieldFocus={onFieldFocus}
                onFieldBlur={onFieldBlur}
                onFieldChange={onFieldChange ? (value: DynamicFieldValue) => onFieldChange(value, row.original.row) : undefined}
                onFieldUpdated={onFieldUpdated}
                inputId={`${row.id}_${fieldDefinition.id}`}
            />
        );
    }

    if (format === 'date') {
        return (
            <DocumentDateField
                isEditable={lineIsActive}
                initialValue={getValue()}
                fieldDefinition={fieldDefinition}
                field={field}
                onFieldFocus={onFieldFocus}
                onFieldBlur={onFieldBlur}
                onFieldUpdated={onFieldUpdated}
                inputId={`${tableName ? `${tableName}_` : ''}${row.id}_${fieldDefinition.id}`}
            />
        );
    }

    if (format === 'decimal') {
        return (
            <DocumentNumberField
                isEditable={lineIsActive}
                initialValue={getValue()}
                fieldDefinition={fieldDefinition}
                field={field}
                onFieldFocus={onFieldFocus}
                onFieldBlur={onFieldBlur}
                onFieldUpdated={onFieldUpdated}
                inputId={`${row.id}_${fieldDefinition.id}`}
            />
        );
    }

    if (format === 'select') {
        return (
            <DocumentSelectField
                isEditable={lineIsActive}
                initialValue={getValue()}
                fieldDefinition={fieldDefinition}
                field={field}
                onFieldFocus={onFieldFocus}
                onFieldBlur={onFieldBlur}
                onFieldUpdated={onFieldUpdated}
                inputId={`${row.id}_${fieldDefinition.id}`}
            />
        );
    }

    if (format === 'lookup') {
        if (!documentId) {
            return null;
        }

        return (
            <DocumentLookupField
                showAsText={showAsText}
                isEditable={lineIsActive}
                initialValue={getValue()}
                fieldDefinition={{
                    ...fieldDefinition,
                    ...(lineProps?.readOnlyFields
                        && lineProps?.readOnlyFields[fieldDefinition.id]
                        && typeof lineProps.readOnlyFields[fieldDefinition.id] === 'boolean'
                        && { isReadOnly: lineProps?.readOnlyFields[fieldDefinition.id] }),
                    ...(lineProps?.requiredFields
                        && lineProps?.requiredFields[fieldDefinition.id]
                        && typeof lineProps.requiredFields[fieldDefinition.id] === 'boolean'
                        && { isRequired: lineProps?.requiredFields[fieldDefinition.id] }),
                }}
                field={field}
                documentId={documentId}
                onFieldFocus={onFieldFocus}
                onFieldBlur={onFieldBlur}
                onFieldUpdated={onFieldUpdated}
                onFieldInit={onFieldInit}
                inputId={`${row.id}_${fieldDefinition.id}`}
                lineId={row.original.row}
            />
        );
    }

    return (
        <div>Format {format} not yet implemented</div>
    );
};

export default memo(EditableCell);
