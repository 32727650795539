import { FC, FocusEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import FormError from '@components/primitives/form/FormError';
import { Sizes } from '@utils/sizes';

interface Props {
    className?: string;
    name: string;
    type?: 'text' | 'number' | 'email' | 'tel' | 'password';
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    placeholder?: string;
    maxLength?: number;
    isReadOnly?: boolean;
    size?: Sizes.xl | Sizes.md;
    min?: number;
    max?: number;
    disablePasswordManagers?: boolean;
    showValidationError?: boolean;
    testId?: string;
}

const FormInput: FC<Props> = (
    {
        className,
        name,
        placeholder,
        type = 'text',
        onFocus,
        onBlur,
        maxLength,
        isReadOnly = false,
        min,
        max,
        size = Sizes.md,
        disablePasswordManagers,
        showValidationError = true,
        testId,
    },
) => {
    const { register } = useFormContext();
    const classes = classNames('form__field', 'form__field--input', className, {
        'form__field--xl': size === Sizes.xl,
    });

    return (
        <div className={classes}>
            <input
                {...register(name)}
                onKeyDown={(e) => {
                    // Prevent the user from entering the letter 'e' in number fields (H519)
                    if (type === 'number' && ['e', 'E'].includes(e.key)) {
                        e.preventDefault();
                    }
                }}
                data-testid={testId}
                data-lpignore="true"
                className="form__input"
                type={type}
                placeholder={placeholder}
                readOnly={isReadOnly}
                maxLength={maxLength}
                onFocus={onFocus}
                onBlur={onBlur}
                min={min}
                max={max}
                id={name}
                {...(disablePasswordManagers ? {
                    'data-1p-ignore': true,
                } : {})}
            />
            {name && showValidationError ? (
                <FormError name={name} />
            ) : null}
        </div>
    );
};

export default FormInput;
