import { OcrCorrectionValue } from '@models/DocumentOcrData';
import { useAtom } from 'jotai';
import { ocrCorrectionsAtom } from '@js/store/ocrCorrections/ocrCorrectionsAtoms';

const useOcrCorrections = () => {
    const [ocrCorrections, setOcrCorrections] = useAtom(ocrCorrectionsAtom);

    const registerOcrCorrection = (id: string, value: number) => {
        setOcrCorrections((ocrCorrections) => {
            return {
                ...ocrCorrections,
                [id]: value,
            };
        });
    };

    const getOcrCorrectionById = (id: string): OcrCorrectionValue | undefined => {
        return ocrCorrections[id];
    };

    return {
        ocrCorrections,
        getOcrCorrectionById,
        registerOcrCorrection,
    };
};

export default useOcrCorrections;
