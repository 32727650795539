import { FC, memo } from 'react';
import { DocumentOcrSummary } from '@models/DocumentOcrSummary';
import classNames from 'classnames';
import OcrRect from '@components/features/ocr/components/OcrRect';

interface Props {
    documentOcrSummary: DocumentOcrSummary;
    page: number;
    fileWidth: number;
    fileHeight: number;
}

const OcrTrainingTableBorder: FC<Props> = ({ documentOcrSummary, page, fileHeight, fileWidth }) => {
    const lineItemTable = documentOcrSummary.list.filter((ocrItem) => ocrItem.id === `lineitemtable${page}` && ocrItem.zone !== '0 0 0 0');
    const lineItemTableEnd = documentOcrSummary.list.filter((ocrItem) => ocrItem.id === `eindelineitemtablebijveldnaam${page}`);
    const tableHeaderBox = documentOcrSummary.list.filter((ocrItem) => ocrItem.id === `tableheaderbox${page}` && ocrItem.zone !== '0 0 0 0');

    return (
        <>
            {lineItemTable?.length ? (
                <>
                    {lineItemTable.map((ocrItem, index) => {
                        if (!ocrItem.zone) {
                            return null;
                        }

                        return (
                            <OcrRect
                                scrollToElement={false}
                                key={ocrItem.id}
                                zone={ocrItem.zone}
                                className={classNames('ocr__rectangle', 'ocr__rectangle--lineItemTable')}
                                fileWidth={fileWidth}
                                fileHeight={fileHeight}
                                truncateWithinDocument
                            >
                                <div className="ocr__label">
                                    Lineitems
                                    {lineItemTableEnd?.length && lineItemTableEnd[index] ? (
                                        <span className="reason">{lineItemTableEnd[index].value} ⊻</span>
                                    ) : null}
                                </div>

                            </OcrRect>
                        );
                    })}
                </>
            ) : null}
            {tableHeaderBox?.length ? (
                <>
                    {tableHeaderBox.map((ocrItem) => {
                        if (!ocrItem.zone) {
                            return null;
                        }

                        return (
                            <OcrRect
                                scrollToElement={false}
                                key={ocrItem.id}
                                zone={ocrItem.zone}
                                className={classNames('ocr__rectangle', 'ocr__rectangle--tableHead')}
                                fileWidth={fileWidth}
                                fileHeight={fileHeight}
                            >
                                <div className="ocr__label">
                                    TableHead
                                </div>

                            </OcrRect>
                        );
                    })}
                </>
            ) : null}
        </>
    );
};

export default memo(OcrTrainingTableBorder);
