import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { unlinkDocumentMatchingLine } from '@repository/documentMatchingRepository';

const useUnlinkMatchingLine = (documentId: number) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: (rowId: number) => unlinkDocumentMatchingLine(documentId, rowId),
        onSuccess: async () => {
            await Promise.all([
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentInvoiceLines, documentId],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentOrderLines, documentId],
                }),
            ]);
        },
    });
};

export default useUnlinkMatchingLine;
