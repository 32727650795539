import { loginRequest } from '@js/authConfig';
import { useMsal } from '@azure/msal-react';
import log, { LogContext } from '@utils/logging';

const useLogin = () => {
    const { instance } = useMsal();

    const login = () => {
        log({
            context: LogContext.User,
            title: 'Logged in',
            reason: 'The user logged in',
        });

        instance.loginRedirect(loginRequest)
            .catch((e) => {
                console.error(e);
            });
    };

    return login;
};

export default useLogin;
