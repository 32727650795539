import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { local } from '@utils/storage';
import { StorageKeys } from '@constants/storageKeys';

const useQueryTenant = () => {
    const [searchParams] = useSearchParams();
    const tenantParam = searchParams.get('tenant');

    useEffect(() => {
        if (!tenantParam) {
            return;
        }

        local.setItem(StorageKeys.TenantId, tenantParam);
    }, [tenantParam]);
};

export default useQueryTenant;
