import { ChangeEvent, Dispatch, FC, useCallback, useEffect } from 'react';
import { Column, ColumnFiltersState } from '@tanstack/react-table';
import classNames from 'classnames';
import FilterIcon from '@images/svg/filter.svg?react';
import CrossIcon from '@images/svg/close.svg?react';
import { SetStateAction } from 'jotai';
import { debounce } from 'lodash';

interface Props {
    column: Column<any, unknown>;
    columnFilterValues: ColumnFiltersState;
    setColumnFilterValues: Dispatch<SetStateAction<ColumnFiltersState>>
}
const Filter: FC<Props> = ({ column, columnFilterValues, setColumnFilterValues }) => {
    // const { format } = column.columnDef.meta;
    const columnFilterValue = column.getFilterValue();
    const valueObject = columnFilterValues.find((item) => item.id === column.id);
    const value = valueObject && valueObject.value ? valueObject.value : '';

    const debouncedSetFilter = useCallback(debounce((value) => {
        column.setFilterValue(value);
    }, 1000), []);

    useEffect(() => {
        if (!value) {
            column.setFilterValue(undefined);
            return;
        }

        debouncedSetFilter(value);
    }, [value]);

    const classes = classNames('gridFilter', 'gridFilter--default', {
        'gridFilter--active': columnFilterValue,
    });

    const onReset = () => {
        column.setFilterValue(undefined);
        setColumnFilterValues((columnFilterValues) => {
            return columnFilterValues.filter((filter) => filter.id !== column.id);
        });
    };

    const onFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
        setColumnFilterValues((columnFilterValues) => {
            const valueObject = columnFilterValues.find((item) => item.id === column.id);

            if (valueObject) {
                return columnFilterValues.map((val) => {
                    if (val.id === column.id) {
                        return {
                            ...val,
                            value: e.target.value,
                        };
                    }

                    return val;
                });
            }

            return [
                ...columnFilterValues,
                {
                    id: column.id,
                    value: e.target.value,
                },
            ];
        });
        // column.setFilterValue(e.target.value || undefined);
    };

    return (
        <div className={classes}>
            <input
                className="defaultGridFilter"
                // value={(columnFilterValue ?? '') as string}
                value={value as string}
                onChange={onFilterChange}
                type="text"
                name={`filter-${column.id}`}
                data-lpignore="true"
            />
            {!columnFilterValue ? (
                <FilterIcon className="defaultGridFilter__icon" />
            ) : null}
            {columnFilterValue ? (
                <button type="button" tabIndex={-1} className="defaultGridFilter__reset" onClick={onReset}><CrossIcon /></button>
            ) : null}
        </div>
    );
};

export default Filter;
