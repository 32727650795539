import { components } from 'react-select';
import { FC } from 'react';
import CustomReactSelectRefreshButton from '@components/primitives/form/components/CustomReactSelectRefreshButton';

type Props = any;

const CustomReactSelectMenu: FC<Props> = (props) => {
    return (
        <components.MenuList {...props}>
            {props.children}
            {props.enableLookupRefresh ? (
                <CustomReactSelectRefreshButton
                    documentId={props.documentId}
                    fieldName={props.fieldName}
                    lookup={props.lookup}
                    title={props.title}
                    onSuccess={props.onListRefreshed}
                />
            ) : null}
        </components.MenuList>
    );
};

export default CustomReactSelectMenu;
