import React, { FC } from 'react';
import { Sizes } from '@utils/sizes';
import DynamicAssignModalInner from '@components/modals/dynamicAssignModal/DynamicAssignModalInner';
import Modal from '@components/primitives/modal/Modal';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { dynamicAssignModalAtom, DynamicAssignModalData } from '@js/store/modals/dynamicAssignModalAtom';

const DynamicAssignModal: FC = () => {
    const [modalState, setModalState] = useAtom(dynamicAssignModalAtom);
    const { t } = useTranslation();

    if (!modalState.data) {
        return null;
    }

    return (
        <Modal<DynamicAssignModalData>
            modalState={modalState}
            setModalState={setModalState}
            size={Sizes.md}
            title={t('dynamicAssign.title', { ns: 'workflowActions' })}
            onClose={modalState.data.onCanceled}
        >
            <DynamicAssignModalInner
                modalData={modalState.data}
            />
        </Modal>
    );
};

export default DynamicAssignModal;
