/* eslint-disable no-console */
import { createStorageKey, getLocalStorageItem } from '@utils/storage';
import { StorageKeys } from '@constants/storageKeys';
import { LoggingSettings } from '@js/store/settingsAtoms';
import { format } from 'date-fns';

export enum LogContext {
    Headers = 'Headers',
    Coding = 'Coding',
    Matching = 'Matching',
    LookupErrors = 'LookupErrors',
    User = 'User',
    CurrentDocClass = 'CurrentDocClass',
}

export type LogInstruction = {
    context: LogContext;
    title: string;
    reason: string;
    data?: any;
};

const log = (logInstruction: LogInstruction) => {
    const loggingSettings: LoggingSettings = getLocalStorageItem(createStorageKey([StorageKeys.LoggingSettings]));

    if (!loggingSettings || !loggingSettings[logInstruction.context]) {
        // Logging for this category is not enabled.
        return;
    }

    const getContextStyling = (context: LogContext): string => {
        return {
            Headers: 'background: #fbe6cd; color: orange; border: 2px solid orange',
            Coding: 'background: #dfffdf; color: green; border: 2px solid green',
            Matching: 'background: #a2d0f0; color: blue; border: 2px solid blue',
            LookupErrors: 'background: #fbcdcd; color: red; border: 2px solid red',
            User: 'background: #f8f8f8; color: #ccc; border: 2px solid #ccc',
            CurrentDocClass: 'background: #03090c; color: #fff; border: 2px solid #03090c',
            LocalStorage: 'background: #aa56da; color: #fff; border: 2px solid #8830ba',
        }[context];
    };

    console.groupCollapsed(
        `%c ${logInstruction.context}`,
        `padding: 5px 10px 5px 5px; border-radius: 3px; font-weight: bold; ${getContextStyling(logInstruction.context)}`,
        logInstruction.title,
    );
    console.log('%c Reason: ', 'font-weight: bold;', logInstruction.reason);
    console.log('%c When: ', 'font-weight: bold;', format(new Date(), 'dd-MM-yyyy HH:mm:ss'));
    if (logInstruction.data) {
        console.log(logInstruction.data);
    }
    console.groupEnd();
};

export default log;
