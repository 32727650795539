import { StorageKeys } from '@constants/storageKeys';
import { createStorageKey } from '@utils/storage';
import { atomWithStorage } from 'jotai/utils';

// Show CC/BCC fields
export const showCopyEmailAddressAtom = atomWithStorage<boolean>(
    createStorageKey([StorageKeys.ShowCopyEmailAddress]),
    false,
);

// Show the comments panel (on the right on documentPage)
export const showCommentsPanelAtom = atomWithStorage<boolean>(
    createStorageKey([StorageKeys.RightPanelCollapsed]),
    true,
);

// Collapse navigation
export const collapseNavigationAtom = atomWithStorage<boolean>(
    createStorageKey([StorageKeys.NavigationCollapsed]),
    false,
);

// Width of the headers
export const widthHeaderFieldsAtom = atomWithStorage<number>(
    createStorageKey([StorageKeys.WidthHeaderFields]),
    300,
);

// Height of coding and matching
export const HeightCodingMatchingAtom = atomWithStorage<number>(
    createStorageKey([StorageKeys.HeightCodingMatching]),
    300,
);

// Copy coding preview width
export const WidthCopyCodingLinesPreviewAtom = atomWithStorage<number[]>(
    createStorageKey([StorageKeys.WidthCopyCodingLinesPreview]),
    [60, 40],
);

// Preview modal height
export const HeightPreviewContentAtom = atomWithStorage<number[]>(
    createStorageKey([StorageKeys.HeightPreviewContent]),
    [60, 40],
);

// File handler section
export const FileHandlerSectionAtom = atomWithStorage<number[]>(
    createStorageKey([StorageKeys.FileHandlerSection]),
    [60, 40],
);
