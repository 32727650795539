import { RefObject, useEffect, useState } from 'react';
import { CreateCodingLineResponse } from '@models/DocumentCoding';
import useCodingTotals from '@components/features/coding/hooks/useCodingTotals';
import useCreateDocumentInvoiceLine from '@components/features/documentInvoiceLines/hooks/useCreateDocumentInvoiceLine';
import { RowSelectionState } from '@tanstack/react-table';
import { COLUMN_ID_SELECT } from '@components/primitives/dataGrid/DataGrid';
import useUpdateDocumentInvoiceLine from '@components/features/documentInvoiceLines/hooks/useUpdateDocumentInvoiceLine';
import useDeleteDocumentInvoiceLine from '@components/features/documentInvoiceLines/hooks/useDeleteDocumentInvoiceLine';
import useInvoiceLinesCaret from '@hooks/caret/useInvoiceLinesCaret';
import useOrderLinesCaret from '@hooks/caret/useOrderLinesCaret';
import useDocumentInvoiceLines from '@components/features/documentInvoiceLines/hooks/useDocumentInvoiceLines';
import useFetchDocumentInvoiceLines from '@components/features/documentInvoiceLines/hooks/useFetchDocumentInvoiceLines';

const useInvoiceLinesGridEffects = (documentId: number, isReadOnlyMode: boolean, invoiceLinesRef: RefObject<HTMLDivElement>) => {
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const { invoiceLinesCaret, setInvoiceLinesCaret, resetInvoiceLinesCaret } = useInvoiceLinesCaret();
    const { setOrderLinesCaret } = useOrderLinesCaret();
    const { data: documentInvoiceLines } = useFetchDocumentInvoiceLines(documentId);
    const { mutate: deleteMatchingLine, isPending: isDeletingMatchingLine } = useDeleteDocumentInvoiceLine(documentId);
    const { mutate: createMatchingLine, isPending: isCreatingMatchingLine } = useCreateDocumentInvoiceLine(documentId);
    const { mutate: updateInvoiceLine } = useUpdateDocumentInvoiceLine(documentId);
    const { getInvoiceLineFieldValue, getNeighborInvoiceLineId } = useDocumentInvoiceLines(documentId);
    const { difference } = useCodingTotals(documentId);

    const onDeleteLine = async (matchingLineIds: number[]) => {
        if (!matchingLineIds?.length) {
            return;
        }
        deleteMatchingLine(matchingLineIds, {
            onSuccess: () => {
                setRowSelection({});
                resetInvoiceLinesCaret();
            },
        });
    };

    const scrollToCodingLine = () => {
        if (!invoiceLinesRef?.current) {
            return;
        }
        const activeRowEl = document.querySelector('.invoiceLines__grid .row--active');

        if (!activeRowEl) {
            return;
        }

        const fromTop = invoiceLinesRef.current.offsetTop + 40; // Plus height of tabs

        invoiceLinesRef.current.scrollTo({
            // @ts-ignore
            top: activeRowEl.offsetTop - fromTop,
            left: invoiceLinesRef.current.scrollLeft,
            behavior: 'smooth',
        });
    };

    const scrollToNewCodingLine = () => {
        // Use timeout, so we have time to update the cache first. Otherwise, we don't scroll to the bottom
        setTimeout(() => {
            if (invoiceLinesRef?.current) {
                // @ts-ignore
                invoiceLinesRef.current.scrollTo({
                    top: 100000,
                    left: 0,
                    behavior: 'smooth',
                });
                // invoiceLinesRef.current.scrollLeft = 0;
            }
        }, 200);
    };

    const onAddLine = async (firstColumnId?: string) => {
        scrollToNewCodingLine();
        createMatchingLine({
            values: [
                {
                    id: 'bedrag',
                    value: difference > 0 ? difference : 0,
                },
            ],
        }, {
            onSuccess: async (response: CreateCodingLineResponse) => {
                if (!response?.rowid) {
                    return;
                }
                const visibleColumns = documentInvoiceLines?.definitions.filter((column) => !column.isHidden);

                if (!visibleColumns?.length) {
                    return;
                }
                const firstField = visibleColumns[0];
                setInvoiceLinesCaret({
                    lineId: response.rowid,
                    fieldId: firstColumnId || firstField.id,
                });
            },
        });
    };

    const onF7Key = async (e: KeyboardEvent) => {
        e.preventDefault();
        if (!invoiceLinesCaret?.lineId || !invoiceLinesCaret?.fieldId) {
            return;
        }
        const { lineId, fieldId } = invoiceLinesCaret;
        const previousLine = getNeighborInvoiceLineId(lineId, 'prev');

        if (previousLine === null) {
            return;
        }
        const previousValue = getInvoiceLineFieldValue(previousLine as number, fieldId);

        if (!previousValue) {
            return;
        }

        updateInvoiceLine({
            data: {
                values: [{
                    id: fieldId,
                    value: previousValue,
                }],
            },
            lineId,
        });
    };

    const onKeyDown = async (e: KeyboardEvent) => {
        if (e.code === 'F7') {
            onF7Key(e);
        }
    };

    useEffect(() => {
        if (!invoiceLinesCaret) {
            return undefined;
        }

        document.addEventListener('keydown', onKeyDown, false);

        return () => {
            document.removeEventListener('keydown', onKeyDown, false);
        };
    }, [invoiceLinesCaret]);

    const onCellClick = (row: any, cell: any, selectedRow?: string | number, selectedCell?: string | number) => {
        if (cell.column.id === COLUMN_ID_SELECT) {
            return;
        }
        if ((row.original.row === selectedRow && cell.column.id === selectedCell) || isReadOnlyMode) {
            return;
        }
        setOrderLinesCaret({
            lineId: row.original.matchkey,
        });
        setInvoiceLinesCaret({
            lineId: row.original.row,
            fieldId: cell.column.id,
        });
    };

    useEffect(() => {
        if (invoiceLinesCaret?.lineId) {
            return;
        }
        scrollToCodingLine();
    }, [invoiceLinesCaret?.lineId]);

    const onLastFieldBlur = (rowIndex: number, firstColumnId: string) => {
        if (!documentInvoiceLines) {
            return;
        }

        if (rowIndex === documentInvoiceLines.rows.length - 1) {
            // Last field of last row blurred. Add new line when amount !== total
            if (difference <= 0) {
                return;
            }
            onAddLine(firstColumnId);
        } else {
            // Last field of other row blurred, focus first field of next line.
            const nextLine = documentInvoiceLines.rows[rowIndex + 1];
            const idField = nextLine.values.find((fieldValue) => fieldValue.id === 'row');

            if (!idField) {
                return;
            }

            setInvoiceLinesCaret({
                lineId: idField.value as number,
                fieldId: firstColumnId,
            });

            if (invoiceLinesRef?.current) {
                invoiceLinesRef.current.scrollTo({
                    left: 0,
                    top: invoiceLinesRef.current.scrollTop + 32,
                    behavior: 'smooth',
                });
            }
        }
    };

    return {
        addingLine: isCreatingMatchingLine,
        onAddLine,
        deletingLine: isDeletingMatchingLine,
        onDeleteLine,
        onCellClick,
        rowSelection,
        setRowSelection,
        onLastFieldBlur,
    };
};

export default useInvoiceLinesGridEffects;
