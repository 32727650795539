import { FC } from 'react';
import Heading from '@components/primitives/heading/Heading';
import { Sizes } from '@utils/sizes';
import FormRow from '@components/primitives/form/FormRow';
import Button from '@components/primitives/button/Button';
import { useTranslation } from 'react-i18next';
import useLogin from '@hooks/useLogin';
import { toast } from 'react-toastify';

const Login: FC = () => {
    const login = useLogin();
    const { t } = useTranslation();

    const handleLogin = () => {
        toast.dismiss();
        login();
    };

    return (
        <>
            <Heading className="loginBox__heading" size={Sizes.xxl}>{t('login.title', { ns: 'authentication' })}</Heading>
            <p className="loginBox__text">{t('login.text', { ns: 'authentication' })}</p><br />
            <FormRow>
                <Button testId="login__azure" primary size={Sizes.lg} onClick={handleLogin}>{t('login.azureButton', { ns: 'authentication' })}</Button>
            </FormRow>
        </>
    );
};

export default Login;
