import { FC } from 'react';
import ModalFooter from '@components/primitives/modal/components/ModalFooter';
import ModalContent from '@components/primitives/modal/components/ModalContent';
import Button from '@components/primitives/button/Button';
import { useTranslation } from 'react-i18next';
import { closedModalState } from '@js/store/modalAtoms';
import { confirmModalAtom, ConfirmModalData } from '@js/store/modals/confirmModalAtom';
import { useSetAtom } from 'jotai';

interface Props {
    modalData: ConfirmModalData;
}

const ConfirmModalInner: FC<Props> = ({ modalData }) => {
    const { text, onCanceled, onConfirm } = modalData;
    const setModalState = useSetAtom(confirmModalAtom);
    const { t } = useTranslation();

    const handleCancel = () => {
        if (onCanceled) {
            onCanceled();
        }
        setModalState(closedModalState);
    };

    const handleConfirm = () => {
        onConfirm();
        setModalState(closedModalState);
    };

    return (
        <>
            <ModalContent>
                {text || t('confirmModal.text', { ns: 'workflowActions' })}
            </ModalContent>
            <ModalFooter>
                <Button type="submit" onClick={handleConfirm} primary>{t('yes')}</Button>
                <Button isLink onClick={handleCancel}>{t('cancel')}</Button>
            </ModalFooter>
        </>
    );
};

export default ConfirmModalInner;
