import { executeGetRequest, executePostRequest } from '@api/client';
import { createFetchLookupPath, createRefreshLookupPath } from '@routing/apiUrls';
import { Lookup } from '@models/Lookup';

type Params = {
    [key: string]: string;
};

export const fetchLookup = async (lookup: string, params: Params, signal?: AbortSignal): Promise<Lookup> => {
    const result = await executeGetRequest<Lookup>(createFetchLookupPath(lookup, params), signal);
    return result.data;
};

export const refreshLookup = async (documentId: number, fieldName: string, key: string, signal?: AbortSignal): Promise<Lookup> => {
    const result = await executePostRequest<Lookup>(createRefreshLookupPath(documentId, fieldName, key), signal);
    return result.data;
};
