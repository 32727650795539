import { FC } from 'react';
import Delayed from '@components/primitives/delayed/Delayed';
import classNames from 'classnames';

interface Props {
    className?: string;
    delayInMs?: number;
}

const Throbber: FC<Props> = ({ className, delayInMs = 100 }) => {
    const classes = classNames('throbber', className);

    return (
        <Delayed timeout={delayInMs}>
            <div className={classes} />
        </Delayed>
    );
};

export default Throbber;
