import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { fetchOcrTraining } from '@repository/ocrTrainingRepository';
import { OcrTraining } from '@models/OcrTraining';
import { minutesToMillis } from '@utils/millis';

const useFetchOcrTraining = (ocrTrainingEnabled: boolean, documentId: number): UseQueryResult<OcrTraining> => {
    return useQuery({
        queryKey: [QueryKey.OcrTraining, documentId],
        queryFn: ocrTrainingEnabled ? ({ signal }) => fetchOcrTraining(documentId, signal) : undefined,
        staleTime: minutesToMillis(30),
        enabled: ocrTrainingEnabled,
    });
};

export default useFetchOcrTraining;
