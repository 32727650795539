import { FC, ReactNode } from 'react';
import { DynamicField, DynamicFieldValue } from '@models/DynamicField';
import DocumentFieldFeedback from '@components/features/documentFields/DocumentFieldFeedback';
import DataGridCell from '@components/primitives/dataGrid/DataGridCell';

interface Props {
    fieldDefinition: DynamicField;
    value: DynamicFieldValue;
    field: any;
    children?: ReactNode;
    giveChildrenPriority?: boolean;
}

const StaticDocumentField: FC<Props> = ({ fieldDefinition, value, field, children, giveChildrenPriority }) => {
    return (
        <div className="dynamicFieldCell--static">
            <DocumentFieldFeedback
                fieldDefinition={fieldDefinition}
                value={value}
                field={field}
                giveChildrenPriority={giveChildrenPriority}
            >
                {children ? (
                    <>{children}</>
                ) : (
                    <DataGridCell
                        value={field?.display ? field.display : value}
                        format={fieldDefinition?.format}
                        decimals={fieldDefinition?.decimals}
                    />
                )}
            </DocumentFieldFeedback>
        </div>
    );
};

export default StaticDocumentField;
