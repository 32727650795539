import { atom } from 'jotai';
import { closedModalState, ModalState } from '@js/store/modalAtoms';
import { ReactNode } from 'react';

export interface DocumentPreviewModalData {
    documentId: number;
    listType: string;
    title?: string | ReactNode;
}

export const documentPreviewModalAtom = atom<ModalState<DocumentPreviewModalData>>(closedModalState);
