import * as React from "react";
const SvgPlaystore = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 135 40", ...props }, /* @__PURE__ */ React.createElement("rect", { width: 135, height: 40, rx: 5, ry: 5 }), /* @__PURE__ */ React.createElement("path", { d: "M130 .8c2.3 0 4.2 1.9 4.2 4.2v30c0 2.3-1.9 4.2-4.2 4.2H5A4.2 4.2 0 0 1 .8 35V5C.8 2.7 2.7.8 5 .8h125m0-.8H5a5 5 0 0 0-5 5v30a5 5 0 0 0 5 5h125a5 5 0 0 0 5-5V5a5 5 0 0 0-5-5Z", style: {
  fill: "#a6a6a6"
} }), /* @__PURE__ */ React.createElement("text", { transform: "translate(41 13)", style: {
  fontFamily: "ProductSans-Regular,&quot",
  fontSize: "8.38px",
  stroke: "#fff",
  strokeMiterlimit: 10,
  strokeWidth: ".2px",
  fill: "#fff"
} }, /* @__PURE__ */ React.createElement("tspan", { x: 0, y: 0, style: {
  letterSpacing: ".02em"
} }, "GET IT"), /* @__PURE__ */ React.createElement("tspan", { x: 25.1, y: 0 }), /* @__PURE__ */ React.createElement("tspan", { x: 27, y: 0, style: {
  letterSpacing: ".02em"
} }, "ON")), /* @__PURE__ */ React.createElement("path", { d: "M68.1 21.8c-2.3 0-4.2 1.7-4.2 4.2s1.9 4.3 4.2 4.3 4.3-1.9 4.3-4.3-2-4.3-4.3-4.3Zm0 6.8c-1.2 0-2.4-1-2.4-2.6s1.1-2.6 2.4-2.6 2.4 1 2.4 2.6-1 2.6-2.4 2.6Zm-9.3-6.9c-2.3 0-4.2 1.8-4.2 4.3s1.9 4.3 4.2 4.3 4.3-1.9 4.3-4.3-2-4.3-4.3-4.3Zm0 6.9c-1.3 0-2.4-1-2.4-2.6s1.1-2.6 2.4-2.6 2.4 1 2.4 2.6-1 2.6-2.4 2.6Zm-11-5.6v1.8H52c-.2 1-.5 1.8-1 2.3-.6.6-1.6 1.3-3.3 1.3-2.7 0-4.8-2.1-4.8-4.8s2.1-4.8 4.8-4.8c1.4 0 2.4.6 3.2 1.3l1.3-1.2c-1.1-1-2.5-1.9-4.5-1.9-3.7 0-6.7 3-6.7 6.6s3 6.7 6.7 6.7a6 6 0 0 0 4.6-1.9 6 6 0 0 0 1.5-4.2V23h-6.1ZM93 24.4c-.4-.9-1.5-2.7-3.7-2.7s-4 1.8-4 4.3c0 2.4 1.8 4.2 4.2 4.2 2 0 3.1-1.2 3.6-1.8l-1.5-1c-.5.7-1.1 1.2-2 1.2s-1.7-.5-2.1-1.3l5.7-2.4-.2-.5ZM87.3 26c0-1.7 1.2-2.5 2.2-2.5.7 0 1.4.4 1.6.9l-3.8 1.6Zm-4.7 4h2V17.6h-2V30Zm-3-7.2a3 3 0 0 0-2.3-1c-2.1 0-4.1 2-4.1 4.3s2 4.2 4 4.2c1 0 1.9-.4 2.3-1v.7c0 1.6-.8 2.5-2.2 2.5-1.1 0-1.8-.8-2.1-1.5l-1.7.7a4 4 0 0 0 3.8 2.5c2.2 0 4-1.3 4-4.5V22h-1.7v.7Zm-2.2 5.9c-1.3 0-2.3-1.1-2.3-2.6s1-2.6 2.3-2.6 2.3 1.1 2.3 2.6-1 2.6-2.3 2.6Zm24.4-11.1h-4.5V30h2v-4.8h2.5c2.1 0 4.1-1.5 4.1-3.8s-2-4-4-4Zm0 6h-2.6v-4.3h2.7c1.4 0 2.2 1.2 2.2 2.2s-.8 2.1-2.2 2.1Zm11.6-1.8c-1.4 0-2.8.6-3.3 2l1.6.6c.4-.7 1-.9 1.7-.9 1 0 2 .6 2 1.6v.1c-.4-.2-1-.4-2-.4-1.8 0-3.6 1-3.6 2.8 0 1.6 1.5 2.7 3.1 2.7 1.3 0 2-.5 2.4-1.2v1h1.9v-4.8c0-2.3-1.7-3.5-3.8-3.5Zm-.2 6.9c-.6 0-1.5-.3-1.5-1 0-1 1-1.4 2-1.4.8 0 1.2.2 1.7.4-.2 1.2-1.2 2-2.2 2Zm10.5-6.6-2 5.4h-.2l-2.2-5.4h-2l3.3 7.6-1.9 4.2h2l5.1-11.8h-2ZM107 30h2V17.5h-2V30Z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M20.7 19.4 10.1 30.7a2.9 2.9 0 0 0 4.2 1.8l12-7-5.6-6Z", style: {
  fill: "#ea4335"
} }), /* @__PURE__ */ React.createElement("path", { d: "m31.5 17.5-5.2-3-5.8 5.2 5.8 5.8 5.2-3a2.9 2.9 0 0 0 0-5Z", style: {
  fill: "#fbbc04"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10 9.3v21.4l11-11L10 9.3Z", style: {
  fill: "#4285f4"
} }), /* @__PURE__ */ React.createElement("path", { d: "m20.8 20 5.5-5.5-12-7A2.9 2.9 0 0 0 10 9.3L20.9 20Z", style: {
  fill: "#34a853"
} }));
export default SvgPlaystore;
