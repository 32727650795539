import { FC, useCallback, useEffect, useRef, useState } from 'react';
import InvoiceLines from '@components/features/documentInvoiceLines/InvoiceLines';
import { createStorageKey, getLocalStorageItem, setLocalStorageItem } from '@utils/storage';
import { StorageKeys } from '@constants/storageKeys';
import classNames from 'classnames';
import OrderLines from '@components/features/documentOrderLines/OrderLines';
import { collapseNavigationAtom } from '@js/store/viewSettingsAtoms';
import { useAtomValue } from 'jotai';
import useFetchDocumentInvoiceLines from '@components/features/documentInvoiceLines/hooks/useFetchDocumentInvoiceLines';

interface Props {
    documentId: number;
    isPreviewMode: boolean;
    ocrTrainingEnabled: boolean;
}
const Matching: FC<Props> = ({ documentId, isPreviewMode, ocrTrainingEnabled }) => {
    const matchingRef = useRef(null);
    const [filteredOrderLines, setFilteredOrderLines] = useState<boolean>(true);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const navCollapsed = useAtomValue(collapseNavigationAtom);
    const { isLoading } = useFetchDocumentInvoiceLines(documentId);

    const classes = classNames('matching', {
        'matching--dragging': isDragging,
    });

    const onFilter = () => {
        setFilteredOrderLines((filtered) => {
            return !filtered;
        });
    };

    const setInvoiceWidth = (width: number) => {
        setLocalStorageItem(createStorageKey([StorageKeys.WidthInvoiceLines]), width.toString());
        // @ts-ignore
        matchingRef.current.style.gridTemplateColumns = `4rem calc(${width}px - 4rem) 4rem calc(100% - 4rem - ${width}px)`;
    };

    // Use saved sizes
    useEffect(() => {
        const savedWidth = getLocalStorageItem(
            createStorageKey([StorageKeys.WidthInvoiceLines]),
        );

        if (savedWidth) {
            setInvoiceWidth(+savedWidth);
        }
    }, []);

    const mouseMove = useCallback((e: MouseEvent) => {
        if (!isDragging || !matchingRef?.current) {
            return;
        }
        const navExpandedWidth = 244;
        const navCollapsedWidth = 75;
        const navWidth = navCollapsed ? navCollapsedWidth : navExpandedWidth;

        const width = e.clientX - navWidth;

        if (e.clientX > (window.innerWidth - 20)) {
            return;
        }

        setInvoiceWidth(width);
    }, [isDragging]);

    const onMouseDown = useCallback(() => {
        setIsDragging(true);
    }, []);

    const mouseUp = useCallback(() => {
        setIsDragging(false);
    }, []);

    useEffect(() => {
        const bodyEl = document.querySelector('body');

        if (!bodyEl) {
            return;
        }
        if (!isDragging) {
            bodyEl.classList.remove('isResizing');
            return;
        }

        bodyEl.classList.add('isResizing');
    }, [isDragging]);

    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', mouseMove);
            window.addEventListener('mouseup', mouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', mouseMove);
            window.removeEventListener('mouseup', mouseUp);
        };
    }, [isDragging, mouseMove, mouseUp]);

    return (
        <div ref={matchingRef} className={classes}>
            <InvoiceLines
                documentId={documentId}
                isPreviewMode={isPreviewMode}
                ocrTrainingEnabled={ocrTrainingEnabled}
            />
            {!isLoading ? (
                <OrderLines
                    documentId={documentId}
                    onMouseDown={onMouseDown}
                    onFilter={onFilter}
                    isPreviewMode={isPreviewMode}
                    filteredOrderLines={filteredOrderLines}
                />
            ) : null}
        </div>
    );
};

export default Matching;
