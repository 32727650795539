import { FC, useEffect, useState } from 'react';
import ModalFooter from '@components/primitives/modal/components/ModalFooter';
import ModalContent from '@components/primitives/modal/components/ModalContent';
import Button from '@components/primitives/button/Button';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import FormRow from '@components/primitives/form/FormRow';
import FormInput from '@components/primitives/form/FormInput';
import PdfIcon from '@images/svg/pdf.svg?react';
import FormEditor from '@components/primitives/form/FormEditor';
import Select from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import useEmailDocumentSchema from '@components/modals/emailDocumentModal/validation/useEmailDocumentSchema';
import Message from '@components/primitives/message/Message';
import CustomReactSelectInput from '@components/primitives/form/components/CustomReactSelectInput';
import CustomReactSelectNoOptions from '@components/primitives/form/components/CustomReactSelectNoOptions';
import useFetchMailTemplates from '@components/modals/emailDocumentModal/hooks/useFetchMailTemplates';
import useFetchMailTemplate from '@components/modals/emailDocumentModal/hooks/useFetchMailTemplate';
import FormMultiEmail from '@components/primitives/form/FormMultiEmail';
import { useAtom, useSetAtom } from 'jotai';
import { showCopyEmailAddressAtom } from '@js/store/viewSettingsAtoms';
import classNames from 'classnames';
import { closedModalState } from '@js/store/modalAtoms';
import useEmailDocument from '@components/features/emailDocument/hooks/useEmailDocument';
import { EmailDocumentForm } from '@models/EmailDocument';
import { toast } from 'react-toastify';
import { emailDocumentModalAtom, EmailDocumentModalData } from '@js/store/modals/emailDocumentModalAtom';
import useAzureAccessToken from '@hooks/useAzureAccessToken';

interface Props {
    modalData: EmailDocumentModalData;
}

export interface Option {
    label: string;
    value: number;
}

const EmailDocumentModalInner: FC<Props> = ({ modalData }) => {
    const { documentId } = modalData;
    const setModalState = useSetAtom(emailDocumentModalAtom);
    const [showCopyEmailAddress, setShowCopyEmailAddress] = useAtom(showCopyEmailAddressAtom);
    const [globalError, setGlobalError] = useState<string | undefined>();
    const [templates, setTemplates] = useState<Option[]>([]);
    const [templateId, setTemplateId] = useState<undefined | number>(undefined);
    const { data: mailTemplates } = useFetchMailTemplates();
    const { data: mailTemplate } = useFetchMailTemplate(templateId, documentId);
    const { t } = useTranslation();
    const { mutate: emailDocument, isPending: isEmailingDocument } = useEmailDocument();
    const { getAzureAccessToken } = useAzureAccessToken();
    const schema = useEmailDocumentSchema();
    const methods = useForm<EmailDocumentForm>({
        resolver: yupResolver(schema),
        mode: 'onTouched',
        defaultValues: {
            toAddress: '',
            cc: '',
            bcc: '',
            subject: '',
            body: '',
            withAnnotations: false,
        },
    });

    const onSubmit = async (data: EmailDocumentForm) => {
        const azureToken = await getAzureAccessToken();

        if (!azureToken) {
            console.warn('No azure token');
            return;
        }

        emailDocument({
            documentId,
            data,
            azureToken,
        }, {
            onSuccess: () => {
                toast.success(t('email.toastSuccess', { ns: 'documentActions' }));
                setModalState(closedModalState);
            },
            onError: (err: any) => {
                let errorMsg = err?.response?.data?.message;
                if (!errorMsg) {
                    errorMsg = t('error');
                }
                setGlobalError(errorMsg);
            },
        });
    };

    const { handleSubmit, setValue, clearErrors } = methods;

    const closeModal = () => {
        setModalState(closedModalState);
    };

    // Transform mailtemplate-list to options.
    useEffect(() => {
        if (!mailTemplates?.list) {
            return;
        }
        setTemplates(mailTemplates.list.map((item) => {
            return {
                label: item.title,
                value: item.id,
            };
        }));
    }, [mailTemplates]);

    // Default select first item of list.
    useEffect(() => {
        if (!templates.length) {
            return;
        }
        setTemplateId(templates[0].value);
    }, [templates]);

    // Prefill to and subject field by selecting template.
    useEffect(() => {
        setValue('toAddress', mailTemplate?.data.toAddress || '');
        setValue('subject', mailTemplate?.data.subject || '');
        clearErrors();
    }, [mailTemplate]);

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="false">
                    <ModalContent>
                        <FormRow className="form__row--wide-input form__row--button" name="template" label={t('email.template', { ns: 'documentActions' })}>
                            <div className="form__field">
                                <Select
                                    components={{
                                        Input: CustomReactSelectInput,
                                    }}
                                    className="form__select"
                                    inputId="template"
                                    classNamePrefix="form__select"
                                    onChange={(option) => setTemplateId(option?.value)}
                                    options={templates}
                                    noOptionsMessage={() => <CustomReactSelectNoOptions />}
                                    value={{ value: mailTemplate?.data.id, label: mailTemplate?.data.title }}
                                />
                            </div>
                        </FormRow>
                        <FormRow
                            className="form__row--wide-input form__row--button"
                            label={t('email.toAddress', { ns: 'documentActions' })}
                            name="toAddress"
                        >
                            <div className="form__field">
                                <FormMultiEmail name="toAddress" />
                                <Button
                                    className={classNames('button--right', {
                                        'button--active': showCopyEmailAddress,
                                    })}
                                    isLink
                                    type="button"
                                    onClick={() => setShowCopyEmailAddress(!showCopyEmailAddress)}
                                >
                                    {t('email.copyEmailAddress', { ns: 'documentActions' })}
                                </Button>
                            </div>
                        </FormRow>
                        {showCopyEmailAddress ? (
                            <>
                                <FormRow className="form__row--wide-input form__row--button" label={t('email.cc', { ns: 'documentActions' })} name="cc">
                                    <div className="form__field">
                                        <FormMultiEmail name="cc" />
                                    </div>
                                </FormRow>
                                <FormRow className="form__row--wide-input form__row--button" label={t('email.bcc', { ns: 'documentActions' })} name="bcc">
                                    <div className="form__field">
                                        <FormMultiEmail name="bcc" />
                                    </div>
                                </FormRow>
                            </>
                        ) : null}
                        <FormRow className="form__row--wide-input form__row--button" label={t('email.subject', { ns: 'documentActions' })} name="subject">
                            <FormInput name="subject" type="text" />
                        </FormRow>
                        <FormRow className="form__row--wide-input form__row--button" label={t('email.attachment', { ns: 'documentActions' })}>
                            <div className="form__field">
                                <div className="attachment">
                                    <PdfIcon />
                                    {documentId}.pdf
                                </div>
                            </div>
                        </FormRow>
                        <hr className="spacious" />
                        <FormRow className="form__row--fluid">
                            <FormEditor name="body" defaultValue={mailTemplate?.data.body} />
                        </FormRow>
                        <Message isError>{globalError}</Message>
                    </ModalContent>
                    <ModalFooter>
                        <Button
                            type="submit"
                            disabled={isEmailingDocument}
                            isLoading={isEmailingDocument}
                            primary
                        >
                            {t('email.submit', { ns: 'documentActions' })}
                        </Button>
                        <Button isLink onClick={() => closeModal()}>{t('cancel')}</Button>
                    </ModalFooter>
                </form>
            </FormProvider>
        </>
    );
};

export default EmailDocumentModalInner;
