import { getLocalStorageItem, setLocalStorageItem } from '@utils/storage';
import { VisibilityState } from '@tanstack/react-table';
import { COLUMN_ID_QUICKMENU, COLUMN_ID_SELECT } from '@components/primitives/dataGrid/DataGrid';
import _ from 'lodash';

type SettingsItem = {
    [key: string]: any;
};
const dataGridStore = (storageKey?: string) => {
    const getGridSettings = () => {
        if (!storageKey) {
            return {};
        }

        return getLocalStorageItem(storageKey) || {};
    };

    const setGridSettings = (newValue: SettingsItem) => {
        if (!storageKey) {
            return;
        }

        const value = getGridSettings();

        const newSettings = {
            sortBy: [
                ...(newValue?.sortBy ? newValue.sortBy : value?.sortBy ? value.sortBy : []),
            ],
            columnWidths: {
                ...(value?.columnWidths ? value.columnWidths : {}),
                ...(newValue?.columnWidths ? newValue.columnWidths : {}),
            },
            columnOrder: [
                ...(newValue?.columnOrder ? newValue.columnOrder : value?.columnOrder ? value.columnOrder : []),
            ],
            hiddenColumns: [
                ...(newValue?.hiddenColumns ? newValue.hiddenColumns : value?.hiddenColumns ? value.hiddenColumns : []),
            ],
            columnFilters: [
                ...(newValue?.columnFilters ? newValue.columnFilters : value?.columnFilters ? value.columnFilters : []),
            ],
            showFilters: typeof newValue?.showFilters === 'boolean' ? newValue.showFilters : typeof value?.showFilters === 'boolean' ? value.showFilters : true,
        };

        if (_.isEqual(value, newSettings)) {
            return;
        }

        setLocalStorageItem(storageKey, JSON.stringify(newSettings));
    };

    const getSavedSorting = () => {
        const settings = getGridSettings();
        return settings?.sortBy || null;
    };
    const getSavedColumnSizing = () => {
        const settings = getGridSettings();
        return settings?.columnWidths || {};
    };

    const getSavedColumnOrder = () => {
        const settings = getGridSettings();
        let columnOrder = settings?.columnOrder || [];
        if (columnOrder.length && !columnOrder.includes(COLUMN_ID_SELECT)) {
            columnOrder = [
                COLUMN_ID_SELECT,
                ...columnOrder,
            ];
        }
        if (columnOrder.length && !columnOrder.includes(COLUMN_ID_QUICKMENU)) {
            columnOrder = [
                ...columnOrder,
                COLUMN_ID_QUICKMENU,
            ];
        }
        return columnOrder;
    };

    const getSavedColumnFilters = () => {
        const settings = getGridSettings();
        return settings?.columnFilters || [];
    };

    const getSavedColumnVisibility = () => {
        const settings = getGridSettings();
        const savedHiddenColumns = settings?.hiddenColumns || [];
        const hiddenColumns: VisibilityState = {};
        if (savedHiddenColumns.length) {
            savedHiddenColumns.forEach((hiddenColumn: string) => {
                hiddenColumns[hiddenColumn] = false;
            });
        }
        return hiddenColumns;
    };

    const getSavedShowFilters = (): boolean => {
        const settings = getGridSettings();
        return typeof settings?.showFilters === 'boolean' ? settings.showFilters : true;
    };

    return {
        getGridSettings,
        setGridSettings,
        getSavedSorting,
        getSavedColumnSizing,
        getSavedColumnOrder,
        getSavedColumnFilters,
        getSavedColumnVisibility,
        getSavedShowFilters,
    };
};

export default dataGridStore;
