import { FC } from 'react';
import Select from 'react-select';
import CustomReactSelectInput from '@components/primitives/form/components/CustomReactSelectInput';
import CustomReactSelectNoOptions from '@components/primitives/form/components/CustomReactSelectNoOptions';
import useStartPage from '@components/features/startPageSwitch/hooks/useStartPage';
import { useSetAtom } from 'jotai';
import { startPageAtom } from '@js/store/settingsAtoms';

type Option = {
    label: string;
    value: string;
};

const StartPageSwitch: FC = () => {
    const setStartPage = useSetAtom(startPageAtom);
    const { startPage, pages } = useStartPage();

    const handleChange = (option: Option | null) => {
        setStartPage(option?.value || '');
    };

    return (
        <div className="form__field">
            <Select
                components={{
                    Input: CustomReactSelectInput,
                }}
                inputId="startPage"
                className="form__select"
                classNamePrefix="form__select"
                onChange={handleChange}
                value={startPage}
                options={pages}
                noOptionsMessage={() => <CustomReactSelectNoOptions />}
            />
        </div>
    );
};

export default StartPageSwitch;
