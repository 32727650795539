import { executeGetRequest, executePutRequest } from '@api/client';
import { createFetchSamCodingSourcesPath, createUpdateSamCodingSourcesPath } from '@routing/apiUrls';
import { SamCodingSourcesResponse, UpdateSamCodingSourcesMutation, UpdateSamCodingSourcesResponse } from '@models/SamCodingSources';

export const fetchSamCodingSources = async (documentId: number, signal?: AbortSignal): Promise<SamCodingSourcesResponse> => {
    const result = await executeGetRequest<SamCodingSourcesResponse>(createFetchSamCodingSourcesPath(documentId), signal);
    return result.data;
};

export const updateSamCodingSources = async (mutation: UpdateSamCodingSourcesMutation): Promise<UpdateSamCodingSourcesResponse> => {
    const result = await executePutRequest<UpdateSamCodingSourcesResponse>(createUpdateSamCodingSourcesPath(mutation), {});
    return result.data;
};
