import AttachmentIcon from '@images/svg/attachment.svg?react';
import MailIcon from '@images/svg/mail.svg?react';
import DotsIcon from '@images/svg/dots.svg?react';
import CalendarIcon from '@images/svg/calendar.svg?react';
import UserGroupIcon from '@images/svg/userGroup.svg?react';
import HistoryIcon from '@images/svg/history.svg?react';
import FileFullIcon from '@images/svg/fileFull.svg?react';
import PencilIcon from '@images/svg/pencil.svg?react';
import BinIcon from '@images/svg/bin.svg?react';
import AnnotationsIcon from '@images/svg/annotations.svg?react';
import GraduationIcon from '@images/svg/graduation.svg?react';
import RepairIcon from '@images/svg/wrench.svg?react';
import UsersIcon from '@images/svg/users.svg?react';
import CrownIcon from '@images/svg/crown.svg?react';
import TemplateIcon from '@images/svg/template.svg?react';
import { ReactNode, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DefaultAction, DefaultActionId } from '@models/DocumentActions';
import useFetchDocumentFile from '@hooks/useFetchDocumentFile';
import usePerformDocumentAction from '@hooks/usePerformDocumentAction';
import useFetchDocumentActions from '@components/features/actionBar/hooks/useFetchDocumentActions';
import { local } from '@utils/storage';
import { StorageKeys } from '@constants/storageKeys';
import { decodeToken } from '@repository/accessTokenRepository';
import useFetchCurrentUser from '@components/features/currentUser/hooks/useFetchCurrentUser';
import useApplyOcrTraining from '@components/features/ocrTraining/hooks/useApplyOcrTraining';
import { useHotkeys } from 'react-hotkeys-hook';
import { APPLY_OCR_TRAINING, BACK_TO_GROUP, DELETE_OCR_TRAINING, OCR_TRAINING_SQL, PARK_DOCUMENT, SAVE_FILE, SHOW_HISTORY } from '@constants/hotkeys';
import useFetchOcrTraining from '@components/features/ocrTraining/hooks/useFetchOcrTraining';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { annotationModeAtom } from '@js/store/annotationAtoms';
import { disableDocumentAtom, redPencilModeAtom } from '@js/store/documentAtoms';
import useTrackEvent from '@hooks/useTrackEvent';
import useFetchDocumentHeaders from '@components/features/documentHeaders/hooks/useFetchDocumentHeaders';
import { ToggleListGroup } from '@components/primitives/toggleList/ToggleList';
import { featureFlagRedPencil, featureFlagTemplateSharing, featureFlagTemplateSharingManagement } from '@utils/featureFlags';
import useSharedTemplatesList from '@components/features/templateSharing/hooks/useSharedTemplatesList';
import useFetchSuperVisorActions from '@components/features/superVisor/hooks/useFetchSuperVisorActions';
import usePerformSuperVisorAction from '@components/features/superVisor/hooks/usePerformSuperVisorAction';
import { AiTwotoneEdit } from 'react-icons/ai';
import { openEditDocumentModalAtom } from '@js/store/openEditDocumentModalAtom';
import { FaRegThumbsDown, FaRegThumbsUp } from 'react-icons/fa';
import useConfirmSharedTemplate from '@components/features/templateSharing/hooks/useConfirmSharedTemplate';
import useDeclineSharedTemplate from '@components/features/templateSharing/hooks/useDeclineSharedTemplate';
import { currentTemplateAtom, isApplyingSharedTemplateAtom } from '@js/store/templateSharingAtoms';
import Throbber from '@components/primitives/throbber/Throbber';
import { createDocumentPath } from '@routing/appUrls';
import { useNavigate } from 'react-router-dom';
import { addPagesToDocumentModalAtom } from '@js/store/modals/addPagesToDocumentModalAtom';
import { deleteOcrTrainingModalAtom } from '@js/store/modals/deleteOcrTrainingModalAtom';
import { documentAuditModalAtom } from '@js/store/modals/documentAuditModalAtom';
import { emailDocumentModalAtom } from '@js/store/modals/emailDocumentModalAtom';
import { repairDocumentModalAtom } from '@js/store/modals/repairDocumentModalAtom';
import { performDocumentActionModalAtom } from '@js/store/modals/performDocumentActionModalAtom';
import { ocrTrainingLogModalAtom } from '@js/store/modals/ocrTrainingLogModalAtom';
import { ocrTrainingSqlModalAtom } from '@js/store/modals/ocrTrainingSqlModalAtom';
import { templateSharingManagementModalAtom } from '@js/store/modals/templateSharingManagementModalAtom';

export type DocumentButton = {
    id: DefaultActionId;
    title: string;
    onClick: (id: DefaultActionId | string) => void;
    onSuccess?: (id: DefaultActionId) => void;
    icon?: ReactNode;
    enabled?: boolean;
    isHidden?: boolean;
    list?: ToggleListGroup[];
    className?: string;
    showInPreviewModal?: boolean;
    showOnDocumentPage?: boolean;
    trackOpenList?: () => void;
};

type Response = {
    documentButtons: DocumentButton[];
};

const useDocumentButtons = (
    documentId: number,
    ocrTrainingEnabled: boolean,
    ocrTrainingAllowed: boolean,
    listType: string,
    isPreviewMode?: boolean,
): Response => {
    const { data: user } = useFetchCurrentUser();
    const { data: documentActions } = useFetchDocumentActions(documentId);
    const { mutate: applyOcrTraining, isPending: isApplyingOcrTraining } = useApplyOcrTraining(documentId);
    const { data: file } = useFetchDocumentFile(documentId);
    const { t } = useTranslation();
    const { mutate: performDocumentAction } = usePerformDocumentAction(listType, documentId);
    const { data: ocrTraining } = useFetchOcrTraining(ocrTrainingEnabled, documentId);
    const token = local.getItem(StorageKeys.AccessToken);
    const decodedToken = token ? decodeToken(token) : null;
    const tokenUser = decodedToken?.user ? JSON.parse(decodedToken?.user) : null;
    const { data: documentHeaders } = useFetchDocumentHeaders(documentId);
    const { trackEvent } = useTrackEvent();
    const setOcrTrainingSqlModal = useSetAtom(ocrTrainingSqlModalAtom);
    const setDeleteOcrTrainingModal = useSetAtom(deleteOcrTrainingModalAtom);
    const [annotationMode, toggleAnnotationMode] = useAtom(annotationModeAtom);
    const setDisableDocument = useSetAtom(disableDocumentAtom);
    const setDocumentAuditModal = useSetAtom(documentAuditModalAtom);
    const setAddPagesToDocumentModal = useSetAtom(addPagesToDocumentModalAtom);
    const setEmailDocumentModal = useSetAtom(emailDocumentModalAtom);
    const setPerformDocumentActionModal = useSetAtom(performDocumentActionModalAtom);
    const setOcrTrainingLogModal = useSetAtom(ocrTrainingLogModalAtom);
    const setRepairDocumentModal = useSetAtom(repairDocumentModalAtom);
    const setTemplateSharingManagementModal = useSetAtom(templateSharingManagementModalAtom);
    const [redPencilMode, toggleRedPencilMode] = useAtom(redPencilModeAtom);
    const documentInReadonlyMode = documentHeaders?.meta?.readOnly || false;
    const templateSharingEnabled = featureFlagTemplateSharing && ocrTrainingEnabled;
    const { sharedTemplatesList, isLoading: isLoadingSharedTemplates } = useSharedTemplatesList(ocrTrainingEnabled, documentId);
    const { data: superVisorActions } = useFetchSuperVisorActions(documentId);
    const { mutate: performSuperVisorAction } = usePerformSuperVisorAction(
        listType,
        documentId,
        isPreviewMode,
    );
    const setEditDocumentModal = useSetAtom(openEditDocumentModalAtom);
    const isApplyingSharedTemplate = useAtomValue(isApplyingSharedTemplateAtom);

    const supportedSuperVisorActions = ['SupervisorDelete', 'SupervisorAssign'];
    const superVisorButtons: ToggleListGroup = superVisorActions ? superVisorActions.supervisorActions
        .filter((action) => supportedSuperVisorActions.includes(action.id))
        .map((action) => {
            const title = {
                SupervisorDelete: t('superVisorActions.delete', { ns: 'documentActions' }),
                SupervisorAssign: t('superVisorActions.assign', { ns: 'documentActions' }),
            }[action.id] || '';

            const Icon = {
                SupervisorDelete: BinIcon,
                SupervisorAssign: UsersIcon,
            }[action.id] || undefined;

            const actionId = {
                SupervisorDelete: 'delete',
                SupervisorAssign: 'assign',
            }[action.id] || '';

            return {
                ...action,
                title,
                showInPreviewModal: true,
                icon: Icon ? <Icon /> : undefined,
                onClick: () => {
                    setPerformDocumentActionModal({
                        isOpen: true,
                        data: {
                            title,
                            documentId,
                            listType,
                            action,
                            buttonLabel: title,
                            returnValue: true,
                            onSubmit: (formData: any, closeModal: () => void) => {
                                performSuperVisorAction({
                                    id: action.id,
                                    userId: formData.userId,
                                    remark: formData.remark,
                                }, {
                                    onSuccess: () => {
                                        trackEvent({
                                            category: 'supervisorActions',
                                            action: actionId,
                                            value: documentId,
                                        });
                                        closeModal();
                                        toast.success(t('toastSuccess', { ns: 'workflowActions', action: title, documentId }));
                                    },
                                });
                            },
                        },
                    });
                },
            };
        }) : [];

    const customSuperVisorButtons: ToggleListGroup = superVisorButtons ? [
        {
            id: 'SupervisorRepair',
            enabled: true,
            title: t('repairDocument.button', { ns: 'documentActions' }),
            showInPreviewModal: true,
            icon: <RepairIcon />,
            onClick: () => {
                setRepairDocumentModal({
                    isOpen: true,
                    data: {
                        documentId,
                    },
                });
            },
        },
    ] : [];

    useEffect(() => {
        setDisableDocument(isApplyingOcrTraining);
    }, [isApplyingOcrTraining]);

    const getDefaultActionById = (id: DefaultActionId | string): DefaultAction | undefined => {
        return documentActions?.defaultActions.find((defaultAction: DefaultAction) => {
            return defaultAction.id === id;
        });
    };

    const actionIsEnabled = (id: DefaultActionId): boolean => {
        const defaultAction = getDefaultActionById(id);
        if (!defaultAction) {
            return true;
        }

        return defaultAction.enabled;
    };

    const actionIsHidden = (id: DefaultActionId): boolean => {
        const defaultAction = getDefaultActionById(id);

        if (!defaultAction) {
            return true;
        }

        if (id === DefaultActionId.BackToGroup) {
            if (tokenUser?.GroupFetchManual) {
                return defaultAction ? defaultAction.isHidden : false;
            }

            return true;
        }

        return defaultAction.isHidden;
    };
    const currentTemplate = useAtomValue(currentTemplateAtom);
    const { mutate: confirmSharedTemplate } = useConfirmSharedTemplate(documentId);
    const { mutate: declineSharedTemplate } = useDeclineSharedTemplate(documentId);

    const onApplyOcrTraining = () => {
        if (!ocrTrainingEnabled) {
            return;
        }
        trackEvent({
            category: 'ocrTraining',
            action: 'apply-training',
            value: documentId,
        });
        applyOcrTraining(undefined, {
            onSuccess: () => {
                toast.success(t('apply.toastSuccess', { ns: 'ocrTraining' }));
            },
        });
    };

    const onDeleteOcrTraining = () => {
        if (!ocrTrainingEnabled || !ocrTraining?.fields.length) {
            return;
        }
        setDeleteOcrTrainingModal({
            isOpen: true,
            data: {
                documentId,
            },
        });
    };

    const onOcrTrainingSql = () => {
        if (!ocrTrainingEnabled || !ocrTraining?.fields.length) {
            return;
        }
        setOcrTrainingSqlModal({
            isOpen: true,
            data: {
                documentId,
            },
        });
    };

    const onOcrTrainingLog = () => {
        if (!ocrTrainingEnabled) {
            return;
        }
        setOcrTrainingLogModal({
            isOpen: true,
            data: {
                documentId,
            },
        });
    };

    const onParkDocument = () => {
        if (!actionIsEnabled(DefaultActionId.Park) || actionIsHidden(DefaultActionId.Park)) {
            return;
        }

        const action = getDefaultActionById(DefaultActionId.Park);

        if (!action) {
            return;
        }
        setPerformDocumentActionModal({
            isOpen: true,
            data: {
                title: t('park.button', { ns: 'documentActions' }),
                documentId,
                listType,
                action,
                buttonLabel: t('park.modal.button', { ns: 'documentActions' }),
                onSuccess: () => {
                    toast.success(t('park.toastSuccess', { ns: 'documentActions', documentId }));
                },
            },
        });
    };

    const onBackToGroup = () => {
        if (!actionIsEnabled(DefaultActionId.BackToGroup) || actionIsHidden(DefaultActionId.BackToGroup)) {
            return;
        }
        performDocumentAction({
            documentId,
            actionId: DefaultActionId.BackToGroup,
        }, {
            onSuccess: () => {
                toast.success(t('backToGroup.toastSuccess', { ns: 'documentActions', documentId }));
            },
        });
    };

    const onShowHistory = () => {
        setDocumentAuditModal({
            isOpen: true,
            data: {
                documentId,
            },
        });
    };

    const onSaveFile = () => {
        if (!file?.url) {
            toast.error(t('saveAsFile.toastError', { ns: 'documentActions' }));
            return;
        }
        window.location.href = file.url;
    };

    const navigate = useNavigate();
    const navigateToDocument = () => {
        navigate(createDocumentPath(documentId, true));
    };

    const onEditDocument = () => {
        navigateToDocument();
        setEditDocumentModal({
            isOpen: true,
            data: {
                documentId,
            },
        });
    };

    useHotkeys(
        PARK_DOCUMENT,
        () => {
            onParkDocument();
        },
        [documentActions],
    );

    useHotkeys(BACK_TO_GROUP, () => {
        onBackToGroup();
    }, [documentActions]);

    useHotkeys(SHOW_HISTORY, () => {
        onShowHistory();
    }, [documentActions]);

    useHotkeys(SAVE_FILE, () => {
        if (!file) {
            return;
        }

        onSaveFile();
    }, [documentActions, file]);

    useHotkeys(APPLY_OCR_TRAINING, () => {
        onApplyOcrTraining();
    }, [documentActions, ocrTrainingEnabled, ocrTraining]);

    useHotkeys(DELETE_OCR_TRAINING, () => {
        onDeleteOcrTraining();
    }, [documentActions, ocrTrainingEnabled, ocrTraining]);

    useHotkeys(OCR_TRAINING_SQL, () => {
        onOcrTrainingSql();
    }, [documentActions, ocrTrainingEnabled, ocrTraining]);

    const [votedForSharedTemplate, setVotedForSharedTemplate] = useState(false);

    useEffect(() => {
        setVotedForSharedTemplate(false);
    }, [documentId, currentTemplate?.id]);

    const documentButtons = [
        {
            id: DefaultActionId.TemplateSharing,
            title: t('title', { ns: 'templateSharing' }),
            onClick: () => undefined,
            icon: isApplyingSharedTemplate || isLoadingSharedTemplates
                ? <Throbber className="!m-0" delayInMs={0} />
                : <span className={`text-[22px] text-green-400 ${!sharedTemplatesList?.length && 'opacity-50'}`}>&#x2B22;</span>,
            enabled: templateSharingEnabled && !isApplyingSharedTemplate && !isLoadingSharedTemplates && sharedTemplatesList?.length > 0,
            isHidden: !templateSharingEnabled,
            showInPreviewModal: false,
            list: [sharedTemplatesList],
            trackOpenList: () => {
                trackEvent({
                    category: 'template-sharing',
                    action: 'open-list',
                });
            },
        },
        {
            id: DefaultActionId.ConfirmSharedTemplate,
            title: 'Confirm shared template',
            onClick: () => {
                if (!currentTemplate) return;
                confirmSharedTemplate({
                    templateId: currentTemplate?.id,
                });
                setVotedForSharedTemplate(true);
            },
            icon: <FaRegThumbsUp className="text-[32px]" />,
            enabled: templateSharingEnabled && !isApplyingSharedTemplate && !!currentTemplate && !votedForSharedTemplate,
            isHidden: !templateSharingEnabled,
            showInPreviewModal: false,
        },
        {
            id: DefaultActionId.DeclineSharedTemplate,
            title: 'Decline shared template',
            onClick: () => {
                if (!currentTemplate) return;
                declineSharedTemplate({
                    templateId: currentTemplate?.id,
                });
                setVotedForSharedTemplate(true);
            },
            icon: <FaRegThumbsDown className="text-[32px]" />,
            enabled: templateSharingEnabled && !isApplyingSharedTemplate && !!currentTemplate && !votedForSharedTemplate,
            isHidden: !templateSharingEnabled,
            showInPreviewModal: false,
        },
        {
            id: DefaultActionId.AddPages,
            title: t('addPages.button', { ns: 'documentActions' }),
            onClick: () => {
                setAddPagesToDocumentModal({
                    isOpen: true,
                    data: {
                        documentId,
                    },
                });
            },
            icon: <AttachmentIcon className="topBar__action-svg--large" />,
            enabled: true,
            isHidden: false,
        },
        {
            id: DefaultActionId.Email,
            title: t('email.button', { ns: 'documentActions' }),
            onClick: () => {
                setEmailDocumentModal({
                    isOpen: true,
                    data: {
                        documentId,
                    },
                });
            },
            icon: <MailIcon className="topBar__action-svg--large" />,
            enabled: actionIsEnabled(DefaultActionId.Email),
            isHidden: !user?.allowSendMail,
            showInPreviewModal: true,
        },
        {
            id: DefaultActionId.AnnotationMode,
            title: t('annotationMode.button', { ns: 'documentActions' }),
            onClick: () => toggleAnnotationMode(),
            icon: <AnnotationsIcon />,
            enabled: true,
            isHidden: false,
            className: annotationMode ? 'topBar__action--active' : undefined,
        },
        {
            id: DefaultActionId.RedPencil,
            title: t('redPencilMode.button', { ns: 'documentActions' }),
            onClick: () => toggleRedPencilMode(),
            icon: <PencilIcon />,
            enabled: true,
            isHidden: !featureFlagRedPencil,
            className: redPencilMode ? 'topBar__action--active' : undefined,
        },
        {
            id: DefaultActionId.OcrTraining,
            title: t('ocrTraining.button', { ns: 'documentActions' }),
            onClick: () => undefined,
            icon: <GraduationIcon />,
            className: 'topBar__action--training',
            enabled: ocrTrainingAllowed && !documentInReadonlyMode && ocrTrainingEnabled,
            isHidden: !ocrTrainingAllowed,
            list: [
                [
                    {
                        id: DefaultActionId.OcrTrainingApply,
                        title: t('apply.button', { ns: 'ocrTraining' }),
                        subTitle: APPLY_OCR_TRAINING,
                        onClick: () => onApplyOcrTraining(),
                        enabled: !documentInReadonlyMode,
                        isHidden: !ocrTrainingAllowed,
                    },
                    {
                        id: DefaultActionId.OcrTrainingDelete,
                        title: t('delete', { ns: 'ocrTraining' }),
                        subTitle: DELETE_OCR_TRAINING,
                        onClick: () => onDeleteOcrTraining(),
                        enabled: !!ocrTraining?.fields.length && !documentInReadonlyMode,
                        isHidden: !ocrTrainingAllowed,
                    },
                    {
                        id: DefaultActionId.OcrTrainingSql,
                        title: t('sql.button', { ns: 'ocrTraining' }),
                        subTitle: OCR_TRAINING_SQL,
                        onClick: () => onOcrTrainingSql(),
                        enabled: !!ocrTraining?.fields.length && !documentInReadonlyMode,
                        isHidden: !ocrTrainingAllowed,
                    },
                ],
                [
                    {
                        id: DefaultActionId.OcrTrainingLog,
                        title: t('log.button', { ns: 'ocrTraining' }),
                        onClick: () => onOcrTrainingLog(),
                        enabled: ocrTrainingAllowed && ocrTrainingEnabled,
                        isHidden: !ocrTrainingAllowed,
                    },
                ],
            ],
        },
        ...(superVisorButtons && superVisorButtons.length > 0 ? [{
            id: DefaultActionId.SuperVisorActions,
            title: t('superVisorActions.button', { ns: 'documentActions' }),
            onClick: () => undefined,
            icon: <CrownIcon className="topBar__action-svg--xlarge" />,
            enabled: true,
            isHidden: false,
            showInPreviewModal: true,
            showOnDocumentPage: false,
            list: [
                superVisorButtons,
                customSuperVisorButtons,
            ],
        }] : []),
        {
            id: DefaultActionId.MoreActions,
            title: t('moreActions.button', { ns: 'documentActions' }),
            onClick: () => undefined,
            icon: <DotsIcon />,
            enabled: true,
            isHidden: false,
            showInPreviewModal: true,
            list: [
                [
                    {
                        id: DefaultActionId.Park,
                        title: t('park.button', { ns: 'documentActions' }),
                        subTitle: PARK_DOCUMENT,
                        onClick: () => onParkDocument(),
                        icon: <CalendarIcon />,
                        enabled: actionIsEnabled(DefaultActionId.Park),
                        isHidden: actionIsHidden(DefaultActionId.Park),
                    },
                    {
                        id: DefaultActionId.BackToGroup,
                        title: t('backToGroup.button', { ns: 'documentActions' }),
                        subTitle: BACK_TO_GROUP,
                        onClick: () => onBackToGroup(),
                        icon: <UserGroupIcon />,
                        enabled: actionIsEnabled(DefaultActionId.BackToGroup),
                        isHidden: actionIsHidden(DefaultActionId.BackToGroup),
                    },
                ],
                [
                    {
                        id: DefaultActionId.History,
                        title: t('history.button', { ns: 'documentActions' }),
                        subTitle: SHOW_HISTORY,
                        onClick: () => onShowHistory(),
                        icon: <HistoryIcon />,
                        enabled: true,
                        isHidden: false,
                        showInPreviewModal: true,
                    },
                    {
                        id: DefaultActionId.EditDocument,
                        title: t('editDocument.button', { ns: 'documentActions' }),
                        onClick: () => onEditDocument(),
                        icon: <AiTwotoneEdit />,
                        enabled: true,
                        isHidden: false,
                        showInPreviewModal: false,
                    },
                    {
                        id: DefaultActionId.SaveAsFile,
                        title: t('saveAsFile.button', { ns: 'documentActions' }),
                        subTitle: SAVE_FILE,
                        onClick: () => onSaveFile(),
                        icon: <FileFullIcon />,
                        enabled: true,
                        isHidden: false,
                        showInPreviewModal: true,
                    },
                ],
                [
                    {
                        id: DefaultActionId.TemplateSharingManagement,
                        title: t('management.title', { ns: 'templateSharing' }),
                        onClick: () => {
                            setTemplateSharingManagementModal({
                                isOpen: true,
                                data: {
                                    documentId,
                                },
                            });
                        },
                        icon: <TemplateIcon />,
                        enabled: featureFlagTemplateSharingManagement,
                        isHidden: !featureFlagTemplateSharingManagement,
                        showInPreviewModal: false,
                    },
                ],
                [
                    {
                        id: DefaultActionId.Delete,
                        title: t('delete.button', { ns: 'documentActions' }),
                        onClick: () => {
                            const action = getDefaultActionById(DefaultActionId.Delete);

                            if (!action) {
                                return;
                            }
                            setPerformDocumentActionModal({
                                isOpen: true,
                                data: {
                                    title: t('delete.button', { ns: 'documentActions' }),
                                    documentId,
                                    listType,
                                    action,
                                    buttonLabel: t('delete.modal.button', { ns: 'documentActions' }),
                                    onSuccess: () => {
                                        toast.success(t('delete.toastSuccess', { ns: 'documentActions', documentId }));
                                    },
                                },
                            });
                        },
                        icon: <BinIcon />,
                        enabled: actionIsEnabled(DefaultActionId.Delete),
                        isHidden: actionIsHidden(DefaultActionId.Delete),
                    },
                ],
            ],
        },
    ];

    return {
        documentButtons,
    };
};

export default useDocumentButtons;
