import { FC } from 'react';
import { DocumentOcrSummary } from '@models/DocumentOcrSummary';
import OcrRect from '@components/features/ocr/components/OcrRect';
import useHeaderCaret from '@hooks/caret/useHeaderCaret';
import useCodingCaret from '@hooks/caret/useCodingCaret';
import useInvoiceLinesCaret from '@hooks/caret/useInvoiceLinesCaret';
import useDocumentCoding from '@components/features/coding/hooks/useDocumentCoding';

interface Props {
    documentId: number;
    page: number;
    documentOcrSummary?: DocumentOcrSummary;
    fileWidth: number;
    fileHeight: number;
}
const HighlightedOcr: FC<Props> = ({ documentId, page, documentOcrSummary, fileWidth, fileHeight }) => {
    const { getCodingLineFieldValue } = useDocumentCoding(documentId);
    const { headerCaret } = useHeaderCaret();
    const { codingCaret } = useCodingCaret();
    const { invoiceLinesCaret } = useInvoiceLinesCaret();

    const getHighlightedOcrId = (): string | undefined => {
        const caretOnHeaderFields = !!headerCaret;
        const caretOnCodingFields = !!codingCaret;
        const caretOnMatchingFields = !!invoiceLinesCaret;
        const activeCodingLine = caretOnCodingFields && codingCaret?.lineId ? getCodingLineFieldValue(codingCaret.lineId, 'ocrrow') : undefined;

        if (!headerCaret && !codingCaret && !invoiceLinesCaret) {
            return undefined;
        }

        return caretOnHeaderFields
            ? headerCaret?.fieldId
            : caretOnCodingFields
                ? `lineitem_${codingCaret.fieldId}_${activeCodingLine}`
                : caretOnMatchingFields
                    ? `lineitem_${invoiceLinesCaret?.fieldId}_${invoiceLinesCaret?.lineId}`
                    : undefined;
    };

    const ocrItem = documentOcrSummary?.list.find((item) => item.id === getHighlightedOcrId() && item.page === page);

    if (!ocrItem?.zone) {
        return null;
    }

    return (
        <OcrRect
            scrollToElement
            zone={ocrItem.zone}
            title={ocrItem.value as string}
            className="ocr__rectangle ocr__rectangle--active"
            fileWidth={fileWidth}
            fileHeight={fileHeight}
        />
    );
};
export default HighlightedOcr;
