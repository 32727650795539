import DataGrid, { COLUMN_ID_QUICKMENU, COLUMN_ID_SELECT } from '@components/primitives/dataGrid/DataGrid';
import { createColumnHelper } from '@tanstack/react-table';
import React, { FC, MouseEvent, useEffect, useMemo, useRef } from 'react';
import PlusIcon from '@images/svg/plus.svg?react';
import MagicIcon from '@images/svg/magic.svg?react';
import useFetchDocumentCoding from '@components/features/coding/hooks/useFetchDocumentCoding';
import classNames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import { redPencilModeAtom } from '@js/store/documentAtoms';
import { useTranslation } from 'react-i18next';
import useCodingGridEffects from '@components/features/coding/hooks/useCodingGridEffects';
import { StorageKeys } from '@constants/storageKeys';
import Button from '@components/primitives/button/Button';
import { Sizes } from '@utils/sizes';
import EditableCell from '@components/primitives/dataGrid/components/EditableCell';
import useCodingCaret from '@hooks/caret/useCodingCaret';
import useFetchOcrTraining from '@components/features/ocrTraining/hooks/useFetchOcrTraining';
import useDocumentCoding from '@components/features/coding/hooks/useDocumentCoding';
import EditableCellTrainingButton from '@components/primitives/dataGrid/components/EditableCellTrainingButton';
import { OcrTainingContext } from '@models/OcrTraining';
import useLookupErrors from '@js/store/lookupErrors/useLookupErrors';
import useRedPencilEffects from '@components/features/coding/hooks/useRedPencilEffects';
import { createStorageKey } from '@utils/storage';
import { currentDocClassAtom } from '@js/store/appAtoms';
import QuickMenu, { QuickMenuItem } from '@components/primitives/dataGrid/components/QuickMenu';
import useCodingUpdater from '@components/features/coding/hooks/useCodingUpdater';
import useSetCodingFieldProperties from '@components/features/coding/hooks/useSetCodingFieldProperties';
import { ocrCodingTrainingModalAtom } from '@js/store/modals/ocrCodingTrainingModalAtom';
import CopyCodingButton from '@components/features/coding/components/CopyCodingButton';
import AddCodingLineButton from '@components/features/coding/components/AddCodingLineButton';
import DeleteCodingLineButton from '@components/features/coding/components/DeleteCodingLineButton';

interface Props {
    documentId: number;
    isPreviewMode: boolean;
    ocrTrainingEnabled: boolean;
}

const Coding: FC<Props> = ({ documentId, isPreviewMode, ocrTrainingEnabled }) => {
    const currentDocClass = useAtomValue(currentDocClassAtom);
    const { codingCaret, setCodingCaret, resetCodingCaret } = useCodingCaret();
    const redPencilMode = useAtomValue(redPencilModeAtom);
    const setOcrCodingTrainingModal = useSetAtom(ocrCodingTrainingModalAtom);
    const { redPencilField, onRedPencilFieldChange } = useRedPencilEffects(documentId);
    const { data: ocrTraining } = useFetchOcrTraining(ocrTrainingEnabled, documentId);
    const { data: documentCoding } = useFetchDocumentCoding(documentId);
    const { t } = useTranslation();
    const codingRef = useRef(null);
    const isReadOnlyMode = documentCoding?.meta.readOnly || false;
    const { lookupErrors, unregisterLookupError } = useLookupErrors();
    const { getCodingLineField } = useDocumentCoding(documentId);
    const {
        onAddLine,
        addingLine,
        onDeleteLine,
        deletingLine,
        onCellClick,
        rowSelection,
        setRowSelection,
        onLastFieldBlur,
    } = useCodingGridEffects(documentId, isReadOnlyMode, codingRef);
    const { handleCodingFieldPropertiesOnInit } = useSetCodingFieldProperties(documentId);
    const { onCodingFieldInit, onCodingFieldChange } = useCodingUpdater(documentId);

    const onRowButton = async () => {
        await onAddLine();
        if (codingCaret) {
            resetCodingCaret();
        }
    };

    // Unregister lookupErrors when codingLines disappear
    useEffect(() => {
        if (!documentId || !documentCoding || !lookupErrors.length) {
            return;
        }

        const codingLineIds = documentCoding.rows.map((row) => {
            const rowValue = row.values.find((field) => field.id === 'row');
            return rowValue?.value;
        });

        lookupErrors.forEach((lookupError) => {
            if (!codingLineIds.includes(lookupError.lineId)) {
                unregisterLookupError(`Line with id ${lookupError.lineId} doesn't exist anymore`, lookupError.lineId);
            }
        });
    }, [documentCoding, lookupErrors]);

    const data = useMemo(() => {
        if (!documentCoding) {
            return [];
        }

        return documentCoding.rows.map((row) => {
            return row.values.reduce((result, field) => ({
                ...result,
                [field.id.toLowerCase()]: field.value,
            }), {});
        });
    }, [documentCoding]);

    const onOcrCodingTraining = (row: any) => {
        setOcrCodingTrainingModal({
            isOpen: true,
            data: {
                documentId,
                row,
            },
        });
    };

    const getQuickMenuItems = (): QuickMenuItem[] => {
        if (isPreviewMode || !!documentCoding?.meta.readOnly) {
            return [];
        }

        return [
            {
                title: t('coding.modal.title', { ns: 'ocrTraining' }),
                Icon: MagicIcon,
                onClick: (row: any) => {
                    onOcrCodingTraining(row);
                },
            },
        ];
    };

    const columnHelper = createColumnHelper<any>();
    const columns = useMemo(() => {
        if (!documentCoding?.definitions) {
            return [];
        }

        return [
            ...(documentCoding?.definitions
                .filter((column) => column.title)
                .filter((column) => !column.isHidden)
                .map((column) => {
                    return columnHelper.accessor(column.id.toLowerCase(), {
                        header: column.title,
                        enableSorting: false,
                        enableColumnFilter: false,
                        meta: {
                            documentId,
                            fieldDefinition: column,
                            name: column.id,
                            format: column.format,
                            showAsText: isPreviewMode,
                            codingAccrualsEnabled: documentCoding?.meta.codingAccrualsEnabled,
                            ...(column.id === redPencilField && redPencilMode ? {
                                onFieldChange: onRedPencilFieldChange,
                            } : {}),
                        },
                        cell: EditableCell,
                    });
                })),
            columnHelper.display({
                id: COLUMN_ID_QUICKMENU,
                meta: {
                    className: 'quickMenu',
                },
                cell: (props) => {
                    return (
                        <QuickMenu
                            backgroundColor={props.row.original.wf_highlightcolor}
                            items={getQuickMenuItems()}
                            cellProps={props}
                        />
                    );
                },
                size: 120,
                enableResizing: false,
            }),
        ];
    }, [documentCoding, redPencilMode]);

    const selectedLines = useMemo(() => {
        return Object.keys(rowSelection).reduce((result: any[], index) => {
            return [
                ...result,
                data[+index],
            ];
        }, []).map((row) => row.row);
    }, [rowSelection]);

    const linesAreSelected = (): boolean => {
        return !!selectedLines.length;
    };

    return (
        <div className="coding">
            {!isPreviewMode ? (
                <div className="buttonStrip grid__buttons">
                    <AddCodingLineButton
                        addingLine={addingLine}
                        deletingLine={deletingLine}
                        isReadOnlyMode={isReadOnlyMode}
                        onAddLine={onAddLine}
                    />
                    <DeleteCodingLineButton
                        addingLine={addingLine}
                        deletingLine={deletingLine}
                        isReadOnlyMode={isReadOnlyMode}
                        linesAreSelected={linesAreSelected()}
                        onDeleteLine={() => onDeleteLine(selectedLines)}
                    />
                    <CopyCodingButton
                        documentId={documentId}
                        isReadOnlyMode={isReadOnlyMode}
                    />
                </div>
            ) : null}
            <div
                className={classNames('coding__grid', {
                    'coding__grid--redPencil': redPencilMode,
                })}
                ref={codingRef}
            >
                <DataGrid
                    pageSize={25}
                    className="dataGrid--noFilters-selectableRows dataGrid--ocrTraining dataGrid--hasQuickMenu"
                    columns={columns}
                    data={data}
                    name={`${documentId}_codingLines`}
                    onFieldUpdated={onCodingFieldChange}
                    onLastFieldBlur={onLastFieldBlur}
                    columnHelper={columnHelper}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    enableRowSelection={() => !isReadOnlyMode}
                    storageKey={createStorageKey([currentDocClass, StorageKeys.GridCoding])}
                    selectedRow={codingCaret?.lineId}
                    selectedCell={codingCaret?.fieldId}
                    onFieldInit={(lineId, fieldId, value) => {
                        handleCodingFieldPropertiesOnInit(lineId, fieldId, value);
                        onCodingFieldInit(lineId, fieldId, value);
                    }}
                    onCellSelect={(lineId, fieldId) => {
                        setCodingCaret({
                            lineId,
                            fieldId,
                        });
                    }}
                    getField={(lineId, fieldId) => {
                        return getCodingLineField(lineId, fieldId);
                    }}
                    getHeaderComponent={(fieldDefinition) => {
                        const fieldTraining = ocrTraining?.fields.find((field) => {
                            return field.fieldId === `lineitem_${fieldDefinition.name}_0`;
                        });

                        if (!ocrTrainingEnabled || !ocrTraining?.templateId) {
                            return null;
                        }

                        return (
                            <EditableCellTrainingButton
                                documentId={documentId}
                                fieldDefinition={fieldDefinition}
                                trainingField={{
                                    format: fieldDefinition.format,
                                    fieldId: `lineitem_${fieldDefinition.name}_0`,
                                    field: fieldTraining,
                                    label: fieldDefinition.title,
                                    context: OcrTainingContext.Coding,
                                    coding: {
                                        lineId: 0,
                                        fieldId: fieldDefinition.id,
                                    },
                                }}
                            />
                        );
                    }}
                    getRowProps={(row, selectedRow) => {
                        const classes = classNames('dataGrid__tr', {
                            'dataGrid__tr--active': selectedRow === row.original.row,
                            'dataGrid__tr--previewMode': isPreviewMode,
                        });

                        return {
                            className: classes,
                        };
                    }}
                    getCellProps={(cellInfo, selectedRow, selectedCell) => {
                        const lineId = cellInfo.row.original.row;
                        const { name, format, className } = cellInfo.column.columnDef.meta;
                        const classes = className || classNames('dataGrid__cell', {
                            'dataGrid__cell--select': cellInfo.column.id === COLUMN_ID_SELECT,
                            'dataGrid__cell--decimal': format === 'decimal',
                            'dataGrid__cell--active': selectedCell === name && selectedRow === lineId,
                            'dataGrid__cell--ocrTraining': ocrTrainingEnabled,
                        });

                        return {
                            className: classes,
                            ...(!isPreviewMode ? {
                                onClick: (e: MouseEvent<HTMLDivElement>) => {
                                    if (e.target instanceof HTMLDivElement || e.target instanceof HTMLSpanElement) {
                                        // Prevent activating cell when clicking on training button
                                        if (e.target.classList && e.target.classList.contains('fieldOcrTrainingIcon')) {
                                            return;
                                        }
                                        onCellClick(cellInfo.row, cellInfo, selectedRow, selectedCell);
                                    }
                                },
                            } : {}),
                        };
                    }}
                />
                {!data.length && !isReadOnlyMode && !isPreviewMode ? (
                    <div className="coding__emptyMessage">
                        <Button
                            onClick={() => onAddLine()}
                            isLoading={addingLine}
                            secondary
                            size={Sizes.lg}
                            icon={addingLine ? null : <PlusIcon />}
                        >
                            {t('noItems.button', { ns: 'coding' })}
                        </Button>
                    </div>
                ) : !isReadOnlyMode && !isPreviewMode ? (
                    <button tabIndex={-1} type="button" className="coding__rowButton" onClick={onRowButton} disabled={addingLine || deletingLine} />
                ) : null}
            </div>
        </div>
    );
};

export default Coding;
