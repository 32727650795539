import { useMatomo } from '@datapunt/matomo-tracker-react';
import { TrackEventParams } from '@datapunt/matomo-tracker-react/lib/types';
import useFetchCurrentUser from '@components/features/currentUser/hooks/useFetchCurrentUser';

const useTrackEvent = () => {
    const { data: user } = useFetchCurrentUser();
    const { trackEvent: trackEventMatomo } = useMatomo();

    const trackEvent = (params: TrackEventParams) => {
        trackEventMatomo({
            ...params,
            customDimensions: [
                {
                    id: 1,
                    value: user?.erp || 'unknown',
                },
                {
                    id: 2,
                    value: user?.userType.toString() || 'unknown',
                },
            ],
        });
    };

    return {
        trackEvent,
    };
};

export default useTrackEvent;
