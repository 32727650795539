import PageContent from '@components/primitives/pageContent/PageContent';
import PageContentBlock from '@components/primitives/pageContent/PageContentBlock';
import FormRow from '@components/primitives/form/FormRow';
import LanguageSwitch from '@components/features/languageSwitch/LanguageSwitch';
import StartPageSwitch from '@components/features/startPageSwitch/StartPageSwitch';
import ThemeSwitch from '@components/features/themeSwitch/ThemeSwitch';
import Replacements from '@components/features/replacements/Replacements';
import SamSettings from '@components/features/sam/SamSettings';
import { FC } from 'react';
import useFetchVersion from '@hooks/useFetchVersion';
import { useTranslation } from 'react-i18next';
import useFetchCurrentUser from '@components/features/currentUser/hooks/useFetchCurrentUser';

const GeneralSettingsTab: FC = () => {
    const { t } = useTranslation();
    const { data: version } = useFetchVersion();
    const { data: user } = useFetchCurrentUser();
    return (
        <PageContent>
            <PageContentBlock title={t('applicationSettings', { ns: 'settings' })}>
                <FormRow name="language" label={t('language.label')}>
                    <LanguageSwitch />
                </FormRow>
                <FormRow name="startPage" label={t('startPage.label', { ns: 'settings' })}>
                    <StartPageSwitch />
                </FormRow>
                <FormRow name="theme" label={t('theme.label', { ns: 'settings' })}>
                    <ThemeSwitch />
                </FormRow>
            </PageContentBlock>
            <PageContentBlock title={t('absence', { ns: 'settings' })}>
                <Replacements />
            </PageContentBlock>
            {version?.samTenantLevel && version.samTenantLevel > 0 && user?.userType === 2 ? (
                <PageContentBlock title={t('sam.sectionTitle', { ns: 'settings' })}>
                    <SamSettings />
                </PageContentBlock>
            ) : null}
        </PageContent>
    );
};

export default GeneralSettingsTab;
