import { Dispatch, FC, SetStateAction } from 'react';
import ChevronLeftIcon from '@images/svg/chevron-left.svg?react';
import classNames from 'classnames';
import useCopyOrderLine from '@components/features/matching/hooks/useCopyOrderLine';
import { useTranslation } from 'react-i18next';
import { RowSelectionState } from '@tanstack/react-table';

interface Props {
    documentId: number;
    selectedLines: string[];
    setRowSelection: Dispatch<SetStateAction<RowSelectionState>>;
    isReadOnly: boolean;
}

const CopyButton: FC<Props> = ({ documentId, selectedLines, setRowSelection, isReadOnly }) => {
    const { t } = useTranslation();
    const { mutate: copyOrderLine, isPending: isCopyingOrderLine } = useCopyOrderLine(documentId);
    const copyButtonClasses = classNames('buttonStrip__button', {
        'buttonStrip__button--loading': isCopyingOrderLine,
    });

    const onCopy = async () => {
        if (!selectedLines.length) {
            return;
        }

        copyOrderLine({
            matchKeys: selectedLines,
        }, {
            onSuccess: () => {
                setRowSelection({});
            },
        });
    };

    return (
        <button
            type="button"
            title={t('actions.copy', { ns: 'matching' })}
            className={copyButtonClasses}
            onClick={onCopy}
            disabled={isCopyingOrderLine || !selectedLines.length || isReadOnly}
        >
            <ChevronLeftIcon className="xs" />
        </button>
    );
};

export default CopyButton;
