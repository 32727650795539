import { atom } from 'jotai';
import { closedModalState, ModalState } from '@js/store/modalAtoms';
import { Folder } from '@components/pages/settingsPage/components/tabs/importProfiles/ImportProfilesTab';

export interface EwsSelectFolderModalData {
    title: string;
    folders: Folder[];
    selectedFolder: Folder | undefined;
    onSelect: (folder: Folder) => void;
}

export const ewsSelectFolderModalAtom = atom<ModalState<EwsSelectFolderModalData>>(closedModalState);