import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { deleteOcrTrainingField } from '@repository/ocrTrainingRepository';

const useDeleteOcrTrainingField = (documentId: number) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: (fieldId: string) => deleteOcrTrainingField(documentId, fieldId),
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [QueryKey.OcrTraining, documentId],
            });
        },
    });
};

export default useDeleteOcrTrainingField;
