import { FC, ReactNode, useEffect } from 'react';
import useFetchCurrentUser from '@components/features/currentUser/hooks/useFetchCurrentUser';
import { useNavigate } from 'react-router-dom';
import Throbber from '@components/primitives/throbber/Throbber';
import { createListPath, PagePath } from '@routing/appUrls';
import log, { LogContext } from '@utils/logging';

interface Props {
    children: ReactNode;
    roles?: Roles[];
}

export enum Roles {
    AllowFileHandler = 'AllowFileHandler',
}

const ProtectedRoute: FC<Props> = ({ children, roles }) => {
    const { isLoading, data: user } = useFetchCurrentUser();
    const navigate = useNavigate();
    const allRoles = [
        {
            id: Roles.AllowFileHandler,
            condition: user?.allowFilehandler,
        },
    ];
    const isLoggedIn = !!user;

    const userHasPermission = (): boolean => {
        let permission = true;
        roles?.forEach((role) => {
            if (!permission) {
                return;
            }

            const roleSettings = allRoles.find((r) => r.id === role);
            if (!roleSettings) {
                console.warn(`Role "${role}" is not supported.`);
                return;
            }

            permission = !!roleSettings.condition;
        });

        return permission;
    };

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (!isLoggedIn) {
            log({
                context: LogContext.User,
                title: 'Redirect to login page',
                reason: 'User is not logged in',
            });
            navigate(PagePath.login);
        }

        // Check roles
        if (!userHasPermission()) {
            log({
                context: LogContext.User,
                title: 'Redirect to list page',
                reason: 'User has no permission',
            });
            navigate(createListPath());
        }
    }, [isLoading, isLoggedIn, roles]);

    if (isLoading) {
        return (
            <Throbber />
        );
    }

    if (!isLoggedIn || !userHasPermission()) {
        return null;
    }

    return (
        <>{children}</>
    );
};

export default ProtectedRoute;
