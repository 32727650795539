import { FC, useEffect } from 'react';
import { useRouteError, isRouteErrorResponse, useNavigate } from 'react-router-dom';
import useFetchCurrentUser from '@components/features/currentUser/hooks/useFetchCurrentUser';
import Heading from '@components/primitives/heading/Heading';
import Layout from '@components/primitives/layout/Layout';
import Content from '@components/primitives/content/Content';
import PageHead from '@components/primitives/pageHead/PageHead';
import PageContent from '@components/primitives/pageContent/PageContent';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import usePageTitle from '@hooks/usePageTitle';
import { PagePath } from '@routing/appUrls';

const ErrorBoundary: FC = () => {
    const error = useRouteError() as Error;
    const { createPageTitle } = usePageTitle();
    const navigate = useNavigate();
    const { data: user } = useFetchCurrentUser();
    const { t } = useTranslation();

    const title = error?.message || t('errorBoundary.title');
    const content = error?.stack || t('errorBoundary.message');

    const isRouteError = isRouteErrorResponse(error);

    useEffect(() => {
        if (isRouteError) {
            navigate(PagePath.dashboard);
        }
    }, [isRouteError]);

    const errorComponent = !user ? (
        <>
            <div>
                <Heading>{t('errorBoundary.title')}</Heading>
                <strong>{title}</strong><br /><br />
                <code>{content}</code>
            </div>
        </>
    ) : (
        <div className="app">
            <Layout>
                <Content isLight>
                    <PageHead title={t('errorBoundary.title')} />
                    <PageContent>
                        <strong>{title}</strong><br /><br />
                        <code>{content}</code>
                    </PageContent>
                </Content>
            </Layout>
        </div>
    );

    return (
        <>
            <Helmet>
                <title>{createPageTitle(title)}</title>
            </Helmet>
            {errorComponent}
        </>
    );
};

export default ErrorBoundary;
