import * as React from "react";
const SvgAppstore = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 119.7 40", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M110.1 0H7.5a13.2 13.2 0 0 0-2 .2 6.7 6.7 0 0 0-1.9.6A6.4 6.4 0 0 0 2 2 6.3 6.3 0 0 0 .8 3.6a6.6 6.6 0 0 0-.6 2 13 13 0 0 0-.2 2v24.9a13 13 0 0 0 .2 2 6.6 6.6 0 0 0 .6 1.9A6.2 6.2 0 0 0 2 38a6.3 6.3 0 0 0 1.6 1.2 6.7 6.7 0 0 0 2 .6 13.5 13.5 0 0 0 2 .2h104.6a13.3 13.3 0 0 0 2-.2 6.8 6.8 0 0 0 1.8-.6 6.3 6.3 0 0 0 1.7-1.2 6.4 6.4 0 0 0 1.1-1.6 6.6 6.6 0 0 0 .7-2 13.5 13.5 0 0 0 .2-2V7.6a13.5 13.5 0 0 0-.2-2 6.6 6.6 0 0 0-.7-1.9A6.5 6.5 0 0 0 116 .8a6.8 6.8 0 0 0-1.9-.6 13 13 0 0 0-2-.2h-1.9Z", style: {
  fill: "#a6a6a6"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8.4 39.1h-.9a12.7 12.7 0 0 1-1.8-.1 5.9 5.9 0 0 1-1.7-.6 5.4 5.4 0 0 1-1.4-1 5.3 5.3 0 0 1-1-1.4 5.7 5.7 0 0 1-.5-1.7 12.4 12.4 0 0 1-.2-1.8v-25A12.4 12.4 0 0 1 1 5.7 5.8 5.8 0 0 1 1.6 4a5.4 5.4 0 0 1 1-1.4 5.6 5.6 0 0 1 1.4-1 5.8 5.8 0 0 1 1.7-.5A12.6 12.6 0 0 1 7.5.9h104.7a12.4 12.4 0 0 1 1.8.2 6 6 0 0 1 1.7.5A5.6 5.6 0 0 1 118 4a5.8 5.8 0 0 1 .5 1.7 13 13 0 0 1 .2 1.9v24.9a12.7 12.7 0 0 1-.2 1.8 5.7 5.7 0 0 1-.5 1.7 5.5 5.5 0 0 1-1 1.4 5.4 5.4 0 0 1-1.4 1 5.9 5.9 0 0 1-1.7.6 12.5 12.5 0 0 1-1.9.1h-1.9Z" }), /* @__PURE__ */ React.createElement("g", { "data-name": "<Group>" }, /* @__PURE__ */ React.createElement("g", { "data-name": "<Group>" }, /* @__PURE__ */ React.createElement("path", { "data-name": "<Path>", d: "M24.8 20.3a5 5 0 0 1 2.3-4.2 5 5 0 0 0-4-2.1c-1.6-.2-3.3 1-4.1 1-.9 0-2.2-1-3.6-1a5.3 5.3 0 0 0-4.5 2.8c-2 3.3-.5 8.2 1.4 11 .9 1.3 2 2.7 3.4 2.7s1.9-.9 3.6-.9c1.6 0 2.1.9 3.5.9 1.5 0 2.5-1.4 3.4-2.7a11 11 0 0 0 1.5-3.1 4.8 4.8 0 0 1-3-4.4ZM22 12.2a4.9 4.9 0 0 0 1.2-3.5 5 5 0 0 0-3.3 1.7 4.6 4.6 0 0 0-1.1 3.3 4.1 4.1 0 0 0 3.2-1.5Z", style: {
  fill: "#fff"
} })), /* @__PURE__ */ React.createElement("path", { d: "M42.3 27.1h-4.7l-1.2 3.4h-2L39 18.1h2l4.6 12.4h-2Zm-4.2-1.5h3.7L40 20ZM55.2 26c0 2.8-1.5 4.6-3.8 4.6a3 3 0 0 1-2.9-1.6v4.5h-1.9v-12h1.8v1.4a3.2 3.2 0 0 1 3-1.6c2.2 0 3.8 1.9 3.8 4.7Zm-2 0c0-1.9-.9-3-2.3-3-1.5 0-2.4 1.2-2.4 3s1 3 2.4 3 2.3-1.2 2.3-3ZM65.1 26c0 2.8-1.5 4.6-3.8 4.6a3 3 0 0 1-2.8-1.6v4.5h-1.9v-12h1.8v1.4a3.2 3.2 0 0 1 3-1.6c2.2 0 3.7 1.9 3.7 4.7Zm-1.9 0c0-1.9-1-3-2.4-3s-2.4 1.2-2.4 3 1 3 2.4 3c1.5 0 2.4-1.2 2.4-3ZM71.7 27c.1 1.3 1.3 2 3 2 1.5 0 2.7-.7 2.7-1.8 0-1-.7-1.6-2.3-2l-1.6-.4c-2.3-.5-3.4-1.6-3.4-3.3 0-2.2 1.9-3.6 4.6-3.6 2.6 0 4.4 1.4 4.4 3.6h-1.8c-.2-1.3-1.2-2-2.7-2s-2.5.8-2.5 1.9c0 .8.7 1.4 2.3 1.7l1.3.4c2.6.6 3.6 1.6 3.6 3.4 0 2.3-1.8 3.8-4.8 3.8-2.7 0-4.6-1.4-4.7-3.7ZM83.3 19.3v2.1h1.8V23h-1.8v5c0 .8.4 1.1 1.1 1.1a5.8 5.8 0 0 0 .7 0v1.5a5.1 5.1 0 0 1-1 0c-2 0-2.6-.6-2.6-2.4v-5.2h-1.3v-1.5h1.3v-2.1ZM86 26c0-2.9 1.7-4.7 4.4-4.7 2.6 0 4.3 1.8 4.3 4.7 0 2.8-1.7 4.6-4.3 4.6-2.7 0-4.3-1.8-4.3-4.6Zm6.8 0c0-2-1-3.1-2.4-3.1S88 24 88 25.9c0 2 .9 3.2 2.4 3.2s2.4-1.2 2.4-3.1ZM96.2 21.4H98V23a2.2 2.2 0 0 1 2.2-1.7 2.9 2.9 0 0 1 .6.1v1.8a2.6 2.6 0 0 0-.8-.2 1.9 1.9 0 0 0-2 2.1v5.4h-1.8ZM109.4 27.8c-.3 1.7-1.9 2.8-4 2.8-2.5 0-4.2-1.8-4.2-4.6s1.7-4.7 4.2-4.7 4 1.8 4 4.5v.6h-6.3v.1a2.4 2.4 0 0 0 2.4 2.6 2 2 0 0 0 2.1-1.3Zm-6.3-2.7h4.5a2.2 2.2 0 0 0-2.2-2.3 2.3 2.3 0 0 0-2.3 2.3Z", style: {
  fill: "#fff"
} })), /* @__PURE__ */ React.createElement("g", { "data-name": "<Group>" }, /* @__PURE__ */ React.createElement("path", { d: "M37.8 8.7a2.6 2.6 0 0 1 2.8 3c0 1.9-1 3-2.8 3h-2.1v-6ZM36.6 14h1.1a1.9 1.9 0 0 0 2-2.2 1.9 1.9 0 0 0-2-2.1h-1.1ZM41.7 12.4a2.1 2.1 0 1 1 4.2 0 2.1 2.1 0 1 1-4.2 0Zm3.3 0c0-1-.4-1.5-1.2-1.5-.8 0-1.2.6-1.2 1.5 0 1 .4 1.6 1.2 1.6.8 0 1.2-.6 1.2-1.6ZM51.6 14.7h-1l-.9-3.3-1 3.3h-.9l-1.2-4.5h.9l.8 3.4 1-3.4h.8l1 3.4.8-3.4h1ZM53.9 10.2h.8v.7a1.3 1.3 0 0 1 1.4-.8 1.5 1.5 0 0 1 1.6 1.7v2.9h-1V12c0-.7-.2-1-.9-1a1 1 0 0 0-1 1v2.7h-1ZM59 8.4h1v6.3h-1ZM61.2 12.4a2.1 2.1 0 1 1 4.3 0 2.1 2.1 0 1 1-4.3 0Zm3.4 0c0-1-.5-1.5-1.3-1.5-.7 0-1.2.6-1.2 1.5 0 1 .5 1.6 1.2 1.6.8 0 1.3-.6 1.3-1.6ZM66.4 13.4c0-.8.6-1.3 1.7-1.3h1.2v-.5c0-.5-.3-.7-1-.7-.4 0-.8.2-.9.5h-.8c0-.8.8-1.3 1.8-1.3 1.1 0 1.8.6 1.8 1.5v3.1h-.9v-.6a1.5 1.5 0 0 1-1.4.7 1.4 1.4 0 0 1-1.5-1.4Zm2.9-.4v-.3h-1.1c-.6 0-1 .3-1 .7 0 .4.4.6 1 .6a1 1 0 0 0 1.1-1ZM71.3 12.4c0-1.4.8-2.3 2-2.3a1.5 1.5 0 0 1 1.3.8V8.4h1v6.3h-.9V14a1.6 1.6 0 0 1-1.5.8c-1.1 0-1.9-1-1.9-2.4Zm1 0c0 1 .4 1.6 1.2 1.6.7 0 1.2-.6 1.2-1.6 0-.9-.5-1.5-1.2-1.5-.8 0-1.2.6-1.2 1.5ZM79.2 12.4a2.1 2.1 0 1 1 4.3 0 2.1 2.1 0 1 1-4.3 0Zm3.4 0c0-1-.5-1.5-1.2-1.5-.8 0-1.3.6-1.3 1.5 0 1 .5 1.6 1.3 1.6.7 0 1.2-.6 1.2-1.6ZM84.7 10.2h.8v.7a1.3 1.3 0 0 1 1.4-.8 1.5 1.5 0 0 1 1.6 1.7v2.9h-.9V12c0-.7-.3-1-1-1a1 1 0 0 0-1 1v2.7h-1ZM93.5 9v1.2h1v.8h-1v2.3c0 .5.2.7.7.7a3 3 0 0 0 .3 0v.7a3 3 0 0 1-.5 0c-1 0-1.4-.3-1.4-1.2V11H92v-.8h.7V9.1ZM95.7 8.4h.9V11a1.4 1.4 0 0 1 1.4-.8 1.5 1.5 0 0 1 1.6 1.7v2.9h-1V12c0-.7-.2-1-.9-1a1 1 0 0 0-1.1 1v2.7h-.9ZM104.8 13.5a1.8 1.8 0 0 1-2 1.3 2 2 0 0 1-2-2.3 2 2 0 0 1 2-2.4c1.3 0 2 .9 2 2.3v.3h-3.2a1.2 1.2 0 0 0 1.2 1.3 1 1 0 0 0 1.1-.5Zm-3.2-1.5h2.3a1 1 0 0 0-1.1-1.1 1.2 1.2 0 0 0-1.2 1.1Z", style: {
  fill: "#fff"
} })));
export default SvgAppstore;
