import { Document } from '@models/Document';
import { FC } from 'react';

interface Props {
    document?: Document;
}

const DocumentTitle: FC<Props> = ({ document }) => {
    if (!document?.title) {
        return null;
    }

    if (!document.title.indexOf('\r6814')) {
        return <>{document.title}</>;
    }

    const [companyName, invoiceNr] = document.title.split('\r');

    return (
        <>
            <span className="topBar__title-company">{companyName}</span>&nbsp;
            <span className="topBar__title-invoiceNr">{invoiceNr}</span>
        </>
    );
};

export default DocumentTitle;
