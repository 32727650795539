import { RefObject, useEffect, useState } from 'react';
import { RowSelectionState } from '@tanstack/react-table';
import { COLUMN_ID_SELECT } from '@components/primitives/dataGrid/DataGrid';
import useInvoiceLinesCaret from '@hooks/caret/useInvoiceLinesCaret';
import useOrderLinesCaret from '@hooks/caret/useOrderLinesCaret';
import useDocumentInvoiceLines from '@components/features/documentInvoiceLines/hooks/useDocumentInvoiceLines';

const useOrderLinesGridEffects = (documentId: number, orderLinesRef: RefObject<HTMLDivElement>) => {
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const { documentInvoiceLines } = useDocumentInvoiceLines(documentId);
    const { setInvoiceLinesCaret } = useInvoiceLinesCaret();
    const { orderLinesCaret, setOrderLinesCaret } = useOrderLinesCaret();

    const isActiveLine = (id: string): boolean => {
        return orderLinesCaret?.lineId === id;
    };

    const scrollToOrderLine = () => {
        if (!orderLinesRef?.current) {
            return;
        }
        const activeRowEl = document.querySelector('.orderLines__grid .row--active');

        if (!activeRowEl) {
            return;
        }

        const fromTop = orderLinesRef.current.offsetTop + 40; // Plus height of tabs

        orderLinesRef.current.scrollTo({
            // @ts-ignore
            top: activeRowEl.offsetTop - fromTop,
            left: 0,
            behavior: 'smooth',
        });
    };

    const onCellClick = (row: any, cell: any) => {
        if (cell.column.id === COLUMN_ID_SELECT) {
            return;
        }
        if (isActiveLine(row.original.matchkey)) {
            return;
        }

        setOrderLinesCaret({
            lineId: row.original.matchkey,
        });

        const line = documentInvoiceLines?.rows?.find((invoiceLine) => {
            const matchKeyField = invoiceLine.values.find((field) => field.id === 'matchkey');

            if (!matchKeyField) {
                return false;
            }

            return matchKeyField.value === row.original.matchkey;
        });

        if (!line?.values) {
            return;
        }

        const lineIdField = line.values.find((field) => field.id === 'row');

        if (typeof lineIdField?.value !== 'number') {
            return;
        }
        setInvoiceLinesCaret({
            lineId: lineIdField.value as number,
        });
    };

    useEffect(() => {
        if (!orderLinesCaret?.lineId) {
            return;
        }
        scrollToOrderLine();
    }, [orderLinesCaret]);

    return {
        onCellClick,
        rowSelection,
        setRowSelection,
    };
};

export default useOrderLinesGridEffects;
