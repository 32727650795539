import { atom } from 'jotai';
import { Sizes } from '@utils/sizes';

export const showAsyncConfirmModalAtom = atom<boolean>(false);

export interface AsyncConfirmModalProps {
    title?: string;
    message: string;
    size?: Sizes;
}
export const asyncConfirmModalPropsAtom = atom<AsyncConfirmModalProps | null>(null);

interface AsyncConfirmModalButtonHandlers {
    onConfirm: () => void;
    onCancel: () => void;
}
export const asyncConfirmModalButtonHandlersAtom = atom<AsyncConfirmModalButtonHandlers>({
    onConfirm: () => {},
    onCancel: () => {},
});
