import { useAtom, useSetAtom } from 'jotai';
import { currentTemplateAtom, isApplyingSharedTemplateAtom } from '@js/store/templateSharingAtoms';
import useFetchSharedTemplates from '@components/features/templateSharing/hooks/useFetchSharedTemplates';
import useApplySharedTemplate from '@components/features/templateSharing/hooks/useApplySharedTemplate';
import useDeclineSharedTemplate from '@components/features/templateSharing/hooks/useDeclineSharedTemplate';
import { Template } from '@models/SharedTemplates';
import { useEffect } from 'react';
import useTrackEvent from '@hooks/useTrackEvent';

const useSharedTemplatesList = (ocrTrainingEnabled: boolean, documentId: number) => {
    const [currentTemplate, setCurrentTemplate] = useAtom(currentTemplateAtom);
    const setIsApplyingSharedTemplate = useSetAtom(isApplyingSharedTemplateAtom);
    const { data, isLoading } = useFetchSharedTemplates(documentId);
    const { mutate: applySharedTemplate, isPending: isApplyingSharedTemplate } = useApplySharedTemplate(ocrTrainingEnabled, documentId);
    const { mutate: declineSharedTemplate } = useDeclineSharedTemplate(documentId);
    const { trackEvent } = useTrackEvent();

    useEffect(() => {
        setIsApplyingSharedTemplate(isApplyingSharedTemplate);
    }, [isApplyingSharedTemplate]);

    const onTemplateClick = (clickedTemplate: Template) => {
        trackEvent({
            category: 'template-sharing',
            action: clickedTemplate.id === currentTemplate?.id ? 'decline' : 'apply',
        });

        if (clickedTemplate.id === currentTemplate?.id) {
            // remove template
            declineSharedTemplate({ templateId: clickedTemplate.id }, {
                onSuccess: () => {
                    setCurrentTemplate(null);
                },
            });
        } else {
            // apply template
            applySharedTemplate({ templateId: clickedTemplate.id }, {
                onSuccess: () => {
                    setCurrentTemplate(clickedTemplate);
                },
            });
        }
    };

    const sharedTemplatesList = data?.templates?.length
        ? data.templates.map((template: Template) => ({
            id: template.id,
            title: template.name,
            onClick: () => onTemplateClick(template),
            enabled: currentTemplate?.id !== template.id && !isApplyingSharedTemplate,
        }))
        : [];

    return {
        sharedTemplatesList,
        isLoading,
    };
};
export default useSharedTemplatesList;
