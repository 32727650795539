import * as Sentry from '@sentry/react';

interface SentryUser {
    id?: string;
    email?: string;
    username?: string;
}

const setSentryUser = (user: SentryUser | null) => {
    Sentry.setUser(user);
};

const useSentry = () => {
    return {
        setSentryUser,
    };
};

export default useSentry;
