export const UPLOAD_FILES_HOTKEYS = 'Shift+Alt+U';
export const ROTATE_PAGES_HOTKEYS = 'Shift+Alt+R';
export const MERGE_FILES_HOTKEYS = 'Shift+Alt+M';
export const SPLIT_FILE_HOTKEYS = 'Shift+Alt+S';
export const EMAIL_PAGES_HOTKEYS = 'Shift+Alt+E';
export const DELETE_PAGES_HOTKEYS = 'Delete';
export const PROCESS_FILES_HOTKEYS = 'Shift+Alt+P';
export const SELECT_ALL_FILES_HOTKEYS = 'Ctrl+A';
export const CANCEL_HOTKEYS = 'Esc';
export const FOCUS_PREVIOUS_FILE_HOTKEYS = 'Up';
export const FOCUS_NEXT_FILE_HOTKEYS = 'Down';
export const SELECT_FOCUSED_FILE_HOTKEYS = 'Space';
export const CLOSE_MODAL_HOTKEYS = 'Esc';
export const DELETE_SELECTED_CODINGLINES = 'Delete';
export const DELETE_SELECTED_INVOICELINES = 'Delete';
export const APPLY_OCR_TRAINING = 'Ctrl+Alt+T';
export const DELETE_OCR_TRAINING = 'Ctrl+Alt+W';
export const OCR_TRAINING_SQL = 'Ctrl+Alt+\'';
export const PARK_DOCUMENT = 'Ctrl+Alt+P';
export const BACK_TO_GROUP = 'Ctrl+Alt+G';
export const SHOW_HISTORY = 'Ctrl+Alt+H';
export const SAVE_FILE = 'Ctrl+Alt+S';
export const CLOSE_TOGGLELIST = 'Esc';

// File handler hotkeys
export const FILE_HANDLER_NAVIGATE_LEFT_HOTKEY = 'ArrowLeft';
export const FILE_HANDLER_NAVIGATE_RIGHT_HOTKEY = 'ArrowRight';
export const FILE_HANDLER_NAVIGATE_UP_HOTKEY = 'ArrowUp';
export const FILE_HANDLER_NAVIGATE_DOWN_HOTKEY = 'ArrowDown';
export const FILE_HANDLER_SEND_TO_ADMINISTRATION_HOTKEY = 'Enter';
export const FILE_HANDLER_SPLIT_DOCUMENT_HOTKEY = 'Space';
export const FILE_HANDLER_DELETE_PAGE_HOTKEY = 'Delete';
export const FILE_HANDLER_DELETE_DOCUMENT_HOTKEY = 'Backspace';
export const FILE_HANDLER_ROTATE_PAGE_HOTKEY = 'R';
export const FILE_HANDLER_ROTATE_DOCUMENT_HOTKEY = 'Shift+R';
