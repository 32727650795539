import { forwardRef, useEffect, useState } from 'react';

interface ToggleProps {
    name: string;
    label?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    defaultChecked?: boolean;
    [key: string]: any;
}

const Toggle = forwardRef<HTMLInputElement, ToggleProps>(({ name, onChange, label = '', checked, defaultChecked = false, ...props }, ref) => {
    const [isChecked, setIsChecked] = useState(defaultChecked);

    const handleToggleChange = (e) => {
        e.preventDefault();
        setIsChecked(oldValue => !oldValue);
        onChange && onChange(e);
    };
    
    useEffect(() => {
        setIsChecked(defaultChecked);
    }, [defaultChecked]);

    return (
        <div
            className="flex items-center space-x-2 cursor-pointer"
            role="button"
            tabIndex={0}
            onClick={handleToggleChange}
        >
            <div className="relative">
                <input
                    id={name}
                    type="checkbox"
                    className="sr-only"
                    checked={isChecked}
                    ref={ref}
                    {...props}
                />
                <div className={`block w-14 h-8 rounded-full ${isChecked ? 'bg-brand-primary' : 'bg-border-color'}`} />
                <div className={`dot absolute top-1 bg-white w-6 h-6 rounded-full !transition !transform ${isChecked ? 'right-1' : 'left-1'}`} />
            </div>
            <span className="text-gray-700">{label}</span>
        </div>
    );
});

export default Toggle;