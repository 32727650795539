import { FC, memo } from 'react';
import { DocumentOcrLine, DocumentOcrWord } from '@models/DocumentOcrData';
import OcrRect from '@components/features/ocr/components/OcrRect';
import classNames from 'classnames';

interface Props {
    ocrLine: DocumentOcrLine;
    page: number;
    onOcrClick?: (ocrItem: DocumentOcrWord) => void;
    selectedOcrItems: DocumentOcrWord[];
    isDragging: boolean;
    fileWidth: number;
    fileHeight: number;
    shiftPressed?: boolean;
    // eslint-disable-next-line react/no-unused-prop-types
    rerenderDependency?: any[];
}

const OcrLine: FC<Props> = ({ page, ocrLine, onOcrClick, selectedOcrItems, isDragging, fileWidth, fileHeight, shiftPressed }) => {
    if (!ocrLine.words.length) {
        return null;
    }

    return (
        <>
            {ocrLine.words.map((ocrWord) => {
                const selectedOcrItem = selectedOcrItems.find((item) => item.page === ocrWord.page && item.text === ocrWord.text && item.zone === ocrWord.zone);
                const zones = ocrWord.zone.split(/\s/u);
                const [, , width, height] = zones;
                const handleClick = () => {
                    if (!onOcrClick) {
                        return;
                    }

                    onOcrClick({
                        ...ocrWord,
                        page,
                    });
                };

                const classes = classNames('ocr__rectangle', {
                    'ocr__rectangle--isSelected': !!selectedOcrItem,
                    'ocr__rectangle--large': +width > 500 && +height > 1200,
                });
                return (
                    <OcrRect
                        showToolTip={isDragging ? false : !selectedOcrItems.length}
                        title={ocrWord.text}
                        className={classes}
                        key={ocrWord.zone}
                        zone={ocrWord.zone}
                        onClick={handleClick}
                        fileWidth={fileWidth}
                        fileHeight={fileHeight}
                        shiftPressed={shiftPressed}
                    />
                );
            })}
        </>
    );
};

const propsAreEqual = (prevProps: Props, nextProps: Props) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};
export default memo(OcrLine, propsAreEqual);
