import { StorageKeys } from '@constants/storageKeys';
import { createStorageKey } from '@utils/storage';
import { atom } from 'jotai';
import { SearchDocumentResponse } from '@models/SearchDocument';
import { atomWithStorage } from 'jotai/utils';

export interface SearchOperators {
    [key: string]: string;
}

export const SearchOperatorsAtom = atomWithStorage<SearchOperators>(
    createStorageKey([StorageKeys.SearchOperators]),
    {},
);

export const SearchValuesAtom = atomWithStorage<SearchOperators>(
    createStorageKey([StorageKeys.SearchValues]),
    {},
);

export const SearchResultAtom = atom<SearchDocumentResponse | undefined>(undefined);
