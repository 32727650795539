import useFetchDocClasses from '@components/features/docClass/hooks/useFetchDocClasses';
import { useAtomValue } from 'jotai';
import { currentDocClassAtom } from '@js/store/appAtoms';

const useDocClasses = () => {
    const { data } = useFetchDocClasses();
    const currentDocClass = useAtomValue(currentDocClassAtom);
    const currentDocClassItem = data?.list.find((docClassItem) => docClassItem.name === currentDocClass);

    const docClasses = data ? data.list : [];

    const otherDocClasses = docClasses.filter((docClassItem) => docClassItem.name !== currentDocClass);

    return {
        currentDocClassItem,
        docClasses,
        otherDocClasses,
    };
};

export default useDocClasses;
