import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { minutesToMillis } from '@utils/millis';
import { fetchSuperVisorActions } from '@repository/superVisorRepository';
import useFetchCurrentUser from '@components/features/currentUser/hooks/useFetchCurrentUser';

const useFetchSuperVisorActions = (documentId: number) => {
    const { data: user } = useFetchCurrentUser();

    return useQuery({
        queryKey: [QueryKey.SuperVisorActions, documentId],
        queryFn: ({ signal }) => fetchSuperVisorActions(documentId, signal),
        staleTime: minutesToMillis(30),
        retry: 0,
        enabled: user?.allowSupervisor,
    });
};

export default useFetchSuperVisorActions;
