import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@components/primitives/button/Button';
import ModalFooter from '@components/primitives/modal/components/ModalFooter';
import useFetchDocument from '@hooks/useFetchDocument';
import PreviewContent from '@components/modals/previewModal/components/PreviewContent';
import ModalContent from '@components/primitives/modal/components/ModalContent';
import Message from '@components/primitives/message/Message';
import useWorkflowActions from '@components/features/workflowActions/hooks/useWorkflowActions';
import { createDocumentPath } from '@routing/appUrls';
import { useNavigate } from 'react-router-dom';
import { closedModalState } from '@js/store/modalAtoms';
import { AxiosError } from 'axios';
import { useSetAtom } from 'jotai';
import { documentPreviewModalAtom, DocumentPreviewModalData } from '@js/store/modals/documentPreviewModalAtom';

interface Props {
    modalData: DocumentPreviewModalData;
}

const PreviewModalInner: FC<Props> = ({ modalData }) => {
    const { documentId, listType } = modalData;
    const setModalState = useSetAtom(documentPreviewModalAtom);
    const { t } = useTranslation();
    const { data: document, error, isLoading } = useFetchDocument(documentId, false);
    const { onActionClick } = useWorkflowActions(listType, documentId);
    const navigate = useNavigate();

    const closeModal = () => {
        setModalState(closedModalState);
    };

    const navigateToDocument = () => {
        closeModal();
        navigate(createDocumentPath(documentId, true));
    };

    const onEditDocument = async () => {
        if (document?.meta.canEdit) {
            navigateToDocument();
            return;
        }

        if (!document?.meta.actionTakeOwnerShip) {
            return;
        }

        await onActionClick(documentId, {
            id: document?.meta.actionTakeOwnerShip,
            enabled: true,
            title: document?.meta.actionTakeOwnerShip,
            askConfirmation: false,
            remarkEnabled: false,
            remarkRequired: false,
            showAsButton: false,
            userChoices: [],
            adviceRequestor: {
                id: '',
                name: '',
            },
            useValidation: false,
            useDynamicAssign: false,
        }, () => {
            navigateToDocument();
        }, t('preview.modal.editDocument.toastSuccess', { ns: 'list' }));
    };

    return (
        <>
            {isLoading || !document || (error instanceof AxiosError && error?.response?.status === 400) ? (
                <ModalContent isLoading={isLoading}>
                    <Message isNoItems>{t('preview.modal.error.message', { ns: 'list' })}</Message>
                </ModalContent>
            ) : (
                <PreviewContent
                    document={document}
                    listType={listType}
                />
            )}
            <ModalFooter>
                <Button primary onClick={() => closeModal()}>{t('close')}</Button>
                {document?.meta.canEdit || (document?.meta.canTakeOwnerShip && document?.meta.actionTakeOwnerShip) ? (
                    <Button onClick={onEditDocument}>{t('preview.modal.editDocument.button', { ns: 'list' })}</Button>
                ) : null}
            </ModalFooter>
        </>
    );
};

export default PreviewModalInner;
