import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { fetchDocumentAnnotations } from '@repository/documentAnnotationsRepository';
import { DocumentAnnotations } from '@models/DocumentAnnotations';

const useFetchAnnotations = (documentId: number): UseQueryResult<DocumentAnnotations> => {
    return useQuery({
        queryKey: [QueryKey.DocumentAnnotations, documentId],
        queryFn: ({ signal }) => fetchDocumentAnnotations(documentId, signal),
        staleTime: 0,
    });
};

export default useFetchAnnotations;
