import { FC, MutableRefObject } from 'react';
import Annotation from '@components/features/annotations/Annotation';
import { DocumentAnnotation } from '@models/DocumentAnnotations';

interface Props {
    annotations?: DocumentAnnotation[];
    documentId: number;
    page: number;
    wrapperRef: MutableRefObject<HTMLDivElement | null>;
    readOnly?: boolean;
    fileWidth: number;
    fileHeight: number;
}

const Annotations: FC<Props> = ({ annotations, documentId, wrapperRef, page, readOnly, fileWidth, fileHeight }) => {
    if (!annotations?.length) {
        return null;
    }

    return (
        <>
            {annotations.map((annotation) => (
                <Annotation
                    readOnly={readOnly}
                    wrapperRef={wrapperRef}
                    key={annotation.id}
                    annotation={annotation}
                    documentId={documentId}
                    page={page}
                    fileWidth={fileWidth}
                    fileHeight={fileHeight}
                />
            ))}
        </>
    );
};

export default Annotations;
