import { createDocumentPath, createListPath, DEFAULT_LIST_TYPE, PagePath } from '@routing/appUrls';
import { useNavigate } from 'react-router-dom';
import useDocumentStatus from '@hooks/useDocumentStatus';
import useDataGridState from '@components/primitives/dataGrid/hooks/useDataGridState';
import { useAtomValue, useSetAtom } from 'jotai';
import { currentDocClassAtom } from '@js/store/appAtoms';
import { createStorageKey } from '@utils/storage';
import { StorageKeys } from '@constants/storageKeys';
import { currentTemplateAtom } from '@js/store/templateSharingAtoms';
import useDocListInfo from '@hooks/useDocListInfo';
import { documentPreviewModalAtom } from '@js/store/modals/documentPreviewModalAtom';

const useDocumentNavigation = (listType: string, isPreviewMode?: boolean) => {
    const setCurrentTemplate = useSetAtom(currentTemplateAtom);
    const currentDocClass = useAtomValue(currentDocClassAtom);
    const navigate = useNavigate();
    const {
        resetColumnFilters,
    } = useDataGridState(createStorageKey([currentDocClass, StorageKeys.GridDocList, DEFAULT_LIST_TYPE]));
    const { nrOfFilteredDocuments, nrOfDocuments, firstDocumentId, getNextDocumentId, getPrevDocumentId } = useDocListInfo(currentDocClass, listType);
    const { isEditMode } = useDocumentStatus();
    const setDocumentPreviewModal = useSetAtom(documentPreviewModalAtom);
    const previewDocument = (documentId: number) => {
        if (!listType) {
            return;
        }

        setDocumentPreviewModal((modalState) => {
            return {
                ...modalState,
                data: {
                    ...modalState.data,
                    documentId,
                    listType,
                },
            };
        });
    };

    const navigateToDocument = (documentId?: number) => {
        if (!documentId) {
            console.warn('No document found.');
            return;
        }
        setCurrentTemplate(null);
        navigate(createDocumentPath(documentId, isEditMode));
    };

    const onAllDocumentsProcessed = () => {
        navigate(PagePath.allDocumentsProcessed);
    };

    const onAllFilteredDocumentsProcessed = () => {
        resetColumnFilters();
        navigate(createListPath(currentDocClass, DEFAULT_LIST_TYPE));
    };

    const firstDocument = () => {
        if (!nrOfDocuments) {
            console.warn('No documents in current list.');
            onAllDocumentsProcessed();
            return;
        }

        if (!nrOfFilteredDocuments) {
            onAllFilteredDocumentsProcessed();
            return;
        }

        navigateToDocument(firstDocumentId || undefined);
    };

    const prevDocument = async (documentId: number) => {
        if (!nrOfDocuments) {
            console.warn('No documents in current list.');
            onAllDocumentsProcessed();
            return;
        }

        if (!nrOfFilteredDocuments) {
            onAllFilteredDocumentsProcessed();
            return;
        }

        const prevDocumentId = getPrevDocumentId(documentId);

        if (typeof prevDocumentId !== 'number') {
            console.warn('No prev document found.');
            return;
        }

        if (isPreviewMode) {
            previewDocument(prevDocumentId);
            return;
        }

        navigateToDocument(prevDocumentId);
    };

    const nextDocument = async (documentId: number) => {
        if (!nrOfDocuments) {
            console.warn('No documents in current list.');
            onAllDocumentsProcessed();
            return;
        }

        if (!nrOfFilteredDocuments) {
            onAllFilteredDocumentsProcessed();
            return;
        }

        const nextDocumentId = getNextDocumentId(documentId);

        if (typeof nextDocumentId !== 'number') {
            console.warn('No next document found.');
            return;
        }

        if (isPreviewMode) {
            previewDocument(nextDocumentId);
            return;
        }

        navigateToDocument(nextDocumentId);
    };

    return {
        prevDocument,
        nextDocument,
        firstDocument,
    };
};

export default useDocumentNavigation;
