import { FC, ReactNode } from 'react';
import DateTimeCell from '@components/primitives/dataGrid/components/DateTimeCell';
import DateCell from '@components/primitives/dataGrid/components/DateCell';
import DecimalCell from '@components/primitives/dataGrid/components/DecimalCell';
import TextCell from '@components/primitives/dataGrid/components/TextCell';
import { Link } from 'react-router-dom';

interface Props {
    format: 'text' | 'time' | 'date' | 'decimal' | 'int' | 'lookup' | 'select' | 'memo',
    to?: string;
    value: string | number;
    decimals?: number;
}

const DataGridCell: FC<Props> = ({ value, format, to, decimals }) => {
    const Parent: FC<{ children: ReactNode }> = ({ children }) => {
        if (to) {
            return (
                <Link to={to}>
                    {children}
                </Link>
            );
        }

        return (
            <>
                {children}
            </>
        );
    };

    if (format === 'time' && typeof value === 'string') {
        return (
            <Parent>
                <DateTimeCell date={value} />
            </Parent>
        );
    }

    if (format === 'date' && typeof value === 'string') {
        return (
            <Parent>
                <DateCell date={value} />
            </Parent>
        );
    }

    if (format === 'decimal') {
        return (
            <Parent>
                <DecimalCell value={value} decimals={decimals} />
            </Parent>
        );
    }

    return (
        <Parent>
            <TextCell value={value} />
        </Parent>
    );
};

export default DataGridCell;
