import { FC, ReactNode, useEffect, useMemo } from 'react';
import {
    CellContext, Column,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel, getSortedRowModel, HeaderContext, OnChangeFn, Row, RowSelectionState,
    useReactTable, VisibilityState,
} from '@tanstack/react-table';
import classNames from 'classnames';
import ReactPaginate from 'react-paginate';
import ArrowIcon from '@images/svg/arrow.svg?react';
import Filter from '@components/primitives/dataGrid/components/filters/Filter';
import fuzzyFilter from '@components/primitives/dataGrid/utils/filters/fuzzyFilter';
import numericFilter from '@components/primitives/dataGrid/utils/filters/numericFilter';
import useDataGridState from '@components/primitives/dataGrid/hooks/useDataGridState';
import numericSorting from '@components/primitives/dataGrid/utils/sorting/numericSorting';
import IndeterminateCheckbox from '@components/primitives/dataGrid/components/IndeterminateCheckbox';
import Button from '@components/primitives/button/Button';
import CrossIcon from '@images/svg/close.svg?react';
import { useTranslation } from 'react-i18next';
import {
    purifyColumnFilters,
    purifyColumnOrder,
    purifySorting,
} from '@components/primitives/dataGrid/utils/purifyDataGridState';
import { useSetAtom } from 'jotai';
import SortIcon from '@components/primitives/dataGrid/components/SortIcon';
import Tooltip from '@components/primitives/tooltip/Tooltip';
import DataGridOptions, { CSVHeader, CSVRow } from '@components/primitives/dataGrid/components/DataGridOptions';
import { DocListState } from '@hooks/useDocListInfo';
import GridSettingsModal from '@components/modals/gridSettingsModal/GridSettingsModal';
import { gridSettingsModalAtom } from '@js/store/modals/gridSettingsModalAtom';

interface Props {
    columns: any;
    data: any;
    name: string;
    className?: string;
    pageSize?: number;
    getRowProps?: (row: Row<any>, selectedRow?: string | number) => object;
    getCellProps?: (cellInfo: any, selectedRow?: string | number, selectedCell?: string | number) => object;
    storageKey?: string;
    disableSettings?: boolean;
    selectedRow?: string | number;
    selectedCell?: string | number;
    onCellSelect?: (lineId: number, fieldId: string) => void;
    onOrderChange?: (state: DocListState) => void;
    rowSelection?: RowSelectionState;
    setRowSelection?: OnChangeFn<RowSelectionState>;
    enableRowSelection?: (row: Row<any>) => boolean;
    columnHelper?: any;
    renderNrOfRows?: (nrOfRows: number, nrOfFilteredRows: number) => ReactNode;
    onFieldUpdated?: (rowIndex: number, columnId: string, value: string | number) => void;
    onFieldInit?: (lineId: number, fieldId: string, value: string) => void;
    getField?: (lineId: number, fieldId: string) => any;
    getHeaderComponent?: (column: any) => ReactNode;
    resetFiltersDependencies?: Array<any>;
    onLastFieldBlur?: (rowIndex: number, firstColumnId: string,) => void;
}

export const COLUMN_ID_SELECT = 'select';
export const COLUMN_ID_QUICKMENU = 'quickMenu';

const DataGrid: FC<Props> = ({
    columns,
    data,
    name,
    className,
    pageSize,
    getRowProps,
    getCellProps,
    storageKey,
    disableSettings,
    selectedRow,
    selectedCell,
    onCellSelect,
    onOrderChange,
    columnHelper,
    rowSelection,
    setRowSelection,
    enableRowSelection,
    renderNrOfRows,
    onFieldUpdated,
    onFieldInit,
    getField,
    getHeaderComponent,
    resetFiltersDependencies,
    onLastFieldBlur,
}) => {
    const setGridSettingsModal = useSetAtom(gridSettingsModalAtom);
    const { t } = useTranslation();
    const {
        sorting,
        setSorting,
        resetSorting,
        columnSizing,
        setColumnSizing,
        columnOrder,
        setColumnOrder,
        columnVisibility,
        setColumnVisibility,
        columnFilters,
        setColumnFilters,
        resetColumnFilters,
        columnFilterValues,
        setColumnFilterValues,
        showFilters,
        setShowFilters,
    } = useDataGridState(storageKey);

    const handleOrderChange = () => {
        if (!onOrderChange) {
            return;
        }
        onOrderChange({
            sorting,
            columnFilters,
        });
    };

    const allColumns = useMemo(() => {
        if (!columnHelper) {
            console.warn('Pass columnHelper.');
            return columns;
        }

        if (!columns.length) {
            return columns;
        }

        return [
            columnHelper.display({
                id: COLUMN_ID_SELECT,
                size: 35,
                meta: {
                    className: 'dataGrid__cell dataGrid__cell--select',
                },
                enableResizing: false,
                header: (info: HeaderContext<any, any>) => (
                    <>
                        {rowSelection ? (
                            <IndeterminateCheckbox
                                className="dataGrid__head-checkbox"
                                {...{
                                    checked: info.table.getIsAllPageRowsSelected(),
                                    indeterminate: info.table.getIsSomePageRowsSelected(),
                                    onChange: info.table.getToggleAllPageRowsSelectedHandler(),
                                    id: `${name ? `${name}_` : ''}${info.header.id}_all`,
                                }}
                            />
                        ) : (
                            <div className="dataGrid__head-checkbox-placeholder" />
                        )}
                    </>
                ),
                cell: (info: CellContext<any, any>) => (
                    <>
                        {rowSelection ? (
                            <IndeterminateCheckbox
                                {...{
                                    checked: info.row.getIsSelected(),
                                    indeterminate: info.row.getIsSomeSelected(),
                                    onChange: info.row.getToggleSelectedHandler(),
                                    disabled: !info.row.getCanSelect(),
                                    id: `${name ? `${name}_` : ''}${info.cell.id}`,
                                }}
                            />
                        ) : (
                            <div className="dataGrid__head-checkbox-placeholder" />
                        )}
                    </>
                ),
            }),
            ...columns,
        ];
    }, [columns, rowSelection, columnHelper]);

    const purifiedSorting = purifySorting(sorting, allColumns);
    const purifiedColumnFilters = purifyColumnFilters(columnFilters, allColumns);
    const purifiedColumnOrder = purifyColumnOrder(columnOrder, allColumns);

    const table = useReactTable({
        data,
        columns: allColumns,
        filterFns: {
            fuzzy: fuzzyFilter,
            numeric: numericFilter,
        },
        sortingFns: {
            numeric: numericSorting,
        },
        state: {
            rowSelection,
            columnSizing,
            columnOrder: purifiedColumnOrder,
            columnVisibility,
            columnFilters: purifiedColumnFilters,
            sorting: purifiedSorting,
        },
        autoResetPageIndex: false,
        columnResizeMode: 'onChange',
        onSortingChange: setSorting,
        onColumnSizingChange: setColumnSizing,
        onColumnVisibilityChange: setColumnVisibility,
        onColumnFiltersChange: setColumnFilters,
        onRowSelectionChange: setRowSelection,
        enableRowSelection,
        enableMultiSort: true,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        debugAll: false,
        initialState: {
            pagination: {
                pageSize: pageSize || 100,
            },
            rowSelection,
            columnSizing,
            columnOrder: purifiedColumnOrder,
            columnVisibility,
            columnFilters: purifiedColumnFilters,
            sorting: purifiedSorting,
        },
        meta: {
            ...(onFieldUpdated ? { onFieldUpdated } : {}),
            ...(onLastFieldBlur ? { onLastFieldBlur } : {}),
            ...(onFieldInit ? { onFieldInit } : {}),
            ...(selectedRow !== undefined ? { selectedRow } : {}),
            ...(onCellSelect ? { onCellSelect } : {}),
            ...(getField ? { getField } : {}),
            ...(name ? { name } : {}),
        },
    });

    useEffect(() => {
        if (!setRowSelection) {
            return;
        }
        // Reset row selection on grid change.
        table.resetRowSelection();
    }, [storageKey]);

    useEffect(() => {
        if (!resetFiltersDependencies?.length) {
            return;
        }

        resetColumnFilters();
    }, [resetFiltersDependencies]);

    const currentPage = table.getState().pagination.pageIndex;
    const visibleRows = table.getRowModel().rows;
    const headerGroups = table.getHeaderGroups();

    const csvData: Array<CSVRow> = table.getPrePaginationRowModel().rows.map((row) => {
        // Make sure we escape quotes in the text.
        const escapedRow: CSVRow = {};
        Object.entries(row.original).forEach(([key, value]) => {
            if (typeof value === 'string') {
                escapedRow[key] = value.replace(/"/g, '""');
            } else {
                escapedRow[key] = value;
            }
        });
        return escapedRow;
    });
    const csvHeaders: Array<CSVHeader> = headerGroups.map((headerGroup) => {
        return headerGroup.headers
            .filter((header) => {
                return header.id !== COLUMN_ID_SELECT && header.id !== COLUMN_ID_QUICKMENU;
            })
            .map((header) => {
                return {
                    label: header.column.columnDef.header as string,
                    key: header.column.id,
                };
            });
    })[0];

    const sortingAvailable = columns.filter((column: any) => !!column.sortingFn).length > 0;
    const filteringAvailable = columns.filter((column: any) => !!column.filterFn && column.enableColumnFilter !== false).length > 0;

    useEffect(() => {
        handleOrderChange();

        // Go to first page when sorting changes.
        const currentPageIndex = table.getState().pagination.pageIndex;
        if (currentPageIndex === 0) {
            return;
        }
        table.setPageIndex(0);
    }, [sorting]);

    useEffect(() => {
        handleOrderChange();

        // Go to first page when filters changes.
        const currentPageIndex = table.getState().pagination.pageIndex;
        if (currentPageIndex === 0) {
            return;
        }
        table.setPageIndex(0);
    }, [columnFilters]);

    // Go to previous page when landing on empty page
    useEffect(() => {
        if (visibleRows.length || currentPage === 0) {
            return;
        }
        table.setPageIndex(currentPage - 1);
    }, [visibleRows, currentPage]);

    const paginationActivated = table.getPageCount() > 1;

    const classes = classNames('dataGrid', className, {
        'dataGrid--pagination': paginationActivated,
        'dataGrid--hasSettingsCog': !!storageKey && !disableSettings,
        'dataGrid--hideFilters': !showFilters,
    });

    const onSettingsSaved = (orderedColumns: any, hiddenColumns: VisibilityState) => {
        setColumnVisibility(hiddenColumns);
        setColumnOrder([
            COLUMN_ID_SELECT,
            ...orderedColumns.map((column: any) => column.id),
            COLUMN_ID_QUICKMENU,
        ]);
    };
    const onOpenGridSettings = () => {
        setGridSettingsModal({
            isOpen: true,
            data: {
                name,
                onSettingsSaved,
                columns: table.getAllLeafColumns().filter((column: Column<unknown, unknown>) => column.accessorFn),
                defaultColumns: columns.filter((column: any) => column.accessorKey),
            },
        });
    };

    const onResetFilters = () => {
        resetColumnFilters();
    };

    const onResetSorting = () => {
        resetSorting();
    };

    const onToggleFilters = () => {
        setShowFilters((value) => !value);
    };

    return (
        <>
            <div
                {...{
                    className: classes,
                    style: {
                        width: table.getTotalSize(),
                    },
                }}
            >
                <div className="dataGrid__head">
                    <DataGridOptions
                        storageKey={storageKey}
                        csvFileName={`${name}_export.csv`}
                        csvData={csvData}
                        csvHeaders={csvHeaders}
                        onOpenGridSettings={onOpenGridSettings}
                        gridSettingsEnabled={!!storageKey && !disableSettings}
                        onResetFilters={onResetFilters}
                        resetFiltersVisible={filteringAvailable}
                        resetFiltersEnabled={!!columnFilters?.length}
                        onResetSorting={onResetSorting}
                        resetSortingEnabled={!!sorting?.length}
                        resetSortingVisible={sortingAvailable}
                        toggleFiltersVisible={filteringAvailable}
                        showFilters={showFilters}
                        onToggleShowFilters={onToggleFilters}
                    />
                    {table.getHeaderGroups().map((headerGroup) => (
                        <div
                            key={headerGroup.id}
                            className="dataGrid__tr"
                        >
                            {headerGroup.headers.map((header) => (
                                <div
                                    key={header.id}
                                    className={`dataGrid__head-cell ${header.column.getIsSorted() ? 'dataGrid__head-cell--sorted' : ''}`}
                                    style={{
                                        width: header.getSize(),
                                    }}
                                >
                                    <div
                                        className={`dataGrid__label ${header.column.getCanSort() ? 'dataGrid__label--canSort' : ''}`}
                                        onClick={header.column.getToggleSortingHandler()}
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext(),
                                            )}
                                        {getHeaderComponent && header.id !== COLUMN_ID_SELECT && header.id !== COLUMN_ID_QUICKMENU ? (
                                            <>
                                                {getHeaderComponent(header.column.columnDef.meta?.fieldDefinition)}
                                            </>
                                        ) : null}
                                    </div>
                                    {header.column.getCanFilter() ? (
                                        <div>
                                            <Filter
                                                column={header.column}
                                                columnFilterValues={columnFilterValues}
                                                setColumnFilterValues={setColumnFilterValues}
                                            />
                                        </div>
                                    ) : null}
                                    {header.column.getIsSorted() ? (
                                        <SortIcon
                                            id={header.column.id}
                                            isDesc={header.column.getIsSorted() === 'desc'}
                                            sorting={purifySorting(sorting, columns)}
                                        />
                                    ) : null}
                                    <div
                                        {...{
                                            onMouseDown: header.getResizeHandler(),
                                            onTouchStart: header.getResizeHandler(),
                                            className: `dataGrid__resizer ${
                                                header.column.getIsResizing() ? 'dataGrid__resizer--isResizing' : ''
                                            }`,
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                {table.getRowModel().rows.length ? (
                    <div className="dataGrid__body">
                        {table.getRowModel().rows.map((row) => (
                            <div
                                key={row.id}
                                className="dataGrid__tr"
                                {...{
                                    ...(getRowProps ? getRowProps(row, selectedRow) : {}),
                                }}
                            >
                                {row.getVisibleCells().map((cell) => (
                                    <div
                                        key={cell.id}
                                        {...{
                                            style: {
                                                width: cell.column.getSize(),
                                            },
                                            className: cell.column.columnDef.meta?.className ? cell.column.columnDef.meta?.className : 'dataGrid__cell',
                                            ...(getCellProps ? getCellProps(cell, selectedRow, selectedCell) : {}),
                                        }}
                                    >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                ) : null}
                {paginationActivated || columnFilters?.length || renderNrOfRows || purifiedSorting?.length ? (
                    <div className="dataGrid__footer">
                        <div className="dataGrid__footerInner">
                            {paginationActivated ? (
                                <ReactPaginate
                                    forcePage={currentPage}
                                    className="pagination"
                                    breakLabel="..."
                                    nextLabel={<ArrowIcon />}
                                    onPageChange={({ selected }) => {
                                        table.setPageIndex(selected);
                                    }}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={1}
                                    pageCount={table.getPageCount()}
                                    previousLabel={<ArrowIcon />}
                                />
                            ) : null}
                            {renderNrOfRows ? (
                                <div className="dataGrid__footerInfo">
                                    {renderNrOfRows(data.length, table.getPrePaginationRowModel().rows.length)}
                                </div>
                            ) : null}
                            {purifiedColumnFilters?.length ? (
                                <div className="dataGrid__footerButtons">
                                    <Tooltip
                                        title={t('resetFilters.tooltip.title', { ns: 'dataGrid' })}
                                        list={purifiedColumnFilters.map((filter) => {
                                            const filterColumn = allColumns.find((column: any) => column.accessorKey === filter.id);
                                            return `${filterColumn.header}: ${filter.value}`;
                                        })}
                                    >
                                        <Button
                                            primary
                                            onClick={onResetFilters}
                                            icon={<CrossIcon />}
                                            testId="list__reset-filters"
                                        >
                                            {t('resetFilters.button', { ns: 'dataGrid' })}
                                        </Button>
                                    </Tooltip>
                                </div>
                            ) : null}
                            {purifiedSorting?.length ? (
                                <div className="dataGrid__footerButtons">
                                    <Tooltip
                                        title={t('resetSorting.tooltip.title', { ns: 'dataGrid' })}
                                        list={purifiedSorting.map((sorting) => {
                                            const sortingColumn = allColumns.find((column: any) => column.accessorKey === sorting.id);
                                            return sortingColumn.header;
                                        })}
                                    >
                                        <Button
                                            primary
                                            onClick={onResetSorting}
                                            icon={<CrossIcon />}
                                        >
                                            {t('resetSorting.button', { ns: 'dataGrid' })}
                                        </Button>
                                    </Tooltip>
                                </div>
                            ) : null}
                        </div>
                    </div>
                ) : null}
            </div>
            <GridSettingsModal name={name} />
        </>
    );
};

export default DataGrid;
