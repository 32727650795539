import { atom } from 'jotai';
import { closedModalState, ModalState } from '@js/store/modalAtoms';
import { Sizes } from '@utils/sizes';

export interface ListModalData {
    title: string;
    text: string;
    list: string[];
    isError?: boolean
    size?: Sizes;
}

export const listModalAtom = atom<ModalState<ListModalData>>(closedModalState);
