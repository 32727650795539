import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Login from '@components/pages/loginPage/components/Login';
import Tenant from '@components/pages/loginPage/components/Tenant';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import Main from '@components/primitives/main/Main';
import LoginBg from '@images/login.jpg';
import HublLogo from '@images/svg/hubl.svg?react';
import useLogout from '@hooks/useLogout';
import usePageTitle from '@hooks/usePageTitle';
import useQueryTenant from '@components/pages/loginPage/hooks/useQueryTenant';
import AppStoreBadge from '@images/appstore.svg?react';
import PlayStoreBadge from '@images/playstore.svg?react';

const LoginPage: FC = () => {
    const isAuthenticated = useIsAuthenticated();
    const { t } = useTranslation();
    const logout = useLogout();
    const { accounts } = useMsal();
    const currentAccount = accounts.length ? accounts[0] : null;
    const { createPageTitle } = usePageTitle();
    useQueryTenant();

    return (
        <>
            <Helmet>
                <title>{createPageTitle(t('login.title', { ns: 'authentication' }))}</title>
                <meta name="description" content={t('meta.description')} />
            </Helmet>
            <Main hasCover>
                <section className="loginBox">
                    <div
                        className="loginBox__image"
                        style={{
                            backgroundImage: `url(${LoginBg})`,
                        }}
                    />
                    <div className="loginBox__content">
                        <HublLogo className="loginBox__logo" />
                        <div>
                            {!isAuthenticated ? (
                                <Login />
                            ) : (
                                <Tenant />
                            )}
                        </div>
                    </div>
                </section>
                <div className="loginBox__footer">
                    {isAuthenticated && currentAccount ? (
                        <p data-testid="login-text-logged-in">
                            {t('login.textLoggedIn', { ns: 'authentication', username: currentAccount.username })}
                            &nbsp;-&nbsp;
                            <span onClick={logout}>{t('logout', { ns: 'authentication' })}</span>
                        </p>
                    ) : null}
                    <a
                        href="https://apps.apple.com/nl/app/whitevision/id1546713138"
                        target="_blank"
                        rel="noreferrer"
                        data-testid="login-appstore-badge"
                    >
                        <AppStoreBadge className="storeBadge" />
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=nl.whitevision.whitevisionapp&hl=en&gl=US"
                        target="_blank"
                        rel="noreferrer"
                        data-testid="login-playstore-badge"
                    >
                        <PlayStoreBadge className="storeBadge" />
                    </a>
                </div>
            </Main>
        </>
    );
};

export default LoginPage;
