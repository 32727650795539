import { atom } from 'jotai';
import { closedModalState, ModalState } from '@js/store/modalAtoms';
import { SelectingOcrTrainingFieldContext, TrainingField } from '@models/OcrTraining';
import { DocumentOcrWord } from '@models/DocumentOcrData';

export interface OcrTrainingFieldModalData {
    documentId: number;
    trainingField: TrainingField;
    ocrValue?: DocumentOcrWord;
    ocrNearbyValue?: DocumentOcrWord;
    context?: SelectingOcrTrainingFieldContext;
}

export const ocrTrainingFieldModalAtom = atom<ModalState<OcrTrainingFieldModalData>>(closedModalState);
