import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetAtom } from 'jotai';
import { copyCodingModalAtom } from '@js/store/modals/copyCodingModalAtom';
import useDocumentHeaders from '@components/features/documentHeaders/hooks/useDocumentHeaders';
import CopyIcon from '@images/svg/copy.svg?react';

interface Props {
    documentId: number;
    isReadOnlyMode: boolean;
}

const CopyCodingButton: FC<Props> = ({ documentId, isReadOnlyMode }) => {
    const setCopyCodingModal = useSetAtom(copyCodingModalAtom);
    const { getHeaderValue } = useDocumentHeaders(documentId);
    const selectedCreditor = getHeaderValue('crediteur');
    const { t } = useTranslation();

    const onCopyCoding = () => {
        if (!documentId) {
            console.warn('Document ID is missing');
            return;
        }

        setCopyCodingModal({
            isOpen: true,
            data: {
                documentId,
            },
        });
    };

    return (
        <button
            type="button"
            className="buttonStrip__button"
            onClick={onCopyCoding}
            title={t('copy.button', { ns: 'coding' })}
            disabled={isReadOnlyMode || !selectedCreditor}
        >
            <CopyIcon />
        </button>
    );
};

export default CopyCodingButton;
