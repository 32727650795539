import { DocumentOcrWord } from '@models/DocumentOcrData';
import { UpdateCodingField } from '@models/DocumentCoding';
import log, { LogContext } from '@utils/logging';
import useUpdateDocumentInvoiceLine from '@components/features/documentInvoiceLines/hooks/useUpdateDocumentInvoiceLine';
import { toast } from 'react-toastify';
import useDocumentInvoiceLines from '@components/features/documentInvoiceLines/hooks/useDocumentInvoiceLines';
import { useTranslation } from 'react-i18next';
import { DocumentInvoiceLineField, DocumentInvoiceLines } from '@models/DocumentInvoiceLines';
import useOcrUtils from '@components/features/ocr/hooks/useOcrUtils';
import { useQueryClient } from '@tanstack/react-query';
import { LookupListItem } from '@models/Lookup';
import { QueryKey } from '@constants/queryKey';
import useLookupParams from '@components/features/lookup/hooks/useLookupParams';
import useGetLookupOptionsFromCache from '@components/features/lookup/hooks/useGetLookupOptionsFromCache';
import { DynamicFieldValue } from '@models/DynamicField';

const useInvoiceLinesUpdater = (documentId: number) => {
    const { mutate: updateInvoiceLine } = useUpdateDocumentInvoiceLine(documentId);
    const { getLookupParams } = useLookupParams(documentId);
    const { getLookupOptionsFromCache } = useGetLookupOptionsFromCache();
    const { documentInvoiceLines, getInvoiceLineFieldDefinition } = useDocumentInvoiceLines(documentId);
    const { t } = useTranslation();
    const { formatOcrValue, ocrToLookupValue } = useOcrUtils(documentId);
    const client = useQueryClient();
    const queryCache = client.getQueryCache();

    const getDocumentInvoicesLinesFromCache = (): DocumentInvoiceLines | undefined => {
        const coding = queryCache.find<DocumentInvoiceLines>({
            queryKey: [QueryKey.DocumentInvoiceLines, documentId],
        });
        return coding?.state?.data;
    };

    const getSelectedLookupOption = (fieldId: string, value: DynamicFieldValue) => {
        const fieldDefinition = getInvoiceLineFieldDefinition(fieldId);

        if (!fieldDefinition || fieldDefinition.format !== 'lookup') {
            return undefined;
        }

        const lookupParams = getLookupParams(fieldDefinition.lookup);
        const lookupData = getLookupOptionsFromCache(fieldDefinition.lookup, lookupParams);

        if (!lookupData) {
            return undefined;
        }

        return lookupData.list?.find((option) => option.key === value);
    };

    const convertLookupOptionToValuesToSave = (lineId: number, fieldId: string, option: LookupListItem, overwrite: boolean) => {
        const invoiceLinesFromCache = getDocumentInvoicesLinesFromCache();
        const fieldDefinition = getInvoiceLineFieldDefinition(fieldId);
        const row = invoiceLinesFromCache?.rows.find((row) => row.values.find((field) => field.id === 'row' && field.value === lineId));
        const result: UpdateCodingField[] = [];

        if (!row || !fieldDefinition || !Object.entries(option).length) {
            return result;
        }

        const lookupParams = getLookupParams(fieldDefinition.lookup);
        const lookupData = getLookupOptionsFromCache(fieldDefinition.lookup, lookupParams);

        for (const [id, value] of Object.entries(option)) {
            const targetFieldDefinition = invoiceLinesFromCache?.definitions.find((field) => field.id === id);
            const targetField = row.values.find((field) => field.id === id);

            // Make sure the field exists in the coding line
            if (targetField && targetFieldDefinition) {
                // Check the scheme of this entry and make sure this entry has useValue enabled and is not hidden
                const lookupScheme = lookupData?.scheme.find((item) => item.columnId === id);
                if (lookupScheme?.useValue && (value !== null && value !== undefined)) {
                    // Make sure the value is different from the current value
                    if (targetField.value !== value) {
                        // Overwrite the value if it's not set or if the overwrite flag is set
                        if (overwrite || !targetField.value) {
                            result.push({
                                id,
                                value: value as string | number | null,
                            });
                        }
                    }
                }
            }
        }
        return result;
    };

    const purifyValues = (values: DocumentInvoiceLineField[]): DocumentInvoiceLineField[] => {
        return values.map((val) => {
            const fieldDefinition = getInvoiceLineFieldDefinition(val.id);

            if (!fieldDefinition) {
                return val;
            }

            // Truncate string to maxLength
            if (fieldDefinition.maxLength && val.value && typeof val.value === 'string' && (val.value.length > fieldDefinition.maxLength)) {
                toast.warn(t('validation.ocr.tooLarge', { maxLength: fieldDefinition.maxLength }));
                return {
                    ...val,
                    value: val.value.substring(0, fieldDefinition.maxLength),
                };
            }

            return val;
        });
    };

    const onInvoiceFieldChange = (lineId: number, fieldId: string, value: DynamicFieldValue) => {
        const fieldDefinition = getInvoiceLineFieldDefinition(fieldId);

        if (!fieldDefinition || fieldDefinition?.isReadOnly || documentInvoiceLines?.meta?.readOnly) {
            return;
        }

        const selectedOption = getSelectedLookupOption(fieldId, value);

        const values: UpdateCodingField[] = [
            {
                id: fieldId,
                value,
            },
            ...(selectedOption ? convertLookupOptionToValuesToSave(lineId, fieldId, selectedOption, true) : []),
        ];

        log({
            context: LogContext.Matching,
            title: `Update field "${fieldId}"${lineId !== undefined ? ` (line ${lineId})` : ''}`,
            reason: 'On invoiceLine field change',
            data: { values },
        });

        updateInvoiceLine({
            data: {
                values: purifyValues(values),
            },
            lineId,
        });
    };
    const onInvoiceFieldOcr = (lineId: number, fieldId: string, ocr: DocumentOcrWord) => {
        const fieldDefinition = getInvoiceLineFieldDefinition(fieldId);

        if (!fieldDefinition || fieldDefinition?.isReadOnly || documentInvoiceLines?.meta.readOnly) {
            return;
        }

        const values: DocumentInvoiceLineField[] = [
            {
                id: fieldId,
                value: formatOcrValue(ocr.text, fieldDefinition.format),
                zone: ocr.zone,
            },
        ];

        if (fieldDefinition.format === 'lookup') {
            const selectedOption = ocrToLookupValue(ocr.text, fieldDefinition.lookup, lineId);

            if (!selectedOption) {
                return;
            }

            const dependentValues = convertLookupOptionToValuesToSave(lineId, fieldId, selectedOption, true);

            dependentValues.forEach((val) => {
                values.push(val);
            });
        }

        log({
            context: LogContext.Matching,
            title: `Update field "${fieldId}" (line ${lineId})`,
            reason: 'On OCR clicked',
            data: { values },
        });

        updateInvoiceLine({
            data: {
                values: purifyValues(values),
            },
            lineId,
        });
    };

    return {
        onInvoiceFieldChange,
        onInvoiceFieldOcr,
    };
};

export default useInvoiceLinesUpdater;
