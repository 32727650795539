import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateDocumentCodingLines } from '@repository/documentCodingRepository';
import { QueryKey } from '@constants/queryKey';
import useFetchDocumentCoding from '@components/features/coding/hooks/useFetchDocumentCoding';
import { useTranslation } from 'react-i18next';
import { extractNumbers, sum } from '@utils/numberUtilities';
import { toast } from 'react-toastify';

interface UpdateCodingLineMutationValues {
    zone?: string;
    page?: number;
    id: string;
    value: string | null | number;
}

export interface UpdateCodingLineMutation {
    rowId: number;
    values: UpdateCodingLineMutationValues[];
}

export type UpdateCodingLinesMutation = UpdateCodingLineMutation[];

const useUpdateCodingLines = (documentId: number) => {
    const client = useQueryClient();
    const { data: documentCoding } = useFetchDocumentCoding(documentId);
    const { t } = useTranslation();

    const getFieldDefinition = (fieldId: string) => {
        if (!documentCoding?.definitions) {
            return null;
        }

        return documentCoding.definitions.find((field) => field.id === fieldId);
    };

    const purifyData = (data: UpdateCodingLinesMutation) : UpdateCodingLinesMutation => {
        const purifiedData = data.map((codingLine) => {
            return {
                ...codingLine,
                values: codingLine.values.map((codingLine) => {
                    const field = getFieldDefinition(codingLine.id);
                    if (!field || !codingLine.value) {
                        return codingLine;
                    }

                    let { value } = codingLine;
                    if (typeof value === 'string' && field.maxLength && value.length > field.maxLength) {
                        toast.warn(t('validation.ocr.tooLarge', { maxLength: field.maxLength }));
                        value = value.substring(0, field.maxLength);
                    }

                    if (field.format === 'decimal') {
                        value = sum(extractNumbers(codingLine.value.toString()));
                    }

                    return {
                        ...codingLine,
                        value,
                    };
                }),
            };
        });

        return purifiedData;
    };

    return useMutation({
        mutationFn: (mutationData: UpdateCodingLinesMutation) => {
            const purifiedData = purifyData(mutationData);
            return updateDocumentCodingLines(documentId, purifiedData);
        },
        onSuccess: async () => {
            await Promise.all([
                // Please note: this line ensures that the VAT amount is automatically calculated when changing the VAT code.
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentCoding, documentId],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentOcrSummary, documentId],
                }),
            ]);
        },
    });
};

export default useUpdateCodingLines;
