import SortArrowIcon from '@images/svg/sortArrow.svg?react';
import { FC } from 'react';
import classNames from 'classnames';
import { SortingState } from '@tanstack/react-table';

interface Props {
    id: string;
    isDesc: boolean;
    sorting: SortingState;
}
const SortIcon: FC<Props> = ({ id, isDesc, sorting }) => {
    const index = sorting.findIndex((column) => column.id === id);

    return (
        <span
            className={classNames('dataGrid__sortIcon', {
                'dataGrid__sortIcon--desc': isDesc,
            })}
        >
            {sorting.length > 1 ? (
                <span>
                    {index + 1}
                </span>
            ) : null}
            <SortArrowIcon />
        </span>
    );
};

export default SortIcon;
