import { useEffect, useMemo, useState } from 'react';
import useFetchDocList from '@components/features/docList/hooks/useFetchDocList';
import {
    ColumnFiltersState,
    createColumnHelper,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from '@tanstack/react-table';
import fuzzyFilter from '@components/primitives/dataGrid/utils/filters/fuzzyFilter';
import numericFilter from '@components/primitives/dataGrid/utils/filters/numericFilter';
import numericSorting from '@components/primitives/dataGrid/utils/sorting/numericSorting';
import { DocListItem } from '@models/DocList';
import useDataGridState from '@components/primitives/dataGrid/hooks/useDataGridState';
import { purifyColumnFilters, purifySorting } from '@components/primitives/dataGrid/utils/purifyDataGridState';
import { StorageKeys } from '@constants/storageKeys';
import { createStorageKey } from '@utils/storage';
import { SEARCH_LIST_TYPE } from '@routing/appUrls';
import { SearchResultAtom } from '@js/store/searchAtoms';
import { SearchDocumentResponseList } from '@models/SearchDocument';
import { useAtomValue, useAtom } from 'jotai';
import { firstDocumentAtom } from '@js/store/appAtoms';

export type DocListState = {
    sorting: SortingState,
    columnFilters: ColumnFiltersState,
};

const useDocListInfo = (docClass: string, listType: string) => {
    const { data: docList, isLoading } = useFetchDocList(docClass, listType, { refetchOnWindowFocus: false, enabled: listType !== SEARCH_LIST_TYPE });
    const searchResult = useAtomValue(SearchResultAtom);
    const list = listType === SEARCH_LIST_TYPE ? searchResult : docList;
    const [firstDocumentId, setFirstDocumentId] = useAtom(firstDocumentAtom);

    const data = useMemo(() => {
        if (!list?.list) {
            return [];
        }

        return list.list.map((row) => {
            const obj: any = {};
            Object.entries(row).forEach(([key, value]) => {
                obj[key.toLowerCase()] = value;
            });
            return obj;
        });
    }, [list?.list]);

    const columnHelper = createColumnHelper<DocListItem | SearchDocumentResponseList>();
    const columns = useMemo(() => {
        if (!list?.scheme) {
            return [];
        }

        return list.scheme
            .filter((column) => !column.isHidden)
            .map((column) => {
                return columnHelper.accessor(column.columnId, {
                    header: column.title,
                    ...(column.format === 'text' || column.format === 'lookup' || column.format === 'memo' ? {
                        filterFn: 'fuzzy',
                        sortingFn: 'alphanumeric',
                    } : column.format === 'decimal' || column.format === 'int' ? {
                        filterFn: 'numeric',
                        sortingFn: 'numeric',
                    } : {
                        enableColumnFilter: false,
                    }),
                });
            });
    }, [list?.scheme]);

    const storageKey = listType === SEARCH_LIST_TYPE
        ? createStorageKey([docClass, StorageKeys.GridSearch])
        : createStorageKey([docClass, StorageKeys.GridDocList, listType]);

    const {
        getSavedSorting,
        getSavedColumnFilters,
    } = useDataGridState(storageKey);
    const [sorting, setSorting] = useState<SortingState>(getSavedSorting());
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(getSavedColumnFilters());

    const updateDocListState = (state: DocListState) => {
        if (state.sorting !== sorting) {
            setSorting(state.sorting);
        }
        if (state.columnFilters !== columnFilters) {
            setColumnFilters(state.columnFilters);
        }
    };

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
            numeric: numericFilter,
        },
        sortingFns: {
            numeric: numericSorting,
        },
        state: {
            sorting: purifySorting(sorting, columns),
            columnFilters: purifyColumnFilters(columnFilters, columns),
        },
        autoResetPageIndex: false,
        enableMultiSort: false,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugAll: false,
        initialState: {
            sorting: purifySorting(sorting, columns),
        },
    });

    const docIds: number[] = table.getPrePaginationRowModel().rows.map((row) => row.original.docid as number);

    useEffect(() => {
        setFirstDocumentId(docIds[0]);
    }, [docIds[0]]);

    const getDocumentIndex = (documentId?: number): number => {
        if (!documentId) {
            return 0;
        }
        return docIds.findIndex((id) => id === documentId);
    };

    const getPrevDocumentId = (documentId?: number): number | null => {
        if (!documentId) {
            return firstDocumentId;
        }

        const currentIndex = getDocumentIndex(documentId);

        if (currentIndex === -1) {
            return firstDocumentId;
        }

        return currentIndex === 0 ? docIds[docIds.length - 1] : docIds[currentIndex - 1];
    };

    const getNextDocumentId = (documentId?: number): number | null => {
        if (!documentId) {
            return firstDocumentId;
        }

        const currentIndex = getDocumentIndex(documentId);

        if (currentIndex === -1) {
            return firstDocumentId;
        }

        return (currentIndex === (docIds.length - 1)) ? docIds[0] : docIds[currentIndex + 1];
    };

    const getAllDocumentIds = (exception?: number) => {
        return data
            .map((doc) => doc.docid)
            .filter((docid) => docid !== exception);
    };

    const getFilteredDocumentIds = (exception?: number) => {
        if (exception) {
            return docIds.filter((docid) => docid !== exception);
        }

        return docIds;
    };

    return {
        recentDocumentsLoading: isLoading,
        updateDocListState,
        nrOfDocuments: data.length,
        nrOfFilteredDocuments: docIds.length,
        firstDocumentId,
        getDocumentIndex,
        getPrevDocumentId,
        getNextDocumentId,
        getAllDocumentIds,
        getFilteredDocumentIds,
    };
};

export default useDocListInfo;
