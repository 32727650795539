import { FC } from 'react';
import { SavedComment } from '@models/Comments';
import Heading from '@components/primitives/heading/Heading';
import { HeadingTags } from '@utils/headingTags';
import { Sizes } from '@utils/sizes';
import { formatDateTime } from '@utils/formatDate';
import { useTranslation } from 'react-i18next';
import Text from '@components/primitives/text/Text';
import useFetchCurrentUser from '@components/features/currentUser/hooks/useFetchCurrentUser';

interface Props {
    comment: SavedComment;
}

const Comment: FC<Props> = ({ comment }) => {
    const { data: user } = useFetchCurrentUser();
    const { t } = useTranslation();

    return (
        <div className="comment">
            <div className="comment__head">
                <Heading className="comment__heading" tag={HeadingTags.h4} size={Sizes.sm} noMargin>
                    {user?.userId === comment.user.id ? t('you') : comment.user.name}
                </Heading>
                <span className="comment__date">{formatDateTime(comment.dateTime, true)}</span>
            </div>
            <div className="comment__text">
                <Text text={comment.text} />
            </div>
        </div>
    );
};

export default Comment;
