import { FC } from 'react';
import classNames from 'classnames';
import FilterIcon from '@images/svg/unfilter.svg?react';
import { useTranslation } from 'react-i18next';

interface Props {
    onFilter: () => void;
    isFiltered: boolean;
}

const FilterButton: FC<Props> = ({ onFilter, isFiltered }) => {
    const { t } = useTranslation();
    const filterButtonClasses = classNames('buttonStrip__button', {
        'buttonStrip__button--active': !isFiltered,
    });

    return (
        <button
            type="button"
            title={isFiltered ? t('actions.unfilter', { ns: 'matching' }) : t('actions.filter', { ns: 'matching' })}
            className={filterButtonClasses}
            onClick={onFilter}
        >
            <FilterIcon className="md" />
        </button>
    );
};

export default FilterButton;
