import { ColumnFilter, ColumnFiltersState, ColumnOrderState, ColumnSort, SortingState } from '@tanstack/react-table';
import { COLUMN_ID_QUICKMENU, COLUMN_ID_SELECT } from '@components/primitives/dataGrid/DataGrid';

// sometimes the saved sorting/filtering may contain column ids that don't exist (anymore).
// In that case an error will occur. To prevent that, we filter non-existent colom ids from this state.

export const purifyColumnFilters = (columnFiltersState: ColumnFiltersState, columns: any[]): ColumnFiltersState => {
    return columnFiltersState?.filter((columnFilter: ColumnFilter) => {
        return columns.findIndex((column) => column.accessorKey === columnFilter.id) !== -1;
    });
};

export const purifySorting = (sortingState: SortingState, columns: any[]): SortingState => {
    return sortingState?.filter((columnSort: ColumnSort) => {
        return columns.findIndex((column) => column.accessorKey === columnSort.id) !== -1;
    });
};

export const purifyColumnOrder = (columnOrder: ColumnOrderState, columns: any[]): ColumnOrderState => {
    const allColumnIds = columns.map((col) => col.accessorKey);
    const missingColumnsInOrder: string[] = allColumnIds.filter((columnId: string) => columnId && !columnOrder.includes(columnId));
    const userColumns = columnOrder.filter((columnId: string) => {
        return columnId && columnId !== COLUMN_ID_SELECT && columnId !== COLUMN_ID_QUICKMENU && allColumnIds.includes(columnId);
    });

    return [
        COLUMN_ID_SELECT,
        ...userColumns,
        ...missingColumnsInOrder,
        COLUMN_ID_QUICKMENU,
    ];
};
