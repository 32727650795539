import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { DefaultActionId } from '@models/DocumentActions';

interface Props {
    id: DefaultActionId | string;
    title: string;
    subTitle?: string;
    icon?: ReactNode;
    onClick: (id: DefaultActionId | string) => void;
    enabled?: boolean;
    toggle: () => void;
    className?: string;
}

const ToggleListItem: FC<Props> = ({ id, title, subTitle, icon, onClick, enabled, toggle, className }) => {
    const classes = classNames('toggleList__item', className, {
        'toggleList__item--hasIcon': icon,
    });

    const onItemClick = () => {
        toggle();
        onClick(id);
    };

    return (
        <button type="button" className={classes} onClick={onItemClick} disabled={!enabled}>
            {icon ? (
                <div className="toggleList__item-icon">
                    {icon}
                </div>
            ) : null}
            <span className="title">
                {title}
            </span>
            {subTitle ? (
                <span className="subTitle">{subTitle}</span>
            ) : null}
        </button>
    );
};

export default ToggleListItem;
