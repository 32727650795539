import { FC } from 'react';
import classNames from 'classnames';
import RefreshIcon from '@images/svg/refresh.svg?react';
import useRefreshDocumentMatching from '@components/features/matching/hooks/useRefreshDocumentMatching';
import { useTranslation } from 'react-i18next';

interface Props {
    documentId: number;
    isReadOnly: boolean;
}

const RefreshButton: FC<Props> = ({ documentId, isReadOnly }) => {
    const { t } = useTranslation();
    const { mutate: refreshDocumentMatching, isPending: isRefreshingDocumentMatching } = useRefreshDocumentMatching(documentId);
    const refreshButtonClasses = classNames('buttonStrip__button', {
        'buttonStrip__button--loading': isRefreshingDocumentMatching,
    });

    const onRefresh = async () => {
        await refreshDocumentMatching();
    };

    return (
        <button
            type="button"
            title={t('actions.refresh', { ns: 'matching' })}
            className={refreshButtonClasses}
            onClick={onRefresh}
            disabled={isRefreshingDocumentMatching || isReadOnly}
        >
            <RefreshIcon className="sm" />
        </button>
    );
};

export default RefreshButton;
