import { SpecialZoomLevel, ZoomEvent } from '@react-pdf-viewer/core';
import { t } from 'i18next';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { useEffect, useState } from 'react';
import { getLocalStorageItem, setLocalStorageItem } from '@utils/storage';

interface UseZoom {
    storageKey?: string;
    defaultZoomLevel?: number;
}

const useZoom = ({
    storageKey,
    defaultZoomLevel,
}: UseZoom) => {
    const [zoomLevel, setZoomLevel] = useState<number | string>(defaultZoomLevel || 1);

    useEffect(() => {
        if (!storageKey) {
            return;
        }

        const zoomLevel = getLocalStorageItem(storageKey);
        if (!zoomLevel) {
            return;
        }
        setZoomLevel(+zoomLevel);
    }, []);
    const zoomPluginInstance = zoomPlugin();
    const { ZoomOut, ZoomIn, zoomTo } = zoomPluginInstance;
    const onZoomLevelClick = (zoomLevel: number | SpecialZoomLevel) => {
        zoomTo(zoomLevel);
    };
    const zoomLevels = [
        [
            {
                id: 'zoomlevel_pageWidth',
                title: t('pdfViewer.zoomLevels.pageWidth', { ns: 'document' }),
                onClick: () => onZoomLevelClick(SpecialZoomLevel.PageWidth),
                enabled: true,
                className:
                    zoomLevel === SpecialZoomLevel.PageWidth
                        ? 'toggleList__item--active'
                        : undefined,
            },
            {
                id: 'zoomlevel_pageFit',
                title: t('pdfViewer.zoomLevels.pageFit', { ns: 'document' }),
                onClick: () => onZoomLevelClick(SpecialZoomLevel.PageFit),
                enabled: true,
                className:
                    zoomLevel === SpecialZoomLevel.PageFit
                        ? 'toggleList__item--active'
                        : undefined,
            },
        ],
        [
            {
                id: 'zoomlevel_50',
                title: '50%',
                onClick: () => onZoomLevelClick(0.5),
                enabled: true,
                className:
                    zoomLevel === 0.5 ? 'toggleList__item--active' : undefined,
            },
            {
                id: 'zoomlevel_75',
                title: '75%',
                onClick: () => onZoomLevelClick(0.75),
                enabled: true,
                className:
                    zoomLevel === 0.75 ? 'toggleList__item--active' : undefined,
            },
            {
                id: 'zoomlevel_100',
                title: '100%',
                onClick: () => onZoomLevelClick(1),
                enabled: true,
                className:
                    zoomLevel === 1 ? 'toggleList__item--active' : undefined,
            },
            {
                id: 'zoomlevel_125',
                title: '125%',
                onClick: () => onZoomLevelClick(1.25),
                enabled: true,
                className:
                    zoomLevel === 1.25 ? 'toggleList__item--active' : undefined,
            },
            {
                id: 'zoomlevel_150',
                title: '150%',
                onClick: () => onZoomLevelClick(1.5),
                enabled: true,
                className:
                    zoomLevel === 1.5 ? 'toggleList__item--active' : undefined,
            },
            {
                id: 'zoomlevel_200',
                title: '200%',
                onClick: () => onZoomLevelClick(2),
                enabled: true,
                className:
                    zoomLevel === 2 ? 'toggleList__item--active' : undefined,
            },
            {
                id: 'zoomlevel_300',
                title: '300%',
                onClick: () => onZoomLevelClick(3),
                enabled: true,
                className:
                    zoomLevel === 3 ? 'toggleList__item--active' : undefined,
            },
            {
                id: 'zoomlevel_400',
                title: '400%',
                onClick: () => onZoomLevelClick(4),
                enabled: true,
                className:
                    zoomLevel === 4 ? 'toggleList__item--active' : undefined,
            },
        ],
    ];

    const onZoom = (e: ZoomEvent) => {
        if (!storageKey) {
            return;
        }
        setLocalStorageItem(storageKey, e.scale.toString());
        setZoomLevel(e.scale);
    };

    return {
        zoomPluginInstance,
        ZoomOut,
        ZoomIn,
        zoomLevel,
        zoomLevels,
        onZoom,
    };
};
export default useZoom;
