import { FC, ReactNode, useState, MouseEvent } from 'react';
import Text from '@components/primitives/text/Text';
import { createPortal } from 'react-dom';

interface Props {
    text?: string;
    title?: string;
    children: ReactNode
    force?: boolean;
}
const MouseToolTip: FC<Props> = ({ text, title, children, force }) => {
    const [left, setLeft] = useState<number>(0);
    const [top, setTop] = useState<number>(0);
    const offset = {
        left: 15,
        top: 15,
    };

    if (!text?.trim()) {
        return <>{children}</>;
    }

    const onMouseMove = (e: MouseEvent<HTMLSpanElement>) => {
        if (!text) {
            return;
        }
        setLeft(e.clientX + offset.left);
        setTop(e.clientY + offset.top);
    };

    const onMouseLeave = () => {
        // Component is responsible for destroying this component
        if (force) {
            return;
        }
        setLeft(0);
        setTop(0);
    };

    return (
        <span className="mouseToolTip__trigger" onMouseMove={onMouseMove} onMouseLeave={onMouseLeave}>
            {children}
            {left && top ? (
                <>
                    {createPortal(
                        (
                            <span
                                className="mouseToolTip"
                                style={{
                                    left,
                                    top,
                                }}
                            >
                                {title ? (
                                    <strong>{title}</strong>
                                ) : null}
                                <Text text={text} />
                            </span>
                        ), document.body,
                    )}
                </>
            ) : null}
        </span>
    );
};

export default MouseToolTip;
