import { executeGetRequest, executePostRequest } from '@api/client';
import { createFetchDocumentPath, createValidateDocumentPath } from '@routing/apiUrls';
import { Document, DocumentValidations } from '@models/Document';

export const fetchDocument = async (documentId: number, editMode?: boolean, signal?: AbortSignal) => {
    const result = await executeGetRequest<Document>(createFetchDocumentPath(documentId, editMode), signal);
    return result.data;
};

export const validateDocument = async (documentId: number, actionId: string) => {
    const result = await executePostRequest<DocumentValidations>(createValidateDocumentPath(documentId, actionId), {});
    return result.data;
};
