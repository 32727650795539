import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { fetchDocClasses } from '@repository/docClassesRepository';
import { DocClasses } from '@models/DocClasses';
import { minutesToMillis } from '@utils/millis';
import { useEffect } from 'react';
import log, { LogContext } from '@utils/logging';
import { useAtom } from 'jotai';
import { currentDocClassAtom } from '@js/store/appAtoms';

const useFetchDocClasses = (): UseQueryResult<DocClasses> => {
    const [currentDocClass, setCurrentDocClass] = useAtom(currentDocClassAtom);

    const query = useQuery({
        queryKey: [QueryKey.DocClasses],
        queryFn: ({ signal }) => fetchDocClasses(signal),
        staleTime: minutesToMillis(30),
    });

    const docClasses = query.data;
    const currentDocClassItem = docClasses && docClasses.list ? docClasses.list.find((docClassItem) => docClassItem.name === currentDocClass) : undefined;

    useEffect(() => {
        if (!docClasses?.list.length) {
            return;
        }

        if (currentDocClassItem) {
            return;
        }

        log({
            context: LogContext.CurrentDocClass,
            title: 'Set current docClass to default',
            reason: 'User didn\'t have an existing docClass',
            data: {
                newDocClass: docClasses.list[0].name,
            },
        });
        setCurrentDocClass(docClasses.list[0].name);
    }, [docClasses, currentDocClassItem]);

    return query;
};

export default useFetchDocClasses;
