import { FC, ReactNode } from 'react';
import Navigation from '@components/primitives/navigation/Navigation';

interface Props {
    children: ReactNode;
}

const Layout: FC<Props> = ({ children }) => {
    return (
        <>
            <Navigation />
            {children}
        </>
    );
};

export default Layout;
