import { BsArrowReturnRight } from "react-icons/bs";

interface FolderProps {
    folder: any;
    selectedFolder: any;
    onFolderClick: () => void;
}

const Folder = ({ folder, selectedFolder, onFolderClick }: FolderProps) => {
    return (
        <div
            role="button"
            tabIndex={0}
            onClick={onFolderClick}
            className="p-0 pl-8 bg-white/25 rounded-md relative flex flex-col cursor-pointer"
        >
            <span className={`rounded-l-md py-4 ${selectedFolder?.path === folder.path ? "bg-brand-secondary text-white font-bold" : "hover:bg-white/50"}`}>
                <BsArrowReturnRight className="ml-2 mr-6 w-4 h-4 opacity-50" />
                {folder.name}
            </span>
        </div>
    )
}

export default Folder;