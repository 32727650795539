import { FC } from 'react';
import { NumericFormat } from 'react-number-format';
import { getCurrentLocale } from '@utils/locales';
import OcrDiagonal from '@components/features/ocr/components/OcrDiagonal';
import OcrRect from '@components/features/ocr/components/OcrRect';

interface Props {
    zone: string;
    id: string;
    correctedValue: number;
    fileWidth: number;
    fileHeight: number;
}
const OcrCorrection: FC<Props> = ({ zone, id, correctedValue, fileWidth, fileHeight }) => {
    const currentLocale = getCurrentLocale();
    const zones = zone.split(/\s/u);
    const [, , width, height] = zones;

    return (
        <OcrRect
            className="ocrCorrection"
            zone={zone}
            fileWidth={fileWidth}
            fileHeight={fileHeight}
        >
            <span className="ocrCorrection__sticker">
                <NumericFormat
                    value={correctedValue}
                    thousandSeparator={currentLocale.thousandSeparator}
                    decimalSeparator={currentLocale.decimalSeparator}
                    decimalScale={id.indexOf('aantal') !== -1 ? 3 : 2}
                    fixedDecimalScale={false}
                    displayType="text"
                />
            </span>
            <OcrDiagonal width={+width} height={+height} />
        </OcrRect>
    );
};

export default OcrCorrection;
