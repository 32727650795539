import { FC, MouseEvent, useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { createDocumentPath, createListPath, DEFAULT_LIST_TYPE, PagePath } from '@routing/appUrls';
import HublLogo from '@images/svg/hubl.svg?react';
import HublIcon from '@images/svg/hublIcon.svg?react';
import SettingsIcon from '@images/svg/cog.svg?react';
import ListIcon from '@images/svg/searchList.svg?react';
import DocumentIcon from '@images/svg/file.svg?react';
import { BiCloudUpload as FileHandlerIcon } from 'react-icons/bi';
import SupportIcon from '@images/svg/info.svg?react';
import ArrowIcon from '@images/svg/arrow.svg?react';
import DashboardIcon from '@images/svg/dashboard.svg?react';
import LayersIcon from '@images/svg/layers.svg?react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import useFetchCurrentUser from '@components/features/currentUser/hooks/useFetchCurrentUser';
import DocumentCounter from '@components/primitives/navigation/components/DocumentCounter';
import { useAtom, useAtomValue } from 'jotai';
import { currentDocClassAtom } from '@js/store/appAtoms';
import { collapseNavigationAtom } from '@js/store/viewSettingsAtoms';
import ToggleList, { ToggleListPosition } from '@components/primitives/toggleList/ToggleList';
import useChangeCurrentDocClass from '@components/features/docClass/hooks/useChangeCurrentDocClass';
import useDocListInfo from '@hooks/useDocListInfo';
import useDocClasses from '@components/features/docClass/hooks/useDocClasses';

const Navigation: FC = () => {
    const [collapseNavigation, setCollapseNavigation] = useAtom(collapseNavigationAtom);
    const [animate, setAnimate] = useState<boolean>(false);
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const currentDocClass = useAtomValue(currentDocClassAtom);
    const { nrOfDocuments, firstDocumentId } = useDocListInfo(currentDocClass, DEFAULT_LIST_TYPE);
    const navigate = useNavigate();
    const { documentId } = useParams();
    const changeCurrentDocClass = useChangeCurrentDocClass();
    const { data: user } = useFetchCurrentUser();
    const { currentDocClassItem, docClasses } = useDocClasses();

    const onProcessingClick = async (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (!nrOfDocuments) {
            navigate(PagePath.allDocumentsProcessed);
            return;
        }
        navigate(createDocumentPath(firstDocumentId || undefined, true));
    };

    // Prevents animation on page refresh
    useEffect(() => {
        if (!animate) {
            return;
        }
        setTimeout(() => {
            setAnimate(false);
        }, 600);
    }, [animate]);

    const toggle = () => {
        setCollapseNavigation(!collapseNavigation);
        setAnimate(true);
    };

    const classes = classNames('nav', {
        'nav--collapsed': collapseNavigation,
        'nav--animate': animate,
    });

    const buttonClasses = classNames('nav__toggle', {
        'nav__toggle--collapsed': collapseNavigation,
    });

    return (
        <nav className={classes}>
            <div className="nav__top">
                <Link to={PagePath.dashboard} className="logo" data-testid="navigation-logo">
                    <HublLogo />
                </Link>
                <Link to={PagePath.dashboard} className="icon" data-testid="navigation-icon">
                    <HublIcon />
                </Link>
            </div>
            <div className="nav__content">
                <ul className="nav__list">
                    <li className="nav__item">
                        <NavLink
                            title={t('dashboard', { ns: 'navigation' })}
                            to={PagePath.dashboard}
                            end
                            data-testid="navigation-button-dashboard"
                            className={({ isActive }) => classNames('nav__link', {
                                'nav__link--active': isActive,
                            })}
                        >
                            <DashboardIcon />
                            <span className="nav__link-text">{t('dashboard', { ns: 'navigation' })}</span>
                        </NavLink>
                    </li>
                    {currentDocClassItem ? (
                        <>
                            <li className="nav__docClass-selector">
                                <ToggleList
                                    className="docClassSelector__toggleList"
                                    title={t('docClassSelector.title', { ns: 'navigation' })}
                                    isDark
                                    position={ToggleListPosition.CenterLeft}
                                    list={[
                                        docClasses
                                            .map((docClassItem) => {
                                                return {
                                                    enabled: true,
                                                    id: docClassItem.name,
                                                    title: docClassItem.title,
                                                    onClick: () => changeCurrentDocClass(docClassItem.name),
                                                    className: classNames({
                                                        'toggleList__item--active': currentDocClass === docClassItem.name,
                                                    }),
                                                };
                                            }),
                                    ]}
                                    trigger={(toggle) => (
                                        <button
                                            className="nav__docClass-selector-button"
                                            type="button"
                                            onClick={toggle}
                                        >
                                            <LayersIcon />
                                            <span className="nav__link-text">
                                                {currentDocClassItem.title}
                                            </span>
                                        </button>
                                    )}
                                />
                            </li>
                            <li key={currentDocClassItem.name} className="nav__item">
                                <NavLink
                                    title={currentDocClassItem.title}
                                    to={createListPath(currentDocClassItem.name)}
                                    data-testid="navigation-button-list"
                                    className={() => classNames('nav__link', {
                                        'nav__link--active': pathname.indexOf(`/list/${currentDocClassItem.name}`) === 0
                                            || pathname.indexOf(`/search/${currentDocClassItem.name}`) === 0,
                                    })}
                                >
                                    <ListIcon />
                                    <span className="nav__link-text">{t('list', { ns: 'navigation' })}</span>
                                </NavLink>
                            </li>
                        </>
                    ) : null}
                    <li className="nav__item">
                        <NavLink
                            title={t('processing', { ns: 'navigation' })}
                            to={createDocumentPath(undefined, true)}
                            onClick={onProcessingClick}
                            data-testid="navigation-button-processing"
                            className={() => classNames('nav__link', {
                                'nav__link--active': pathname === PagePath.allDocumentsProcessed || pathname.indexOf('/document/') === 0,
                            })}
                        >
                            <DocumentIcon className="svg--stroke" />
                            <span className="nav__link-text">{t('processing', { ns: 'navigation' })}</span>
                            {documentId ? (
                                <DocumentCounter documentId={+documentId} navigationIsCollapsed={collapseNavigation} />
                            ) : null}
                        </NavLink>
                        {collapseNavigation && documentId ? (
                            <DocumentCounter documentId={+documentId} navigationIsCollapsed={collapseNavigation} />
                        ) : null}
                    </li>
                    {user?.allowFilehandler && (
                        <li className="nav__item">
                            <NavLink
                                title={t('fileHandler', { ns: 'navigation' })}
                                to={PagePath.fileHandler}
                                data-testid="navigation-button-fileHandler"
                                className={({ isActive }) => classNames('nav__link', {
                                    'nav__link--active': isActive,
                                })}
                            >
                                <FileHandlerIcon className="nav__icon" />
                                <span className="nav__link-text">
                                    {t('fileHandler', { ns: 'navigation' })}
                                </span>
                            </NavLink>
                        </li>
                    )}
                    <li className="nav__item">
                        <NavLink
                            title={t('settings', { ns: 'navigation' })}
                            to={PagePath.settings}
                            data-testid="navigation-button-settings"
                            className={({ isActive }) => classNames('nav__link', {
                                'nav__link--active': isActive,
                            })}
                        >
                            <SettingsIcon className="svg--stroke" />
                            <span className="nav__link-text">{t('settings', { ns: 'navigation' })}</span>
                        </NavLink>
                    </li>
                </ul>
                {currentDocClassItem ? (
                    <ToggleList
                        className="docClassSelector__toggleList docClassSelector__toggleList--bottom"
                        title={t('docClassSelector.title', { ns: 'navigation' })}
                        isDark
                        position={ToggleListPosition.CenterLeft}
                        list={[
                            docClasses.map((docClassItem) => {
                                return {
                                    enabled: true,
                                    id: docClassItem.name,
                                    title: docClassItem.title,
                                    onClick: () => changeCurrentDocClass(docClassItem.name),
                                    className: classNames({
                                        'toggleList__item--active': currentDocClass === docClassItem.name,
                                    }),
                                };
                            }),
                        ]}
                        trigger={(toggle) => (
                            <span
                                className="docClassSelector--collapsed"
                                onClick={toggle}
                            >
                                <LayersIcon />
                                <span className="nav__link-text">
                                    {currentDocClassItem.title}
                                </span>
                            </span>
                        )}
                    />
                ) : null}
            </div>
            <div className="nav__bottom">
                <ul className="nav__footer">
                    <li className="nav__item">
                        <NavLink
                            title={t('support', { ns: 'navigation' })}
                            to={PagePath.support}
                            data-testid="navigation-button-support"
                            className={({ isActive }) => classNames('nav__link', {
                                'nav__link--active': isActive,
                            })}
                        >
                            <SupportIcon className="svg--combined" />
                            <span className="nav__link-text">{t('support', { ns: 'navigation' })}</span>
                        </NavLink>
                    </li>
                </ul>
                <button
                    type="button"
                    className={buttonClasses}
                    onClick={toggle}
                    data-testid="navigation-collapse"
                >
                    <ArrowIcon />
                </button>
            </div>
        </nav>
    );
};

export default Navigation;
