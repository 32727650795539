import { atom } from 'jotai';
import { getLocalStorageItem, setLocalStorageItem } from '@utils/storage';

const createAtom = <T>(storageKey: string, initialValue: T, stringify: boolean = false) => {
    const baseAtom = atom(initialValue);
    return atom(
        (get) => get(baseAtom),
        (get, set, update) => {
            const nextValue = typeof update === 'function' ? update(get(baseAtom)) : update;
            set(baseAtom, nextValue);
            if (stringify) {
                setLocalStorageItem(storageKey, JSON.stringify(nextValue));
                return;
            }
            setLocalStorageItem(storageKey, nextValue);
        },
    );
};
export const booleanAtomWithLocalStorage = (storageKey: string, initialValue: boolean) => {
    const getInitialValue = (): boolean => {
        const item = getLocalStorageItem(storageKey);
        if (item) {
            return item === 'true';
        }
        return initialValue;
    };
    return createAtom<boolean>(storageKey, getInitialValue());
};
export const numberAtomWithLocalStorage = (storageKey: string, initialValue: number) => {
    const getInitialValue = (): number => {
        const item = getLocalStorageItem(storageKey);
        if (item) {
            return +item;
        }
        return initialValue;
    };
    return createAtom<number>(storageKey, getInitialValue());
};
export const stringAtomWithLocalStorage = (storageKey: string, initialValue: string) => {
    const getInitialValue = (): string => {
        return getLocalStorageItem(storageKey) || initialValue;
    };
    return createAtom<string>(storageKey, getInitialValue());
};
export const objectAtomWithLocalStorage = <T> (storageKey: string, initialValue: T) => {
    const getInitialValue = (): T => {
        const savedValue = getLocalStorageItem(storageKey);
        if (savedValue) {
            return JSON.parse(savedValue);
        }
        return initialValue;
    };
    return createAtom<T>(storageKey, getInitialValue(), true);
};
