import { QueryKey } from '@constants/queryKey';
import { DocumentActionResponse } from '@repository/performDocumentActionRepository';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { currentDocClassAtom } from '@js/store/appAtoms';
import useDocListInfo from '@hooks/useDocListInfo';
import { createListPath, DEFAULT_LIST_TYPE, PagePath } from '@routing/appUrls';
import { useNavigate } from 'react-router-dom';
import useDataGridState from '@components/primitives/dataGrid/hooks/useDataGridState';
import { createStorageKey } from '@utils/storage';
import { StorageKeys } from '@constants/storageKeys';
import useDocumentNavigation from '@hooks/useDocumentNavigation';
import { textModalAtom } from '@js/store/modals/textModalAtom';
import { documentPreviewModalAtom } from '@js/store/modals/documentPreviewModalAtom';

const useHandleDocumentActionResponse = (listType: string, documentId?: number, isPreviewMode?: boolean) => {
    const { t } = useTranslation();
    const client = useQueryClient();
    const currentDocClass = useAtomValue(currentDocClassAtom);
    const {
        resetColumnFilters,
    } = useDataGridState(createStorageKey([currentDocClass, StorageKeys.GridDocList, listType]));
    const { nextDocument } = useDocumentNavigation(listType, isPreviewMode);
    const setTextModal = useSetAtom(textModalAtom);
    const { getAllDocumentIds, getFilteredDocumentIds } = useDocListInfo(currentDocClass, listType);
    const navigate = useNavigate();
    const setDocumentPreviewModal = useSetAtom(documentPreviewModalAtom);

    const onAllDocumentsProcessed = () => {
        if (listType === DEFAULT_LIST_TYPE) {
            navigate(PagePath.allDocumentsProcessed);
            return;
        }

        if (isPreviewMode) {
            setDocumentPreviewModal({
                isOpen: false,
            });
        }
    };

    const onAllFilteredDocumentsProcessed = () => {
        resetColumnFilters();
        navigate(createListPath(currentDocClass, listType));
    };

    return async (data: DocumentActionResponse) => {
        if ((data?.statuscode === 1 && data?.validationMessage) || (data?.statuscode === 2 && data?.validationMessage)) {
            setTextModal({
                isOpen: true,
                data: {
                    title: t('errorModal.title', { ns: 'workflowActions' }),
                    text: data.validationMessage,
                    isError: true,
                },
            });
            return;
        }

        // Opens url when API tells us to do.
        if (data?.openUrl) {
            window.open(data.openUrl);
        }

        if (data.openNextDocument) {
            if (documentId) {
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocList, currentDocClass, listType],
                });
                const otherDocuments = getAllDocumentIds(documentId);
                const otherFilteredDocuments = getFilteredDocumentIds(documentId);

                if (!otherFilteredDocuments.length && otherDocuments.length) {
                    console.info('Filtered documents processed - Reset filter');
                    onAllFilteredDocumentsProcessed();
                    return;
                }

                if (!otherDocuments.length) {
                    console.info('All documents processed');
                    onAllDocumentsProcessed();
                    return;
                }

                await nextDocument(documentId);
            }
            return;
        }

        if (documentId) {
            await Promise.all([
                await client.invalidateQueries({
                    queryKey: [QueryKey.Document, documentId],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentActions, documentId],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocList, currentDocClass, listType],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentAnnotations, documentId],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentHeaders, documentId],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentExternalInfo, documentId],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentCoding, documentId],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentOrderLines, documentId],
                }),
            ]);
        }
    };
};

export default useHandleDocumentActionResponse;
