import React, { FC } from 'react';
import PerformDocumentActionModalInner from '@components/modals/performDocumentActionModal/PerformDocumentActionModalInner';
import Modal from '@components/primitives/modal/Modal';
import { useAtom } from 'jotai';
import { performDocumentActionModalAtom, PerformDocumentActionModalData } from '@js/store/modals/performDocumentActionModalAtom';

const PerformDocumentActionModal: FC = () => {
    const [modalState, setModalState] = useAtom(performDocumentActionModalAtom);

    if (!modalState.data) {
        return null;
    }

    return (
        <Modal<PerformDocumentActionModalData>
            modalState={modalState}
            setModalState={setModalState}
            title={modalState.data.title}
            onClose={modalState.data.onCanceled}
        >
            <PerformDocumentActionModalInner
                modalData={modalState.data}
                // documentId={performDocumentActionModal.data.documentId}
                // listType={performDocumentActionModal.data.listType}
                // action={performDocumentActionModal.data.action}
                // buttonLabel={performDocumentActionModal.data.buttonLabel}
                // onSuccess={performDocumentActionModal.data.onSuccess}
                // onSubmit={performDocumentActionModal.data.onSubmit}
                // returnValue={performDocumentActionModal.data.returnValue || false}
                // onCanceled={performDocumentActionModal.data.onCanceled}
            />
        </Modal>
    );
};

export default PerformDocumentActionModal;
