import { StorageKeys } from '@constants/storageKeys';

interface InMemoryStorage {
    [key: string]: string;
}

const storageFactory = (storage: Storage) => {
    let inMemoryStorage: InMemoryStorage = {};
    let supported : boolean | undefined;

    const isSupported = () => {
        if (supported !== undefined) {
            return supported;
        }

        try {
            const testKey = '__some_random_key_you_are_not_going_to_use__';
            storage.setItem(testKey, testKey);
            storage.removeItem(testKey);
            supported = true;
            return true;
        } catch (e) {
            supported = false;
            return false;
        }
    };

    const clear = () => {
        if (isSupported()) {
            storage.clear();
        } else {
            inMemoryStorage = {};
        }
    };

    const getItem = (name: string) => {
        if (isSupported()) {
            return storage.getItem(name);
        }
        if (inMemoryStorage[name]) {
            return inMemoryStorage[name];
        }
        return null;
    };

    const removeItem = (name: string) => {
        if (isSupported()) {
            storage.removeItem(name);
        } else {
            delete inMemoryStorage[name];
        }
    };

    const setItem = (name: string, value: string) => {
        if (isSupported()) {
            storage.setItem(name, value);
        } else {
            inMemoryStorage[name] = String(value);
        }
    };

    return {
        getItem,
        setItem,
        removeItem,
        clear,
    };
};

export const local = storageFactory(localStorage);
export const session = storageFactory(sessionStorage);

export const createStorageKey = (storageKey: (StorageKeys | string)[], omitTenantId: boolean = false): string => {
    const tenantId = local.getItem(StorageKeys.TenantId);
    const keys = [
        ...(omitTenantId || !tenantId ? [] : [tenantId.toUpperCase()]),
        ...storageKey,
    ];

    return keys.join('_');
};

export const getLocalStorageItem = (storageKey: string): any => {
    const value = local.getItem(storageKey);

    if (!value) {
        return null;
    }

    let parsedValue;

    try {
        parsedValue = JSON.parse(value);
    } catch (e) {
        parsedValue = value;
    }

    return parsedValue;
};

export const setLocalStorageItem = (storageKey: string, value: string) => {
    local.setItem(storageKey, value);
};
