import { FC } from 'react';
import { formatDate } from '@utils/formatDate';
import { toLocalDate } from '@utils/dateUtils';

interface Props {
    date: string,
}

const DateCell: FC<Props> = ({ date }) => {
    const dateString = toLocalDate(date);

    return (
        <>{dateString ? formatDate(dateString) : null}</>
    );
};

export default DateCell;
