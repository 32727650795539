import { StorageKeys } from '@constants/storageKeys';
import { LogContext } from '@utils/logging';
import { createStorageKey } from '@utils/storage';
import { atomWithStorage } from 'jotai/utils';

export type LoggingSettings = {
    [key in LogContext]: boolean;
};

export const loggingSettingsAtom = atomWithStorage<LoggingSettings>(
    createStorageKey([StorageKeys.LoggingSettings]),
    Object.entries(LogContext).reduce((result, [key]): any => {
        return {
            ...result,
            [key]: false,
        };
    }, {} as LoggingSettings),
);

export const startPageAtom = atomWithStorage<string>(
    createStorageKey([StorageKeys.StartPage]),
    'DASHBOARD_PAGE',
);

export enum Themes {
    Light = 'LIGHT',
    Dark = 'DARK',
    Auto = 'AUTO',
}

export const themeAtom = atomWithStorage<Themes>(
    createStorageKey([StorageKeys.Theme]),
    Themes.Light,
);
