import { FC } from 'react';
import AddComment from '@components/features/comments/AddComment';
import useFetchDocumentComments from '@components/features/comments/hooks/useFetchDocumentComments';
import classNames from 'classnames';
import Message from '@components/primitives/message/Message';
import { useTranslation } from 'react-i18next';
import Throbber from '@components/primitives/throbber/Throbber';
import Comment from './Comment';

interface Props {
    documentId: number;
    userCanAddComments: boolean;
}

const Comments: FC<Props> = ({ userCanAddComments, documentId }) => {
    const { data: comments, isLoading } = useFetchDocumentComments(documentId);
    const { t } = useTranslation();

    const classes = classNames('comments', {
        'comments--readOnly': !userCanAddComments,
    });

    return (
        <div className={classes}>
            <AddComment userCanAddComments={userCanAddComments} documentId={documentId} />
            {comments?.list?.length ? (
                <>
                    {comments.list.map((comment) => (
                        <Comment key={comment.id} comment={comment} />
                    ))}
                </>
            ) : isLoading ? (
                <Throbber />
            ) : !userCanAddComments ? (
                <Message isNoItems>{t('noItemsMsg', { ns: 'comments' })}</Message>
            ) : null}
        </div>
    );
};

export default Comments;
