import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { minutesToMillis } from '@utils/millis';
import { fetchSamCodingSources } from '@repository/samCodingSourcesRepository';
import { SamCodingSourcesResponse } from '@models/SamCodingSources';

const useFetchSamCodingSources = (documentId: number): UseQueryResult<SamCodingSourcesResponse> => {
    return useQuery({
        queryKey: [QueryKey.SamCodingSources, documentId],
        queryFn: ({ signal }) => fetchSamCodingSources(documentId, signal),
        staleTime: minutesToMillis(10),
    });
};

export default useFetchSamCodingSources;
