import { FC } from 'react';

interface Props {
    value: string | number | null;
}

const TextCell: FC<Props> = ({ value }) => {
    return (
        <>
            {value}
        </>
    );
};

export default TextCell;
