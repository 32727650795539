import { FC, useEffect, useMemo, useRef, useState } from 'react';
import WarningIcon from '@images/svg/warning.svg?react';
import classNames from 'classnames';
import Select from 'react-select';
import CustomReactSelectInput from '@components/primitives/form/components/CustomReactSelectInput';
import CustomReactSelectNoOptions from '@components/primitives/form/components/CustomReactSelectNoOptions';
import { useTranslation } from 'react-i18next';
import { DynamicField, DynamicFieldValue } from '@models/DynamicField';
import { useSetAtom } from 'jotai';
import StaticDocumentField from '@components/features/documentFields/StaticDocumentField';
import DocumentFieldFeedback from '@components/features/documentFields/DocumentFieldFeedback';
import { textModalAtom } from '@js/store/modals/textModalAtom';

interface Option {
    value: string;
    label: string;
}

interface Props {
    isEditable: boolean;
    initialValue: DynamicFieldValue;
    fieldDefinition: DynamicField;
    field: any;
    onFieldFocus?: () => void;
    onFieldBlur?: () => void;
    onFieldUpdated?: (id: string, value: string) => void;
    inputId: string;
}
const DocumentSelectField: FC<Props> = ({
    isEditable,
    initialValue,
    fieldDefinition,
    field,
    onFieldFocus,
    onFieldBlur,
    onFieldUpdated,
    inputId,
}) => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const [cacheValue, setCacheValue] = useState<string | undefined>(initialValue as string);
    const { isRequired, isReadOnly, choices } = fieldDefinition;
    const [value, setValue] = useState<Option | null>(null);
    const options = choices?.map((choice) => {
        return {
            value: choice,
            label: choice,
        };
    });

    const selectedOption = useMemo(() => options?.find((option) => option.value === initialValue), [options, initialValue]);
    const setTextModal = useSetAtom(textModalAtom);

    const updateData = (value: string) => {
        if (value === cacheValue || !onFieldUpdated) {
            return;
        }

        onFieldUpdated(field.id, value);
        setCacheValue(value);
    };

    const onFocus = () => {
        if (onFieldFocus) {
            onFieldFocus();
        }
    };

    const onBlur = () => {
        if (onFieldBlur) {
            onFieldBlur();
        }
    };

    const onLookupErrorClick = () => {
        setTextModal({
            isOpen: true,
            data: {
                title: fieldDefinition.title,
                text: t('lookupError.invalid', { ns: 'coding', value: initialValue }),
            },
        });
    };

    useEffect(() => {
        if (!initialValue || initialValue === value?.value) {
            return;
        }

        const initialOption = options?.find((option) => {
            if (typeof option.value === 'string' && typeof initialValue === 'string') {
                return option.value.toLowerCase() === initialValue.toLowerCase();
            }
            return option.value === initialValue;
        });

        if (!initialOption) {
            setValue({
                label: t('validation.invalidLabel', { ns: 'headers', value: initialValue }),
                value: initialValue as string,
            });
            return;
        }

        setValue(initialOption || null);
    }, [initialValue]);

    if (!isEditable) {
        return (
            <StaticDocumentField
                value={initialValue}
                fieldDefinition={fieldDefinition}
                field={field}
            >
                <>
                    {!value || !options ? null : !selectedOption ? (
                        <button type="button" onClick={onLookupErrorClick} className="coding__lookupError" title={t('lookupError.invalid', { ns: 'coding', value })}>
                            <WarningIcon className="lookupError" />
                        </button>
                    ) : (
                        <>{selectedOption.label}</>
                    )}
                </>
            </StaticDocumentField>
        );
    }

    return (
        <div className="form__field form__field--select">
            <Select
                components={{
                    Input: CustomReactSelectInput,
                }}
                className={classNames('form__select', {
                    'form__select--required': !value && isRequired && !isReadOnly && options?.length,
                })}
                menuPosition="fixed"
                classNamePrefix="form__select"
                value={value}
                onChange={(newValue) => {
                    setValue(newValue);
                    updateData(newValue?.value || '');
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                options={options}
                noOptionsMessage={() => <CustomReactSelectNoOptions />}
                placeholder={isReadOnly ? '' : t('select')}
                inputId={inputId}
                isDisabled={isReadOnly}
                isClearable
                ref={ref}
            />
            <DocumentFieldFeedback
                fieldDefinition={fieldDefinition}
                value={value?.value || ''}
                field={field}
            />
        </div>
    );
};

export default DocumentSelectField;
