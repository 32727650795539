import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { fetchDocumentDynamicAssign } from '@repository/documentDynamicAssignRepository';
import { DocumentDynamicAssign } from '@models/DocumentDynamicAssign';

const useFetchDocumentDynamicAssign = (documentId: number): UseQueryResult<DocumentDynamicAssign> => {
    return useQuery({
        queryKey: [QueryKey.DocumentDynamicAssign, documentId],
        queryFn: ({ signal }) => fetchDocumentDynamicAssign(documentId, signal),
        staleTime: 0,
        refetchOnWindowFocus: false,
    });
};

export default useFetchDocumentDynamicAssign;
