import { useMemo } from 'react';
import useFetchDocumentCoding from '@components/features/coding/hooks/useFetchDocumentCoding';
import useMatchingTotals from '@components/features/matching/hooks/useMatchingTotals';
import useFetchDocument from '@hooks/useFetchDocument';
import useDocumentStatus from '@hooks/useDocumentStatus';
import useDocumentHeaders from '@components/features/documentHeaders/hooks/useDocumentHeaders';

const useCodingTotals = (documentId: number) => {
    const { isEditMode } = useDocumentStatus();
    const { data: document } = useFetchDocument(documentId, isEditMode);
    const { getHeaderValue } = useDocumentHeaders(documentId);
    const { data: documentCoding } = useFetchDocumentCoding(documentId);
    const { matchingTotal } = useMatchingTotals(documentId);

    // const codingTotal = useMemo(() => {
    //     let amount: number = 0;
    //     codingLines?.forEach((row) => {
    //         const amountField = row.fields.find((val) => val.id === 'bedrag');
    //         if (!amountField?.value) {
    //             return;
    //         }
    //         amount += +amountField.value;
    //     });
    //
    //     return amount;
    // }, [codingLines]);

    const codingTotal = useMemo(() => {
        let amount: number = 0;
        documentCoding?.rows.forEach((row) => {
            const amountField = row.values.find((val) => val.id === 'bedrag');
            if (!amountField?.value) {
                return;
            }
            amount += +amountField.value;
        });
        return amount;
    }, [documentCoding]);

    const invoiceTotal = documentCoding ? getHeaderValue(documentCoding.meta.codingTotalField) as number : 0;
    const difference = invoiceTotal - codingTotal - (document?.meta?.matchingEnabled ? matchingTotal : 0);
    const absoluteDifference = Math.abs(difference);

    return {
        invoiceTotal: Math.round((invoiceTotal + Number.EPSILON) * 100) / 100,
        codingTotal: Math.round((codingTotal + Number.EPSILON) * 100) / 100,
        difference: Math.round((difference + Number.EPSILON) * 100) / 100,
        absoluteDifference: Math.round((absoluteDifference + Number.EPSILON) * 100) / 100,
    };
};

export default useCodingTotals;
