import { DynamicFieldFormat } from '@models/DynamicField';
import { extractNumbers, sum } from '@utils/numberUtilities';
import useLookupParams from '@components/features/lookup/hooks/useLookupParams';
import useGetLookupOptionsFromCache from '@components/features/lookup/hooks/useGetLookupOptionsFromCache';

const useOcrUtils = (documentId: number) => {
    const { getLookupParams } = useLookupParams(documentId);
    const { getLookupOptionsFromCache } = useGetLookupOptionsFromCache();
    const formatOcrValue = (value: string, format: DynamicFieldFormat): string => {
        if (format === 'decimal') {
            return sum(extractNumbers(value.toString())).toFixed(2).toString();
        }

        return value;
    };

    const ocrToLookupValue = (ocrValue :string, lookup: string | null, lineId?: number) => {
        const lookupParams = getLookupParams(lookup, lineId);
        const lookupData = getLookupOptionsFromCache(lookup, lookupParams);

        return lookupData?.list?.find((option) => {
            if (!option?.display || !ocrValue) {
                return false;
            }

            return option.display.toLowerCase().indexOf(ocrValue.toLowerCase().trim()) !== -1;
        });
    };

    return {
        formatOcrValue,
        ocrToLookupValue,
    };
};

export default useOcrUtils;
