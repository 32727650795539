import { FC } from 'react';
import { formatDateTime } from '@utils/formatDate';

interface Props {
    date: string,
}

const DateTimeCell: FC<Props> = ({ date }) => {
    return (
        <>{formatDateTime(date)}</>
    );
};

export default DateTimeCell;
