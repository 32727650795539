import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { repairFile } from '@repository/fileRepository';
import { RepairFileMutation } from '@models/File';

const useRepairDocument = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: (mutation: RepairFileMutation) => repairFile(mutation.documentId),
        onSuccess: async (data, mutation) => {
            await client.invalidateQueries({
                queryKey: [QueryKey.File, mutation.documentId],
            });
        },
    });
};

export default useRepairDocument;
