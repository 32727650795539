import { FC, MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';
import { Sizes } from '@utils/sizes';
import { HeadingTags } from '@utils/headingTags';

interface Props {
    children: ReactNode;
    tag?: HeadingTags;
    size?: Sizes;
    noMargin?: boolean;
    marginLarge?: boolean;
    className?: string;
    title?: string;
    isLight?: boolean;
    icon?: ReactNode;
    onClick?: (e: MouseEvent<HTMLHeadingElement>) => void;
    testId?: string;
}

const Heading: FC<Props> = ({ children, icon, marginLarge, tag = HeadingTags.h2, size = Sizes.lg, noMargin, className, title, isLight, onClick, testId }) => {
    const Element = tag;

    const classes = classNames('heading', className, {
        'heading--xs': size === Sizes.xs,
        'heading--sm': size === Sizes.sm,
        'heading--md': size === Sizes.md,
        'heading--lg': size === Sizes.lg,
        'heading--xl': size === Sizes.xl,
        'heading--xxl': size === Sizes.xxl,
        'heading--xxxl': size === Sizes.xxxl,
        'heading--noMargin': noMargin,
        'heading--marginLarge': marginLarge,
        'heading--light': isLight,
        'heading--icon': !!icon,
    });

    return (
        <Element className={classes} title={title} onClick={onClick} data-testid={testId}>{icon || null}{children}</Element>
    );
};

export default Heading;
