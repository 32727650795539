import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import WarningIcon from '@images/svg/warning.svg?react';
import classNames from 'classnames';
import Select from 'react-select';
import CustomReactSelectInput from '@components/primitives/form/components/CustomReactSelectInput';
import CustomReactSelectNoOptions from '@components/primitives/form/components/CustomReactSelectNoOptions';
import { useTranslation } from 'react-i18next';
import useLookupOptions from '@components/features/lookup/hooks/useLookupOptions';
import CustomReactSelectMenu from '@components/primitives/form/components/CustomReactSelectMenu';
import { DynamicField, DynamicFieldValue } from '@models/DynamicField';
import useLookupErrors from '@js/store/lookupErrors/useLookupErrors';
import { useSetAtom } from 'jotai';
import StaticDocumentField from '@components/features/documentFields/StaticDocumentField';
import DocumentFieldFeedback from '@components/features/documentFields/DocumentFieldFeedback';
import { textModalAtom } from '@js/store/modals/textModalAtom';

interface Option {
    value: string;
    label: string;
}

interface Props {
    isEditable: boolean;
    showAsText?: boolean;
    initialValue: DynamicFieldValue;
    fieldDefinition: DynamicField;
    field: any;
    documentId: number;
    onFieldFocus?: () => void;
    onFieldBlur?: () => void;
    onFieldUpdated?: (id: string, value: string) => void;
    onFieldInit?: (id: string, value: string) => void;
    inputId: string;
    lineId?: number;
    priorityFeedbackElement?: ReactNode;
}

const DocumentLookupField: FC<Props> = ({
    isEditable,
    showAsText,
    initialValue,
    fieldDefinition,
    field,
    documentId,
    onFieldFocus,
    onFieldBlur,
    onFieldUpdated,
    onFieldInit,
    inputId,
    lineId,
    priorityFeedbackElement,
}) => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const [cacheValue, setCacheValue] = useState<string | undefined>(initialValue as string);
    const { isRequired, isReadOnly, lookup, id } = fieldDefinition;
    const [value, setValue] = useState<Option | null>(null);
    const { data, options, isLoading } = useLookupOptions(lookup, documentId, lineId);
    const selectedOption = useMemo(() => options?.find((option) => option.value === initialValue), [options, initialValue]);
    const [hasLookupError, setHasLookupError] = useState<boolean>(false);
    const [initialized, setInitialized] = useState<boolean>(false);
    const { registerLookupError, unregisterLookupError } = useLookupErrors();
    const setTextModal = useSetAtom(textModalAtom);

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (!initialValue || isReadOnly || selectedOption || !options) {
            setHasLookupError(false);
            return;
        }

        setHasLookupError(true);
    }, [selectedOption, isLoading, isReadOnly, initialValue, options]);

    useEffect(() => {
        setCacheValue(initialValue as string);
    }, [initialValue]);

    useEffect(() => {
        if (!lineId) {
            return;
        }

        if (!hasLookupError || isReadOnly) {
            unregisterLookupError(isReadOnly ? 'This field is readOnly' : 'The lookupError has been solved', lineId, id);
            return;
        }
        registerLookupError(lineId, id);
    }, [hasLookupError, lineId, id, isReadOnly]);

    const updateData = (value: string) => {
        if (value === cacheValue || !onFieldUpdated) {
            return;
        }
        onFieldUpdated(field.id, value);
        setCacheValue(value);
    };

    const onFocus = () => {
        if (onFieldFocus) {
            onFieldFocus();
        }
    };

    const onBlur = () => {
        if (onFieldBlur) {
            onFieldBlur();
        }
    };

    const onLookupErrorClick = () => {
        setTextModal({
            isOpen: true,
            data: {
                title: fieldDefinition.title,
                text: t('lookupError.invalid', { ns: 'coding', value: initialValue }),
            },
        });
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        if (isLoading) {
            return;
        }

        const initialOption = options?.find((option) => {
            if (typeof option.value === 'string' && typeof initialValue === 'string') {
                return option.value.toLowerCase() === initialValue.toLowerCase();
            }
            return option.value === initialValue;
        });

        // The current value of the field is not available as options.
        if (!initialOption && initialValue) {
            setValue({
                label: t('validation.invalidLabel', { ns: 'headers', value: initialValue }),
                value: initialValue as string,
            });
            return;
        }

        setValue(initialOption || null);
    }, [options, initialValue, isReadOnly, isLoading]);

    useEffect(() => {
        setInitialized(false);
    }, [documentId]);

    useEffect(() => {
        if (!initialValue || typeof initialValue !== 'string' || !data || initialized || !onFieldInit || !field) {
            return;
        }

        onFieldInit(field.id, initialValue);
        setInitialized(true);
    }, [initialValue, data, initialized]);

    const CustomReactSelectMenuComponent = useCallback((props: any) => {
        return (
            <CustomReactSelectMenu
                {...props}
                documentId={documentId}
                lookup={lookup}
                fieldName={fieldDefinition.id}
                title={fieldDefinition?.title}
                enableLookupRefresh={fieldDefinition?.enableLookupRefresh}
                onListRefreshed={(value: string) => updateData(value)}
            />
        );
    }, []);

    if (showAsText) {
        if (!initialValue) {
            return null;
        }

        return (
            <>{selectedOption ? selectedOption.label : t('validation.invalidLabel', { ns: 'headers', value: initialValue })}</>
        );
    }

    if (!isEditable) {
        return (
            <StaticDocumentField
                value={initialValue}
                fieldDefinition={fieldDefinition}
                field={field}
                giveChildrenPriority={!!priorityFeedbackElement}
            >
                <>
                    {priorityFeedbackElement ? (
                        <>{priorityFeedbackElement}</>
                    ) : !value || isLoading || !options ? null : !selectedOption ? (
                        <button
                            type="button"
                            onClick={onLookupErrorClick}
                            className="coding__lookupError"
                            title={t('lookupError.invalid', { ns: 'coding', value: value?.value })}
                        >
                            <WarningIcon className="lookupError" />
                        </button>
                    ) : (
                        <>{selectedOption.label}</>
                    )}
                </>
            </StaticDocumentField>
        );
    }

    return (
        <div className="form__field form__field--select">
            <Select
                components={{
                    Input: CustomReactSelectInput,
                    MenuList: CustomReactSelectMenuComponent,
                }}
                className={classNames('form__select', {
                    'form__select--required': (!value && isRequired && !isReadOnly && options?.length && !isLoading)
                        || (!selectedOption && value && !isReadOnly && !isLoading),
                })}
                menuPosition="fixed"
                classNamePrefix="form__select"
                value={value}
                onChange={(newValue) => {
                    setValue(newValue);
                    updateData(newValue?.value || '');
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                options={options}
                noOptionsMessage={() => <CustomReactSelectNoOptions />}
                placeholder={isReadOnly ? '' : t('select')}
                inputId={inputId}
                isDisabled={isReadOnly}
                isClearable
                ref={ref}
            />
            <DocumentFieldFeedback
                fieldDefinition={fieldDefinition}
                value={value?.value || ''}
                field={field}
                giveChildrenPriority={!!priorityFeedbackElement}
            >
                {priorityFeedbackElement ? (
                    <>{priorityFeedbackElement}</>
                ) : undefined}
            </DocumentFieldFeedback>
        </div>
    );
};

export default DocumentLookupField;
