import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const useCopyToClipboard = () => {
    const { t } = useTranslation();
    const copyToClipBoard = (content: string) => {
        navigator.clipboard.writeText(content).then(() => {
            toast.success(t('copyToClipboard.toastSuccess'));
        });
    };

    return copyToClipBoard;
};

export default useCopyToClipboard;
