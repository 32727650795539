import { FC, FocusEventHandler, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Select from 'react-select';
import classNames from 'classnames';
import FormError from '@components/primitives/form/FormError';
import { useTranslation } from 'react-i18next';
import CustomReactSelectInput from '@components/primitives/form/components/CustomReactSelectInput';
import CustomReactSelectNoOptions from '@components/primitives/form/components/CustomReactSelectNoOptions';

export interface Option {
    value: string | number;
    label: string;
}

interface Props {
    className?: string;
    name?: string;
    options: Option[];
    defaultValue?: Option;
    onChange?: (selectedOption: Option) => void;
    onFocus?: FocusEventHandler<HTMLInputElement>;
    onMenuOpen?: () => void;
    onMenuClose?: () => void;
    placeholder?: string;
    menuPosition?: 'absolute' | 'fixed';
    isReadOnly?: boolean;
    differentValidationField?: string;
}

const FormSelect: FC<Props> = ({
    className,
    name,
    options,
    onChange,
    onFocus,
    placeholder,
    onMenuOpen,
    onMenuClose,
    menuPosition = 'absolute',
    isReadOnly,
    defaultValue,
    differentValidationField,
}) => {
    const [localValue, setLocalValue] = useState<any>();
    const { setValue, watch } = useFormContext();
    const classes = classNames('form__field', 'form__field--select', className);
    const { t } = useTranslation();
    const fieldValue = name ? watch(name) : undefined;

    const handleChange = (selectedOption: Option) => {
        const value = selectedOption?.value || '';
        if (name) {
            setValue(name, value);
        }
        setLocalValue(selectedOption);
        if (!onChange) {
            return;
        }

        onChange(selectedOption);
    };

    // Update local value when fieldValue changes
    useEffect(() => {
        if (!fieldValue) {
            setLocalValue(null);
            return;
        }
        const val = options.find((option) => fieldValue === option.value);

        setLocalValue(val);
    }, [fieldValue, options]);

    return (
        <div className={classes}>
            <Select
                components={{
                    Input: CustomReactSelectInput,
                }}
                className="form__select"
                menuPosition={menuPosition}
                classNamePrefix="form__select"
                defaultValue={defaultValue}
                value={localValue}
                onChange={handleChange}
                onFocus={onFocus}
                options={options}
                placeholder={placeholder || t('select')}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                inputId={name}
                isDisabled={isReadOnly}
                noOptionsMessage={() => <CustomReactSelectNoOptions />}
                isClearable
            />
            {differentValidationField ? (
                <FormError name={differentValidationField} />
            ) : name ? (
                <FormError name={name} />
            ) : null}
        </div>
    );
};

export default FormSelect;
