import log, { LogContext } from '@utils/logging';
import { createListPath } from '@routing/appUrls';
import { useAtom } from 'jotai';
import { currentDocClassAtom } from '@js/store/appAtoms';
import { useNavigate } from 'react-router-dom';

const useChangeCurrentDocClass = () => {
    const [currentDocClass, setCurrentDocClass] = useAtom(currentDocClassAtom);
    const navigate = useNavigate();

    const changeCurrentDocClass = (docClass: string) => {
        if (docClass === currentDocClass) {
            navigate(createListPath(docClass));
            return;
        }

        setCurrentDocClass(docClass);
        log({
            context: LogContext.CurrentDocClass,
            title: 'Current docClass changed',
            reason: 'User changed docClass',
            data: {
                newDocClass: docClass,
            },
        });

        navigate(createListPath(docClass));
    };

    return changeCurrentDocClass;
};

export default useChangeCurrentDocClass;
