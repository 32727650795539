import { atom } from 'jotai';
import { closedModalState, ModalState } from '@js/store/modalAtoms';
import { DefaultActionId } from '@models/DocumentActions';

export interface UserChoice {
    id: string;
    name: string;
}

export interface Action {
    id: DefaultActionId | string;
    askConfirmation?: boolean;
    remarkRequired?: boolean;
    defaultNumDays?: number;
    minNumDays?: number;
    maxNumDays?: number;
    userChoices?: UserChoice[];
}

export interface PerformDocumentActionModalData {
    title: string;
    action: Action;
    documentId: number;
    listType: string;
    onSuccess?: (response: any) => void;
    onSubmit?: (response: any, closeModal: () => void) => void;
    onCanceled?: () => void;
    buttonLabel?: string;
    returnValue?: boolean;
}

export const performDocumentActionModalAtom = atom<ModalState<PerformDocumentActionModalData>>(closedModalState);
