import { Themes } from '@js/store/settingsAtoms';
import { useTranslation } from 'react-i18next';

const useTheme = () => {
    const { t } = useTranslation();

    const themes = [
        {
            value: Themes.Light,
            label: t('theme.light', { ns: 'settings' }),
        },
        {
            value: Themes.Dark,
            label: t('theme.dark', { ns: 'settings' }),
        },
        {
            value: Themes.Auto,
            label: t('theme.auto', { ns: 'settings' }),
        },
    ];

    return {
        themes,
    };
};

export default useTheme;
