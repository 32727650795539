import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { getCurrentLocale } from '@utils/locales';

export const defaultNS = 'global';

i18n
    .use(initReactI18next)
    .use(resourcesToBackend((language: string, namespace: string) => import(`./../locales/${language}/${namespace}.json`)))
    .init({
        ns: [
            'allDocumentsProcessed',
            'annotations',
            'authentication',
            'coding',
            'codingAccruals',
            'comments',
            'dashboard',
            'dataGrid',
            'document',
            'documentActions',
            'externalInfo',
            'fileHandler',
            'global',
            'headers',
            'list',
            'lineSource',
            'matching',
            'navigation',
            'ocrTraining',
            'sam',
            'search',
            'settings',
            'support',
            'templateSharing',
            'translation',
            'workflowActions',
        ],
        defaultNS,
        fallbackLng: 'nl-NL',
        lng: getCurrentLocale().code,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
