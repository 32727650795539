import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import FormError from '@components/primitives/form/FormError';

interface Props {
    name: string;
    className?: string;
    placeholder?: string;
    isReadOnly?: boolean;
}

const FormText: FC<Props> = ({ className, name, placeholder, isReadOnly }) => {
    const { register } = useFormContext();
    const classes = classNames('form__field', 'form__field--text', className);

    return (
        <div className={classes}>
            <textarea
                className="form__text"
                id={name}
                placeholder={placeholder}
                readOnly={isReadOnly}
                {...register(name)}
            />
            {name ? (
                <FormError name={name} />
            ) : null}
        </div>
    );
};

export default FormText;
