import { FC } from 'react';
import classNames from 'classnames';
import EuroIcon from '@images/svg/euro.svg?react';
import useRejectMatchingDifference from '@components/features/matching/hooks/useRejectMatchingDifference';
import { useTranslation } from 'react-i18next';

interface Props {
    documentId: number;
    isReadOnly: boolean;
}

const RejectButton: FC<Props> = ({ documentId, isReadOnly }) => {
    const { t } = useTranslation();
    const { mutate: rejectMatchingDifference, isPending: isRejectingMatchingDifference } = useRejectMatchingDifference(documentId);
    const rejectButtonClasses = classNames('buttonStrip__button', {
        'buttonStrip__button--active': isRejectingMatchingDifference,
    });

    const onReject = async () => {
        await rejectMatchingDifference({});
    };

    return (
        <button
            type="button"
            title={t('actions.reject', { ns: 'matching' })}
            className={rejectButtonClasses}
            onClick={onReject}
            disabled={isRejectingMatchingDifference || isReadOnly}
        >
            <EuroIcon className="sm error" />
        </button>
    );
};

export default RejectButton;
