import { FC, ReactNode } from 'react';
import WarningIcon from '@images/svg/warning.svg?react';
import classNames from 'classnames';
import { DynamicField, DynamicFieldValue } from '@models/DynamicField';
import { useTranslation } from 'react-i18next';
import { useSetAtom } from 'jotai';
import { textModalAtom } from '@js/store/modals/textModalAtom';

interface Props {
    fieldDefinition: DynamicField;
    value: DynamicFieldValue;
    field: any;
    children?: ReactNode;
    giveChildrenPriority?: boolean;
}

const DocumentFieldFeedback: FC<Props> = ({ fieldDefinition, value, field, children, giveChildrenPriority }) => {
    const { t } = useTranslation();
    const feedback = field?.feedback ? field.feedback : undefined;
    const isRequired = !!fieldDefinition?.isRequired;
    const isReadOnly = !!fieldDefinition?.isReadOnly;
    const isRequiredEmptyField = isRequired && !isReadOnly && (value === undefined || value === null || value === '');
    const setTextModal = useSetAtom(textModalAtom);

    const classes = classNames('dynamicField__feedback', {
        'dynamicField__feedback--warning': feedback?.status === 1,
        'dynamicField__feedback--error': feedback?.status === 2,
    });

    const onRequiredWarningClick = () => {
        setTextModal({
            isOpen: true,
            data: {
                title: t('validation.required.title', { field: fieldDefinition?.title }),
                text: t('validation.required.modal.text', { field: fieldDefinition?.title }),
            },
        });
    };

    const onFeedbackWarningClick = () => {
        if (!feedback) {
            return;
        }

        setTextModal({
            isOpen: true,
            data: {
                title: fieldDefinition.title,
                text: feedback.message,
            },
        });
    };

    return (
        <>
            {!!giveChildrenPriority && children ? (
                <>{children}</>
            ) : feedback ? (
                <button tabIndex={-1} type="button" onClick={onFeedbackWarningClick} className={classes} title={feedback.message}>
                    <WarningIcon />
                </button>
            ) : isRequiredEmptyField ? (
                <button tabIndex={-1} type="button" onClick={onRequiredWarningClick} className={classes} title={t('lookupError.required', { ns: 'coding' })}>
                    <WarningIcon />
                </button>
            ) : !giveChildrenPriority && children ? (
                <>{children}</>
            ) : null}
        </>
    );
};

export default DocumentFieldFeedback;
