import { FC } from 'react';

interface Props {
    text?: string;
    newLines?: boolean;
}

const Text: FC<Props> = ({ text, newLines = true }) => {
    if (!text) {
        return null;
    }

    if (!newLines) {
        return <>{text}</>;
    }

    return (
        <>
            {text
                .split('\n')
                .map((p, index) => (
                    <p key={index}>{p}</p>
                ))}
        </>
    );
};

export default Text;
