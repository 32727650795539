import { atom } from 'jotai';

export const atomWithToggle = (initialValue: boolean) => {
    const baseAtom = atom(initialValue, (get, set, nextValue?: boolean) => {
        const update = nextValue ?? !get(baseAtom);
        set(baseAtom, update);
    });

    return baseAtom;
};
