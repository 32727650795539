import './init';
import './sentry-init';

import { MatomoProvider } from '@datapunt/matomo-tracker-react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider, QueryMeta } from '@tanstack/react-query';
import './scss/app.scss';
import { toast, ToastContainer } from 'react-toastify';
import { FadeInDown } from '@utils/toastAnimations';
import './js/i18n';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from '@js/authConfig';
import { CookiesProvider } from 'react-cookie';
import router from '@routing/routes';
import ThemeProvider from '@components/features/themeProvider/ThemeProvider';
import React, { Suspense } from 'react';
import { matomoInstance } from '@src/matomo-init';

export const msalInstance = new PublicClientApplication(msalConfig);

const onGlobalQueryError = (error: any, meta?: QueryMeta) => {
    if (meta?.disableErrorToaster) {
        return;
    }

    const errorMessage = error?.response?.data?.message || error?.response?.data?.Message;
    if (errorMessage) {
        toast.error(errorMessage);
    }
};

const queryClient = new QueryClient({
    queryCache: new QueryCache({
        onError: (error, query) => onGlobalQueryError(error, query.meta),
    }),
    mutationCache: new MutationCache({
        onError: (error, variables, context, mutation) => onGlobalQueryError(error, mutation.options.meta),
    }),
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <MsalProvider instance={msalInstance}>
        <CookiesProvider>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider>
                    {/*// @ts-ignore*/}
                    <MatomoProvider value={matomoInstance}>
                        <Suspense>
                            <RouterProvider router={router} />
                            <ToastContainer
                                position="bottom-right"
                                autoClose={3000}
                                transition={FadeInDown}
                                theme="dark"
                            />
                        </Suspense>
                    </MatomoProvider>
                </ThemeProvider>
            </QueryClientProvider>
        </CookiesProvider>
    </MsalProvider>,
);
