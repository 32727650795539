import { useAtom, useSetAtom } from 'jotai';
import { Sizes } from '@utils/sizes';
import {
    asyncConfirmModalButtonHandlersAtom,
    AsyncConfirmModalProps,
    asyncConfirmModalPropsAtom,
    showAsyncConfirmModalAtom,
} from '@js/store/modals/asyncConfirmModalAtoms';

const useAsyncConfirmModal = () => {
    const [show, setShow] = useAtom(showAsyncConfirmModalAtom);
    const [asyncConfirmModalProps, setAsyncConfirmModalProps] = useAtom(asyncConfirmModalPropsAtom);
    const setAsyncConfirmModalButtonHandlers = useSetAtom(asyncConfirmModalButtonHandlersAtom);

    const asyncConfirm = async ({ title, message, size = Sizes.sm }: AsyncConfirmModalProps): Promise<boolean> => {
        setAsyncConfirmModalProps({ title, message, size });
        setShow(true);

        return new Promise((resolve) => {
            setAsyncConfirmModalButtonHandlers({
                onConfirm: () => {
                    setShow(false);
                    resolve(true);
                },
                onCancel: () => {
                    setShow(false);
                    resolve(false);
                },
            });
        });
    };

    return {
        show,
        setShow,
        asyncConfirm,
        asyncConfirmModalProps,
    };
};
export default useAsyncConfirmModal;
