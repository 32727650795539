import { useSetAtom } from 'jotai';
import { ewsSelectFolderModalAtom } from '@js/store/modals/ewsSelectFolderModalAtom';
import { BsArrowReturnRight } from 'react-icons/bs';
import { FcOpenedFolder } from 'react-icons/fc';
import Throbber from '@components/primitives/throbber/Throbber';
import { Office365Folder } from '@models/ImportProfiles';

interface FolderSelectProps {
    folders: Office365Folder[];
    folderType: FolderType;
    selectedFolder?: Office365Folder;
    isLoading: boolean;
    onSelect: (folder: Office365Folder) => void;
    [key: string]: any;
}

export enum FolderType {
    SourceFolder = 'sourceFolder',
    ErrorFolder = 'errorFolder',
    ArchiveFolder = 'archiveFolder',
}

const FolderSelect = ({
    isLoading,
    folders,
    folderType,
    selectedFolder,
    onSelect,
}: FolderSelectProps) => {
    const setEwsSelectFolderModal = useSetAtom(ewsSelectFolderModalAtom);

    const modalTitle = (folderType === FolderType.SourceFolder)
        ? 'Selecteer een bronmap'
        : (folderType === FolderType.ErrorFolder)
            ? 'Selecteer een foutmap'
            : 'Selecteer een archiefmap';

    const handleOpenFolderSelector = (e) => {
        e.preventDefault();
        setEwsSelectFolderModal({
            isOpen: true,
            data: {
                title: modalTitle,
                folders,
                selectedFolder,
                onSelect,
            },
        });
    };

    return (
        <div className="form__field">
            <div
                role="button"
                tabIndex={0}
                className={`form__select h-12 overflow-hidden w-full bg-gray-50 hover:bg-gray-100 p-4 rounded-lg flex flex-row items-center cursor-pointer
                    ${!isLoading ? 'underline' : ''}`}
                onClick={handleOpenFolderSelector}
            >
                {isLoading
                    ? (
                        <>
                            <Throbber />
                        </>
                    )
                    : (
                        <>
                            <FcOpenedFolder className="w-8 h-8 mr-4" />
                            <BsArrowReturnRight className="mr-2" />
                            {
                                (selectedFolder?.name)
                                    ? selectedFolder.name
                                    : 'Selecteer een map'
                            }
                        </>
                    )}
            </div>
        </div>
    );
};

export default FolderSelect;
