import { useMutation, useQueryClient } from '@tanstack/react-query';
import { LineSourceMutation } from '@models/LineSource';
import { updateLineSource } from '@repository/lineSourceRepository';
import { QueryKey } from '@constants/queryKey';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { textModalAtom } from '@js/store/modals/textModalAtom';

const useUpdateLineSource = () => {
    const client = useQueryClient();
    const setTextModal = useSetAtom(textModalAtom);
    const { t } = useTranslation();

    return useMutation({
        mutationFn: (data: LineSourceMutation) => updateLineSource(data.documentId, data.lineSource),
        onSuccess: async (response, variables) => {
            await Promise.all([
                await client.invalidateQueries({
                    queryKey: [QueryKey.LineSource, variables.documentId],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentCoding, variables.documentId],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentInvoiceLines, variables.documentId],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentOcrSummary, variables.documentId],
                }),
            ]);

            const toastMessage = {
                ocr: t('ocr.toastSuccess', { ns: 'lineSource' }),
                xml: t('xml.toastSuccess', { ns: 'lineSource' }),
                copy: t('copyCoding.toastSuccess', { ns: 'lineSource' }),
            }[variables.lineSource];

            toast.success(toastMessage);
        },
        onError: (error: any) => {
            if (!error?.response?.data?.Message) {
                return;
            }
            setTextModal({
                isOpen: true,
                data: {
                    title: t('errorModal.title', { ns: 'lineSource' }),
                    text: error.response.data.Message,
                },
            });
        },
        meta: {
            disableErrorToaster: true,
        },
    });
};

export default useUpdateLineSource;
