import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { secondsToMillis } from '@utils/millis';
import { ImportProfile } from '@models/ImportProfiles';
import { getImportProfile } from '@repository/importProfilesRepository';

const useFetchImportProfile = (id: string): UseQueryResult<ImportProfile> => {
    return useQuery({
        queryKey: [QueryKey.ImportProfile, id],
        queryFn: ({ signal }) => getImportProfile(id, signal),
        staleTime: secondsToMillis(30),
        refetchOnWindowFocus: false,
        enabled: !!id,
    });
};

export default useFetchImportProfile;
