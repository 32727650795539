import { FC, ReactNode } from 'react';

interface Props {
    children: ReactNode;
    title: string;
    list?: string[];
}

const Tooltip: FC<Props> = ({ children, title, list }) => {
    return (
        <div className="tooltip">
            {children}
            <div className="tooltip__content">
                <h6 className="tooltip__title">
                    {title}
                </h6>
                {list?.length ? (
                    <ul className="tooltip__list">
                        {list.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                ) : null}
            </div>
        </div>
    );
};

export default Tooltip;
