import { useEffect, useState } from 'react';
import {
    ColumnFiltersState,
    ColumnOrderState,
    ColumnSizingState,
    SortingState,
    VisibilityState,
} from '@tanstack/react-table';
import dataGridStore from '@components/primitives/dataGrid/utils/dataGridStore';

const useDataGridState = (storageKey?: string) => {
    const {
        setGridSettings,
        getSavedSorting,
        getSavedColumnSizing,
        getSavedColumnOrder,
        getSavedColumnFilters,
        getSavedColumnVisibility,
        getSavedShowFilters,
    } = dataGridStore(storageKey);

    const [sorting, setSorting] = useState<SortingState>(getSavedSorting());
    const [columnSizing, setColumnSizing] = useState<ColumnSizingState>(getSavedColumnSizing());
    const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(getSavedColumnOrder());
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(getSavedColumnVisibility());
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(getSavedColumnFilters());
    const [columnFilterValues, setColumnFilterValues] = useState<ColumnFiltersState>(getSavedColumnFilters());
    const [showFilters, setShowFilters] = useState<boolean>(getSavedShowFilters());

    useEffect(() => {
        setGridSettings({
            sortBy: sorting,
        });
    }, [sorting]);

    useEffect(() => {
        setGridSettings({
            columnWidths: columnSizing,
        });
    }, [columnSizing]);

    useEffect(() => {
        setGridSettings({
            columnOrder,
        });
    }, [columnOrder]);

    useEffect(() => {
        setGridSettings({
            columnFilters,
        });
    }, [columnFilters]);

    useEffect(() => {
        const newHiddenColumns: string[] = [];
        for (const [key] of Object.entries(columnVisibility)) {
            newHiddenColumns.push(key);
        }

        setGridSettings({
            hiddenColumns: newHiddenColumns,
        });
    }, [columnVisibility]);

    useEffect(() => {
        setGridSettings({
            showFilters,
        });
    }, [showFilters]);

    useEffect(() => {
        // Get saved state on list change
        setSorting(getSavedSorting());
        setColumnSizing(getSavedColumnSizing());
        setColumnOrder(getSavedColumnOrder());
        setColumnVisibility(getSavedColumnVisibility());
        setColumnFilters(getSavedColumnFilters());
        setColumnFilterValues(getSavedColumnFilters());
        setShowFilters(getSavedShowFilters());
    }, [storageKey]);

    const resetSorting = () => {
        setSorting([]);
        setGridSettings({
            sortBy: [],
        });
    };

    const resetColumnFilters = () => {
        setColumnFilters([]);
        setColumnFilterValues([]);
        setGridSettings({
            columnFilters: [],
        });
    };

    return {
        sorting,
        setSorting,
        resetSorting,
        columnSizing,
        setColumnSizing,
        columnOrder,
        setColumnOrder,
        columnVisibility,
        setColumnVisibility,
        columnFilters,
        setColumnFilters,
        columnFilterValues,
        setColumnFilterValues,
        getSavedSorting,
        getSavedColumnFilters,
        resetColumnFilters,
        showFilters,
        setShowFilters,
    };
};

export default useDataGridState;
