import { forwardRef, MouseEventHandler, FocusEventHandler, ChangeEventHandler, KeyboardEventHandler, ChangeEvent } from 'react';

interface Props {
    autoComplete?: 'on' | 'off';
    className?: string;
    disabled?: boolean;
    id?: string;
    name?: string;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    onChange?: ChangeEventHandler<HTMLInputElement>
    onClick?: MouseEventHandler<HTMLInputElement>;
    onFocus?: FocusEventHandler<HTMLInputElement>;
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
    placeholder?: string;
    readOnly?: boolean;
    required?: boolean;
    title?: string;
    value?: string;
}

const CustomDateInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
    const {
        autoComplete,
        className,
        disabled,
        id,
        name,
        onBlur,
        onChange,
        onClick,
        onFocus,
        onKeyDown,
        placeholder,
        readOnly,
        required,
        title,
        value,
    } = props;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!onChange) {
            return;
        }

        onChange({
            ...event,
            target: {
                ...event.target,
                value: event.target.value.replace(/\.|\/| |_/g, '-'),
            },
        });
    };

    return (
        <input
            autoComplete={autoComplete}
            className={className}
            disabled={disabled}
            id={id}
            name={name}
            onBlur={onBlur}
            onChange={handleChange}
            onClick={onClick}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            readOnly={readOnly}
            required={required}
            title={title}
            type="text"
            ref={ref}
            value={value}
        />
    );
});

export default CustomDateInput;
