import { executeGetRequest, executePostRequest } from '@api/client';
import { createAddCommentPath, createFetchCommentsPath } from '@routing/apiUrls';
import { SavedComments, Comment } from '@models/Comments';

export const fetchComments = async (documentId: number, signal?: AbortSignal): Promise<SavedComments> => {
    const result = await executeGetRequest<SavedComments>(createFetchCommentsPath(documentId), signal);
    return result.data;
};

export const addComment = async (documentId: number, comment: Comment): Promise<void> => {
    const result = await executePostRequest<void>(createAddCommentPath(documentId), comment);
    return result.data;
};
