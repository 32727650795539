import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { fetchFile } from '@repository/fileRepository';
import { File } from '@models/File';
import { minutesToMillis } from '@utils/millis';

const useFetchDocumentFile = (documentId: number): UseQueryResult<File> => {
    return useQuery({
        queryKey: [QueryKey.File, documentId],
        queryFn: ({ signal }) => fetchFile(documentId, signal),
        staleTime: minutesToMillis(30),
    });
};

export default useFetchDocumentFile;
