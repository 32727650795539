import React, { FC, useMemo, useState } from 'react';
import ModalFooter from '@components/primitives/modal/components/ModalFooter';
import ModalContent from '@components/primitives/modal/components/ModalContent';
import Button from '@components/primitives/button/Button';
import { useTranslation } from 'react-i18next';
import Throbber from '@components/primitives/throbber/Throbber';
import { formatDateTime } from '@utils/formatDate';
import Message from '@components/primitives/message/Message';
import Text from '@components/primitives/text/Text';
import useFetchDocumentAudits from '@components/modals/documentAuditModal/hooks/useFetchDocumentAudits';
import classNames from 'classnames';
import { createColumnHelper } from '@tanstack/react-table';
import DataGrid from '@components/primitives/dataGrid/DataGrid';
import { closedModalState } from '@js/store/modalAtoms';
import { useSetAtom, useAtomValue } from 'jotai';
import { documentAuditModalAtom, DocumentAuditModalData } from '@js/store/modals/documentAuditModalAtom';
import { createStorageKey } from '@utils/storage';
import { StorageKeys } from '@constants/storageKeys';
import { currentDocClassAtom } from '@js/store/appAtoms';

interface Props {
    modalData: DocumentAuditModalData;
}

const DocumentAuditModalInner: FC<Props> = ({ modalData }) => {
    const { documentId } = modalData;
    const currentDocClass = useAtomValue(currentDocClassAtom);
    const setModalState = useSetAtom(documentAuditModalAtom);
    const [detailed, setDetailed] = useState<boolean>(false);
    const { data: documentAudits, isLoading } = useFetchDocumentAudits(documentId);
    const { t } = useTranslation();

    const onToggleDetailed = () => {
        setDetailed((detailed) => {
            return !detailed;
        });
    };

    const data = useMemo(() => {
        if (!documentAudits?.list) {
            return [];
        }

        return documentAudits.list
            .filter((row) => {
                if (detailed) {
                    return true;
                }

                return row.level === 10 || row.level === 20;
            })
            .map((row) => {
                const obj: any = {};
                Object.entries(row).forEach(([key, value]) => {
                    // const El = key === 'text' && row.level === 10 ? 'strong' : 'span';

                    if (typeof value === 'object') {
                        obj[key] = `${value.name} ${value.id ? `(${value.id})` : ''}`;
                    } else if (key === 'dateTime') {
                        obj[key] = new Date(value);
                    } else {
                        obj[key] = value;
                    }
                });
                return obj;
            });
    }, [documentAudits?.list, detailed]);

    const columnHelper = createColumnHelper<any>();
    const columns = useMemo(() => {
        return [
            columnHelper.accessor('dateTime', {
                header: t('history.columns.date', { ns: 'documentActions' }),
                size: 200,
                enableSorting: false,
                enableColumnFilter: false,
                cell: (info) => {
                    return <>{formatDateTime(info.getValue(), false, true)}</>;
                },
            }),
            columnHelper.accessor('user', {
                header: t('history.columns.user', { ns: 'documentActions' }),
                size: 200,
                enableSorting: false,
                filterFn: 'fuzzy',
                sortingFn: 'alphanumeric',
            }),
            columnHelper.accessor('text', {
                header: t('history.columns.description', { ns: 'documentActions' }),
                size: 758,
                enableSorting: false,
                cell: (info) => {
                    return <div className="audits__description"><Text text={info.getValue()} /></div>;
                },
                filterFn: 'fuzzy',
                sortingFn: 'alphanumeric',
            }),
        ];
    }, []);

    return (
        <>
            <ModalContent>
                {isLoading ? (
                    <Throbber />
                ) : documentAudits?.list.length ? (
                    <div className="dataGrid__wrapper audits__grid">
                        <DataGrid
                            name="documentAudit"
                            pageSize={1000}
                            columns={columns}
                            data={data}
                            storageKey={createStorageKey([currentDocClass, StorageKeys.GridDocumentAudit])}
                            renderNrOfRows={(nrOfRows, nrOfFilteredRows) => {
                                if (nrOfRows === nrOfFilteredRows) {
                                    return (
                                        <>{t('history.nrOfRows', { ns: 'documentActions', count: nrOfRows })}</>
                                    );
                                }

                                return (
                                    <>{t('history.filteredNrOfRows', { ns: 'documentActions', count: nrOfRows, nrOfFilteredRows })}</>
                                );
                            }}
                            columnHelper={columnHelper}
                            getRowProps={(row) => {
                                const classes = classNames('dataGrid__tr', {
                                    'dataGrid__tr--level10': row.original.level === 10,
                                });

                                return {
                                    className: classes,
                                };
                            }}
                        />
                    </div>
                ) : (
                    <Message isNoItems>
                        {t('history.noItemsMsg', { ns: 'documentActions' })}
                    </Message>
                )}
            </ModalContent>
            <ModalFooter>
                <Button type="submit" onClick={() => setModalState(closedModalState)} primary>{t('close')}</Button>
                <Button onClick={onToggleDetailed}>
                    {detailed ? t('history.short', { ns: 'documentActions' }) : t('history.detailed', { ns: 'documentActions' })}
                </Button>
            </ModalFooter>
        </>
    );
};

export default DocumentAuditModalInner;
