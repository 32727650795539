import React, { FC } from 'react';
import { Sizes } from '@utils/sizes';
import WorkflowActionValidationModalInner from '@components/modals/workflowActionValidationModal/WorkflowActionValidationModalInner';
import Modal from '@components/primitives/modal/Modal';
import { useAtom } from 'jotai';
import { workflowActionValidationModalAtom, WorkflowActionValidationModalData } from '@js/store/modals/workflowActionValidationModalAtom';

const WorkflowActionValidationModal: FC = () => {
    const [modalState, setModalState] = useAtom(workflowActionValidationModalAtom);

    if (!modalState.data) {
        return null;
    }

    return (
        <Modal<WorkflowActionValidationModalData>
            modalState={modalState}
            setModalState={setModalState}
            size={Sizes.md}
            onClose={modalState.data.onCanceled}
            title={modalState.data.title}
        >
            <WorkflowActionValidationModalInner
                modalData={modalState.data}
            />
        </Modal>
    );
};

export default WorkflowActionValidationModal;
