import React, { FC, MutableRefObject, useCallback, useEffect, useState } from 'react';
import Text from '@components/primitives/text/Text';
import { DocumentAnnotation } from '@models/DocumentAnnotations';
import { getOriginalFileHeight, getOriginalFileWidth } from '@utils/documentInfo';
import useUpdateAnnotation from '@components/features/annotations/hooks/useUpdateAnnotation';
import classNames from 'classnames';
import { useSetAtom } from 'jotai';
import { annotationModalAtom } from '@js/store/modals/annotationModalAtom';

interface Props {
    annotation: DocumentAnnotation;
    documentId: number;
    page: number;
    wrapperRef: MutableRefObject<HTMLDivElement | null>;
    readOnly?: boolean;
    fileWidth: number;
    fileHeight: number;
}

interface BackendPosition {
    left: number;
    top: number;
}

const Annotation: FC<Props> = ({ annotation, documentId, wrapperRef, page, readOnly, fileWidth, fileHeight }) => {
    const [draggableAnnotation, setDraggableAnnotation] = useState<number | null>(null);
    const [leftOffset, setLeftOffset] = useState<number>(0);
    const [topOffset, setTopOffset] = useState<number>(0);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [left, setLeft] = useState<number | null>(null);
    const [leftPercentage, setLeftPercentage] = useState<number | null>(null);
    const [topPercentage, setTopPercentage] = useState<number | null>(null);
    const [top, setTop] = useState<number | null>(null);
    const { mutate: updateAnnotation } = useUpdateAnnotation(documentId);
    const setAnnotationModal = useSetAtom(annotationModalAtom);
    const [savedLeft, savedTop] = annotation.location.split(', ');
    const MIN_LEFT = -100;
    const MAX_LEFT = 200;
    const MIN_TOP = 0;
    const MAX_TOP = 100;

    const classes = classNames('annotation', {
        'annotation--readOnly': annotation.readOnly || readOnly,
    });

    useEffect(() => {
        setLeft(+savedLeft);
        setTop(+savedTop);
        // eslint-disable-next-line
    }, []);

    const annotationRef = useCallback((annotationNode: HTMLDivElement) => {
        if (!left || !top || !wrapperRef?.current) {
            return;
        }

        let leftPercentage = (+left / getOriginalFileWidth(fileWidth)) * 100;

        if (leftPercentage < MIN_LEFT) {
            leftPercentage = MIN_LEFT;
        }
        // @ts-ignore
        const wrapperOffset = wrapperRef.current.getBoundingClientRect();
        const annotationOffset = annotationNode?.getBoundingClientRect();
        const annotationWidthPercentage = (annotationOffset?.width / wrapperOffset?.width) * 100;
        const annotationHeightPercentage = (annotationOffset?.height / wrapperOffset?.height) * 100;
        const MAX_LEFT_INCL_WIDTH = MAX_LEFT - annotationWidthPercentage;
        const MAX_TOP_INCL_HEIGHT = MAX_TOP - annotationHeightPercentage;

        if (leftPercentage > MAX_LEFT_INCL_WIDTH) {
            leftPercentage = MAX_LEFT_INCL_WIDTH;
        }

        setLeftPercentage(leftPercentage);

        let topPercentage = (+top / getOriginalFileHeight(fileHeight)) * 100;

        if (topPercentage < MIN_TOP) {
            topPercentage = MIN_TOP;
        }

        if (topPercentage > MAX_TOP_INCL_HEIGHT) {
            topPercentage = MAX_TOP_INCL_HEIGHT;
        }

        setTopPercentage(topPercentage);
    }, [left, top]);

    const toBackendPosition = useCallback((x: number, y: number) : BackendPosition => {
        if (!wrapperRef?.current) {
            return { left: 0, top: 0 };
        }

        const viewportOffset = wrapperRef.current.getBoundingClientRect();
        const positionLeftPx = x - viewportOffset.left;
        const positionTopPx = y - viewportOffset.top;
        const positionLeftPercentage = (positionLeftPx / viewportOffset.width) * 100;
        const positionTopPercentage = (positionTopPx / viewportOffset.height) * 100;
        const backendPositionLeft = (getOriginalFileWidth(fileWidth) / 100) * positionLeftPercentage;
        const backendPositionTop = (getOriginalFileHeight(fileHeight) / 100) * positionTopPercentage;

        return {
            left: backendPositionLeft,
            top: backendPositionTop,
        };
    }, [wrapperRef]);

    const onAnnotationClick = () => {
        if (typeof left !== 'number' || typeof top !== 'number' || isDragging || annotation.readOnly || readOnly) {
            return;
        }
        setAnnotationModal({
            isOpen: true,
            data: {
                page,
                documentId,
                position: [+left, +top],
                annotation,
            },
        });
    };

    const onMouseMove = useCallback((e: MouseEvent) => {
        if (!draggableAnnotation || !wrapperRef?.current || annotation.readOnly || readOnly) {
            return;
        }
        setIsDragging(true);
        const { left, top } = toBackendPosition(e.pageX, e.pageY);

        setLeft(left - leftOffset);
        setTop(top - topOffset);
    }, [draggableAnnotation, annotation, leftOffset, topOffset, toBackendPosition, wrapperRef]);

    const onMouseUp = useCallback(async (e: MouseEvent) => {
        if (readOnly) {
            return;
        }
        const { left, top } = toBackendPosition(e.pageX, e.pageY);
        setDraggableAnnotation(null);
        setLeftOffset(0);
        setTopOffset(0);
        setTimeout(() => {
            setIsDragging(false);
        }, 100);

        if (!isDragging) {
            return;
        }

        await updateAnnotation({
            data: {
                page: annotation.page,
                text: annotation.text,
                location: `${(left - leftOffset).toFixed(0)}, ${(top - topOffset).toFixed(0)}`,
            },
            id: annotation.id,
        });
    }, [isDragging, annotation, leftOffset, topOffset, toBackendPosition]);

    const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!wrapperRef?.current) {
            return;
        }

        setDraggableAnnotation(annotation.id);
        const { left, top } = toBackendPosition(e.pageX, e.pageY);

        setLeftOffset(left - +savedLeft);
        setTopOffset(top - +savedTop);
    };

    useEffect(() => {
        if (draggableAnnotation !== null) {
            window.addEventListener('mousemove', onMouseMove);
            window.addEventListener('mouseup', onMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        };
    }, [draggableAnnotation, onMouseMove, onMouseUp]);

    if (typeof left !== 'number' || typeof top !== 'number') {
        return null;
    }

    return (
        <div
            onMouseDown={onMouseDown}
            onClick={onAnnotationClick}
            ref={annotationRef}
            className={classes}
            style={{
                left: `${leftPercentage}%`,
                top: `${topPercentage}%`,
            }}
        >
            <Text key={annotation.id} text={annotation.text} />
        </div>
    );
};

export default Annotation;
