import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { toast } from 'react-toastify';
import { extractNumbers, sum } from '@utils/numberUtilities';
import { useTranslation } from 'react-i18next';
import useFetchDocumentInvoiceLines from '@components/features/documentInvoiceLines/hooks/useFetchDocumentInvoiceLines';
import { DocumentInvoiceLine } from '@models/DocumentInvoiceLines';
import { updateDocumentInvoiceLine } from '@repository/documentInvoiceLinesRepository';

type UpdateMutation = {
    lineId: number;
    data: DocumentInvoiceLine;
};

const useUpdateDocumentInvoiceLine = (documentId: number) => {
    const client = useQueryClient();
    const { data: documentInvoiceLines } = useFetchDocumentInvoiceLines(documentId);
    const { t } = useTranslation();

    const getFieldDefinition = (fieldId: string) => {
        if (!documentInvoiceLines?.definitions) {
            return null;
        }

        return documentInvoiceLines.definitions.find((field) => field.id === fieldId);
    };

    const purifyData = (data: DocumentInvoiceLine) => {
        const purifiedData = {
            values: data?.values?.map((matchingField) => {
                const field = getFieldDefinition(matchingField.id as string);
                if (!field || !matchingField.value) {
                    return matchingField;
                }

                let { value } = matchingField;
                if (typeof value === 'string' && field.maxLength && value.length > field.maxLength) {
                    toast.warn(t('validation.ocr.tooLarge', { maxLength: field.maxLength }));
                    value = value.substring(0, field.maxLength);
                }

                if (field.format === 'decimal') {
                    value = sum(extractNumbers(matchingField.value.toString()));
                }

                return {
                    ...matchingField,
                    value,
                };
            }),
        };

        return purifiedData;
    };

    return useMutation({
        mutationFn: (mutation: UpdateMutation) => {
            const purifiedData = purifyData(mutation.data);
            return updateDocumentInvoiceLine(documentId, mutation.lineId, purifiedData);
        },
        onSuccess: async () => {
            await Promise.all([
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentInvoiceLines, documentId],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentOcrSummary, documentId],
                }),
            ]);
        },
    });
};

export default useUpdateDocumentInvoiceLine;
