import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import useFetchDocument from '@hooks/useFetchDocument';
import useDocumentStatus from '@hooks/useDocumentStatus';
import { DocumentInvoiceLines } from '@models/DocumentInvoiceLines';
import { fetchDocumentInvoiceLines } from '@repository/documentInvoiceLinesRepository';
import { minutesToMillis } from '@utils/millis';

const useFetchDocumentInvoiceLines = (documentId: number): UseQueryResult<DocumentInvoiceLines> => {
    const { isEditMode } = useDocumentStatus();
    const { data: document } = useFetchDocument(documentId, isEditMode);

    return useQuery({
        queryKey: [QueryKey.DocumentInvoiceLines, documentId],
        queryFn: ({ signal }) => fetchDocumentInvoiceLines(documentId, signal),
        staleTime: minutesToMillis(2),
        enabled: document?.meta.matchingEnabled || false,
    });
};

export default useFetchDocumentInvoiceLines;
