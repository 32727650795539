import OcrLine from '@components/features/ocr/components/OcrLine';
import { FC, memo } from 'react';
import { DocumentOcrData, DocumentOcrWord } from '@models/DocumentOcrData';

interface Props {
    page: number;
    onOcrClick?: (ocrItem: DocumentOcrWord) => void;
    selectedOcrItems: DocumentOcrWord[];
    documentOcrData?: DocumentOcrData;
    isDragging: boolean;
    fileWidth: number;
    fileHeight: number;
    rerenderDependency?: any[];
    shiftPressed?: boolean;
}

const AllOcrRectangles: FC<Props> = ({
    documentOcrData,
    page,
    onOcrClick,
    selectedOcrItems,
    isDragging,
    fileWidth,
    fileHeight,
    rerenderDependency,
    shiftPressed,
}) => {
    if (!documentOcrData?.lines?.length) {
        return null;
    }

    return (
        <div className="ocrData">
            {documentOcrData.lines.map((ocrLine, index) => (
                <OcrLine
                    isDragging={isDragging}
                    page={page}
                    onOcrClick={onOcrClick}
                    ocrLine={ocrLine}
                    key={index}
                    selectedOcrItems={selectedOcrItems}
                    fileWidth={fileWidth}
                    fileHeight={fileHeight}
                    rerenderDependency={rerenderDependency}
                    shiftPressed={shiftPressed}
                />
            ))}
        </div>
    );
};

const propsAreEqual = (prevProps: Props, nextProps: Props) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};

export default memo(AllOcrRectangles, propsAreEqual);
