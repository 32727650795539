import stringToObject from '@utils/stringToObject';
import { OPTIONAL_KEY } from '@components/features/lookup/hooks/useLookup';

export const paramsAreFilled = (input: string | null, params: any, ignoreMissingFields?: boolean): boolean => {
    if (!input) {
        return false;
    }

    // No params needed
    if (input?.indexOf('&') === -1) {
        return true;
    }

    const neededParams = stringToObject(input);

    let atLeastOneParamIsRequired = false;

    Object.entries(neededParams).forEach(([, value]) => {
        if (atLeastOneParamIsRequired) {
            return;
        }

        atLeastOneParamIsRequired = value.indexOf(OPTIONAL_KEY) === -1;
    });

    // Params needed, not received
    if ((!params || !Object.entries(params).length) && !ignoreMissingFields && atLeastOneParamIsRequired) {
        return false;
    }

    const filteredNeededParams = Object.entries(neededParams).reduce((result, [key, value]) => {
        if (typeof params[key] !== 'string' && ignoreMissingFields) {
            return result;
        }

        if (value.indexOf(OPTIONAL_KEY) !== -1) {
            return result;
        }

        return {
            ...result,
            [key]: value,
        };
    }, {});

    let result = true;
    for (const [key] of Object.entries(filteredNeededParams)) {
        if (key !== 'fieldname' && !params[key]) {
            result = false;
        }
    }

    return result;
};
