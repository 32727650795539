import ModalContent from '@components/primitives/modal/components/ModalContent';
import ModalFooter from '@components/primitives/modal/components/ModalFooter';
import Button from '@components/primitives/button/Button';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Message from '@components/primitives/message/Message';
import Text from '@components/primitives/text/Text';
import { closedModalState } from '@js/store/modalAtoms';
import { workflowActionValidationModalAtom, WorkflowActionValidationModalData } from '@js/store/modals/workflowActionValidationModalAtom';
import { useSetAtom } from 'jotai';

interface Props {
    modalData: WorkflowActionValidationModalData;
}

const WorkflowActionValidationModalInner: FC<Props> = ({ modalData }) => {
    const { onConfirm, validations, onCanceled } = modalData;
    const setModalState = useSetAtom(workflowActionValidationModalAtom);
    const { t } = useTranslation();
    const closeModal = () => {
        setModalState(closedModalState);
    };
    const handleConfirm = () => {
        onConfirm();
        closeModal();
    };

    const handleCancel = () => {
        if (onCanceled) {
            onCanceled();
        }
        closeModal();
    };

    return (
        <>
            <ModalContent>
                {validations.length ? (
                    <Message isError>
                        <ul>
                            {validations.map((text) => <li key={text}><Text text={text} /></li>)}
                        </ul>
                    </Message>
                ) : null}
                <br />
                <p>
                    {t('validationModal.text', { ns: 'workflowActions' })}
                </p>
            </ModalContent>
            <ModalFooter>
                <Button type="submit" onClick={handleConfirm} primary>{t('yes')}</Button>
                <Button isLink onClick={handleCancel}>{t('cancel')}</Button>
            </ModalFooter>
        </>
    );
};

export default WorkflowActionValidationModalInner;
