import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { DocumentDynamicAssignStep } from '@models/DocumentDynamicAssign';

const useDynamicAssignSchema = (steps?: DocumentDynamicAssignStep[]) => {
    const { t } = useTranslation();
    const validationObject: any = {};

    steps?.forEach((step) => {
        if (step.readOnly) {
            validationObject[`assignment_${step.index}`] = yup.string();
            return;
        }

        validationObject[`assignment_${step.index}`] = yup.string().required(t('validation.dynamicAssign.executedBy.required', { ns: 'workflowActions' }));
    });

    return yup.object(validationObject);
};

export default useDynamicAssignSchema;
