import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { fetchDocList } from '@repository/docListsRepository';
import { DocList } from '@models/DocList';
import { minutesToMillis } from '@utils/millis';

const useFetchDocList = (docClass?: string, listType?: string, config?: object): UseQueryResult<DocList> => {
    return useQuery({
        queryKey: [QueryKey.DocList, docClass, listType],
        queryFn: docClass && listType ? ({ signal }) => fetchDocList(docClass, listType, signal) : undefined,
        enabled: !!docClass && !!listType,
        staleTime: minutesToMillis(2),
        ...config,
    });
};

export default useFetchDocList;
