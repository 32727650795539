import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { fetchDocumentHeaders } from '@repository/documentHeadersRepository';
import { DocumentHeaders } from '@models/DocumentHeaders';
import { minutesToMillis } from '@utils/millis';

const useFetchDocumentHeaders = (documentId?: number): UseQueryResult<DocumentHeaders> => {
    return useQuery({
        queryKey: [QueryKey.DocumentHeaders, documentId],
        queryFn: documentId ? ({ signal }) => fetchDocumentHeaders(documentId, signal) : undefined,
        staleTime: minutesToMillis(1),
        enabled: !!documentId,
    });
};

export default useFetchDocumentHeaders;
