import { executeGetRequest, executePutRequest } from '@api/client';
import { createFetchDocumentHeadersPath, createUpdateDocumentHeadersPath } from '@routing/apiUrls';
import { DocumentHeaders, UpdateDocumentHeaders, UpdateDocumentHeadersResponse } from '@models/DocumentHeaders';

export const fetchDocumentHeaders = async (documentId: number, signal?: AbortSignal): Promise<DocumentHeaders> => {
    const result = await executeGetRequest<DocumentHeaders>(createFetchDocumentHeadersPath(documentId), signal);
    return result.data;
};

export const updateDocumentHeaders = async (documentId: number, data: UpdateDocumentHeaders): Promise<UpdateDocumentHeadersResponse> => {
    const result = await executePutRequest<UpdateDocumentHeadersResponse>(createUpdateDocumentHeadersPath(documentId), data);
    return result.data;
};
