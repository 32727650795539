import { replacePlaceholdersInString } from '@utils/stringUtilities';
import { local } from '@utils/storage';
import { StorageKeys } from '@constants/storageKeys';
import { CopyCodingData } from '@models/DocumentCoding';
import { OPTIONAL_KEY } from '@components/features/lookup/hooks/useLookup';
import { UpdateSamCodingSourcesMutation } from '@models/SamCodingSources';
import { SamCopyCodingMutation } from '@models/SamCopyCoding';

type Params = {
    [key: string]: string;
};

// Authenticate
export function createLoginApiPath(username: string, password: string): string {
    return `/authenticate?username=${username}&password=${password}`;
}

export function createAuthenticateApiPath(tenantId?: string): string {
    const tenant = tenantId || local.getItem(StorageKeys.TenantId);

    let domain = `https://${tenant}.workflowindecloud.nl/api`;

    if (tenant?.toLowerCase() === 'local') {
        domain = 'https://localhost:44325/api';
    }

    if (tenant?.toLowerCase() === 'dev') {
        domain = 'https://devweb.docbaseweb.nl/api';
    }

    if (tenant?.toLowerCase() === 'test') {
        domain = 'https://testweb.docbaseweb.nl/api';
    }

    if (tenant?.toLowerCase() === 'acc') {
        domain = 'https://accweb.docbaseweb.nl/api';
    }

    return `${domain}/authenticate`;
}

// User
export function createFetchCurrentUserPath(): string {
    return '/profile';
}

export function createUpdateCurrentUserPath(): string {
    return '/profile';
}

// Docclasses
export function createFetchDocClassesPath(): string {
    return '/docclasses';
}

// SuperVisor Actions
export function createFetchSuperVisorActionsPath(documentId: number): string {
    return `/supervisor/actions?documentId=${documentId}`;
}

export function createPerformSuperVisorActionPath(documentId: number): string {
    return `/supervisor/actions?documentId=${documentId}`;
}

// Search
export function createSearchDocumentPath(docClass: string): string {
    return `/documentlist/search?docclass=${docClass}`;
}

export function createFetchSearchFieldsPath(docClass: string): string {
    return `/documentlist/searchfields?docclass=${docClass}`;
}

// Doclists
export function createFetchDocListsPath(docClass: string): string {
    return `/documentlists?docclass=${docClass}`;
}

export function createFetchDocListPath(docClass: string, listType: string): string {
    return `/documentlist?docclass=${docClass}&listtype=${listType}`;
}

export function createFetchDocListCountPath(docClass: string, listType: string): string {
    return `/documentlist/count?docclass=${docClass}&listtype=${listType}`;
}

// Document
export function createFetchDocumentPath(documentId: number, editMode?: boolean): string {
    return `/document?documentid=${documentId.toString()}&editmode=${editMode ? 'true' : 'false'}`;
}

export function createValidateDocumentPath(documentId: number, actionId: string): string {
    return `/document/validate?documentid=${documentId.toString()}&actionId=${actionId}`;
}

// Document headers
export function createFetchDocumentHeadersPath(documentId: number): string {
    return `/document/headers?documentid=${documentId.toString()}`;
}

export function createUpdateDocumentHeadersPath(documentId: number): string {
    return `/document/headers?documentid=${documentId.toString()}`;
}

// Document external info
export function createFetchDocumentExternalInfoPath(documentId: number): string {
    return `/document/externalinfo?documentid=${documentId.toString()}`;
}

// Document Ocr
export function createFetchDocumentOcrSummaryPath(documentId: number): string {
    return `/document/ocrsummary?documentid=${documentId.toString()}`;
}

export function createFetchDocumentOcrDataPath(documentId: number, page: number): string {
    return `/document/ocrpage?documentid=${documentId.toString()}&page=${page.toString()}`;
}

// Ocr Training
export function createFetchOcrTrainingPath(documentId: number): string {
    return `/document/ocrtraining?documentid=${documentId.toString()}`;
}

export function createFetchOcrTrainingFieldPath(documentId: number, fieldId: string): string {
    return `/document/ocrtraining/field?documentid=${documentId.toString()}&fieldid=${fieldId}`;
}

export function createDeleteOcrTrainingFieldPath(documentId: number, fieldId: string): string {
    return `/document/ocrtraining/field?documentid=${documentId.toString()}&fieldid=${fieldId}`;
}

export function createAddOcrTrainingFieldPath(documentId: number): string {
    return `/document/ocrtraining/field?documentid=${documentId.toString()}`;
}

export function createUpdateOcrTrainingFieldPath(documentId: number, fieldId: string): string {
    return `/document/ocrtraining/field?documentid=${documentId.toString()}&fieldid=${fieldId}`;
}

export function createApplyOcrTrainingPath(documentId: number): string {
    return `/document/ocrtraining/apply?documentid=${documentId.toString()}`;
}

export function createUpdateOcrTrainingSqlPath(documentId: number): string {
    return `/document/ocrtraining/posttrainingsql?documentid=${documentId.toString()}`;
}

export function createDeleteOcrTrainingSqlPath(documentId: number): string {
    return `/document/ocrtraining/posttrainingsql?documentid=${documentId.toString()}`;
}

export function createFetchOcrTrainingLogPath(documentId: number): string {
    return `/document/ocrtraining/gettraininglog?documentid=${documentId.toString()}`;
}

export function createFetchOcrCodingTrainingPath(documentId: number): string {
    return `/document/ocrtraining/codingtraining?documentid=${documentId.toString()}`;
}

export function createAddOcrCodingTrainingPath(documentId: number): string {
    return `/document/ocrtraining/codingtraining?documentid=${documentId.toString()}`;
}

export function createDeleteOcrCodingTrainingPath(documentId: number): string {
    return `/document/ocrtraining/codingtraining?documentid=${documentId.toString()}`;
}

// Document template sharing
export function createFetchSharedTemplatesPath(documentId: number, limitToEnabledTemplates: boolean): string {
    return `/document/ocrtraining/sharedtemplates?documentid=${documentId.toString()}&onlyEnabled=${limitToEnabledTemplates ? 'true' : 'false'}`;
}

export function createApplySharedTemplatePath(documentId: number, templateId: string): string {
    return `/document/ocrtraining/sharedtemplate/apply?documentid=${documentId.toString()}&templateid=${templateId}`;
}

export function createDeclineSharedTemplatePath(documentId: number, templateId: string): string {
    return `/document/ocrtraining/sharedtemplate/decline?documentid=${documentId.toString()}&templateid=${templateId}`;
}

export function createConfirmSharedTemplatePath(documentId: number, templateId: string): string {
    return `/document/ocrtraining/sharedtemplate/confirm?documentid=${documentId.toString()}&templateid=${templateId}`;
}

export function createSetEnabledSharedTemplatePath(documentId: number, templateId: string, enabled: boolean): string {
    return `/document/ocrtraining/sharedtemplate/setenabled?documentid=${documentId.toString()}&templateid=${templateId}&enable=${enabled ? 'true' : 'false'}`;
}

export function createUpdateSharedTemplatePath(documentId: number, templateId: string, name: string): string {
    return `/document/ocrtraining/sharedtemplate/rename?documentid=${documentId.toString()}&templateid=${templateId}&newname=${name}`;
}

// Document dynamic assign
export function createFetchDocumentDynamicAssignPath(documentId: number): string {
    return `/document/assign?documentid=${documentId.toString()}`;
}

// Document annotations
export function createFetchDocumentAnnotationsPath(documentId: number): string {
    return `/document/annotations?documentid=${documentId.toString()}`;
}

export function createAddDocumentAnnotationPath(documentId: number): string {
    return `/document/annotations?documentid=${documentId.toString()}`;
}

export function createUpdateDocumentAnnotationPath(documentId: number, annotationId: number): string {
    return `/document/annotations?documentid=${documentId.toString()}&annotationid=${annotationId.toString()}`;
}

export function createDeleteDocumentAnnotationPath(documentId: number, annotationId: number): string {
    return `/document/annotations?documentid=${documentId.toString()}&annotationid=${annotationId.toString()}`;
}

// Document audits
export function createFetchDocumentAuditsPath(documentId: number): string {
    return `/document/audits?documentid=${documentId.toString()}`;
}

// Document upload
export function createFetchDocumentUploadUrl(documentId: number): string {
    return `/document/upload?documentid=${documentId.toString()}`;
}

// Document link
export function createLinkDocumentPath(documentId: number, hmac: string, fileName: string): string {
    return `/document/upload?documentId=${documentId.toString()}&hmac=${hmac}&fileName=${encodeURIComponent(fileName)}`;
}

// Document actions
export function createFetchDocumentActionsPath(documentId: number): string {
    return `/document/actions?documentid=${documentId.toString()}`;
}

export function createPerformDocumentActionPath(documentId: number): string {
    return `/document/actions?documentid=${documentId}`;
}

export function createRotateDocumentPagePath(documentId: number): string {
    return `/document/rotate?documentId=${documentId}`;
}

export function createMoveDocumentPagePath(documentId: number): string {
    return `/document/movepage?documentId=${documentId}`;
}

export function createRemoveDocumentOwnerCheckPath(documentId: number): string {
    return `/document/removepages?documentId=${documentId}`;
}

export function createRemoveDocumentPagesPath(documentId: number): string {
    return `/document/removepages?documentId=${documentId}`;
}

// Document coding
export function createFetchDocumentCodingPath(documentId: number): string {
    return `/document/coding?documentid=${documentId.toString()}`;
}

export function createFetchDocumentCodingDefaultsPath(documentId: number): string {
    return `/document/coding/defaults?documentid=${documentId.toString()}`;
}

export function createAddDocumentCodingLinePath(documentId: number): string {
    return `/document/coding?documentid=${documentId.toString()}`;
}

export function createUpdateDocumentCodingLinePath(documentId: number, lineId: number): string {
    return `/document/coding?documentid=${documentId.toString()}&rowid=${lineId.toString()}`;
}

export function createUpdateDocumentCodingLinesPath(documentId: number): string {
    return `/document/coding/bulk?documentid=${documentId.toString()}`;
}

export function createDeleteDocumentCodingLinePath(documentId: number): string {
    return `/document/coding?documentid=${documentId.toString()}`;
}

export function createFetchCopyCodingListPath(documentId: number): string {
    return `/copycoding/getlist?docid=${documentId.toString()}`;
}

export function createCopyCodingPath(data: CopyCodingData): string {
    return `/copycoding/copylines?docid=${data.docid}&fromdocid=${data.fromdocid}`;
}

// Coding Accruals
export function createFetchCodingAccrualsPath(documentId: number): string {
    return `/document/coding/accruals?documentid=${documentId.toString()}`;
}

export function createUpdateCodingAccrualsPath(documentId: number, lineId: number): string {
    return `/document/coding/accruals?documentid=${documentId.toString()}&rowid=${lineId.toString()}`;
}

// Document Invoice Lines
export function createFetchDocumentInvoiceLinesPath(documentId: number): string {
    return `/document/matching?documentid=${documentId.toString()}`;
}

export function createAddDocumentInvoiceLinePath(documentId: number): string {
    return `/document/matching?documentid=${documentId.toString()}`;
}

export function createUpdateDocumentInvoiceLinePath(documentId: number, lineId: number): string {
    return `/document/matching?documentid=${documentId.toString()}&rowid=${lineId.toString()}`;
}

export function createDeleteDocumentInvoiceLinePath(documentId: number): string {
    return `/document/matching?documentid=${documentId.toString()}`;
}

// Document matching
export function createRefreshDocumentMatchingPath(documentId: number): string {
    return `/document/matching/refresh?documentid=${documentId.toString()}`;
}

export function createLinkDocumentMatchingLinePath(documentId: number): string {
    return `/document/matching/link?documentid=${documentId.toString()}`;
}

export function createUnlinkDocumentMatchingLinePath(documentId: number, rowId: number): string {
    return `/document/matching/link?documentid=${documentId.toString()}&rowid=${rowId.toString()}`;
}

export function createFetchDocumentMatchingOrderPath(documentId: number, filtered: boolean): string {
    return `/document/matching/orderlookup?documentid=${documentId.toString()}&filtered=${filtered ? 'true' : 'false'}`;
}

export function createCopyDocumentMatchingOrderLinePath(documentId: number): string {
    return `/document/matching/copy?documentid=${documentId.toString()}`;
}

export function createApproveMatchingDifferencePath(documentId: number): string {
    return `/document/matching/approvedifference?documentid=${documentId.toString()}`;
}

export function createRejectMatchingDifferencePath(documentId: number): string {
    return `/document/matching/rejectdifference?documentid=${documentId.toString()}`;
}

// Document file
export function createFetchFilePath(documentId: number): string {
    return `/document/file?documentid=${documentId.toString()}`;
}

export function createFetchFileRepairedPath(documentId: number): string {
    return `/document/filerepaired?documentid=${documentId.toString()}`;
}

export function createRepairFilePath(documentId: number): string {
    return `/document/repairfile?documentid=${documentId.toString()}`;
}

export function createRotatePdfPagePath(documentId: number): string {
    return `/document/rotate?documentId=${documentId}`;
}

// Comments
export function createFetchCommentsPath(documentId: number): string {
    return `/document/comments?documentid=${documentId.toString()}`;
}

export function createAddCommentPath(documentId: number): string {
    return `/document/comments?documentid=${documentId.toString()}`;
}

// Creditor Validation
export function createFetchCreditorValidationPath(documentId: number): string {
    return `/document/creditorvalidation?documentid=${documentId.toString()}`;
}

// Lookup
export function createFetchLookupPath(lookup: string, params: Params): string {
    const regex = /{([a-zA-Z0-9_|]+)}/gm;
    const match = lookup.match(regex);

    const placeholderReplacements: any = {};
    match?.forEach((placeholder) => {
        const field = placeholder.replace('{', '').replace('}', '').replace(OPTIONAL_KEY, '');
        placeholderReplacements[placeholder] = params[field] ? encodeURIComponent(params[field]) : '';
    });

    const url = replacePlaceholdersInString(lookup, placeholderReplacements);
    return `/${url}`;
}

export function createRefreshLookupPath(documentId: number, fieldName: string, key: string): string {
    return `/lookup/refresh?docid=${documentId}&fieldName=${fieldName}&Key=${key}`;
}

// Mailtemplates
export function createFetchMailTemplatesPath(): string {
    return '/mail/templates';
}

export function createFetchMailTemplatePath(templateId: number, documentId?: number): string {
    return `/mail/template/?documentId=${documentId}&templateId=${templateId}`;
}

// LineSource
export function createFetchLineSourcePath(documentId: number): string {
    return `/document/coding/linesource?documentid=${documentId.toString()}`;
}

export function createUpdateLineSourcePath(documentId: number): string {
    return `/document/coding/linesource?documentid=${documentId.toString()}`;
}

export function createSendMailPath(): string {
    return '/mail/send';
}

// Sam Options
export function createFetchSamCodingSourcesPath(documentId: number): string {
    return `/sam/codingsources/?docid=${documentId.toString()}`;
}

export function createUpdateSamCodingSourcesPath(mutation: UpdateSamCodingSourcesMutation): string {
    return `/sam/codingsources/?docid=${mutation.documentId.toString()}&preferred=${mutation.preferred}&option=${mutation.option}`;
}

// Sam Copy Coding
export function createSamCopyCodingPath(mutation: SamCopyCodingMutation): string {
    return `/sam/copycoding/?docid=${mutation.documentId.toString()}&fromdocid=${mutation.fromDocId}`;
}

// Versions
export function createFetchVersionPath(): string {
    return '/version';
}

// Replacements
export function createFetchReplacementsPath(): string {
    return '/replacements';
}

export function createUpdateReplacementsPath(): string {
    return '/replacements';
}

export function createDeleteReplacementsPath(): string {
    return '/replacements';
}

// News
export function createFetchNewsPath(): string {
    return `${window.location.origin}/news.json`;
}

// Faq
export function createFetchIntroductionPath(): string {
    return `${window.location.origin}/introduction.json`;
}

// Faq
export function createFetchFaqPath(): string {
    return `${window.location.origin}/faq.json`;
}

// Release notes
export function createFetchReleaseNotesPath(): string {
    return `${window.location.origin}/releaseNotes.json`;
}

// Contact
export function createFetchContactPath(): string {
    return `${window.location.origin}/contact.json`;
}

// Videos
export function createFetchVideosPath(): string {
    return `${window.location.origin}/videos.json`;
}

// File handler2
export function createUploadToDocbasePath(): string {
    return '/files/uploadtodocbase';
}
export function createConvertToPdfPath(): string {
    return '/files/converttopdf';
}

// Import profiles
export function createEwsAuthenticatePath(tenantid: string, callback: string): string {
    return `/authews?tenantid=${tenantid}&callback=${callback}`;
}
export function createGetEwsFoldersPath(emailAddress: string): string {
    return `/admin/importprofiles/getfoldertree?emailaddress=${emailAddress}`;
}
export function createGetImportProfilesPath(): string {
    return '/admin/importprofiles';
}
export function createGetImportProfilePath(id: string): string {
    return `/admin/importprofile?id=${id}`;
}
export function createUpdateImportProfilePath(id: string): string {
    return `/admin/importprofile?id=${id}`;
}
export function createDeleteImportProfilePath(id: string): string {
    return `/admin/importprofile?id=${id}`;
}
export function createDuplicateImportProfilePath(id: string): string {
    return `/admin/importprofile`;
}