import useFetchDocumentCoding from '@components/features/coding/hooks/useFetchDocumentCoding';
import { DocumentCodingFieldValue, DocumentCodingRow } from '@models/DocumentCoding';
import { useCallback } from 'react';
import { DynamicField, DynamicFieldValue } from '@models/DynamicField';

const useDocumentCoding = (documentId?: number) => {
    const { data: documentCoding } = useFetchDocumentCoding(documentId);

    const getCodingLine = useCallback((lineId: number): DocumentCodingRow | undefined => {
        return documentCoding?.rows.find((row) => {
            const idField = row.values.find((fieldValue) => fieldValue.id === 'row');
            return idField?.value === lineId;
        });
    }, [documentCoding]);

    const getCodingLineField = useCallback((lineId: number, id: string): DocumentCodingFieldValue | undefined => {
        const codingLine = getCodingLine(lineId);

        return codingLine?.values.find((fieldValue) => fieldValue.id === id);
    }, [documentCoding]);

    const getCodingLineFieldDefinition = useCallback((id: string): DynamicField | undefined => {
        return documentCoding?.definitions.find((field) => field.id === id);
    }, [documentCoding]);

    const getCodingLineFieldValue = useCallback((lineId: number, id: string): string | number | null => {
        const field = getCodingLineField(lineId, id);

        return field?.value !== undefined ? field.value : null;
    }, [documentCoding]);

    const getNeighborCodingLineId = useCallback((lineId: number, direction?: 'next' | 'prev'): DynamicFieldValue | null => {
        const index = documentCoding?.rows.findIndex((r) => {
            return r.values.find((field) => field.id === 'row' && field.value === lineId);
        });

        if (!index) {
            return null;
        }

        const neighbor = direction === 'prev' ? documentCoding?.rows[index - 1] : documentCoding?.rows[index + 1];
        if (!neighbor) {
            return null;
        }

        const neighborIdField = neighbor.values.find((field) => field.id === 'row');
        return typeof neighborIdField?.value === 'number' ? neighborIdField?.value : null;
    }, [documentCoding]);

    return {
        documentCoding,
        getCodingLine,
        getCodingLineField,
        getCodingLineFieldValue,
        getCodingLineFieldDefinition,
        getNeighborCodingLineId,
    };
};

export default useDocumentCoding;
