import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateDocumentCodingLine } from '@repository/documentCodingRepository';
import { DocumentCoding, UpdateCodingField } from '@models/DocumentCoding';
import { QueryKey } from '@constants/queryKey';
import useFetchDocumentCoding from '@components/features/coding/hooks/useFetchDocumentCoding';
import { extractNumbers, sum } from '@utils/numberUtilities';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

type UpdateMutationValues = {
    values: UpdateCodingField[]
};
type UpdateMutation = {
    codingLineId: number;
    data: UpdateMutationValues;
};

const useUpdateCodingLine = (documentId: number) => {
    const client = useQueryClient();
    const { data: documentCoding } = useFetchDocumentCoding(documentId);
    const { t } = useTranslation();

    const getFieldDefinition = (fieldId: string) => {
        if (!documentCoding?.definitions) {
            return null;
        }

        return documentCoding.definitions.find((field) => field.id === fieldId);
    };

    const purifyData = (data: UpdateMutationValues) : UpdateMutationValues => {
        const purifiedData = {
            values: data.values.map((codingLine) => {
                const field = getFieldDefinition(codingLine.id as string);
                if (!field || !codingLine.value) {
                    return codingLine;
                }

                let { value } = codingLine;
                if (typeof value === 'string' && field.maxLength && value.length > field.maxLength) {
                    toast.warn(t('validation.ocr.tooLarge', { maxLength: field.maxLength }));
                    value = value.substring(0, field.maxLength);
                }

                if (field.format === 'decimal') {
                    value = sum(extractNumbers(codingLine.value.toString()));
                }

                return {
                    ...codingLine,
                    value,
                };
            }),
        };

        return purifiedData;
    };

    return useMutation({
        mutationFn: (mutation: UpdateMutation) => {
            const purifiedData = purifyData(mutation.data);
            return updateDocumentCodingLine(documentId, mutation.codingLineId, purifiedData);
        },
        onSuccess: async () => {
            await Promise.all([
                // Please note: this line ensures that the VAT amount is automatically calculated when changing the VAT code.
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentCoding, documentId],
                }),
                await client.invalidateQueries({
                    queryKey: [QueryKey.DocumentOcrSummary, documentId],
                }),
            ]);
        },
        onMutate: (data) => {
            client.setQueryData<DocumentCoding>([QueryKey.DocumentCoding, documentId], (oldDocumentCoding) => {
                if (!oldDocumentCoding) {
                    return oldDocumentCoding;
                }

                return {
                    ...oldDocumentCoding,
                    rows: oldDocumentCoding.rows.map((oldRow) => {
                        const idField = oldRow.values.find((val) => val.id === 'row');

                        if (idField?.value !== data.codingLineId) {
                            return oldRow;
                        }

                        return {
                            ...oldRow,
                            values: oldRow.values.map((val) => {
                                const updatedField = data.data.values.find((v) => v.id === val.id);

                                if (!updatedField?.value) {
                                    return val;
                                }

                                const fieldDefinition = oldDocumentCoding.definitions.find((def) => def.id === val.id);

                                // Don't update date fields in the cache because the API interprets the date strings
                                // This can cause the date picker to select the wrong date first (flicker)
                                if (fieldDefinition?.format === 'date') {
                                    return val;
                                }

                                if (fieldDefinition?.format === 'text') {
                                    return {
                                        ...val,
                                        value: updatedField.value?.toString(),
                                    };
                                }

                                return {
                                    ...val,
                                    value: updatedField.value,
                                };
                            }),
                        };
                    }),
                };
            });
        },
    });
};

export default useUpdateCodingLine;
