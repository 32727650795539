import useFetchDocumentInvoiceLines from '@components/features/documentInvoiceLines/hooks/useFetchDocumentInvoiceLines';
import { useCallback } from 'react';
import { DocumentInvoiceLineField } from '@models/DocumentInvoiceLines';
import { DynamicField, DynamicFieldValue } from '@models/DynamicField';

const useDocumentInvoiceLines = (documentId: number) => {
    const { data: documentInvoiceLines } = useFetchDocumentInvoiceLines(documentId);

    const getInvoiceLineField = useCallback((lineId: number, id: string): DocumentInvoiceLineField | undefined => {
        const codingLine = documentInvoiceLines?.rows.find((row) => {
            const idField = row.values.find((fieldValue) => fieldValue.id === 'row');
            return idField?.value === lineId;
        });

        return codingLine?.values.find((fieldValue) => fieldValue.id === id);
    }, [documentInvoiceLines]);

    const getInvoiceLineFieldDefinition = useCallback((id: string): DynamicField | undefined => {
        return documentInvoiceLines?.definitions.find((field) => field.id === id);
    }, [documentInvoiceLines]);

    const getInvoiceLineFieldValue = useCallback((lineId: number, id: string): string | number | null => {
        const field = getInvoiceLineField(lineId, id);

        return field?.value !== undefined ? field.value : null;
    }, [documentInvoiceLines]);

    const getNeighborInvoiceLineId = useCallback((lineId: number, direction?: 'next' | 'prev'): DynamicFieldValue | null => {
        const index = documentInvoiceLines?.rows.findIndex((r) => {
            return r.values.find((field) => field.id === 'row' && field.value === lineId);
        });

        if (!index) {
            return null;
        }

        const neighbor = direction === 'prev' ? documentInvoiceLines?.rows[index - 1] : documentInvoiceLines?.rows[index + 1];

        if (!neighbor) {
            return null;
        }

        const neighborIdField = neighbor.values.find((field) => field.id === 'row');
        return typeof neighborIdField?.value === 'number' ? neighborIdField?.value : null;
    }, [documentInvoiceLines]);

    return {
        documentInvoiceLines,
        getInvoiceLineField,
        getInvoiceLineFieldValue,
        getInvoiceLineFieldDefinition,
        getNeighborInvoiceLineId,
    };
};

export default useDocumentInvoiceLines;
