import { FC } from 'react';
import GraduationIcon from '@images/svg/graduation.svg?react';
import { TrainingField } from '@models/OcrTraining';
import { useSetAtom } from 'jotai';
import { DynamicField } from '@models/DynamicField';
import { useTranslation } from 'react-i18next';
import { ocrTrainingFieldModalAtom } from '@js/store/modals/ocrTrainingFieldModalAtom';
import { emptyOcrTrainingField } from '@components/modals/ocrTrainingFieldModal/OcrTrainingFieldModalInner';

interface Props {
    documentId: number;
    fieldDefinition: DynamicField;
    trainingField?: TrainingField;
}
const EditableCellTrainingButton: FC<Props> = ({ fieldDefinition, trainingField, documentId }) => {
    const { t } = useTranslation();
    const setOcrTrainingFieldModal = useSetAtom(ocrTrainingFieldModalAtom);
    const onFieldOcrTrainingClick = (trainingField: TrainingField) => {
        setOcrTrainingFieldModal({
            isOpen: true,
            data: {
                documentId,
                trainingField: {
                    ...trainingField,
                    field: {
                        ...emptyOcrTrainingField,
                        ...trainingField.field,
                        ...(fieldDefinition.id === 'bedrag' && !trainingField.field ? {
                            isKeyField: true,
                        } : {}),
                    },
                },
            },
        });
    };

    if (fieldDefinition.isReadOnly) {
        return <span className="fieldOcrTraining__placeholder" />;
    }

    return (
        <>
            {trainingField?.field ? (
                <button
                    tabIndex={-1}
                    title={t('edit', { ns: 'ocrTraining' })}
                    type="button"
                    className="fieldOcrTrainingIcon"
                    onClick={() => onFieldOcrTrainingClick(trainingField)}
                >
                    <GraduationIcon />
                </button>
            ) : trainingField ? (
                <button
                    tabIndex={-1}
                    title={t('add', { ns: 'ocrTraining' })}
                    type="button"
                    className="fieldOcrTrainingIcon fieldOcrTrainingIcon--add"
                    onClick={() => onFieldOcrTrainingClick(trainingField)}
                >
                    <GraduationIcon />
                </button>
            ) : null}
        </>
    );
};

export default EditableCellTrainingButton;
