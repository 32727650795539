import { useEffect } from 'react';
import useFetchCurrentUser from '@components/features/currentUser/hooks/useFetchCurrentUser';
import { BackendLocaleKey, Locale } from '@models/Locales';
import { locales } from '@utils/locales';
import { createStorageKey, setLocalStorageItem } from '@utils/storage';
import { StorageKeys } from '@constants/storageKeys';
import { useTranslation } from 'react-i18next';

const useBackendLanguage = () => {
    const { i18n } = useTranslation();
    const { data: user } = useFetchCurrentUser();

    const getLocaleByBackendCode = (backendCode: BackendLocaleKey): Locale | null => {
        const currentLocale = locales.find((locale) => locale.backendCode === backendCode);

        if (!currentLocale) {
            return null;
        }

        return currentLocale;
    };

    useEffect(() => {
        if (!user) {
            return;
        }
        const currentLocale = getLocaleByBackendCode(user.language as BackendLocaleKey);
        if (!currentLocale) {
            return;
        }

        i18n.changeLanguage(currentLocale.code);
        setLocalStorageItem(createStorageKey([StorageKeys.Language]), currentLocale.code);
    }, [user, i18n]);
};

export default useBackendLanguage;
