import { WorkflowAction } from '@models/DocumentActions';
import { PerformDocumentActionFormType } from '@components/modals/performDocumentActionModal/PerformDocumentActionModalInner';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePerformDocumentAction from '@hooks/usePerformDocumentAction';
import useValidateDocument from '@components/features/workflowActions/hooks/useValidateDocument';
import { toast } from 'react-toastify';
import { QueryKey } from '@constants/queryKey';
import { useQueryClient } from '@tanstack/react-query';
import useDocumentStatus from '@hooks/useDocumentStatus';
import useFetchDocument from '@hooks/useFetchDocument';
import { DynamicField } from '@models/DynamicField';
import useCodingTotals from '@components/features/coding/hooks/useCodingTotals';
import { useSetAtom } from 'jotai';
import useLookupErrors from '@js/store/lookupErrors/useLookupErrors';
import useDocumentCoding from '@components/features/coding/hooks/useDocumentCoding';
import useDocumentHeaders from '@components/features/documentHeaders/hooks/useDocumentHeaders';
import useCodingFieldProperties from '@components/features/coding/hooks/useCodingFieldProperties';
import { DocumentHeaderDefinition } from '@models/DocumentHeaders';
import { documentValidationModalAtom } from '@js/store/modals/documentValidationModalAtom';
import { confirmModalAtom } from '@js/store/modals/confirmModalAtom';
import { textModalAtom } from '@js/store/modals/textModalAtom';
import { Assignments, dynamicAssignModalAtom } from '@js/store/modals/dynamicAssignModalAtom';
import { performDocumentActionModalAtom } from '@js/store/modals/performDocumentActionModalAtom';
import { workflowActionValidationModalAtom } from '@js/store/modals/workflowActionValidationModalAtom';

const useWorkflowActions = (listType: string, documentId?: number) => {
    const [submitting, setSubmitting] = useState<string | null>(null);
    const [loading, setLoading] = useState<string | null>(null);
    const { mutate: validateDocument } = useValidateDocument();
    const { t } = useTranslation();
    const client = useQueryClient();
    const { getCodingLineFieldDefinition, documentCoding } = useDocumentCoding(documentId);
    const { documentHeaders, getHeaderField } = useDocumentHeaders(documentId);
    const { isEditMode } = useDocumentStatus();
    const { data: document } = useFetchDocument(documentId, isEditMode);
    const { lookupErrors } = useLookupErrors();
    // @ts-ignore
    const { difference } = useCodingTotals(documentId);
    const setTextModal = useSetAtom(textModalAtom);
    const setConfirmModal = useSetAtom(confirmModalAtom);
    const setPerformDocumentActionModal = useSetAtom(performDocumentActionModalAtom);
    const setDynamicAssignModal = useSetAtom(dynamicAssignModalAtom);
    const setWorkflowActionValidationModal = useSetAtom(workflowActionValidationModalAtom);
    const setDocumentValidationModal = useSetAtom(documentValidationModalAtom);
    const { getCodingPropsByLineId } = useCodingFieldProperties();

    const onSettled = () => {
        setLoading(null);
        setSubmitting(null);
    };

    const onCanceled = () => {
        setLoading(null);
        setSubmitting(null);
    };

    const { mutate: performDocumentAction } = usePerformDocumentAction(listType, documentId);

    const confirmIfNeeded = (action: WorkflowAction, onConfirm: () => void) => {
        if (!action.askConfirmation) {
            onConfirm();
            return;
        }
        setConfirmModal({
            isOpen: true,
            data: {
                onCanceled,
                onConfirm,
            },
        });
    };

    const validateHeaderFields = (): DocumentHeaderDefinition[] => {
        const requiredFields = documentHeaders?.definitions.filter((fieldDefinition) => fieldDefinition.isRequired);
        return requiredFields?.filter((fieldDefinition) => {
            const field = getHeaderField(fieldDefinition.id);
            return field?.value === null || field?.value === undefined || field?.value === '';
        }) || [];
    };

    enum CodingErrorType {
        IsRequired = 'IsRequired',
        HasLookupError = 'HasLookupError',
    }
    interface CodingError {
        line: number,
        field: DynamicField,
        type: CodingErrorType,
    }
    const validateCodingLines = (): CodingError[] => {
        if (!document?.meta.codingEnabled || !documentId) {
            return [];
        }

        const invalidFields: CodingError[] = [];

        // Coding
        documentCoding?.rows.forEach((row, index) => {
            const lineId = row.values.find((field) => field.id === 'row')?.value;
            const lineProps = getCodingPropsByLineId(documentId, lineId as number);

            row.values.forEach((field) => {
                const fieldIsEmpty = field.value === null || field.value === undefined || field.value === '';

                // Add errors for empty required fields
                if (lineProps?.requiredFields && lineProps?.requiredFields[field.id] && fieldIsEmpty) {
                    const fieldDefinition = getCodingLineFieldDefinition(field.id);

                    if (!fieldDefinition) {
                        return;
                    }

                    invalidFields.push({
                        line: index,
                        field: fieldDefinition,
                        type: CodingErrorType.IsRequired,
                    });
                }

                // Add errors for fields which appear in lookupErrors
                const fieldLookupError = lookupErrors.find((lookupError) => lookupError.lineId === lineId && lookupError.id === field.id);

                if (!fieldLookupError) {
                    return;
                }

                const fieldDefinition = getCodingLineFieldDefinition(field.id);

                if (!fieldDefinition) {
                    return;
                }

                invalidFields.push({
                    line: index,
                    field: fieldDefinition,
                    type: CodingErrorType.HasLookupError,
                });
            });
        });

        return invalidFields;
    };

    const validateCodingTotals = (): boolean => {
        if (!document?.meta.codingEnabled || !documentId || !documentCoding?.meta?.required) {
            return false;
        }
        return difference !== 0;
    };

    const validateIfNeeded = async (documentId: number, action: WorkflowAction, onConfirmed: () => void) => {
        if (!action.useValidation) {
            onConfirmed();
            return;
        }

        const invalidHeaderFields = validateHeaderFields();
        const invalidCodingLines = validateCodingLines();
        const invalidCodingTotal = validateCodingTotals();

        if (invalidHeaderFields.length || invalidCodingLines.length || invalidCodingTotal) {
            console.warn({
                invalidHeaderFields,
                invalidCodingLines,
                lookupErrors,
                invalidCodingTotal,
            });

            setDocumentValidationModal({
                isOpen: true,
                data: {
                    invalidHeaderFields: invalidHeaderFields.map((field) => {
                        return t('validation.required.modal.listItem', { ns: 'headers', field: field.title });
                    }),
                    invalidCodingLines: invalidCodingLines.map((codingError) => {
                        return codingError.type === CodingErrorType.IsRequired
                            ? t('validation.required.modal.listItem', { ns: 'coding', line: codingError.line + 1, field: codingError.field.title })
                            : t('validation.lookupError.modal.listItem', { ns: 'coding', line: codingError.line + 1, field: codingError.field.title });
                    }),
                    invalidCodingTotal,
                    nrOfErrors: invalidHeaderFields.length + invalidCodingLines.length + (invalidCodingTotal ? 1 : 0),
                },
            });
            onCanceled();
            return;
        }

        setLoading(action.id);
        validateDocument({
            documentId,
            actionId: action.id,
        }, {
            onSuccess: (response: any) => {
                setLoading(null);
                if (response.count > 0) {
                    setWorkflowActionValidationModal({
                        isOpen: true,
                        data: {
                            onCanceled,
                            validations: response.list,
                            onConfirm: onConfirmed,
                            title: t('validationModal.title', { ns: 'workflowActions', count: response.count }),
                        },
                    });
                    return;
                }
                onConfirmed();
            },
            onError: (error: any) => {
                setLoading(null);
                setSubmitting(null);

                if (!error?.response?.data?.Message) {
                    return;
                }
                setTextModal({
                    isOpen: true,
                    data: {
                        title: t('errorModal.title', { ns: 'workflowActions' }),
                        text: error.response.data.Message,
                    },
                });
            },
        });
    };

    const assignIfNeeded = (documentId: number, action: WorkflowAction, onAssigned: (data?: Assignments) => void) => {
        if (!action.useDynamicAssign) {
            onAssigned();
            return;
        }
        setDynamicAssignModal({
            isOpen: true,
            data: {
                documentId,
                onCanceled,
                onConfirm: onAssigned,
            },
        });
    };

    const showPerformDocumentActionModalIfNeeded = (documentId: number, action: WorkflowAction, onConfirmed: (result?: any) => void) => {
        if (!action.remarkEnabled) {
            onConfirmed();
            return;
        }
        setPerformDocumentActionModal({
            isOpen: true,
            data: {
                title: action.title,
                documentId,
                listType,
                action,
                buttonLabel: action.id === 'AskAdvice'
                    ? t('askAdvice.modal.button', { ns: 'workflowActions' })
                    : action.id === 'GiveAdvice'
                        ? t('giveAdvice.modal.button', { ns: 'workflowActions' })
                        : undefined,
                onCanceled,
                returnValue: true,
                onSubmit: (result: any, closeModal: () => void) => {
                    onConfirmed(result);
                    closeModal();
                },
            },
        });
    };

    const onActionClick = async (
        documentId: number,
        action: WorkflowAction,
        onSuccess?: (response: any, action: WorkflowAction, documentId: number) => void,
        customToaster?: undefined | string,
    ) => {
        setSubmitting(action.id);
        confirmIfNeeded(action, async () => {
            validateIfNeeded(documentId, action, async () => {
                assignIfNeeded(documentId, action, async (assignResult?: Assignments) => {
                    showPerformDocumentActionModalIfNeeded(documentId, action, async (performDocumentActionResult: PerformDocumentActionFormType) => {
                        // Finally, perform document action
                        setLoading(action.id);
                        performDocumentAction({
                            documentId,
                            actionId: action.id,
                            ...(performDocumentActionResult || undefined),
                            ...(assignResult || undefined),
                        }, {
                            onSettled,
                            onSuccess: async (response: any) => {
                                if (response?.statuscode !== 1 && response?.statuscode !== 2) {
                                    if (customToaster !== undefined) {
                                        if (customToaster) {
                                            toast.success(customToaster);
                                        }
                                    } else {
                                        toast.success(t('toastSuccess', { ns: 'workflowActions', action: action.title, documentId }));
                                    }
                                } else {
                                    // Refresh documentHeaders because voucher no. can be filled now.
                                    await client.invalidateQueries({
                                        queryKey: [QueryKey.DocumentHeaders, documentId],
                                    });
                                }
                                if (!onSuccess) {
                                    return;
                                }
                                onSuccess(response, action, documentId);
                            },
                        });
                    });
                });
            });
        });
    };

    return {
        onActionClick,
        submitting,
        loading,
    };
};

export default useWorkflowActions;
