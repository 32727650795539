import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { fetchEwsFolders } from '@repository/importProfilesRepository';
import { secondsToMillis } from '@utils/millis';
import { Office365Folder } from '@models/ImportProfiles';

const useFetchEwsFolders = (emailAddress: string): UseQueryResult<Office365Folder> => {
    return useQuery({
        queryKey: [QueryKey.EwsFolders, emailAddress],
        queryFn: ({ signal }) => fetchEwsFolders(emailAddress, signal),
        staleTime: secondsToMillis(30),
        refetchOnWindowFocus: false,
        enabled: !!emailAddress,
    });
};

export default useFetchEwsFolders;
