import { createInstance } from '@datapunt/matomo-tracker-react';

export const matomoInstance = createInstance({
    urlBase: 'https://analytics.hubl.online',
    siteId: 1,
    linkTracking: false,
    configurations: {
        disableCookies: true,
        setSecureCookie: false,
        setRequestMethod: 'POST',
    },
});
