import useFetchDocumentInvoiceLines from '@components/features/documentInvoiceLines/hooks/useFetchDocumentInvoiceLines';
import { DocumentInvoiceLine } from '@models/DocumentInvoiceLines';
import useFetchDocumentOrderLines from '@components/features/documentOrderLines/hooks/useFetchDocumentOrderLines';
import { DocumentOrderLine } from '@models/DocumentOrderLines';
import useInvoiceLinesCaret from '@hooks/caret/useInvoiceLinesCaret';
import useOrderLinesCaret from '@hooks/caret/useOrderLinesCaret';

const useLinkedLineInfo = (documentId: number, filteredOrderLines: boolean) => {
    const { data: documentInvoiceLines } = useFetchDocumentInvoiceLines(documentId);
    const { data: documentOrderLines } = useFetchDocumentOrderLines(documentId, filteredOrderLines);
    const { invoiceLinesCaret } = useInvoiceLinesCaret();
    const { orderLinesCaret } = useOrderLinesCaret();

    const getOrderLineByMatchKey = (matchKey: string): DocumentOrderLine | undefined => {
        return documentOrderLines?.list.find((item) => item.matchkey === matchKey);
    };

    const orderLineSelected = (): boolean => {
        if (orderLinesCaret?.lineId === undefined) {
            return false;
        }
        const currentOrderLine = getOrderLineByMatchKey(orderLinesCaret?.lineId);
        return !!currentOrderLine;
    };

    const getInvoiceLineById = (id: number): DocumentInvoiceLine | undefined => {
        return documentInvoiceLines?.rows.find((item) => {
            const idField = item.values.find((val) => val.id === 'row');
            if (!idField) {
                return false;
            }

            return idField.value === id;
        });
    };

    const getInvoiceLineByMatchKey = (matchKey: string): DocumentInvoiceLine | undefined => {
        return documentInvoiceLines?.rows.find((item) => {
            const matchKeyField = item.values.find((val) => val.id === 'matchkey');
            if (!matchKeyField) {
                return false;
            }

            return matchKeyField.value === matchKey;
        });
    };

    const currentInvoiceLine = invoiceLinesCaret?.lineId !== undefined ? getInvoiceLineById(invoiceLinesCaret.lineId) : undefined;
    const currentInvoiceLineMatchKey = currentInvoiceLine?.values?.find((val) => val.id === 'matchkey');
    const currentInvoiceLinePartner = getOrderLineByMatchKey(currentInvoiceLineMatchKey?.value as string);
    const currentInvoiceLineLinked = !!currentInvoiceLinePartner;

    const currentOrderLine = orderLinesCaret?.lineId !== undefined ? getOrderLineByMatchKey(orderLinesCaret.lineId) : null;
    const currentOrderLineMatchKey = currentOrderLine?.matchkey;
    const currentOrderLinePartner = currentOrderLineMatchKey ? getInvoiceLineByMatchKey(currentOrderLineMatchKey as string) : null;
    const currentOrderLineLinked = !!currentOrderLinePartner;

    const linesAreLinked = (): boolean => {
        if (!currentInvoiceLineLinked || !currentOrderLineLinked) {
            return false;
        }
        return currentInvoiceLineMatchKey?.value === currentOrderLineMatchKey;
    };

    return {
        currentInvoiceLine,
        currentInvoiceLineMatchKey: currentInvoiceLineMatchKey?.value,
        currentInvoiceLinePartner,
        currentInvoiceLineLinked,

        currentOrderLine,
        currentOrderLineMatchKey,
        currentOrderLinePartner,
        currentOrderLineLinked,

        orderLineSelected: orderLineSelected(),
        linesAreLinked: linesAreLinked(),
    };
};

export default useLinkedLineInfo;
