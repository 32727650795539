import { useMemo } from 'react';
import useFetchDocumentInvoiceLines from '@components/features/documentInvoiceLines/hooks/useFetchDocumentInvoiceLines';
import useDocumentHeaders from '@components/features/documentHeaders/hooks/useDocumentHeaders';

const useMatchingTotals = (documentId: number) => {
    const { getHeaderValue } = useDocumentHeaders(documentId);
    const { data: documentInvoiceLines } = useFetchDocumentInvoiceLines(documentId);

    const matchingTotal = useMemo(() => {
        let amount: number = 0;
        documentInvoiceLines?.rows
            ?.filter((row) => {
                const matchField = row.values.find((val) => val.id === 'matchvalue');
                return matchField?.value !== -1;
            })
            ?.forEach((row) => {
                const amountField = row.values.find((val) => val.id === 'bedrag');
                if (!amountField?.value) {
                    return;
                }
                amount += +amountField.value;
            });

        return amount;
    }, [documentInvoiceLines]);

    const difference = useMemo(() => {
        let amount: number = 0;
        documentInvoiceLines?.rows?.forEach((row) => {
            const amountField = row.values.find((val) => val.id === 'calc_verschil');
            if (!amountField?.value) {
                return;
            }
            amount -= +amountField.value;
        });

        return amount;
    }, [documentInvoiceLines]);

    const invoiceTotal = documentInvoiceLines ? getHeaderValue(documentInvoiceLines.meta.matchingTotalField) as number : 0;
    // const difference = invoiceTotal - matchingTotal;
    const absoluteDifference = Math.abs(difference);

    return {
        invoiceTotal: Math.round((invoiceTotal + Number.EPSILON) * 100) / 100,
        matchingTotal: Math.round((matchingTotal + Number.EPSILON) * 100) / 100,
        difference: Math.round((difference + Number.EPSILON) * 100) / 100,
        absoluteDifference: Math.round((absoluteDifference + Number.EPSILON) * 100) / 100,
    };
};

export default useMatchingTotals;
