import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

interface AtomWithBroadCastOptions {
    storeInLocalStorage?: boolean;
}

const atomWithBroadcast = (key: string, initialValue: any, options?: AtomWithBroadCastOptions) => {
    const broadcastChannel = new BroadcastChannel(key);
    const baseAtom = (options?.storeInLocalStorage)
        ? atomWithStorage(key, initialValue)
        : atom(initialValue);
    const listeners = new Set<(event: MessageEvent<any>) => void>();

    const broadcastAtom = atom(
        (get) => get(baseAtom),
        (get, set, update: any) => {
            if (!update.fromOnMessage) {
                broadcastChannel.postMessage(update.value);
            }
            else {
                
            }
            set(baseAtom, update.value);
        },
    );
    broadcastAtom.onMount = (setAtom) => {
        const listener = (event: MessageEvent<any>) => {
            setAtom({ fromOnMessage: true, value: event.data });
        };
        listeners.add(listener);
        broadcastChannel.onmessage = (event) => {
            listeners.forEach((f) => f(event));
        };
        return () => {
            listeners.delete(listener);
        };
    };

    return atom(
        (get) => get(broadcastAtom),
        (get, set, update) => {
            set(broadcastAtom, { fromOnMessage: false, value: update });
        },
    );
}

export default atomWithBroadcast;