import { FC } from 'react';
import { HeadingTags } from '@utils/headingTags';
import { Sizes } from '@utils/sizes';
import Heading from '@components/primitives/heading/Heading';
import { Document } from '@models/Document';
import DocumentTitle from '@utils/documentTitle';

interface Props {
    document?: Document;
}

const Title: FC<Props> = ({ document }) => {
    return (
        <Heading tag={HeadingTags.h2} size={Sizes.lg} noMargin className="topBar__title-heading" title={document?.title}>
            <DocumentTitle document={document} />
        </Heading>
    );
};

export default Title;
