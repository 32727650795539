import { RefObject, useEffect, useState } from 'react';
import useDeleteCodingLine from '@components/features/coding/hooks/useDeleteCodingLine';
import useCodingTotals from '@components/features/coding/hooks/useCodingTotals';
import useCreateCodingLine from '@components/features/coding/hooks/useCreateCodingLine';
import useFetchDocumentCoding from '@components/features/coding/hooks/useFetchDocumentCoding';
import { QueryKey } from '@constants/queryKey';
import { useQueryClient } from '@tanstack/react-query';
import { fetchDocumentCodingDefaults } from '@repository/documentCodingRepository';
import useUpdateCodingLine from '@components/features/coding/hooks/useUpdateCodingLine';
import { COLUMN_ID_SELECT } from '@components/primitives/dataGrid/DataGrid';
import { RowSelectionState } from '@tanstack/react-table';
import useCodingCaret from '@hooks/caret/useCodingCaret';
import useDocumentCoding from '@components/features/coding/hooks/useDocumentCoding';

const useCodingGridEffects = (documentId: number, isReadOnlyMode: boolean, codingRef: RefObject<HTMLDivElement>) => {
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const { codingCaret, setCodingCaret, resetCodingCaret } = useCodingCaret();
    const { getCodingLineFieldValue, getNeighborCodingLineId } = useDocumentCoding(documentId);
    const { data: documentCoding } = useFetchDocumentCoding(documentId);
    const { mutate: deleteCodingLine, isPending: isDeletingCodingLine } = useDeleteCodingLine(documentId);
    const { mutate: createCodingLine, isPending: isCreatingCodingLine } = useCreateCodingLine(documentId);
    const { mutate: updateCodingLine } = useUpdateCodingLine(documentId);
    const { difference } = useCodingTotals(documentId);
    const client = useQueryClient();

    const onDeleteLine = async (codingLineIds: number[]) => {
        if (!codingLineIds?.length) {
            return;
        }
        deleteCodingLine(codingLineIds, {
            onSuccess: () => {
                setRowSelection({});
                resetCodingCaret();
            },
        });
    };

    const scrollToNewCodingLine = () => {
        // Use timeout, so we have time to update the cache first. Otherwise, we don't scroll to the bottom
        setTimeout(() => {
            if (codingRef?.current) {
                // @ts-ignore
                codingRef.current.scrollTo({
                    top: 100000,
                    left: 0,
                    behavior: 'smooth',
                });
                // codingRef.current.scrollLeft = 0;
            }
        }, 200);
    };

    const onAddLine = async (firstColumnId?: string) => {
        scrollToNewCodingLine();
        const codingDefaults = await fetchDocumentCodingDefaults(documentId);
        await client.invalidateQueries({
            queryKey: [QueryKey.DocumentCodingDefaults, documentId],
        });
        createCodingLine({
            values: [
                ...(codingDefaults?.values ? codingDefaults.values : []),
                {
                    id: 'bedrag',
                    value: difference || 0,
                },
            ],
        }, {
            onSuccess: async (response) => {
                if (!response?.rowid) {
                    return;
                }
                const visibleColumns = documentCoding?.definitions.filter((column) => !column.isHidden);

                if (!visibleColumns?.length) {
                    return;
                }
                const firstField = visibleColumns[0];
                setCodingCaret({
                    fieldId: firstColumnId || firstField.id,
                    lineId: response.rowid,
                });
            },
        });
    };

    const onF7Key = async (e: KeyboardEvent) => {
        e.preventDefault();
        if (!codingCaret?.fieldId || !codingCaret?.lineId) {
            return;
        }
        const { lineId, fieldId } = codingCaret;
        const previousLine = getNeighborCodingLineId(lineId, 'prev');

        if (previousLine === null) {
            return;
        }
        const previousValue = getCodingLineFieldValue(previousLine as number, fieldId);

        if (!previousValue) {
            return;
        }

        updateCodingLine({
            data: {
                values: [{
                    id: fieldId,
                    value: previousValue,
                }],
            },
            codingLineId: lineId,
        });
    };

    const onKeyDown = async (e: KeyboardEvent) => {
        if (e.code === 'F7') {
            onF7Key(e);
        }
    };

    useEffect(() => {
        if (!codingCaret) {
            return undefined;
        }

        document.addEventListener('keydown', onKeyDown, false);

        return () => {
            document.removeEventListener('keydown', onKeyDown, false);
        };
    }, [codingCaret]);

    const onCellClick = (row: any, cell: any, selectedRow?: string | number, selectedCell?: string | number) => {
        if (cell.column.id === COLUMN_ID_SELECT) {
            return;
        }
        if ((row.original.row === selectedRow && cell.column.id === selectedCell) || isReadOnlyMode) {
            return;
        }
        setCodingCaret({
            fieldId: cell.column.id,
            lineId: row.original.row,
        });
    };

    const onLastFieldBlur = (rowIndex: number, firstColumnId: string) => {
        if (!documentCoding) {
            return;
        }

        if (rowIndex === documentCoding.rows.length - 1) {
            // Last field of last row blurred. Add new line when amount !== total
            if (difference <= 0) {
                return;
            }
            onAddLine(firstColumnId);
        } else {
            // Last field of other row blurred, focus first field of next line.
            const nextLine = documentCoding.rows[rowIndex + 1];
            const idField = nextLine.values.find((fieldValue) => fieldValue.id === 'row');

            if (!idField) {
                return;
            }

            setCodingCaret({
                lineId: idField.value as number,
                fieldId: firstColumnId,
            });

            if (codingRef?.current) {
                codingRef.current.scrollTo({
                    left: 0,
                    top: codingRef.current.scrollTop + 32,
                    behavior: 'smooth',
                });
            }
        }
    };

    return {
        addingLine: isCreatingCodingLine,
        onAddLine,
        deletingLine: isDeletingCodingLine,
        onDeleteLine,
        onCellClick,
        rowSelection,
        setRowSelection,
        onLastFieldBlur,
    };
};

export default useCodingGridEffects;
