import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { fetchDocumentActions } from '@repository/documentActionsRepository';
import { DocumentActions } from '@models/DocumentActions';

const useFetchDocumentActions = (documentId: number): UseQueryResult<DocumentActions> => {
    return useQuery({
        queryKey: [QueryKey.DocumentActions, documentId],
        queryFn: ({ signal }) => fetchDocumentActions(documentId, signal),
        staleTime: 0,
    });
};

export default useFetchDocumentActions;
