import { FC, MouseEvent, useMemo, useRef } from 'react';
import { StorageKeys } from '@constants/storageKeys';
import classNames from 'classnames';
import PlusIcon from '@images/svg/plus.svg?react';
import MinusIcon from '@images/svg/minus.svg?react';
import useInvoiceLinesGridEffects from '@components/features/matching/hooks/useInvoiceLinesGridEffects';
import Button from '@components/primitives/button/Button';
import { Sizes } from '@utils/sizes';
import { useTranslation } from 'react-i18next';
import DataGrid, { COLUMN_ID_SELECT } from '@components/primitives/dataGrid/DataGrid';
import { createColumnHelper } from '@tanstack/react-table';
import { useHotkeys } from 'react-hotkeys-hook';
import { DELETE_SELECTED_INVOICELINES } from '@constants/hotkeys';
import useFetchOcrTraining from '@components/features/ocrTraining/hooks/useFetchOcrTraining';
import useFetchDocumentInvoiceLines from '@components/features/documentInvoiceLines/hooks/useFetchDocumentInvoiceLines';
import useInvoiceLinesCaret from '@hooks/caret/useInvoiceLinesCaret';
import EditableCell from '@components/primitives/dataGrid/components/EditableCell';
import useDocumentInvoiceLines from '@components/features/documentInvoiceLines/hooks/useDocumentInvoiceLines';
import EditableCellTrainingButton from '@components/primitives/dataGrid/components/EditableCellTrainingButton';
import { OcrTainingContext } from '@models/OcrTraining';
import { createStorageKey } from '@utils/storage';
import { useAtomValue } from 'jotai';
import { currentDocClassAtom } from '@js/store/appAtoms';
import useInvoiceLinesUpdater from '@components/features/matching/hooks/useInvoiceLinesUpdater';

interface Props {
    documentId: number;
    isPreviewMode: boolean;
    ocrTrainingEnabled: boolean;
}
const InvoiceLines: FC<Props> = ({ documentId, isPreviewMode, ocrTrainingEnabled }) => {
    const currentDocClass = useAtomValue(currentDocClassAtom);
    const { invoiceLinesCaret, setInvoiceLinesCaret, resetInvoiceLinesCaret } = useInvoiceLinesCaret();
    const { data: ocrTraining } = useFetchOcrTraining(ocrTrainingEnabled, documentId);
    const { data: documentInvoiceLines } = useFetchDocumentInvoiceLines(documentId);
    const { getInvoiceLineField } = useDocumentInvoiceLines(documentId);
    const invoiceLinesRef = useRef(null);
    const { t } = useTranslation();
    const isReadOnlyMode = documentInvoiceLines?.meta.readOnly || false;
    const {
        onAddLine,
        addingLine,
        onDeleteLine,
        deletingLine,
        onCellClick,
        rowSelection,
        setRowSelection,
        onLastFieldBlur,
    } = useInvoiceLinesGridEffects(documentId, isReadOnlyMode, invoiceLinesRef);
    const { onInvoiceFieldChange } = useInvoiceLinesUpdater(documentId);

    const onRowButton = async () => {
        if (invoiceLinesCaret) {
            resetInvoiceLinesCaret();
            return;
        }
        await onAddLine();
    };

    const data = useMemo(() => {
        if (!documentInvoiceLines) {
            return [];
        }

        return documentInvoiceLines.rows.map((row) => {
            return row.values.reduce((result, field) => ({
                ...result,
                [typeof field.id === 'string' ? field.id.toLowerCase() : field.id]: field.value,
            }), {});
        });
    }, [documentInvoiceLines]);

    const selectedLines = useMemo(() => {
        return Object.keys(rowSelection).reduce((result: any[], index) => {
            return [
                ...result,
                data[+index],
            ];
        }, []).map((row) => row.row);
    }, [rowSelection]);

    const linesAreSelected = (): boolean => {
        return !!selectedLines.length;
    };

    useHotkeys(DELETE_SELECTED_INVOICELINES, async () => {
        await onDeleteLine(selectedLines);
    }, [selectedLines]);

    const columnHelper = createColumnHelper<any>();
    const columns = useMemo(() => {
        if (!documentInvoiceLines) {
            return [];
        }
        return documentInvoiceLines.definitions
            .filter((column) => !column.isHidden)
            .map((column) => {
                return columnHelper.accessor(column.id.toLowerCase(), {
                    header: column.title,
                    ...(column.format === 'text' || column.format === 'lookup' ? {
                        filterFn: 'fuzzy',
                        sortingFn: 'alphanumeric',
                    } : column.format === 'decimal' ? {
                        filterFn: 'numeric',
                        sortingFn: 'numeric',
                    } : {
                        enableColumnFilter: false,
                    }),
                    meta: {
                        documentId,
                        fieldDefinition: column,
                        format: column.format,
                        showAsText: isPreviewMode,
                        // ...(column.format === 'decimal' ? {
                        //     className: 'dataGrid__cell dataGrid__cell--decimal',
                        // } : undefined),
                    },
                    cell: EditableCell,
                });
            });
    }, [documentInvoiceLines, ocrTraining]);

    const resetFiltersDependencies = useMemo(() => {
        return [documentId];
    }, [documentId]);

    return (
        <>
            {!isPreviewMode ? (
                <div className="buttonStrip grid__buttons">
                    <button
                        type="button"
                        className={classNames('buttonStrip__button', {
                            'buttonStrip__button--loading': addingLine,
                        })}
                        onClick={() => onAddLine()}
                        title={t('add.button', { ns: 'coding' })}
                        disabled={isReadOnlyMode || addingLine || deletingLine}
                    >
                        <PlusIcon />
                    </button>
                    <button
                        type="button"
                        className={classNames('buttonStrip__button', {
                            'buttonStrip__button--loading': deletingLine,
                        })}
                        onClick={() => onDeleteLine(selectedLines)}
                        title={t('delete.button', { ns: 'coding' })}
                        disabled={!linesAreSelected() || isReadOnlyMode || deletingLine || addingLine}
                    >
                        <MinusIcon />
                    </button>
                </div>
            ) : null}
            <div className="left__grid invoiceLines__grid" ref={invoiceLinesRef}>
                <div className="dynamicFieldGrid">
                    <DataGrid
                        pageSize={25}
                        name={`${documentId}_invoiceLines`}
                        className="dataGrid--ocrTraining"
                        columns={columns}
                        data={data}
                        resetFiltersDependencies={resetFiltersDependencies}
                        onFieldUpdated={onInvoiceFieldChange}
                        onLastFieldBlur={onLastFieldBlur}
                        columnHelper={columnHelper}
                        rowSelection={rowSelection}
                        setRowSelection={setRowSelection}
                        enableRowSelection={() => !isReadOnlyMode}
                        storageKey={createStorageKey([currentDocClass, StorageKeys.GridMatchingInvoiceLines])}
                        selectedRow={invoiceLinesCaret?.lineId}
                        selectedCell={invoiceLinesCaret?.fieldId}
                        onCellSelect={(lineId, fieldId) => {
                            setInvoiceLinesCaret({
                                lineId,
                                fieldId,
                            });
                        }}
                        getField={(lineId, fieldId) => {
                            return getInvoiceLineField(lineId, fieldId);
                        }}
                        getHeaderComponent={(fieldDefinition) => {
                            const fieldTraining = ocrTraining?.fields.find((field) => {
                                return field.fieldId === `lineitem_${fieldDefinition.name.toLowerCase()}_0`;
                            });

                            if (!ocrTrainingEnabled || !ocrTraining?.templateId) {
                                return null;
                            }

                            return (
                                <EditableCellTrainingButton
                                    documentId={documentId}
                                    fieldDefinition={fieldDefinition}
                                    trainingField={{
                                        format: fieldDefinition.format,
                                        fieldId: `lineitem_${fieldDefinition.name.toLowerCase()}_0`,
                                        field: fieldTraining,
                                        label: fieldDefinition.title,
                                        context: OcrTainingContext.Matching,
                                        matching: {
                                            lineId: 0,
                                            fieldId: fieldDefinition.id,
                                        },
                                    }}
                                />
                            );
                        }}
                        getRowProps={(row, selectedRow) => {
                            const classes = classNames('dataGrid__tr', {
                                'dataGrid__tr--active': selectedRow === row.original.row,
                                'dataGrid__tr--previewMode': isPreviewMode,
                                'dataGrid__tr--matchNo': row.original.matchvalue === -1,
                                'dataGrid__tr--matchPriceDifference': row.original.matchvalue === 1,
                                'dataGrid__tr--matchFull': row.original.matchvalue === 2,
                                'dataGrid__tr--matchNumberOfDifference': row.original.matchvalue === 3,
                                'dataGrid__tr--matchDateDifference': row.original.matchvalue === 4,
                            });

                            return {
                                className: classes,
                            };
                        }}
                        getCellProps={(cellInfo, selectedRow, selectedCell) => {
                            const lineId = cellInfo.row.original.row;
                            const column = documentInvoiceLines?.definitions.find((col) => col.id === cellInfo.column.id);
                            const classes = classNames('dataGrid__cell', {
                                'dataGrid__cell--select': cellInfo.column.id === COLUMN_ID_SELECT,
                                'dataGrid__cell--decimal': column?.format === 'decimal',
                                'dataGrid__cell--active': selectedCell === cellInfo.column.id && selectedRow === lineId,
                                'dataGrid__cell--ocrTraining': ocrTrainingEnabled,
                            });

                            return {
                                className: classes,
                                ...(!isPreviewMode ? {
                                    onClick: (e: MouseEvent<HTMLDivElement>) => {
                                        if (e.target instanceof HTMLDivElement || e.target instanceof HTMLSpanElement) {
                                            // Prevent activating cell when clicking on training button
                                            if (e.target.classList && e.target.classList.contains('fieldOcrTrainingIcon')) {
                                                return;
                                            }
                                            onCellClick(cellInfo.row, cellInfo, selectedRow, selectedCell);
                                        }
                                    },
                                } : {}),
                            };
                        }}
                    />
                    {!data.length && !isReadOnlyMode && !isPreviewMode ? (
                        <div className="coding__emptyMessage">
                            <Button
                                onClick={() => onAddLine()}
                                isLoading={addingLine}
                                secondary
                                size={Sizes.lg}
                                icon={addingLine ? null : <PlusIcon />}
                            >
                                {t('noItems.button', { ns: 'matching' })}
                            </Button>
                        </div>
                    ) : !isReadOnlyMode && !isPreviewMode ? (
                        <button tabIndex={-1} type="button" className="coding__rowButton" onClick={onRowButton} disabled={addingLine || deletingLine} />
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default InvoiceLines;
