import { FC, ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
    className?: string;
    name?: string;
    children: ReactNode;
}

const FormLabel: FC<Props> = ({ className, name, children }) => {
    const classes = classNames('form__label', className);

    return (
        <label className={classes} htmlFor={name}>
            {children}
        </label>
    );
};

export default FormLabel;
