import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createStorageKey, setLocalStorageItem } from '@utils/storage';
import { StorageKeys } from '@constants/storageKeys';
import { locales } from '@utils/locales';
import { BackendLocaleKey, Locale, LocaleKey } from '@models/Locales';
import Select from 'react-select';
import useFetchCurrentUser from '@components/features/currentUser/hooks/useFetchCurrentUser';
import useUpdateCurrentUser from '@components/features/currentUser/hooks/useUpdateCurrentUser';
import CustomReactSelectInput from '@components/primitives/form/components/CustomReactSelectInput';
import { refreshAccessToken } from '@repository/accessTokenRepository';
import CustomReactSelectNoOptions from '@components/primitives/form/components/CustomReactSelectNoOptions';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';

type Option = {
    label: string;
    value: BackendLocaleKey;
};

const LanguageSwitch: FC = () => {
    const { t, i18n } = useTranslation();
    const { data: user } = useFetchCurrentUser();
    const [language, setLanguage] = useState<Locale | null>(null);
    const { mutate: updateCurrentUser } = useUpdateCurrentUser();
    const query = useQueryClient();

    const getLocaleByBackendCode = (backendCode: BackendLocaleKey): Locale | null => {
        const currentLocale = locales.find((locale) => locale.backendCode === backendCode);

        if (!currentLocale) {
            return null;
        }

        return currentLocale;
    };

    useEffect(() => {
        if (!user?.language) {
            return;
        }

        const currentLocale = getLocaleByBackendCode(user.language as BackendLocaleKey);

        if (!currentLocale) {
            return;
        }

        setLanguage(currentLocale);
    }, [user]);

    const onLanguageChange = (option: Option | null) => {
        if (!option) {
            return;
        }

        const currentLocale = getLocaleByBackendCode(option.value);

        if (!currentLocale) {
            return;
        }
        setLanguage(currentLocale);
        i18n.changeLanguage(currentLocale.code);
        setLocalStorageItem(createStorageKey([StorageKeys.Language]), currentLocale.code);
        updateCurrentUser({
            language: currentLocale.backendCode,
        }, {
            onSuccess: async () => {
                await refreshAccessToken();
                await query.invalidateQueries({
                    queryKey: [QueryKey.DocClasses],
                });
            },
        });
    };

    if (!locales?.length) {
        return null;
    }

    const sortOnLabel = (a: Option, b: Option) => {
        return a?.label?.toLowerCase() < b?.label?.toLowerCase() ? -1 : a?.label?.toLowerCase() > b?.label?.toLowerCase() ? 1 : 0;
    };

    const options = locales.map((locale) => {
        const label = {
            [LocaleKey.nl_NL]: t('language.nl_NL'),
            [LocaleKey.en_GB]: t('language.en_GB'),
            [LocaleKey.de_DE]: t('language.de_DE'),
            [LocaleKey.fr_FR]: t('language.fr_FR'),
        }[locale.code];

        return {
            value: locale.backendCode,
            label,
        };
    }).sort(sortOnLabel);

    return (
        <div className="form__field">
            <Select
                components={{
                    Input: CustomReactSelectInput,
                }}
                inputId="language"
                className="form__select"
                classNamePrefix="form__select"
                onChange={onLanguageChange}
                value={options.find((option) => option.value === language?.backendCode)}
                options={options}
                noOptionsMessage={() => <CustomReactSelectNoOptions />}
            />
        </div>
    );
};

export default LanguageSwitch;
