import { FC } from 'react';
import { NumericFormat } from 'react-number-format';
import { getCurrentLocale } from '@utils/locales';

interface Props {
    value: number;
    prefix?: string;
}

const Number: FC<Props> = ({ value, prefix = '' }) => {
    const currentLocale = getCurrentLocale();

    return (
        <NumericFormat
            value={value}
            thousandSeparator={currentLocale.thousandSeparator}
            decimalSeparator={currentLocale.decimalSeparator}
            decimalScale={2}
            fixedDecimalScale
            prefix={prefix}
            displayType="text"
            allowNegative={false}
        />
    );
};

export default Number;
