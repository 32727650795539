import { atom } from 'jotai';

export type HeaderCaret = {
    fieldId: string;
    lineId?: string;
};

export const headerCaretAtom = atom<HeaderCaret | undefined>(undefined);

export type InvoiceLinesCaret = {
    lineId: number;
    fieldId?: string;
};

export const invoiceLinesCaretAtom = atom<InvoiceLinesCaret | undefined>(undefined);

export type CodingCaret = {
    lineId: number;
    fieldId: string;
};

export const codingCaretAtom = atom<CodingCaret | undefined>(undefined);
