import { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

export interface QuickMenuItem {
    title: string;
    Icon: FC,
    className?: string,
    onClick?: (row: any) => void;
    to?: (row: any) => string;
}

interface Props {
    items: QuickMenuItem[];
    cellProps: any;
    backgroundColor?: string | null;
}
const QuickMenu: FC<Props> = ({ items, cellProps, backgroundColor }) => {
    if (!items.length) {
        return null;
    }

    return (
        <div
            className="quickMenu__inner"
            style={{
                ...(backgroundColor ? { background: `linear-gradient(60deg, rgba(255,255,255,0) 0%, ${backgroundColor} 3rem, ${backgroundColor} 100%)` } : {}),
            }}
        >
            {items.map((item) => {
                const { title, Icon, onClick, to, className } = item;
                const classes = classNames('quickMenu__button', className);

                if (to) {
                    return (
                        <Link key={title} className={classes} title={title} to={to(cellProps.row.original)}><Icon /></Link>
                    );
                }

                if (!onClick) {
                    return null;
                }

                return (
                    <button tabIndex={-1} type="button" key={title} className={classes} title={title} onClick={() => onClick(cellProps.row.original)}><Icon /></button>
                );
            })}
        </div>
    );
};

export default QuickMenu;
