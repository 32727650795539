import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import FilterIcon from '@images/svg/filter.svg?react';
import useDocListInfo from '@hooks/useDocListInfo';
import { useAtomValue } from 'jotai';
import { currentDocClassAtom } from '@js/store/appAtoms';
import { DEFAULT_LIST_TYPE } from '@routing/appUrls';

interface Props {
    documentId: number;
    navigationIsCollapsed: boolean;
}
const DocumentCounter: FC<Props> = ({ documentId, navigationIsCollapsed }) => {
    const { t } = useTranslation();
    const currentDocClass = useAtomValue(currentDocClassAtom);
    const { nrOfDocuments, nrOfFilteredDocuments, getDocumentIndex } = useDocListInfo(currentDocClass, DEFAULT_LIST_TYPE);
    const currentIndex = getDocumentIndex(documentId);
    const documentsAreFiltered = typeof nrOfDocuments === 'number' && typeof nrOfFilteredDocuments === 'number' && nrOfDocuments > nrOfFilteredDocuments;

    const classes = classNames('nav__counter', {
        'nav__counter--collapsed': navigationIsCollapsed,
        'nav__counter--isFiltered': documentsAreFiltered,
    });

    if (!nrOfFilteredDocuments) {
        return null;
    }

    return (
        <div className={classes} title={t('documentCounter', { ns: 'navigation', nr: currentIndex + 1, total: nrOfFilteredDocuments })}>
            {documentsAreFiltered ? (
                <FilterIcon />
            ) : null}
            {currentIndex + 1}/{nrOfFilteredDocuments}
        </div>
    );
};

export default DocumentCounter;
