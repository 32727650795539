import { FC, ReactNode } from 'react';
import Heading from '@components/primitives/heading/Heading';
import { HeadingTags } from '@utils/headingTags';
import { Sizes } from '@utils/sizes';
import classNames from 'classnames';

interface Props {
    className?: string;
    title?: string;
    children?: ReactNode;
    isPrimary?: boolean;
}

const PageHead: FC<Props> = ({ className, title, children, isPrimary }) => {
    const classes = classNames('pageHead', className, {
        'pageHead--primary': isPrimary,
    });

    return (
        <div className={classes}>
            {children ? (
                <>
                    {children}
                </>
            ) : title ? (
                <Heading noMargin tag={HeadingTags.h1} size={Sizes.lg}>{title}</Heading>
            ) : null}
        </div>
    );
};

export default PageHead;
