import { FC } from 'react';
import classNames from 'classnames';
import EuroIcon from '@images/svg/euro.svg?react';
import useApproveMatchingDifference from '@components/features/matching/hooks/useApproveMatchingDifference';
import { useTranslation } from 'react-i18next';

interface Props {
    documentId: number;
    isReadOnly: boolean;
}

const ApproveButton: FC<Props> = ({ documentId, isReadOnly }) => {
    const { t } = useTranslation();
    const { mutate: approveMatchingDifference, isPending: isApprovingMatchingDifference } = useApproveMatchingDifference(documentId);
    const approveButtonClasses = classNames('buttonStrip__button', {
        'buttonStrip__button--active': isApprovingMatchingDifference,
    });

    const onApprove = async () => {
        await approveMatchingDifference({});
    };

    return (
        <button
            type="button"
            title={t('actions.approve', { ns: 'matching' })}
            className={approveButtonClasses}
            onClick={onApprove}
            disabled={isApprovingMatchingDifference || isReadOnly}
        >
            <EuroIcon className="sm success" />
        </button>
    );
};

export default ApproveButton;
