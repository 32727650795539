import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { MailTemplate } from '@models/MailTemplates';
import { fetchMailTemplate } from '@repository/mailTemplatesRepository';
import { minutesToMillis } from '@utils/millis';

const useFetchMailTemplate = (templateId?: number, documentId?: number): UseQueryResult<MailTemplate> => {
    return useQuery({
        queryKey: [QueryKey.MailTemplate, templateId, documentId],
        queryFn: typeof templateId === 'number' ? ({ signal }) => fetchMailTemplate(templateId, documentId, signal) : undefined,
        enabled: typeof templateId === 'number',
        staleTime: minutesToMillis(30),
        retry: 0,
    });
};

export default useFetchMailTemplate;
