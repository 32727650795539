import { nl, enGB, de, fr } from 'date-fns/locale';
import { createStorageKey, getLocalStorageItem } from '@utils/storage';
import { StorageKeys } from '@constants/storageKeys';
import { BackendLocaleKey, Locale, LocaleKey } from '@models/Locales';

export const locales: Locale[] = [
    {
        code: LocaleKey.nl_NL,
        backendCode: BackendLocaleKey.NL,
        dateLocale: nl,
        dateFormat: 'dd-MM-yyyy',
        dateTimeFormat: 'dd-MM-yyyy HH:mm',
        dateTimeAccurateFormat: 'dd-MM-yyyy HH:mm:ss',
        thousandSeparator: '.',
        decimalSeparator: ',',
    },
    {
        code: LocaleKey.en_GB,
        backendCode: BackendLocaleKey.EN,
        dateLocale: enGB,
        dateFormat: 'MM/dd/yyyy',
        dateTimeFormat: 'MM/dd/yyyy HH:mm',
        dateTimeAccurateFormat: 'MM/dd/yyyy HH:mm:ss',
        thousandSeparator: ',',
        decimalSeparator: '.',
    },
    {
        code: LocaleKey.de_DE,
        backendCode: BackendLocaleKey.DE,
        dateLocale: de,
        dateFormat: 'dd-MM-yyyy',
        dateTimeFormat: 'dd-MM-yyyy HH:mm',
        dateTimeAccurateFormat: 'dd-MM-yyyy HH:mm:ss',
        thousandSeparator: ',',
        decimalSeparator: '.',
    },
    {
        code: LocaleKey.fr_FR,
        backendCode: BackendLocaleKey.FR,
        dateLocale: fr,
        dateFormat: 'dd-MM-yyyy',
        dateTimeFormat: 'dd-MM-yyyy HH:mm',
        dateTimeAccurateFormat: 'dd-MM-yyyy HH:mm:ss',
        thousandSeparator: ',',
        decimalSeparator: '.',
    },
];

export const defaultLocaleCode = LocaleKey.en_GB;

export const defaultLocale = (): Locale => {
    // @ts-ignore
    return locales.find((locale) => locale.code === defaultLocaleCode);
};

export const getCurrentLocale = (): Locale => {
    const savedLanguage = getLocalStorageItem(
        createStorageKey([StorageKeys.Language]),
    );
    const code = savedLanguage || LocaleKey.nl_NL;
    return locales.find((locale) => locale.code === code) || defaultLocale();
};
