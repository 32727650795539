import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { createDocumentInvoiceLine } from '@repository/documentInvoiceLinesRepository';
import { DocumentInvoiceLineForm } from '@models/DocumentInvoiceLines';

const useCreateDocumentInvoiceLine = (documentId: number) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: (data: DocumentInvoiceLineForm) => createDocumentInvoiceLine(documentId, data),
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [QueryKey.DocumentInvoiceLines, documentId],
            });
        },
    });
};

export default useCreateDocumentInvoiceLine;
