import useFetchLineSource from '@components/features/lineSource/hooks/useFetchLineSource';
import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import CheckIcon from '@images/svg/check.svg?react';
import useUpdateLineSource from '@components/features/lineSource/hooks/useUpdateLineSource';
import { LineSource } from '@models/LineSource';
import { useTranslation } from 'react-i18next';
import useFetchCurrentUser from '@components/features/currentUser/hooks/useFetchCurrentUser';
import SamLineSourceButton from '@components/features/lineSource/SamLineSourceButton';

interface Props {
    documentId: number;
}

const LineSourceToggle: FC<Props> = ({ documentId }) => {
    const { data } = useFetchLineSource(documentId);
    const { data: user } = useFetchCurrentUser();
    const { mutate: updateLineSource, isPending: isUpdatingLineSource } = useUpdateLineSource();
    const { t } = useTranslation();
    const [pendingLineSource, setPendingLineSource] = useState<LineSource | null>(null);
    const samEnabledForUser = !!user && user.samEnabledForUser;

    useEffect(() => {
        if (isUpdatingLineSource) {
            return;
        }
        setPendingLineSource(null);
    }, [isUpdatingLineSource]);

    const onButtonClick = (lineSource: LineSource) => {
        if (data?.lineSource === lineSource) {
            return;
        }

        setPendingLineSource(lineSource);
        updateLineSource({
            documentId,
            lineSource,
        });
    };

    if (!data) {
        return null;
    }

    return (
        <div className="lineSource__toggle">
            {samEnabledForUser ? (
                <SamLineSourceButton
                    documentId={documentId}
                />
            ) : null}
            <button
                type="button"
                onClick={() => onButtonClick('ocr')}
                disabled={!data.ocr || isUpdatingLineSource}
                title={!data.ocr ? t('ocr.inactiveTooltip', { ns: 'lineSource' }) : undefined}
                className={classNames('lineSource__button', {
                    'lineSource__button--active': data.lineSource === 'ocr' && !isUpdatingLineSource,
                    'button--loading': pendingLineSource === 'ocr',
                })}
            >
                {t('ocr.button', { ns: 'lineSource' })}
                <CheckIcon />
            </button>
            <button
                type="button"
                onClick={() => onButtonClick('xml')}
                disabled={!data.xml || isUpdatingLineSource}
                title={!data.xml ? t('xml.inactiveTooltip', { ns: 'lineSource' }) : undefined}
                className={classNames('lineSource__button', {
                    'lineSource__button--active': data.lineSource === 'xml' && !isUpdatingLineSource,
                    'button--loading': pendingLineSource === 'xml',
                })}
            >
                {t('xml.button', { ns: 'lineSource' })}
                <CheckIcon />
            </button>
            <button
                type="button"
                onClick={() => onButtonClick('copy')}
                disabled={!data.copyCoding || isUpdatingLineSource}
                title={!data.copyCoding ? t('copyCoding.inactiveTooltip', { ns: 'lineSource' }) : undefined}
                className={classNames('lineSource__button', {
                    'lineSource__button--active': data.lineSource === 'copy' && !isUpdatingLineSource,
                    'button--loading': pendingLineSource === 'copy',
                })}
            >
                {t('copyCoding.button', { ns: 'lineSource' })}
                <CheckIcon />
            </button>
        </div>
    );
};

export default LineSourceToggle;
