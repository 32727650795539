import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { deleteReplacements } from '@repository/replacementsRepository';
import { DeleteReplacementsResponse } from '@models/Replacements';

const useDeleteReplacements = () => {
    const client = useQueryClient();

    return useMutation<DeleteReplacementsResponse>({
        mutationFn: () => deleteReplacements(),
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [QueryKey.Replacements],
            });
        },
    });
};

export default useDeleteReplacements;
