import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { fetchImportProfiles } from '@repository/importProfilesRepository';
import { secondsToMillis } from '@utils/millis';
import { ImportProfile } from '@models/ImportProfiles';

interface ImportProfilesResponse {
    list: ImportProfile[];
    count: number;
}

const useFetchImportProfiles = (): UseQueryResult<ImportProfilesResponse> => {
    return useQuery({
        queryKey: [QueryKey.ImportProfiles],
        queryFn: ({ signal }) => fetchImportProfiles(signal),
        staleTime: secondsToMillis(30),
        refetchOnWindowFocus: false,
    });
};

export default useFetchImportProfiles;
