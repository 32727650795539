import { HTMLProps, useEffect, useRef } from 'react';
import classNames from 'classnames';

const IndeterminateCheckbox = ({ indeterminate, className = '', ...rest }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) => {
    const ref = useRef<HTMLInputElement>(null!);

    useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate;
        }
    }, [ref, indeterminate]);

    return (
        <>
            <label htmlFor={rest.id}>
                <input
                    type="checkbox"
                    ref={ref}
                    className={classNames('cursor-pointer', 'form__checkbox', className, {
                        'form__checkbox--indeterminate': indeterminate,
                    })}
                    {...rest}
                />
                <span className="form__checkbox-shadow" />
            </label>
        </>

    );
};
export default IndeterminateCheckbox;
