import { useEffect, useState } from 'react';
import { isHotkeyPressed } from 'react-hotkeys-hook';

const useIsKeyPressed = () => {
    const [shiftPressed, setShiftPressed] = useState(false);
    const [altPressed, setAltPressed] = useState(false);
    const onKey = () => {
        setShiftPressed(isHotkeyPressed('shift'));
        setAltPressed(isHotkeyPressed('alt'));
    };

    useEffect(() => {
        window.addEventListener('keydown', onKey);
        window.addEventListener('keyup', onKey);

        return () => {
            window.removeEventListener('keydown', onKey);
            window.removeEventListener('keyup', onKey);
        };
    }, []);

    return {
        shiftPressed,
        altPressed,
        altShiftPressed: altPressed && shiftPressed,
    };
};

export default useIsKeyPressed;
