import App from '@js/App';
import { PagePath } from '@routing/appUrls';
import { lazy, Suspense } from 'react';
import lazyRetry, { LazyRetryNames } from '@utils/lazyRetry';
import LoginPage from '@components/pages/loginPage/LoginPage';
import ProtectedRoute, { Roles } from '@routing/components/ProtectedRoute';
import ErrorBoundary from '@components/features/errorBoundary/ErrorBoundary';
import TrackPageVisits from '@components/features/tracking/components/TrackPageVisits';
import * as Sentry from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';
import GeneralSettingsTab from '@components/pages/settingsPage/components/tabs/generalSettings/GeneralSettingsTab';
import ImportProfilesTab from '@components/pages/settingsPage/components/tabs/importProfiles/ImportProfilesTab';

const DashboardPage = lazy(() => lazyRetry(
    () => import('@components/pages/dashboardPage/DashboardPage'),
    LazyRetryNames.Dashboard,
));
const ListPage = lazy(() => lazyRetry(
    () => import('@components/pages/listPage/ListPage'),
    LazyRetryNames.List,
));
const DocumentPage = lazy(() => lazyRetry(
    () => import('@components/pages/documentPage/DocumentPage'),
    LazyRetryNames.Document,
));
const FirstDocumentPage = lazy(() => lazyRetry(
    () => import('@components/pages/firstDocumentPage/FirstDocumentPage'),
    LazyRetryNames.FirstDocumentPage,
));
const AllDocumentProcessedPage = lazy(() => lazyRetry(
    () => import('@components/pages/allDocumentsProcessedPage/AllDocumentProcessedPage'),
    LazyRetryNames.AllDocumentProcessed,
));
const SettingsPage = lazy(() => lazyRetry(
    () => import('@components/pages/settingsPage/SettingsPage'),
    LazyRetryNames.Settings,
));
const GeneralSettingsPage = lazy(() => lazyRetry(
    () => import('@components/pages/settingsPage/components/tabs/generalSettings/GeneralSettingsTab'),
    LazyRetryNames.GeneralSettings,
));
const ImportProfilesSettingsPage = lazy(() => lazyRetry(
    () => import('@components/pages/settingsPage/components/tabs/importProfiles/ImportProfilesTab'),
    LazyRetryNames.ImportProfilesSettings,
));
const ImportProfilePage = lazy(() => lazyRetry(
    () => import('@components/pages/settingsPage/components/tabs/importProfiles/ImportProfilesTab'),
    LazyRetryNames.ImportProfile,
));
const EwsCallbackPage = lazy(() => lazyRetry(
    () => import('@components/pages/settingsPage/EwsCallbackPage'),
    LazyRetryNames.EwsCallback,
));
const EwsSelectFoldersPage = lazy(() => lazyRetry(
    () => import('@components/pages/settingsPage/EwsSelectFoldersPage'),
    LazyRetryNames.EwsSelectFolders,
));
const SupportPage = lazy(() => lazyRetry(
    () => import('@components/pages/supportPage/SupportPage'),
    LazyRetryNames.Support,
));
const TechnicalInformation = lazy(() => lazyRetry(
    () => import('@components/pages/supportPage/components/TechnicalInformation'),
    LazyRetryNames.TechnicalInformation,
));
const Faq = lazy(() => lazyRetry(
    () => import('@components/pages/supportPage/components/Faq'),
    LazyRetryNames.Faq,
));
const ReleaseNotes = lazy(() => lazyRetry(
    () => import('@components/pages/supportPage/components/ReleaseNotes'),
    LazyRetryNames.ReleaseNotes,
));
const Contact = lazy(() => lazyRetry(
    () => import('@components/pages/supportPage/components/Contact'),
    LazyRetryNames.Contact,
));
const Videos = lazy(() => lazyRetry(
    () => import('@components/pages/supportPage/components/Videos'),
    LazyRetryNames.Videos,
));
const Introduction = lazy(() => lazyRetry(
    () => import('@components/pages/supportPage/components/Introduction'),
    LazyRetryNames.Introduction,
));
const SearchPage = lazy(() => lazyRetry(
    () => import('@components/pages/searchPage/SearchPage'),
    LazyRetryNames.Search,
));
const FileHandlerPage = lazy(() => lazyRetry(
    () => import('@components/pages/fileHandlerPage/FileHandlerPage'),
    LazyRetryNames.FileHandler,
));

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter([
    {
        path: PagePath.login,
        element: (
            <Suspense>
                <TrackPageVisits>
                    <LoginPage />
                </TrackPageVisits>
            </Suspense>
        ),
    },
    {
        path: '/',
        errorElement: <ErrorBoundary />,
        element: (
            <ProtectedRoute>
                <TrackPageVisits>
                    <App />
                </TrackPageVisits>
            </ProtectedRoute>
        ),
        children: [
            {
                path: PagePath.search,
                element: (
                    <Suspense>
                        <SearchPage />
                    </Suspense>
                ),
            },
            {
                path: PagePath.list,
                element: (
                    <Suspense>
                        <ListPage />
                    </Suspense>
                ),
            },
            {
                path: PagePath.document,
                element: (
                    <Suspense>
                        <DocumentPage />
                    </Suspense>
                ),
            },
            {
                path: PagePath.firstDocument,
                element: (
                    <Suspense>
                        <FirstDocumentPage />
                    </Suspense>
                ),
            },
            {
                path: PagePath.allDocumentsProcessed,
                element: (
                    <Suspense>
                        <AllDocumentProcessedPage />
                    </Suspense>
                ),
            },
            {
                path: PagePath.fileHandler,
                element: (
                    <Suspense>
                        <ProtectedRoute roles={[Roles.AllowFileHandler]}>
                            <FileHandlerPage />
                        </ProtectedRoute>
                    </Suspense>
                ),
            },
            {
                path: PagePath.settings,
                element: (
                    <Suspense>
                        <SettingsPage />
                    </Suspense>
                ),
                children: [
                    {
                        path: PagePath.settings,
                        element: (
                            <Suspense>
                                <GeneralSettingsTab />
                            </Suspense>
                        ),
                    },
                    {
                        path: PagePath.settingsImportProfiles,
                        element: (
                            <Suspense>
                                <ImportProfilesTab />
                            </Suspense>
                        ),
                    },
                    {
                        path: PagePath.settingsImportProfile,
                        element: (
                            <Suspense>
                                <ImportProfilePage />
                            </Suspense>
                        ),
                    }
                ]
            },
            {
                path: PagePath.support,
                element: (
                    <Suspense>
                        <SupportPage />
                    </Suspense>
                ),
                children: [
                    {
                        path: PagePath.supportVideos,
                        element: (
                            <Suspense>
                                <Videos />
                            </Suspense>
                        ),
                    },
                    {
                        path: PagePath.faq,
                        element: (
                            <Suspense>
                                <Faq />
                            </Suspense>
                        ),
                    },
                    {
                        path: PagePath.contact,
                        element: (
                            <Suspense>
                                <Contact />
                            </Suspense>
                        ),
                    },
                    {
                        path: PagePath.supportTechnicalInformation,
                        element: (
                            <Suspense>
                                <TechnicalInformation />
                            </Suspense>
                        ),
                    },
                    {
                        path: PagePath.releaseNotes,
                        element: (
                            <Suspense>
                                <ReleaseNotes />
                            </Suspense>
                        ),
                    },
                    {
                        path: PagePath.support,
                        element: (
                            <Suspense>
                                <Introduction />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: PagePath.dashboard,
                element: (
                    <Suspense>
                        <DashboardPage />
                    </Suspense>
                ),
            },
        ],
    },
    {
        path: PagePath.ewsCallback,
        element: (
            <Suspense>
                <EwsCallbackPage />
            </Suspense>
        ),
    },
    {
        path: PagePath.ewsSelectFolders,
        element: (
            <Suspense>
                <EwsSelectFoldersPage />
            </Suspense>
        ),
    },
]);

export default router;
