import { validateDocument } from '@repository/documentRepository';
import { useMutation } from '@tanstack/react-query';
import { ValidateDocumentInput } from '@models/ValidateDocument';

const useValidateDocument = () => {
    return useMutation({
        mutationFn: (input: ValidateDocumentInput) => validateDocument(input.documentId, input.actionId),
        meta: {
            disableErrorToaster: true,
        },
    });
};

export default useValidateDocument;
