import React, { FC } from 'react';
import { Sizes } from '@utils/sizes';
import PreviewModalInner from '@components/modals/previewModal/PreviewModalInner';
import Modal from '@components/primitives/modal/Modal';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { documentPreviewModalAtom, DocumentPreviewModalData } from '@js/store/modals/documentPreviewModalAtom';

const PreviewModal: FC = () => {
    const [modalState, setModalState] = useAtom(documentPreviewModalAtom);
    const { t } = useTranslation();

    if (!modalState.data) {
        return null;
    }

    return (
        <Modal<DocumentPreviewModalData>
            modalState={modalState}
            setModalState={setModalState}
            size={Sizes.xxxl}
            className="previewModal"
            title={t('preview.modal.title', { ns: 'list' })}
        >
            <PreviewModalInner
                modalData={modalState.data}
            />
        </Modal>
    );
};

export default PreviewModal;
