import { FC } from 'react';
import { DocumentOcrSummary } from '@models/DocumentOcrSummary';
import classNames from 'classnames';
import { getOriginalFileHeight } from '@utils/documentInfo';
import useInvoiceLinesCaret from '@hooks/caret/useInvoiceLinesCaret';
import useDocumentInvoiceLines from '@components/features/documentInvoiceLines/hooks/useDocumentInvoiceLines';

interface Props {
    documentId: number;
    documentOcrSummary?: DocumentOcrSummary;
    page: number;
    fileHeight: number;
}

const OcrInvoiceLines: FC<Props> = ({ documentId, documentOcrSummary, page, fileHeight }) => {
    const { invoiceLinesCaret } = useInvoiceLinesCaret();
    const { getInvoiceLineFieldValue } = useDocumentInvoiceLines(documentId);
    const offset = 0.2;
    const invoiceLineAmountFields = documentOcrSummary?.list
        .filter((ocrItem) => ocrItem.page === page)
        .filter((ocrItem) => ocrItem.value !== '-')
        .filter((ocrItem) => ocrItem.zone)
        .filter((ocrItem) => ocrItem.id.indexOf('lineitem_bedrag_') !== -1);

    if (!invoiceLineAmountFields?.length) {
        return null;
    }

    return (
        <>
            {invoiceLineAmountFields.map((amountField) => {
                if (!amountField.zone) {
                    return null;
                }

                const lineIndexStr = amountField.id.replace('lineitem_bedrag_', '');
                const lineIndex = lineIndexStr ? +lineIndexStr : undefined;
                const matchValue = lineIndex !== undefined ? getInvoiceLineFieldValue(lineIndex, 'matchvalue') : undefined;
                const zones = amountField.zone.split(/\s/u);
                const [, top,, height] = zones;
                const isActive = invoiceLinesCaret?.lineId ? amountField.id === `lineitem_bedrag_${invoiceLinesCaret?.lineId}` : false;
                const classes = classNames('ocr__invoiceLine', {
                    // Selected by selecting linked invoiceLine
                    'ocr__invoiceLine--active': isActive,
                    'ocr__invoiceLine--matchNo': matchValue === -1,
                    'ocr__invoiceLine--matchPriceDifference': matchValue === 1,
                    'ocr__invoiceLine--matchFull': matchValue === 2,
                    'ocr__invoiceLine--matchNumberOfDifference': matchValue === 3,
                    'ocr__invoiceLine--matchDateDifference': matchValue === 4,
                });

                return (
                    <div
                        className={classes}
                        key={amountField.id}
                        style={{
                            width: '90%',
                            height: `${((+height / getOriginalFileHeight(fileHeight)) * 100) + (offset * 2)}%`,
                            left: '5%',
                            top: `${((+top / getOriginalFileHeight(fileHeight)) * 100) - offset}%`,
                        }}
                    />
                );
            })}
        </>
    );
};

export default OcrInvoiceLines;
