import { FC } from 'react';
import TriangleIcon from '@images/svg/triangle.svg?react';
import ToggleList from '@components/primitives/toggleList/ToggleList';
import useDocumentNavigation from '@hooks/useDocumentNavigation';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Title from '@components/features/topBar/components/Title';
import useDocumentButtons from '@components/features/topBar/hooks/useDocumentButtons';
import useFetchCurrentUser from '@components/features/currentUser/hooks/useFetchCurrentUser';
import { Document } from '@models/Document';
import { useAtomValue } from 'jotai';
import { currentDocClassAtom } from '@js/store/appAtoms';
import useDocListInfo from '@hooks/useDocListInfo';

interface Props {
    document: Document;
    ocrTrainingEnabled: boolean;
    ocrTrainingAllowed: boolean;
    isPreviewMode?: boolean;
    listType: string;
}

const TopBar: FC<Props> = ({
    document,
    ocrTrainingEnabled,
    ocrTrainingAllowed,
    isPreviewMode,
    listType,
}) => {
    const currentDocClass = useAtomValue(currentDocClassAtom);
    const { nrOfFilteredDocuments } = useDocListInfo(currentDocClass, listType);
    const { data: user } = useFetchCurrentUser();
    const { documentButtons } = useDocumentButtons(document.documentId, ocrTrainingEnabled, ocrTrainingAllowed, listType, isPreviewMode);
    const { t } = useTranslation();
    const {
        nextDocument,
        prevDocument,
    } = useDocumentNavigation(listType, isPreviewMode);

    return (
        <>

            <div
                className={classNames('topBar', {
                    'topBar--isPreviewMode': isPreviewMode,
                })}
            >
                <div className="topBar__title">
                    <div className="topBar__navButtons">
                        <button
                            type="button"
                            className="topBar__navButton topBar__navButton--prev"
                            disabled={nrOfFilteredDocuments === 1}
                            onClick={() => prevDocument(document.documentId)}
                            title={t('topBar.previousDocument', { ns: 'document' })}
                        >
                            <TriangleIcon />
                        </button>
                        <button
                            type="button"
                            className="topBar__navButton topBar__navButton--next"
                            disabled={nrOfFilteredDocuments === 1}
                            onClick={() => nextDocument(document.documentId)}
                            title={t('topBar.nextDocument', { ns: 'document' })}
                        >
                            <TriangleIcon />
                        </button>
                    </div>
                    <Title document={document} />
                </div>
                <div className="topBar__actions items-center flex">
                    {documentButtons
                        .filter((documentButton) => !documentButton.isHidden)
                        .filter((documentButton) => {
                            return isPreviewMode ? documentButton.showInPreviewModal
                                : typeof documentButton.showOnDocumentPage === 'boolean' ? documentButton.showOnDocumentPage : true;
                        })
                        .map((documentButton, index) => {
                            const classes = classNames('topBar__action', documentButton.className);
                            if (documentButton.list?.length) {
                                return (
                                    <div key={index}>
                                        <ToggleList
                                            list={documentButton.list.map((group) => {
                                                return group.filter((item) => (isPreviewMode ? item.showInPreviewModal : true));
                                            })}
                                            trackOpenList={documentButton.trackOpenList}
                                            trigger={(toggle) => (
                                                <button
                                                    type="button"
                                                    className={classes}
                                                    onClick={toggle}
                                                    disabled={!documentButton.enabled}
                                                    title={documentButton.title}
                                                >
                                                    {documentButton.icon}
                                                </button>
                                            )}
                                        />
                                    </div>
                                );
                            }

                            return (
                                <button
                                    type="button"
                                    className={classes}
                                    onClick={() => documentButton.onClick(documentButton.id)}
                                    key={index}
                                    disabled={!documentButton.enabled}
                                    title={documentButton.title}
                                >
                                    {documentButton.icon}
                                </button>
                            );
                        })}
                </div>
                <div className="topBar__search">
                    {user?.userfullname} ({user?.userId})
                </div>
            </div>
        </>
    );
};

export default TopBar;
