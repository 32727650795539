import { useMutation } from '@tanstack/react-query';
import { ImportProfile } from '@models/ImportProfiles';
import { updateImportProfile } from '@repository/importProfilesRepository';

interface Data {
    id: string;
    importProfile: ImportProfile;
}

const useUpdateImportProfile = () => {
    return useMutation({
        mutationFn: (data: Data) => updateImportProfile(data?.id, data?.importProfile),
    });
};

export default useUpdateImportProfile;
