import * as yup from 'yup';
import { Structure, Types } from '@components/modals/ocrTrainingFieldModal/OcrTrainingFieldModalInner';
import { OcrTrainingFieldForm } from '@models/OcrTraining';
import { useTranslation } from 'react-i18next';

const useCreateOcrTrainingFieldValidationSchema = (type: Types, structure: Structure): yup.ObjectSchema<OcrTrainingFieldForm> => {
    const { t } = useTranslation();

    return yup.object().shape({
        fieldId: yup.string().required(''),
        page: yup.number(),
        refwordText: yup.string(),
        zone: yup.string().when('typeIsDynamic', {
            is: () => type === Types.Dynamic,
            then: (schema) => schema.trim()
                .required(t('validation.zone.required', { ns: 'ocrTraining' }))
                .test('zoneIsEmpty', t('validation.zone.required', { ns: 'ocrTraining' }), (value) => {
                    return value !== '0 0 0 0';
                }),
        }),
        refwordZone: yup.string(),
        // refwordZone: yup.string().when('typeIsDynamic', {
        //     is: () => type === Types.Dynamic,
        //     then: (schema) => schema.trim()
        //         .required(t('validation.ocrTraining.refWordZone.required'))
        //         .test('refwordZoneIsEmpty', t('validation.refWordZone.required', { ns: 'ocrTraining' }), (value) => {
        //             return value !== '0 0 0 0';
        //         }),
        // }),
        sampleValue: yup.string().when('typeIsLikeValue', {
            is: () => (type !== Types.Empty && structure === Structure.LikeValue) || type === Types.Static,
            then: (schema) => schema.trim()
                .required(t('validation.sampleValue.required', { ns: 'ocrTraining' })),
        }),
        regEx: yup.string().when('structureIsRegex', {
            is: () => structure === Structure.Regex,
            then: (schema) => schema.trim().required(t('validation.regEx.required', { ns: 'ocrTraining' })),
        }),
        regExPart: yup.string(),
        isFreeText: yup.boolean(),
        allowMoveVertical: yup.boolean(),
        searchAllPages: yup.boolean(),
        isMultiLine: yup.boolean(),
        isGroupField: yup.boolean(),
        isAboveLine: yup.boolean(),
        isBelowLine: yup.boolean(),
        isKeyField: yup.boolean(),
        forceSampleValue: yup.boolean(),
    });
};

export default useCreateOcrTrainingFieldValidationSchema;
