import { useQueryClient } from '@tanstack/react-query';
import { useMsal } from '@azure/msal-react';
import { clearAccessToken } from '@repository/accessTokenRepository';
import log, { LogContext } from '@utils/logging';

export default function useLogout() {
    const { instance } = useMsal();
    const client = useQueryClient();

    const logout = () => {
        log({
            context: LogContext.User,
            title: 'Logged out',
            reason: 'The user has logged out',
        });

        instance.logoutPopup()
            .then(() => {
                clearAccessToken('User has logged out');
                client.removeQueries();
            });
    };

    return logout;
}
