import { FC } from 'react';
import FormRow from '@components/primitives/form/FormRow';
import FormInput from '@components/primitives/form/FormInput';
import { Sizes } from '@utils/sizes';
import Button from '@components/primitives/button/Button';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import Heading from '@components/primitives/heading/Heading';
import { local } from '@utils/storage';
import { StorageKeys } from '@constants/storageKeys';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { buildVersion } from '@constants/buildVersion';
import useAzureAccessToken from '@hooks/useAzureAccessToken';
import { useMsal } from '@azure/msal-react';
import useAuthenticate from '@components/pages/loginPage/hooks/useAuthenticate';

export type TenantFormType = {
    tenantId: string;
};

const createSchema = (): yup.ObjectSchema<TenantFormType> => yup.object().shape({
    tenantId: yup.string().trim().required(t('validation.tenantId.required', { ns: 'authentication' })),
});

const Tenant: FC = () => {
    const { t } = useTranslation();
    const schema = createSchema();
    const { accounts } = useMsal();
    const currentAccount = accounts[0];
    const { getAzureAccessToken } = useAzureAccessToken();
    const { mutate: authenticate, isPending: isAuthenticating } = useAuthenticate();

    const methods = useForm({
        resolver: yupResolver(schema),
        mode: 'onTouched',
        defaultValues: {
            tenantId: local.getItem(StorageKeys.TenantId) || '',
        },
    });

    const { handleSubmit } = methods;

    const onSubmit = async (data: TenantFormType) => {
        toast.dismiss();
        const azureToken = await getAzureAccessToken();

        if (!azureToken) {
            console.warn('Missing access token');
            return;
        }

        local.setItem(StorageKeys.TenantId, data.tenantId);
        authenticate({
            ...data,
            emailAddress: currentAccount.username,
            azureToken,
            clientVersion: buildVersion,
        });
    };

    return (
        <>
            <Heading className="loginBox__heading" size={Sizes.xxl}>{t('tenant.title', { ns: 'authentication' })}</Heading>
            <p className="loginBox__text">{t('tenant.text', { ns: 'authentication' })}</p><br />
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormRow name="tenantId">
                        <FormInput
                            size={Sizes.xl}
                            placeholder={t('login.tenantId', { ns: 'authentication' })}
                            type="text"
                            name="tenantId"
                            testId="login-tenant-input"
                        />
                    </FormRow>
                    <FormRow>
                        <Button
                            type="submit"
                            testId="login-submit-tenant"
                            disabled={isAuthenticating}
                            isLoading={isAuthenticating}
                            primary
                            size={Sizes.lg}
                        >
                            {t('login.button', { ns: 'authentication' })}
                        </Button>
                    </FormRow>
                </form>
            </FormProvider>
        </>
    );
};

export default Tenant;
