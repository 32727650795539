import { useAtom } from 'jotai';
import { orderLinesCaretAtom } from '@js/store/orderLines/orderLinesAtoms';

const useOrderLinesCaret = () => {
    const [orderLinesCaret, setOrderLinesCaret] = useAtom(orderLinesCaretAtom);

    const resetOrderLinesCaret = () => {
        if (!orderLinesCaret) {
            return;
        }

        setOrderLinesCaret(undefined);
    };

    return {
        orderLinesCaret,
        setOrderLinesCaret,
        resetOrderLinesCaret,
    };
};

export default useOrderLinesCaret;
