import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { DocumentOcrSummary } from '@models/DocumentOcrSummary';
import { fetchDocumentOcrSummary } from '@repository/documentOcrRepository';
import { minutesToMillis } from '@utils/millis';

const useFetchDocumentOcrSummary = (documentId: number): UseQueryResult<DocumentOcrSummary> => {
    return useQuery({
        queryKey: [QueryKey.DocumentOcrSummary, documentId],
        queryFn: ({ signal }) => fetchDocumentOcrSummary(documentId, signal),
        staleTime: minutesToMillis(2),
    });
};

export default useFetchDocumentOcrSummary;
