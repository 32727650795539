import { executeGetRequest, executePostRequest } from '@api/client';
import { createFetchLineSourcePath, createUpdateLineSourcePath } from '@routing/apiUrls';
import { LineSource, LineSourceResponse, UpdateLineSourceResponse } from '@models/LineSource';

export const fetchLineSource = async (documentId: number, signal?: AbortSignal): Promise<LineSourceResponse> => {
    const result = await executeGetRequest<LineSourceResponse>(createFetchLineSourcePath(documentId), signal);
    return result.data;
};

export const updateLineSource = async (documentId: number, lineSource: LineSource): Promise<UpdateLineSourceResponse> => {
    const result = await executePostRequest<UpdateLineSourceResponse>(createUpdateLineSourcePath(documentId), { lineSource });
    return result.data;
};
