import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { minutesToMillis } from '@utils/millis';
import { fetchLineSource } from '@repository/lineSourceRepository';
import { LineSourceResponse } from '@models/LineSource';
import useFetchDocumentCoding from '@components/features/coding/hooks/useFetchDocumentCoding';

const useFetchLineSource = (documentId: number): UseQueryResult<LineSourceResponse> => {
    const { data: documentCoding } = useFetchDocumentCoding(documentId);
    const enableFetch = !!(documentCoding && !documentCoding.meta.readOnly);

    return useQuery({
        queryKey: [QueryKey.LineSource, documentId],
        queryFn: enableFetch ? ({ signal }) => fetchLineSource(documentId, signal) : undefined,
        staleTime: minutesToMillis(10),
        enabled: enableFetch,
    });
};

export default useFetchLineSource;
