export enum DefaultActionId {
    Delete = 'Delete',
    Email = 'Email',
    Park = 'Park',
    AddPages = 'AddPages',
    Rotate = 'Rotate',
    MoreActions = 'MoreActions',
    BackToGroup = 'BackToGroup',
    History = 'History',
    Print = 'Print',
    EditDocument = 'EditDocument',
    SaveAsFile = 'SaveAsFile',
    OpenInBrowser = 'OpenInBrowser',
    AnnotationMode = 'AnnotationMode',
    RedPencil = 'RedPencil',
    OcrTraining = 'OcrTraining',
    OcrTrainingApply = 'OcrTrainingApply',
    OcrTrainingDelete = 'OcrTrainingDelete',
    OcrTrainingSql = 'OcrTrainingSql',
    OcrTrainingLog = 'OcrTrainingLog',
    TemplateSharing = 'TemplateSharing',
    ConfirmSharedTemplate = 'ConfirmSharedTemplate',
    DeclineSharedTemplate = 'DeclineSharedTemplate',
    SuperVisorActions = 'SuperVisorActions',
    TemplateSharingManagement = 'TemplateSharingManagement',
}

export type DefaultAction = {
    isHidden: boolean;
    id: DefaultActionId;
    enabled: boolean;
    remarkRequired: boolean;
};

export type DocumentActionUser = {
    id: string;
    name: string;
};

export type WorkflowAction = {
    id: string;
    title: string;
    enabled: boolean;
    askConfirmation: boolean;
    remarkEnabled: boolean;
    remarkRequired: boolean;
    showAsButton: boolean;
    userChoices: DocumentActionUser[];
    adviceRequestor: DocumentActionUser;
    useValidation: boolean;
    useDynamicAssign: boolean;
};

export type DocumentActions = {
    defaultActions: DefaultAction[];
    workflowActions: WorkflowAction[];
};
