export type OcrTrainingField = {
    fieldId: string;
    isKeyField: boolean;
    zone: string;
    page: number;
    refwordText: string;
    refwordZone: string;
    searchAllPages: boolean;
    allowMoveVertical: boolean;
    sampleValue: string;
    isFreeText: boolean;
    regEx: string;
    regExPart: string;
    isGroupField: boolean;
    isAboveLine: boolean;
    isBelowLine: boolean;
    isMultiLine: boolean;
    forceSampleValue: boolean;
};

export type OcrTraining = {
    disableTrainingForFields: string[];
    fields: OcrTrainingField[];
    postTrainingSQL: string;
    templateId: string;
    templateProfile: string;
    templateField: string;
    templateProfileField: string;
};

export enum OcrTainingContext {
    Headers = 'Headers',
    Coding = 'Coding',
    Matching = 'Matching',
}
export enum SelectingOcrTrainingFieldContext {
    Value = 'Value',
    NearByValue = 'NearByValue',
}

export type SelectingOcrTrainingField = {
    context: SelectingOcrTrainingFieldContext,
};

export type OcrTrainingCoding = {
    lineId: number;
    fieldId: string;
};

export type OcrTrainingMatching = {
    lineId: number;
    fieldId: string;
};

export type TrainingField = {
    label: string;
    field?: OcrTrainingField;
    fieldId: string;
    context: OcrTainingContext;
    coding?: OcrTrainingCoding;
    matching?: OcrTrainingMatching;
    format: 'text' | 'date' | 'decimal' | 'lookup' | 'select' | 'int';
};

// Delete Ocr Training Field
export type DeleteOcrTrainingFieldResponse = {};

// Create Ocr Training Field
export type CreateOcrTrainingFieldResponse = {};

export type UpdateOcrTrainingFieldResponse = {};

export type UpdateOcrTrainingSqlResponse = {};

export type DeleteOcrTrainingSqlResponse = {};

export type ApplyOcrTrainingResponse = {};

export type OcrTrainingFieldForm = {
    fieldId: string,
    isKeyField?: boolean,
    zone?: string,
    page?: number,
    refwordText?: string,
    refwordZone?: string,
    searchAllPages?: boolean,
    allowMoveVertical?: boolean,
    sampleValue?: string,
    isFreeText?: boolean,
    regEx?: string,
    regExPart?: string,
    isGroupField?: boolean,
    isAboveLine?: boolean,
    isBelowLine?: boolean,
    isMultiLine?: boolean,
    forceSampleValue?: boolean
};

// Update Ocr Training SQL
export type OcrTrainingSqlForm = {
    postTrainingSql?: string;
};

// Fetch Ocr Coding Training
export interface OcrCodingTraining {
    count: number;
    list: Array<string>
}

// Create Ocr Coding Training
export interface OcrCodingTrainingFieldValue {
    id: string;
    value: string | number;
}
export interface OcrCodingTrainingMutation {
    searchText: string;
    fieldValues: Array<OcrCodingTrainingFieldValue>;
}

export interface OcrCodingTrainingFormFieldValues {
    [key: string]: boolean;
}

export interface OcrCodingTrainingForm {
    searchText: string;
    fieldValues: OcrCodingTrainingFormFieldValues;
}
