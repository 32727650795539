import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { isEmail } from 'react-multi-email';
import { EmailDocumentForm } from '@models/EmailDocument';

const useEmailDocumentSchema = (): yup.ObjectSchema<EmailDocumentForm> => {
    const { t } = useTranslation();

    return yup.object().shape({
        toAddress: yup.string()
            .transform((value) => Array.from(new Set(value.split(';'))).join(';'))
            .required(t('email.validation.toAddress.required', { ns: 'documentActions' }))
            .test(
                'toAddress',
                t('email.validation.toAddress.invalid', { ns: 'documentActions' }),
                (value) => {
                    return !!value && value.split(';').every((value) => isEmail(value));
                },
            ),
        cc: yup.string(),
        bcc: yup.string(),
        subject: yup.string()
            .required(t('email.validation.subject.required', { ns: 'documentActions' })),
        body: yup.string()
            .required(t('email.validation.body.required', { ns: 'documentActions' })),
        withAnnotations: yup.boolean()
            .required(),
        azureToken: yup.string(),
    });
};

export default useEmailDocumentSchema;
