import { Params } from '@components/features/lookup/hooks/useLookup';
import { useQueryClient } from '@tanstack/react-query';
import { Lookup } from '@models/Lookup';
import { QueryKey } from '@constants/queryKey';

const useGetLookupOptionsFromCache = () => {
    const client = useQueryClient();
    const queryCache = client.getQueryCache();

    const getLookupOptionsFromCache = (lookup: string | null, params: Params) => {
        const lookupCache = queryCache.find<Lookup>({
            queryKey: [QueryKey.Lookup, lookup, params],
        });
        return lookupCache?.state?.data;
    };

    return {
        getLookupOptionsFromCache,
    };
};

export default useGetLookupOptionsFromCache;
