import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { fetchFileRepaired } from '@repository/fileRepository';
import { File } from '@models/File';
import { minutesToMillis } from '@utils/millis';

const useFetchDocumentFileRepaired = (documentId: number): UseQueryResult<File> => {
    return useQuery({
        queryKey: [QueryKey.FileRepaired, documentId],
        queryFn: ({ signal }) => fetchFileRepaired(documentId, signal),
        staleTime: minutesToMillis(30),
    });
};

export default useFetchDocumentFileRepaired;
