import ModalContent from '@components/primitives/modal/components/ModalContent';
import ModalFooter from '@components/primitives/modal/components/ModalFooter';
import Button from '@components/primitives/button/Button';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import Text from '@components/primitives/text/Text';
import Message from '@components/primitives/message/Message';
import { closedModalState } from '@js/store/modalAtoms';
import { useSetAtom } from 'jotai';
import { textModalAtom, TextModalData } from '@js/store/modals/textModalAtom';

interface Props {
    modalData: TextModalData;
}

const TextModalInner: FC<Props> = ({ modalData }) => {
    const { text, isError } = modalData;
    const setModalState = useSetAtom(textModalAtom);
    const { t } = useTranslation();

    const closeModal = () => {
        setModalState(closedModalState);
    };

    return (
        <>
            <ModalContent>
                {isError ? (
                    <Message isError>
                        <Text text={text} />
                    </Message>
                ) : (
                    <Text text={text} />
                )}
            </ModalContent>
            <ModalFooter>
                <Button type="submit" onClick={closeModal} primary>{t('close')}</Button>
            </ModalFooter>
        </>
    );
};

export default TextModalInner;
