import stringToObject from '@utils/stringToObject';
import { Params } from '@components/features/lookup/hooks/useLookup';
import useDocumentHeaders from '@components/features/documentHeaders/hooks/useDocumentHeaders';
import useDocumentCoding from '@components/features/coding/hooks/useDocumentCoding';

const useLookupParams = (documentId: number) => {
    const { getHeaderValue } = useDocumentHeaders(documentId);
    const { getCodingLineFieldValue } = useDocumentCoding(documentId);
    const getLookupParams = (lookup: string | null, lineId?: number): Params => {
        const lookupFormat = lookup && lookup.indexOf('?') > -1 ? lookup?.split('?')[1] : undefined;

        const headerValues = Object.entries(stringToObject(lookupFormat)).reduce((result, [key]) => {
            const value = getHeaderValue(key);
            // const value = getHeaderValue(documentId, key);
            if (!value) {
                return result;
            }

            return {
                ...result,
                [key]: value,
            };
        }, {});

        const codingLineValues = Object.entries(stringToObject(lookupFormat)).reduce((result, [key]) => {
            if (lineId === undefined) {
                return result;
            }

            const value = getCodingLineFieldValue(lineId, key);

            if (value === null) {
                return result;
            }

            return {
                ...result,
                [key]: value,
            };
        }, {});

        return {
            ...headerValues,
            ...codingLineValues,
        };
    };

    return {
        getLookupParams,
    };
};

export default useLookupParams;
