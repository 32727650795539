import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { fetchDocumentCoding } from '@repository/documentCodingRepository';
import { DocumentCoding } from '@models/DocumentCoding';
import useFetchDocument from '@hooks/useFetchDocument';
import useDocumentStatus from '@hooks/useDocumentStatus';
import { minutesToMillis } from '@utils/millis';

const useFetchDocumentCoding = (documentId?: number, disableCodingEnabledCheck?: boolean): UseQueryResult<DocumentCoding> => {
    const { isEditMode } = useDocumentStatus();
    const { data: document } = useFetchDocument(disableCodingEnabledCheck ? undefined : documentId, isEditMode);

    return useQuery({
        queryKey: [QueryKey.DocumentCoding, documentId],
        queryFn: documentId ? ({ signal }) => fetchDocumentCoding(documentId, signal) : undefined,
        staleTime: minutesToMillis(2),
        enabled: !!documentId && (disableCodingEnabledCheck ? true : (document?.meta.codingEnabled)),
    });
};

export default useFetchDocumentCoding;
