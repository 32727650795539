import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '@constants/queryKey';
import { addDocumentCodingLine } from '@repository/documentCodingRepository';
import { CodingLineForm } from '@models/DocumentCoding';

const useCreateCodingLine = (documentId: number) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: (data: CodingLineForm) => addDocumentCodingLine(documentId, data),
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [QueryKey.DocumentCoding, documentId],
            });
        },
    });
};

export default useCreateCodingLine;
