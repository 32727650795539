import { executeGetRequest } from '@api/client';
import { createFetchDocumentOcrDataPath, createFetchDocumentOcrSummaryPath } from '@routing/apiUrls';
import { DocumentOcrSummary } from '@models/DocumentOcrSummary';
import { DocumentOcrData } from '@models/DocumentOcrData';

export const fetchDocumentOcrData = async (documentId: number, page: number, signal?: AbortSignal): Promise<DocumentOcrData> => {
    const result = await executeGetRequest<DocumentOcrData>(createFetchDocumentOcrDataPath(documentId, page), signal);
    return result.data;
};

export const fetchDocumentOcrSummary = async (documentId: number, signal?: AbortSignal): Promise<DocumentOcrSummary> => {
    const result = await executeGetRequest<DocumentOcrSummary>(createFetchDocumentOcrSummaryPath(documentId), signal);
    return result.data;
};
