import { useAtom } from 'jotai';
import { invoiceLinesCaretAtom } from '@js/store/caretAtoms';

const useInvoiceLinesCaret = () => {
    const [invoiceLinesCaret, setInvoiceLinesCaret] = useAtom(invoiceLinesCaretAtom);

    const resetInvoiceLinesCaret = () => {
        if (!invoiceLinesCaret) {
            return;
        }
        setInvoiceLinesCaret(undefined);
    };

    return {
        invoiceLinesCaret,
        setInvoiceLinesCaret,
        resetInvoiceLinesCaret,
    };
};

export default useInvoiceLinesCaret;
