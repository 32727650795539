import FormRow from '@components/primitives/form/FormRow';
import PageContentBlock from '@components/primitives/pageContent/PageContentBlock';
import { apiBaseUrl } from '@api/client';
import { createEwsAuthenticatePath } from '@routing/apiUrls';
import { PagePath } from '@routing/appUrls';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useFetchCurrentUser from '@components/features/currentUser/hooks/useFetchCurrentUser';
import useAsyncConfirmModal from '@components/modals/asyncConfirmModal/hooks/useAsyncConfirmModal';
import useDeleteImportProfile from '@hooks/useDeleteImportProfile';
import useDuplicateImportProfile from '@hooks/useDuplicateImportProfile';
import useFetchEwsFolders from '@hooks/useFetchEwsFolders';
import useFetchImportProfiles from '@hooks/useFetchImportProfiles';
import useUpdateImportProfile from '@hooks/useUpdateImportProfile';
import { ewsEmailAtom } from '@js/store/importProfiles/ewsEmailAtom';
import { useAtom } from 'jotai';
import Button from '@components/primitives/button/Button';
import { useNavigate } from 'react-router-dom';
import { ImportProfile } from '@models/ImportProfiles';
import Toggle from '../../Toggle';
import FolderSelect, { FolderType } from './FolderSelect';

interface ImportProfileFormProps {
    importProfileId: string;
    importProfile: ImportProfile;
    isLoadingProfile: boolean;
}

enum FolderKey {
    SourceFolder = 'mailFolder',
    ErrorFolder = 'errorFolder',
    ArchiveFolder = 'archiveFolder',
}

const ImportProfileForm = ({ importProfileId, importProfile, isLoadingProfile }: ImportProfileFormProps) => {
    const navigate = useNavigate();
    const [ewsEmail, setEwsEmail] = useAtom(ewsEmailAtom);
    const [currentProfile, setCurrentProfile] = useState<ImportProfile>();
    const { mutateAsync: updateImportProfile, isPending: isUpdatingProfile } = useUpdateImportProfile();
    const { mutateAsync: deleteImportProfile, isPending: isDeletingProfile } = useDeleteImportProfile();
    const { mutateAsync: duplicateImportProfile, isPending: isDuplicatingProfile } = useDuplicateImportProfile();
    const { data: foldersData, isLoading: isFetchingFolders } = useFetchEwsFolders(ewsEmail || currentProfile?.userId);
    const folders = foldersData?.subfolders || [];
    const { refetch: refetchImportProfiles } = useFetchImportProfiles();

    const { data: user } = useFetchCurrentUser();
    const { asyncConfirm } = useAsyncConfirmModal();
    const handleFormChange = (e: any) => {
        const { name, value } = e.target;
        setCurrentProfile({
            ...currentProfile,
            [name]: value,
        });
    };

    const setFolder = ({ folder, folderType }) => {
        setCurrentProfile({
            ...currentProfile,
            [folderType]: {
                id: folder.id,
                name: folder.name,
                path: folder.path,
            },
        });
    };
    const setSourceFolder = (folder: Folder) => setFolder({ folder, folderType: FolderKey.SourceFolder });
    const setErrorFolder = (folder: Folder) => setFolder({ folder, folderType: FolderKey.ErrorFolder });
    const setArchiveFolder = (folder: Folder) => setFolder({ folder, folderType: FolderKey.ArchiveFolder });
    const setIsActive = () => setCurrentProfile({ ...currentProfile, isActive: !currentProfile?.isActive });

    const handleDuplicateProfile = async () => {
        try {
            const newProfile = {
                ...currentProfile,
                name: `${currentProfile?.name} (kopie)`,
            };
            const duplicatedProfile = await duplicateImportProfile(newProfile);
            if (duplicatedProfile) {
                await refetchImportProfiles();
                setCurrentProfile(duplicatedProfile);
            }
            toast.success(`${currentProfile?.name} gedupliceerd`);
        } catch (e) {
            toast.error('Er is iets misgegaan bij het dupliceren van het profiel');
        }
    };

    const handleSaveProfile = async () => {
        try {
            const updatedProfile = await updateImportProfile({
                id: importProfileId,
                importProfile: currentProfile,
            });
            await refetchImportProfiles();
            setCurrentProfile(updatedProfile);
            toast.success(`${currentProfile?.name} opgeslagen`);
        } catch (e) {
            toast.error('Er is iets misgegaan bij het opslaan van het profiel');
        }
    };

    const handleDeleteProfile = async (e) => {
        const confirmed = await asyncConfirm({
            title: 'Verwijderen',
            message: 'Weet u zeker dat u dit profiel wilt verwijderen?',
        });
        if (!confirmed) return;
        try {
            e.preventDefault();
            await deleteImportProfile(importProfileId);
            await refetchImportProfiles();
            setCurrentProfile(undefined);
            navigate(PagePath.settingsImportProfiles);
            toast.success(`${currentProfile?.name} verwijderd`);
        } catch (e) {
            toast.error('Er is iets misgegaan bij het verwijderen van het profiel');
        }
    };

    const handleAuthenticate = async (e) => {
        e.preventDefault();
        if (!user) return;
        if (currentProfile?.userId) {
            const confirmed = await asyncConfirm({
                title: 'Opnieuw instellen',
                message: 'Weet u zeker dat u het e-mailadres wilt wijzigen? Het huidige account zal ontkoppeld worden.',
            });
            if (!confirmed) return;
        }
        setEwsEmail('');
        setCurrentProfile({
            ...currentProfile,
            userId: null,
            mailFolder: null,
            archiveFolder: null,
            errorFolder: null,
        });
        const callbackUrl = window.origin + PagePath.ewsCallback;
        const apiUrl = createEwsAuthenticatePath(user.tenantId, callbackUrl);
        authWindow = window.open(apiBaseUrl + apiUrl, 'Authentication Sign In', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=800,height=1024');
    };

    useEffect(() => {
        setCurrentProfile({
            ...currentProfile,
            userId: ewsEmail,
        });
    }, [ewsEmail]);

    useEffect(() => {
        if (isLoadingProfile) return;
        setCurrentProfile(importProfile);
    }, [importProfileId, isLoadingProfile]);

    return (
        <form onChange={handleFormChange}>
            <PageContentBlock title="Algemene gegevens">
                <FormRow
                    name="name"
                    label="Profielnaam"
                    description="Geef een naam op voor dit profiel (ter interne referentie)."
                >
                    <div className="form__field form__field--input items-center">
                        <textarea
                            className="form__input"
                            name="name"
                            style={{ resize: 'none' }}
                            rows={3}
                            data-lpignore="true"
                            autoComplete="off"
                            defaultValue={currentProfile?.name}
                            maxLength={130}
                        />
                    </div>
                </FormRow>

                <FormRow
                    name="isActive"
                    label="Actief"
                    className=""
                >
                    <div className="form__field form__field--input">
                        <Toggle
                            name="isActive"
                            defaultChecked={currentProfile?.isActive}
                            onChange={setIsActive}
                        />
                    </div>
                </FormRow>
            </PageContentBlock>

            <PageContentBlock title="Mappen">
                <FormRow
                    name="email"
                    label="E-mailadres"
                    description="Het account waarmee u inlogt op de Exchange server. De bronmappen kunt u hieronder instellen."
                >
                    <div className="form__field w-full py-4 text-xl border-2 border-gray-300 rounded-lg flex flex-col items-start justify-center relative">
                        {currentProfile?.userId && (
                            <span className="w-full">
                                {currentProfile?.userId}
                            </span>
                        )}
                        <Button
                            secondary
                            type="submit"
                            className="!mt-4"
                            onClick={handleAuthenticate}
                        >
                            {
                                (!currentProfile?.userId && !ewsEmail)
                                    ? 'Login met Microsoft'
                                    : 'Opnieuw instellen'
                            }
                        </Button>
                    </div>
                </FormRow>

                <FormRow
                    name="sourceFolder"
                    label="Bronmap"
                >
                    <FolderSelect
                        folderType={FolderType.SourceFolder}
                        folders={folders}
                        isLoading={isFetchingFolders}
                        selectedFolder={currentProfile?.mailFolder}
                        onSelect={(folder) => setSourceFolder(folder)}
                    />
                </FormRow>
                <FormRow
                    name="errorFolder"
                    label="Errormap"
                >
                    <FolderSelect
                        folderType={FolderType.ErrorFolder}
                        folders={folders}
                        isLoading={isFetchingFolders}
                        selectedFolder={currentProfile?.errorFolder}
                        onSelect={(folder) => setErrorFolder(folder)}
                    />
                </FormRow>
                <FormRow
                    name="archiveFolder"
                    label="Archiefmap"
                >
                    <FolderSelect
                        folderType={FolderType.ArchiveFolder}
                        folders={folders}
                        isLoading={isFetchingFolders}
                        selectedFolder={currentProfile?.archiveFolder}
                        onSelect={(folder) => setArchiveFolder(folder)}
                    />
                </FormRow>
            </PageContentBlock>
            <PageContentBlock>
                <div className="flex flex-row items-center justify-between w-full">
                    <div className="flex flex-row items-center">
                        <Button
                            primary
                            onClick={handleSaveProfile}
                            isLoading={isUpdatingProfile}
                            disabled={!currentProfile || isUpdatingProfile}
                        >
                            Profiel opslaan
                        </Button>
                        <Button
                            isLoading={isDuplicatingProfile}
                            secondary
                            onClick={handleDuplicateProfile}
                            disabled={isDuplicatingProfile || !currentProfile}
                        >
                            Dupliceer profiel
                        </Button>
                    </div>
                    <Button
                        tertiary
                        isLoading={isDeletingProfile}
                        onClick={handleDeleteProfile}
                        disabled={!currentProfile?.id || isDeletingProfile}
                    >
                        Profiel verwijderen
                    </Button>
                </div>
            </PageContentBlock>

            <pre>
                currentProfile: {JSON.stringify(currentProfile, null, 2)}
            </pre>

        </form>
    );
};
export default ImportProfileForm;
